import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { ProjectService } from 'src/app/services/project.service';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
import { Request, Cfdi } from 'src/app/models/request';
import { RequestService } from 'src/app/services/request.service';
import * as moment from 'moment';
import { XmlParser } from '@angular/compiler';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-consult-deal-memo',
  templateUrl: './consult-deal-memo.component.html',
  styleUrls: ['./consult-deal-memo.component.css'],
})
export class ConsultDealMemoComponent implements OnInit, OnDestroy {
  dealSelect;
  idCompany: any;
  idProject: any;
  consultaDealMemo;
  reporteDM = new Array();
  ev = new EventEmitter();
  indexCtaActual;
  fechaActual: Date;
  periods = [] as any;
  periodoActual = {} as any;
  allDealMemos = new Array();
  dealMemos = [] as any;
  selectAll: boolean;
  CFDIs = [];
  countRequest = {} as any;
  dataExtra = {} as any;
  matchs = [] as any;
  arrXML = [];
  arrCountRequest = [] as any;

  solicitudSeleccionada = [] as any;

  subscriberDelMemo: Subscription;
  subscriberPeriods: Subscription;
  subscriberCountRequest: Subscription;
  subscriberGetXML: Subscription;

  constructor(
    private router: Router,
    private dealMemoService: DealMemoService,
    private projectService: ProjectService,
    private requestService: RequestService
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.fechaActual = new Date();
    this.getPeriods();
    this.getCountRequest();
    this.getXML();
  }

  getPeriods() {
    this.subscriberPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.periods.forEach(element => {
          const fechaInicio = new Date(element.fechaInicio + 'T00:00:00');
          const fechaFin = new Date(element.fechaFin + 'T23:59:59');
          if (this.fechaActual >= fechaInicio && this.fechaActual <= fechaFin) {
            this.periodoActual = element;
            this.pagosAVencer();
          }
        });
      });
  }

  getCountRequest() {
    this.subscriberCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        console.log('CountRequest', res);
        if (res === undefined) {
          this.countRequest = 1;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
          for (let index = 0; index < res.countRequest.length; index++) {
            const element = res.countRequest[index];
            console.log(index + 1, element);
            if (element !== index + 1) {
              this.countRequest = res.countRequest[index - 1] + 1;
              break;
            }
          }
          console.log(this.countRequest);
        }
      });
  }

  getXML() {
    this.subscriberGetXML = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        console.log(res);
        this.arrXML = res.arrXML;
      });
  }

  pagosAVencer() {
    this.reporteDM = [];
    this.subscriberDelMemo = this.dealMemoService
      .reporteDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        this.allDealMemos = res;
        this.dealMemos = res.sort((a, b) => {
          return a.countDealMemo - b.countDealMemo;
        });
        this.filterDealMemos();
        this.subscriberDelMemo.unsubscribe();
      });
  }

  filterDealMemos() {
    this.reporteDM = [];
    this.dealMemos.forEach(obj => {
      console.log(obj);
      obj.pagos.forEach((elementPagos, i) => {
        if (
          elementPagos.periodo === this.periodoActual.nombrePeriodo &&
          elementPagos.pagado !== true &&
          !elementPagos.estatus
        ) {
          const numeroCuenta = obj.proveedor.toString().padStart(3, '0');
          const proveedor =
            obj.nacionalidad === 'Mexicana'
              ? '201-01-' + numeroCuenta
              : '201-02-' + numeroCuenta;
          const request: Request = {
            // countRequest: 0,
            numProvider: obj.proveedor,
            providerName: obj.nombreProveedor,
            rfcProvider: obj.rfcProveedor,
            dataExtra: {
              numberPay: i,
            },
            concepto:
              `Pago ${i + 1} de ${obj.pagos.length} - ` +
              moment(elementPagos.fechaInicio).format('L') +
              ' a ' +
              moment(elementPagos.fechaFin).format('L'),
            fechaCreado: new Date(),
            fechaIni: elementPagos.fechaInicio,
            fechaFin: elementPagos.fechaFin,
            tipoPago: 'Transferencia',
            // idDepartament: obj.idDepartament,
            // nameDepartament: obj.nameDepartament,
            pagoSoli: elementPagos.fechaFin,
            solicito: obj.solicito,
            tipoSolicitud: 'Directa',
            // folioComp: '',
            // fechaComp: '',
            periodo: this.periodoActual.nombrePeriodo,
            etapa: this.periodoActual.etapa,
            departamento: obj.departamento,
            puesto: obj.puesto,
            movimientos: [
              {
                Cargo: elementPagos.importe,
                Cuenta: elementPagos.cuentaPres,
                NombreCuenta: elementPagos.Descripcion,
              },
              {
                Cargo: 0,
                Cuenta: '119-01-001',
                NombreCuenta: 'IVA',
              },
              {
                Abono: 0,
                Cuenta: '216-06-001',
                NombreCuenta: 'Retencion de ISR',
              },
              {
                Abono: 0,
                Cuenta: '216-10-001',
                NombreCuenta: 'Retencion de IVA',
              },
              {
                Abono: 0,
                Cuenta: proveedor,
                NombreCuenta: obj.nombreProveedor,
              },
            ],
            tipoMoneda: obj.tipoMoneda,
            tipoCambio: obj.tipoCambio,
            estatus: false,
            comprobado: 0,
            numDealMemo: obj.countDealMemo,
            idDealMemo: obj.id,
            aprobada: false,
            numTransferencia: '',
            matchs: [],
          };
          // obj.dataActual = elementPagos;
          this.reporteDM.push(request);
        }
      });
    });
    console.log(this.reporteDM);
  }

  dealSeleccionado(deal) {
    console.log(deal);
    $('#modalXML').modal('show');
    this.solicitudSeleccionada = deal;
  }

  cfdiSelect(xml) {
    this.solicitudSeleccionada.folioComp = xml.folioComprobante;
    this.solicitudSeleccionada.fechaComp = xml.fecha;
    this.solicitudSeleccionada.movimientos[1].Cargo = xml.iva || 0;
    this.solicitudSeleccionada.movimientos[2].Abono = xml.retISR || 0;
    this.solicitudSeleccionada.movimientos[3].Abono = xml.retIVA || 0;
    this.solicitudSeleccionada.movimientos[4].Abono = xml.total || 0;
    $('#modalXML').modal('hide');
  }

  matchCFDI() {
    let elementsNumber = 0;
    this.reporteDM.forEach(request => {
      request.matchs = [];
      for (let index = 0; index < this.arrXML.length; index++) {
        const XML = this.arrXML[index];
        if (
          request.rfcProvider === XML.rfc &&
          request.movimientos[0].Cargo === XML.subtotal &&
          !XML.asociado
        ) {
          request.matchs.push(XML);

          // XML.asociado = true;
          // elementsNumber++;
          // request.folioComp = XML.folioComprobante;
          // request.fechaComp = XML.fecha;
          // request.movimientos[1].Cargo = XML.iva || 0;
          // request.movimientos[2].Abono = XML.retISR || 0;
          // request.movimientos[3].Abono = XML.retIVA || 0;
          // request.movimientos[4].Abono = XML.total || 0;
          // break;
        }
      }

      if (request.matchs.length === 1) {
        elementsNumber++;
        request.folioComp = request.matchs[0].folioComprobante;
        request.fechaComp = request.matchs[0].fecha;
        request.movimientos[1].Cargo = request.matchs[0].iva || 0;
        request.movimientos[2].Abono = request.matchs[0].retISR || 0;
        request.movimientos[3].Abono = request.matchs[0].retIVA || 0;
        request.movimientos[4].Abono = request.matchs[0].total || 0;
        // delete request.matchs;
      }
    });
    // Notiflix.Notify.Success(
    //   `${elementsNumber} ${elementsNumber > 1 ? 'Asociados' : 'Asociado'}`
    // );
  }

  // numeroAFecha(numeroDeDias) {
  //   var diasDesde1900 = true ? 25567 + 1 : 25567;
  //   // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
  //   return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
  // }

  confirmGenerateRequest() {
    console.log(this.reporteDM);
    let suma = 0;
    this.reporteDM.forEach(element => {
      if (element.folioComp) {
        suma++;
      }
    });
    Notiflix.Confirm.Show(
      'Confirmacion',
      `Se generaran ${suma} solicitudes`,
      'Confirmar',
      'Cancelar',
      () => {
        console.log('Ok');
        this.generatedRequest();
      },
      () => {
        console.log('No Ok');
      }
    );
  }

  generatedRequest() {
    // guardar solicitud y dealmemo
    let validation = false;
    this.reporteDM.forEach(request => {
      if (request.folioComp) {
        validation = true;
        delete request.matchs;
        request.countRequest = this.countRequest;
        this.arrCountRequest.push(this.countRequest);
        this.countRequest++;
        this.saveRequest(request);
        this.updateDealMemo(request);
        this.updateXML(request);
      }
    });

    if (validation) {
      this.updateCountRequest();
      this.saveXML();
      this.pagosAVencer();
    }

    Notiflix.Notify.Success('Se guardo correctamente');

    // actualizar XML

    // actualizar countRequest
  }

  saveRequest(request) {
    console.log(request);
    this.requestService.addRequest(this.idCompany, this.idProject, request);
  }

  updateDealMemo(request: Request) {
    for (let index = 0; index < this.allDealMemos.length; index++) {
      const deal = this.allDealMemos[index];
      if (request.idDealMemo === deal.id) {
        deal.pagos[request.dataExtra.numberPay].numberSol =
          request.countRequest;
        deal.pagos[request.dataExtra.numberPay].estatus = true;
        deal.pagos[request.dataExtra.numberPay].asociado = true;
        console.log(deal);
        this.dealMemoService.updateDealMemo2(
          this.idCompany,
          this.idProject,
          request.idDealMemo,
          deal
        );
      }
    }
  }

  updateCountRequest() {
    console.log(this.countRequest);
    this.requestService.countRequest(
      this.idCompany,
      this.idProject,
      this.arrCountRequest
    );
  }

  updateXML(request) {
    for (let index = 0; index < this.arrXML.length; index++) {
      const XML = this.arrXML[index];
      if (XML.folioComprobante === request.folioComp) {
        XML.asociado = true;
        XML.request = request.countRequest;
      }
    }
  }

  saveXML() {
    const obj = {} as any;
    obj.arrXML = this.arrXML;
    this.projectService.saveXML(this.idCompany, this.idProject, obj);
  }

  ngOnDestroy() {
    if (this.subscriberDelMemo) {
      this.subscriberDelMemo.unsubscribe();
    }
    this.subscriberPeriods.unsubscribe();
    this.subscriberGetXML.unsubscribe();
    this.subscriberCountRequest.unsubscribe();
  }
}
