import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { PurchaseOrder1Component } from '../purchase-order1/purchase-order1.component';
import { PurchaseOrder } from 'src/app/models/purchaseOrder';

declare var $;

@Component({
  selector: 'app-consult-po1',
  templateUrl: './consult-po1.component.html',
  styleUrls: ['./consult-po1.component.css'],
})
export class ConsultPO1Component implements OnInit {
  @ViewChild(PurchaseOrder1Component) purchaseOrder: PurchaseOrder1Component;

  idCompany: string;
  idProject: string;
  arrPurchaseOrder = [] as any;
  po = {
    comprometidos: [] as any,
    cuentas: [] as any,
  } as PurchaseOrder;
  conCalendario: string;
  objPo;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;

  constructor(
    private dealMemoService: DealMemoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);

    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;

    this.getPurchaseOrder();
  }

  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);

        this.arrPurchaseOrder = res.sort((a, b) => {
          return a.orderCounter - b.orderCounter;
        });
      });
  }

  ordenEdit(obj) {
    $('#editPurchaseOrder').modal('show');
    console.log('ORDEN SELECCIONADA', obj);

    this.purchaseOrder.po = {} as any;
    this.purchaseOrder.po = obj;
    this.objPo = this.clonar(obj);
  }

  clonar(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  updatePo() {
    console.log(this.po);
    this.purchaseOrder.editOrder(this.objPo);
    $('#editPurchaseOrder').modal('hide');
  }
}
