import { Component, OnInit, OnDestroy } from '@angular/core';
import { Provider } from 'src/app/models/providers';
import { AuthService } from 'src/app/services/auth.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { ProjectService } from 'src/app/services/project.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { BudgetService } from 'src/app/services/budget.service';
import { RequestService } from 'src/app/services/request.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Request } from 'src/app/models/request';
import { Router } from '@angular/router';
import { Budget } from 'src/app/models/budget';
import { Period } from 'src/app/models/period';
import { Subscription } from 'rxjs';
import { NgxXml2jsonService } from 'ngx-xml2json';
import Notiflix from 'notiflix-angular';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css'],
})
export class RequestComponent implements OnInit, OnDestroy {
  isAdmin = false;
  xml;
  providers: any[];
  providersSelect: any[];
  proveedorSelecionado = {} as Provider;
  datosProveedor: any = {} as Provider;
  request = {
    tipoPago: 'Transferencia',
    tipoSolicitud: 'Directa',
    tipoMoneda: 'MXN',
    estatus: false,
    aprobada: false,
  } as Request;
  idCompany: any;
  idProject: any;
  isDealMemo: any;
  idBudget: any;
  valor = {} as any;

  budget: any = [] as Budget;
  ctasPresupuesto;
  periods = [];
  periodoActual = {} as Period;
  countRequest = {} as Request;
  departament;
  departamento;
  puestos;
  puesto;
  parPres: any;
  objPres;
  binding: any;
  acumuladoIVA;
  valSol = [] as any;
  valObj = {} as any;
  valTotales = {} as any;
  moneda;
  catCtas;
  CtasAfectables = [] as any;
  editVal = {} as any;
  deleteIndex;
  project = {} as any;
  tipoDeSolicitud = true;
  operacionesFiscales = {} as any;
  objDealMemo;
  indexCtaDeal;
  folios = [] as any;
  budgets = [];
  acumulados = [] as any;

  budgetArrays = [] as any;

  subscriberProviders: Subscription;
  subscriberBudget: Subscription;
  subscriberCatCtas: Subscription;
  subscribeProject: Subscription;
  subscribeCountRequest: Subscription;
  subscribePeriods: Subscription;
  subscribeGetFolios: Subscription;
  subscribeGetIva: Subscription;
  subscribeGetAcumulado: Subscription;

  constructor(
    private authService: AuthService,
    private providersService: ProvidersService,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private requestService: RequestService,
    private dealMemoService: DealMemoService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService
  ) {
    this.getAdmin();
  }

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isDealMemo = url.root.children.primary.segments[4].path;
    this.getCount();
    this.getProvider();
    this.getBudget();
    this.getPeriods();
    this.getCatCtas();
    this.getProject();
    this.getFoliosFiscales();
    // this.getAcumuladoIVA();
    // this.getAcumulado();
  }
  getAdmin() {
    this.authService.userData$.subscribe(res => {
      this.authService.roleUser(res.uid).subscribe(resAdmin => {
        const user: any = resAdmin;
        if (user[0].userType === 'Administrador') {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      });
    });
  }
  getFoliosFiscales() {
    this.subscribeGetFolios = this.projectService
      .getFoliosFiscales(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res === undefined) {
        } else {
          const folios: any = res;
          this.folios = folios.folios;
        }
      });
  }
  getReporDeal(event) {
    this.objDealMemo = event;
    const fechaActual = new Date();
    const arr = event.ctas;
    this.valObj = {};
    this.valSol = [];
    this.request.numDealMemo = event.countDealMemo;

    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (
        element.Descripcion === event.departamento &&
        element.Total_Estimado
      ) {
        this.departamento = element;
        this.getPuesto();
      }
      if (
        element.Descripcion === event.puesto &&
        element.Subtotal_Estimado >= 0
      ) {
        this.puesto = element;
        this.getCtaPres();
        break;
      }
    }

    let datoImporte = 0;
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      const fechaInicio = new Date(element.fechaIni + 'T00:00:00');
      const fechaFin = new Date(element.fechaFin + 'T23:59:59');
      if (fechaInicio <= fechaActual && fechaFin >= fechaActual) {
        this.indexCtaDeal = index;
        datoImporte = element.importe;
        this.valor.Cuenta = element.cuentaPres;
        this.valor.importe = element.importe;
        break;
      }
    }

    (<any>document.getElementById('selectProvider')).value = event.proveedor;
    this.changeSelectProvider();
    this.request.concepto = event.descripcionActividad;
    this.operacionesFiscales = this.projectService.operaciones(
      this.datosProveedor.regimen,
      datoImporte
    );
    this.operaciones();
    this.valores();
  }
  operaciones() {
    const operacion = this.projectService.operaciones(
      this.datosProveedor.regimen,
      this.valor.importe
    );
    if (this.datosProveedor.regimen == 0) {
      this.valor.subtotal = this.valor.importe + this.valor.iva;
      this.valor.total =
        this.valor.subtotal - this.valor.retIva - this.valor.retIsr;
    } else if (this.datosProveedor.regimen == 611) {
      this.valor.iva = 0;
      this.valor.retIva = 0;
      this.valor.subtotal = this.valor.importe;
      this.valor.total = this.valor.subtotal - this.valor.retIsr;
    } else {
      this.valor.iva = operacion.iva;
      this.valor.subtotal = operacion.subtotal;
      this.valor.retIva = operacion.retIva;
      this.valor.retIsr = operacion.retIsr;
      this.valor.total = operacion.total;
    }
  }
  getProvider() {
    this.subscriberProviders = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }
  getProject() {
    this.subscribeProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.request.tipoMoneda = this.project.monedaLocal;
        this.request.tipoCambio = this.project.tipoCambioGral;
      });
  }
  getCatCtas() {
    this.subscriberCatCtas = this.projectService
      .getCatCtas(this.idCompany, this.idProject)
      .subscribe(res => {
        this.catCtas = Object.assign([], res);
      });
  }
  joinCtas() {
    let counter = 0;
    for (let index = 0; index < this.catCtas.length; index++) {
      const elementCatCtas = this.catCtas[index];
      if (elementCatCtas.CodigoAgrupador === '115-01') {
        counter = index;
        this.ctasPresupuesto.forEach(elementBudget => {
          counter += 1;
          const objBudget = {} as any;
          const segmentos = elementBudget.CodigoAgrupador.split('-');
          objBudget.Nivel = elementCatCtas.Nivel + segmentos.length;
          objBudget.CodigoAgrupador =
            elementCatCtas.CodigoAgrupador +
            '-' +
            elementBudget.CodigoAgrupador;
          objBudget.NombreCuenta = elementBudget.Descripcion;
          objBudget.CodigoAgrupadorSAT = elementCatCtas.CodigoAgrupadorSAT;
          if (segmentos.length === 3) {
            objBudget.Afectable = 'afectable';
          } else {
            objBudget.Afectable = 'noAfectable';
          }
          this.catCtas.splice(counter, 0, objBudget);
        });
      }
    }
    this.listCatCtas();
  }
  listCatCtas() {
    const arr = [];
    this.catCtas.forEach(element => {
      if (element.Afectable === 'afectable') {
        arr.push(element);
      }
    });
    this.CtasAfectables = arr;
    this.asignarNombreCuenta();
  }
  asignarNombreCuenta() {
    this.valSol.forEach(objValSol => {
      this.CtasAfectables.forEach(element => {
        if (objValSol.Cuenta === element.CodigoAgrupador) {
          objValSol.NombreCuenta = element.NombreCuenta;
        }
      });
    });
  }
  // getBudget() {
  //   this.subscriberBudget = this.budgetService
  //     .getBudget(this.idCompany, this.idProject)
  //     .subscribe(res => {
  //       this.budgets = res;
  //       const obj = this.budgetService.joinBudget(res);
  //       this.budget = obj.budget;
  //       this.idBudget = obj.id;
  //       this.getDepartament();
  //     });
  // }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.budget = arrBudget; // Este es el array que mostramos en la tablaas -->>
        }
      });
  }
  getDepartament() {
    this.departament = this.filterBudget('Total_Estimado');
  }
  getPuesto() {
    this.request.departamento = this.departamento.Descripcion;
    this.puestos = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }
  getCtaPres() {
    this.request.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
    }
    // this.joinCtas();
  }
  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }
  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element: Period = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        this.request.fechaIni = element.fechaInicio;
        this.request.fechaFin = element.fechaFin;
        break;
      }
    }
  }
  getCount() {
    this.subscribeCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countRequest.countRequest = 1;
          this.request.countRequest = this.countRequest.countRequest;
        } else {
          this.countRequest.countRequest = contador.countRequest + 1;
          this.request.countRequest = this.countRequest.countRequest;
        }
      });
  }
  filterBudget(nivel) {
    const arr = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segments = element.CodigoAgrupador.split('-');
      if (segments.length === 1) {
        arr.push(element);
      }
    }
    return arr;
  }
  filterProvider() {
    const arr = [];
    for (let index = 0; index < this.providers.length; index++) {
      const element = this.providers[index];
      const nombre = element.nombre.toLowerCase();
      if (nombre.indexOf(this.proveedorSelecionado.nombre.toLowerCase()) >= 0) {
        arr.push(element);
      }
    }
    this.providersSelect = arr;
    this.changeSelectProvider();
  }
  changeSelectProvider() {
    const proveedor = (<any>document.getElementById('selectProvider')).value;
    const numProveedor = parseInt(proveedor, 10);
    for (let index = 0; index < this.providers.length; index++) {
      const element: Provider = this.providers[index];
      if (element.numero === numProveedor) {
        this.datosProveedor = element;
        this.request.providerName = element.nombre;
        this.request.numProvider = element.numero;
        break;
      }
    }
  }
  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    this.request.fechaIni = this.binding.fechaInicio;
    this.request.fechaFin = this.binding.fechaFin;
    this.request.etapa = this.binding.etapa;
  }
  validationValSol() {
    if (
      this.valObj.Cuenta &&
      (this.valObj.CargoME ||
        this.valObj.AbonoME ||
        this.valObj.Cargo ||
        this.valObj.Abono)
    ) {
      this.CtasAfectables.forEach(element => {
        if (element.CodigoAgrupador === this.valObj.Cuenta) {
          this.valObj.NombreCuenta = element.NombreCuenta;
        }
      });
      this.valSol.push(this.valObj);
      this.request.movimientos = this.valSol;
      this.addValSol();
    } else {
      Notiflix.Notify.Failure(
        'No puedes agregar una cuenta, completa los campos'
      );
    }
  }
  addValSol() {
    this.valObj = {};
    let suma1 = 0;
    let suma2 = 0;
    let suma3 = 0;
    let suma4 = 0;
    this.valSol.forEach(element => {
      this.valTotales.Cargo = suma1 += element.Cargo || 0;
      this.valTotales.Abono = suma2 += element.Abono || 0;
      this.valTotales.CargoME = suma3 += element.CargoME || 0;
      this.valTotales.AbonoME = suma4 += element.AbonoME || 0;
    });
    this.valTotales.Cargo = this.valTotales.Cargo.toFixed(4);
    this.valTotales.Abono = this.valTotales.Abono.toFixed(4);
    this.valTotales.CargoME = this.valTotales.CargoME.toFixed(4);
    this.valTotales.AbonoME = this.valTotales.AbonoME.toFixed(4);
    $('#valSol').modal('hide');
  }
  tipoMoneda() {
    if (this.request.tipoMoneda === 'MXN') {
      this.request.tipoCambio = this.project.tipoCambioGral;
    }
  }
  validationsAddRequest() {
    const request = this.request;
    const uuid = this.request.folioComp;
    if (
      request.numProvider > 0 &&
      request.concepto &&
      request.pagoSoli &&
      request.solicito &&
      request.folioComp &&
      request.fechaComp &&
      request.departamento &&
      request.puesto &&
      request.tipoCambio > 0 &&
      request.movimientos.length > 0 &&
      request.periodo
    ) {
      let validationFolio: Boolean;
      if (this.request.tipoSolicitud === 'GXC') {
        this.request.comprobado = 0;
        validationFolio = true;
      } else {
        validationFolio = this.validateFolio(uuid);
      }
      if (validationFolio) {
        let cuentas = true;
        for (let index = 0; index < this.valSol.length; index++) {
          const element = this.valSol[index];
          if (!element.Cuenta) {
            Notiflix.Notify.Failure('Falta Agregar una cuenta');
            cuentas = false;
            break;
          }
        }
        if (cuentas) {
          this.validateDealMemo();
        }
      }
    } else {
      Notiflix.Notify.Failure('Revisa que los campos sean completados');
    }
  }
  valores() {
    if (this.valor.importe) {
      this.valSol.push(this.valor);
      this.request.movimientos = this.valSol;
      this.valor = {};
      this.sumaSolicitud();
    } else {
      Notiflix.Notify.Failure('Agrega un importe');
    }
  }
  sumaSolicitud() {
    let importe = 0;
    let iva = 0;
    let subtotal = 0;
    let retencionIva = 0;
    let retencionIsr = 0;
    let total = 0;

    this.request.movimientos.forEach(element => {
      const obj: any = element;

      importe = importe + obj.importe;
      iva = iva + obj.iva;
      subtotal = subtotal + obj.subtotal;
      retencionIva = retencionIva + obj.retIva;
      retencionIsr = retencionIsr + obj.retIsr;
      total = total + obj.total;
    });
    this.request.importe = importe; // Esto es un cargo al presupuesto
    this.request.iva = iva; // Esto es un cargo al presupuesto
    this.request.subtotal = subtotal;
    this.request.retIva = retencionIva; // Esto abona al catalogo de cuentas
    this.request.retIsr = retencionIsr; // Esto abona al catalogo de cuentas
    this.request.total = total; // Esto abona al proveedor
  }
  validateDealMemo() {
    if (this.isDealMemo === 'consultDealMemo') {
      if (
        this.objDealMemo.ctas[this.indexCtaDeal].semanas >
        this.objDealMemo.ctas[this.indexCtaDeal].contadorPagos
      ) {
        // this.acumulado();
        this.addRequest();
      } else {
        Notiflix.Notify.Failure(
          'Ya no puedes crear mas solicitudes del Deal Memo en esta etapa'
        );
      }
    } else {
      this.acumulado();
    }
  }

  // getAcumulado() {
  //   this.subscribeGetAcumulado = this.dealMemoService
  //     .getAcumulados(this.idCompany, this.idProject)
  //     .subscribe(res => {
  //       this.acumulados = Object.assign([], res);
  //     });
  // }

  acumulado() {
    // const findBudget = (cuenta, acumulado) => {
    //   for (let i = 0; i < this.budget.length; i++) {
    //     const element = this.budget[i];
    //     if (element.CodigoAgrupador === cuenta) {
    //       if (element.Importe_Estimado > acumulado) {
    //         return true;
    //       } else {
    //         this.request.excedido = true;
    //         Notiflix.Report.Warning(
    //           'Alerta',
    //           'Esta solicitud excedio el presupuesto, se guardo pero no podra ser pagada hasta que se modifique el estimado',
    //           'Ok'
    //         );
    //         return false;
    //       }
    //     }
    //   }
    // };
    // const getPosition = cuenta => {
    //   return this.acumulados
    //     .map(e => {
    //       return e.CodigoAgrupador;
    //     })
    //     .indexOf(cuenta);
    // };

    // this.valSol.forEach(element => {
    //   if (element.Cuenta) {
    //     const position = getPosition(element.Cuenta);

    //     let acumulado;
    //     if (position >= 0) {
    //       const objAcumulado = this.acumulados[position];

    //       acumulado =
    //         objAcumulado.dealMemo ||
    //         0 + objAcumulado.pagoDirecto ||
    //         0 + objAcumulado.ordenDeCompra ||
    //         0 + objAcumulado.comprobacion ||
    //         0 + element.importe;

    //       if (this.request.tipoSolicitud != 'GXC') {
    //         objAcumulado.pagoDirecto =
    //           objAcumulado.pagoDirecto || 0 + element.importe;
    //       } else {
    //         objAcumulado.GXC = objAcumulado.GXC || 0 + element.importe;
    //       }
    //     } else {
    //       acumulado = 0;
    //       const obj = {} as any;
    //       obj.CodigoAgrupador = element.Cuenta;
    //       if (this.project.monedaLocal === this.request.tipoMoneda) {
    //         obj.pagoDirecto = element.importe;
    //       } else if (
    //         this.project.monedaLocal === 'USD' &&
    //         this.request.tipoMoneda === 'MXN'
    //       ) {
    //         obj.pagoDirecto = element.importe / this.request.tipoCambio;
    //       } else if (
    //         this.project.monedaLocal === 'MXN' &&
    //         this.request.tipoMoneda === 'USD'
    //       ) {
    //         obj.pagoDirecto = element.importe * this.request.tipoCambio;
    //       } else if (
    //         this.project.monedaLocal === 'USD' &&
    //         this.request.tipoMoneda === 'EUR'
    //       ) {
    //         obj.pagoDirecto = element.importe * this.request.tipoCambio;
    //       } else if (
    //         this.project.monedaLocal === 'MXN' &&
    //         this.request.tipoMoneda === 'EUR'
    //       ) {
    //         obj.pagoDirecto = element.importe * this.request.tipoCambio;
    //       }
    //       this.acumulados.push(obj);
    //     }
    //     const confirmacion = findBudget(element.Cuenta, acumulado);
    //   }
    // });
    // const objAcumulado = Object.assign({}, this.acumulados);
    // this.dealMemoService.addAcumulados(
    //   this.idCompany,
    //   this.idProject,
    //   objAcumulado
    // );
    // this.acumuladoIva();
    this.addRequest();
  }
  addRequest() {
    this.request.fechaCreado = new Date();
    this.request.aprobada = false;
    this.requestService
      .addRequest(this.idCompany, this.idProject, this.request)
      .then(() => {
        this.requestService.countRequest(
          this.idCompany,
          this.idProject,
          this.countRequest
        );
        if (this.isDealMemo === 'consultDealMemo') {
          this.objDealMemo.ctas[this.indexCtaDeal].contadorPagos += 1;
          this.dealMemoService.updateDealMemo(
            this.idCompany,
            this.idProject,
            this.objDealMemo.idDocument,
            this.objDealMemo
          );
        }
        $('#modalRequest').modal('hide');
        this.folios.push(this.request.folioComp);
        const obj = { folios: this.folios };
        this.projectService.addFolioFiscal(this.idCompany, this.idProject, obj);
        Notiflix.Notify.Success('Se guardo exitosamente');
        this.request.numProvider = 0;
        this.datosProveedor = {};
        this.request.concepto = '';
        this.request.solicito = '';
        this.request.pagoSoli = undefined;
        this.request.fechaComp = undefined;
        this.request.folioComp = '';
        this.request.departamento = '';
        this.departamento = '';
        this.request.tipoPago = 'Transferencia';
        this.request.tipoSolicitud = 'Directa';
        this.puesto = '';
        this.puestos = [];
        this.request.tipoMoneda = 'MXN';
        this.request.movimientos = [];
        this.valSol = [];
        this.request.importe = 0;
        this.request.iva = 0;
        this.request.subtotal = 0;
        this.request.retIva = 0;
        this.request.retIsr = 0;
        this.request.total = 0;
        (<any>document.getElementById('selectProvider')).value = '';
        (<any>document.getElementById('inputGroupFile01')).value = '';
        document
          .getElementById('form-request')
          .classList.remove('was-validated');
      })
      .catch(err => {
        console.error(err);
        Notiflix.Notify.Failure('Ocurrio un error al guardar la solicitud');
      });
  }
  // getAcumuladoIVA() {
  //   this.subscribeGetIva = this.requestService
  //     .getAcumuladoIVA(this.idCompany, this.idProject)
  //     .subscribe(res => {
  //       const obj: any = res;
  //       if (res === undefined) {
  //         obj.acumulado = 0;
  //       }
  //       if (!obj.acumulado || obj.acumulado === 0) {
  //         obj.acumulado = 0;
  //       }
  //       this.acumuladoIVA = obj;
  //     });
  // }
  // acumuladoIva() {
  //   if (this.project.monedaLocal === this.request.tipoMoneda) {
  //     this.acumuladoIVA.acumulado += this.request.iva;
  //   } else if (
  //     this.project.monedaLocal === 'USD' &&
  //     this.request.tipoMoneda === 'MXN'
  //   ) {
  //     this.acumuladoIVA.acumulado += this.request.iva / this.request.tipoCambio;
  //   } else if (
  //     this.project.monedaLocal === 'MXN' &&
  //     this.request.tipoMoneda === 'USD'
  //   ) {
  //     this.acumuladoIVA.acumulado += this.request.iva * this.request.tipoCambio;
  //   } else if (
  //     this.project.monedaLocal === 'USD' &&
  //     this.request.tipoMoneda === 'EUR'
  //   ) {
  //     this.acumuladoIVA.acumulado += this.request.iva * this.request.tipoCambio;
  //   } else if (
  //     this.project.monedaLocal === 'MXN' &&
  //     this.request.tipoMoneda === 'EUR'
  //   ) {
  //     this.acumuladoIVA.acumulado += this.request.iva * this.request.tipoCambio;
  //   }
  //   this.requestService.acumuladoIVA(
  //     this.idCompany,
  //     this.idProject,
  //     this.acumuladoIVA
  //   );
  // }
  editValSol(obj, index) {
    $('#valSol').modal('show');
    this.editVal = obj;
    this.deleteIndex = index;
  }
  deleteValSol() {
    const index = this.deleteIndex;
    this.valSol.splice(index, 1);
    $('#valSol').modal('hide');
  }
  // inputCargoME() {
  //   this.valObj.Cargo = this.valObj.CargoME * this.request.tipoCambio;
  //   if (this.valObj.CargoME > 0 || this.valObj.CargoME < 0) {
  //     (<any>document.getElementById('abonoME')).disabled = true;
  //     (<any>document.getElementById('cargo')).disabled = true;
  //     (<any>document.getElementById('abono')).disabled = true;
  //   } else {
  //     (<any>document.getElementById('abonoME')).disabled = false;
  //     (<any>document.getElementById('cargo')).disabled = false;
  //     (<any>document.getElementById('abono')).disabled = false;
  //   }
  // }
  // inputAbonoME() {
  //   this.valObj.Abono = this.valObj.AbonoME * this.request.tipoCambio;
  //   if (this.valObj.AbonoME > 0 || this.valObj.AbonoME < 0) {
  //     (<any>document.getElementById('cargoME')).disabled = true;
  //     (<any>document.getElementById('cargo')).disabled = true;
  //     (<any>document.getElementById('abono')).disabled = true;
  //   } else {
  //     (<any>document.getElementById('cargoME')).disabled = false;
  //     (<any>document.getElementById('cargo')).disabled = false;
  //     (<any>document.getElementById('abono')).disabled = false;
  //   }
  // }
  // inputCargoMXN() {
  //   this.valObj.CargoME = this.valObj.Cargo / this.request.tipoCambio;
  //   if (this.valObj.Cargo > 0 || this.valObj.Cargo < 0) {
  //     (<any>document.getElementById('cargoME')).disabled = true;
  //     (<any>document.getElementById('abonoME')).disabled = true;
  //     (<any>document.getElementById('abono')).disabled = true;
  //   } else {
  //     (<any>document.getElementById('cargoME')).disabled = false;
  //     (<any>document.getElementById('abonoME')).disabled = false;
  //     (<any>document.getElementById('abono')).disabled = false;
  //   }
  // }
  // inputAbonoMXN() {
  //   this.valObj.AbonoME = this.valObj.Abono / this.request.tipoCambio;
  //   if (this.valObj.Abono > 0 || this.valObj.Abono < 0) {
  //     (<any>document.getElementById('cargoME')).disabled = true;
  //     (<any>document.getElementById('abonoME')).disabled = true;
  //     (<any>document.getElementById('cargo')).disabled = true;
  //   } else {
  //     (<any>document.getElementById('cargoME')).disabled = false;
  //     (<any>document.getElementById('abonoME')).disabled = false;
  //     (<any>document.getElementById('cargo')).disabled = false;
  //   }
  // }
  changeTypeRequest() {
    if (
      this.request.tipoSolicitud === 'GXC' ||
      this.request.tipoSolicitud === 'Cheque vs factura'
    ) {
      this.tipoDeSolicitud = false;
      this.request.folioComp = '';
      this.request.folioComp = 'GXC';
      this.request.fechaComp = 'GXC';
    } else if (this.request.tipoSolicitud === 'Anexo') {
      this.tipoDeSolicitud = true;
      this.request.folioComp = 'ANEXO';
      setTimeout(() => {
        (<any>document.getElementById('foliocomp')).disabled = true;
      }, 300);
    } else {
      this.tipoDeSolicitud = true;
      this.request.folioComp = '';
      setTimeout(() => {
        (<any>document.getElementById('foliocomp')).disabled = false;
      }, 300);
    }
  }
  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
  }
  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.assignData(obj);
  }
  assignData(obj) {
    const uuid =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    const validacionFolio = this.validateFolio(uuid);
    if (validacionFolio) {
      if (this.isDealMemo === 'consultDealMemo') {
        this.request.folioComp =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;
        this.request.fechaComp = obj['cfdi:Comprobante'][
          '@attributes'
        ].Fecha.substr(0, 10);
      } else {
        let existProvider = false;
        const rfcProveedor =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;
        for (let index = 0; index < this.providers.length; index++) {
          const element = this.providers[index];
          if (element.rfc === rfcProveedor) {
            this.datosProveedor = element;
            this.request.providerName = element.nombre;
            this.request.numProvider = element.numero;
            existProvider = true;
            break;
          }
        }
        if (existProvider) {
        } else {
          Notiflix.Notify.Failure('No existe el proveedor en la base de datos');
        }
        this.request.concepto =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
            '@attributes'
          ].Descripcion;
        this.request.folioComp =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;
        this.request.fechaComp = obj['cfdi:Comprobante'][
          '@attributes'
        ].Fecha.substr(0, 10);
        const importe =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
            '@attributes'
          ].Importe;
        const importeInt = parseInt(importe, 10);
        this.valor.Cuenta = 'Selecciona una Cuenta';
        this.valor.importe = importeInt;
        this.operacionesFiscales = this.projectService.operaciones(
          this.datosProveedor.regimen,
          importeInt
        );
        this.operaciones();
        this.valores();
      }
    }
  }
  validateFolio(uuid) {
    let validacionFolio = true;
    this.folios.forEach(element => {
      if (element === uuid) {
        Notiflix.Notify.Failure('El folio fiscal ya se encuentra registrado');
        validacionFolio = false;
      }
    });
    return validacionFolio;
  }
  ngOnDestroy() {
    this.subscriberProviders.unsubscribe();
    this.subscriberCatCtas.unsubscribe();
    this.subscriberBudget.unsubscribe();
    this.subscribeCountRequest.unsubscribe();
    this.subscribePeriods.unsubscribe();
    this.subscribeProject.unsubscribe();
    this.subscribeGetFolios.unsubscribe();
    // this.subscribeGetIva.unsubscribe();
    // this.subscribeGetAcumulado.unsubscribe();
  }
}
