import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
import { element } from 'protractor';
declare var JQuery;
declare var $;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dataUser = {} as User;
  empresas = [];
  isAdmin: boolean;
  userType;
  sinEmpresas = false;
  loading = false;

  subscriberRoleUser: Subscription;
  subscriberGetEmpresas: Subscription;
  subscriberGetdashboard: Subscription;

  constructor(
    private empresasService: EmpresasService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.isLogin().then(result => {
      console.log(result);
      this.dataUser = result;
      this.subscriberRoleUser = this.authService
        .roleUser(this.dataUser.uid)
        .subscribe(res => {
          console.log(res);
          if (res.length === 0) {
            this.authService.logOutUser().then(res => console.log(res));
          }
          const idUser = res[0].id;
          this.userType = res[0].userType;
          if (this.userType === 'Administrador') {
            this.isAdmin = true;
            this.sinEmpresas = true;
            this.subscriberGetEmpresas = this.empresasService
              .getCompany()
              .subscribe(empresas => {
                this.empresas = empresas;
                this.notificaciones();
                this.loading = false;
              });
          } else {
            this.isAdmin = false;
            this.subscriberGetdashboard = this.empresasService
              .getCompanySpecific(idUser)
              .subscribe(empresas => {
                if (empresas.length === 0) {
                  this.sinEmpresas = false;
                } else {
                  this.sinEmpresas = true;
                  this.empresas = empresas;
                  this.notificaciones();
                }
                this.loading = false;
              });
          }
        });
    });
  }

  notificaciones() {
    const dateActual = new Date();
    this.empresas.forEach(elementEmpresas => {
      let tareasVencidas = false;
      if (elementEmpresas.tasks) {
        elementEmpresas.tasks.forEach(elementTask => {
          const dateTask = new Date(elementTask.dateMax + 'T23:59:59');
          if (dateActual > dateTask && !elementTask.file) {
            tareasVencidas = true;
          }
        });
      }
      if (tareasVencidas) {
        // Notiflix.Report.Warning(
        //   'Alerta',
        //   `La empresa ${elementEmpresas.name} tiene tareas vencidas`,
        //   'Ok'
        // );
        Notiflix.Notify.Warning(
          `La empresa ${elementEmpresas.name} tiene tareas vencidas`
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriberRoleUser) {
      this.subscriberRoleUser.unsubscribe();
    }
    if (this.subscriberGetEmpresas) {
      this.subscriberGetEmpresas.unsubscribe();
    }
    if (this.subscriberGetdashboard) {
      this.subscriberGetdashboard.unsubscribe();
    }
  }
}
