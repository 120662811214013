import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import Notiflix from 'notiflix-angular';
import * as moment from 'moment';

import * as XLSX from 'xlsx';
declare var $;

@Component({
  selector: 'app-request-aproved',
  templateUrl: './request-aproved.component.html',
  styleUrls: ['./request-aproved.component.css'],
})
export class RequestAprovedComponent implements OnInit {
  idCompany;
  idProject;
  objTotales = {} as any;
  fecha = new Date();
  id;
  solicitudSeleccionada;
  cargaBatch = [] as any;
  arrCargaBatch = [] as any;
  listRequest = [] as any;
  requestComplete = [] as any;
  request = [] as any;
  solicitud = [] as any;

  subscriberAprovedRequest: Subscription;

  constructor(private router: Router, private requestService: RequestService) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getAprovedRequest();
  }
  getAprovedRequest() {
    this.listRequest = [];
    this.subscriberAprovedRequest = this.requestService
      .getRequestAprovadas(this.idCompany, this.idProject)
      .subscribe(res => {
        this.listRequest = res;

        this.listRequest = this.filterRequest(res);
        console.log(this.listRequest);

        this.totales();
      });
  }
  filterRequest(arr) {
    // console.log(arr);
    this.arrCargaBatch = arr;
    const arrRequest = [] as any;
    for (let index = 0; index < arr.length; index++) {
      const request = arr[index];
      let sumaCargo = 0;
      let sumaAbono = 0;
      let IVA = 0;
      let RetISR = 0;
      let RetIVA = 0;
      request.movimientos.forEach((element: any) => {
        if (element.Cargo) {
          if (element.NombreCuenta === 'IVA') {
            IVA = element.Cargo;
          } else {
            sumaCargo += element.Cargo;
          }
        } else {
          if (element.NombreCuenta === 'Retencion de ISR') {
            RetISR = element.Abono;
          }
          if (element.NombreCuenta === 'Retencion de IVA') {
            RetIVA = element.Abono;
          }
          sumaAbono += element.Abono;
        }
      });
      request.IVA = IVA;
      request.RetISR = RetISR;
      request.RetIVA = RetIVA;
      request.Cargo = sumaCargo;
      request.Abono = sumaAbono;
      arrRequest.push(request);
    }
    return arrRequest.sort((a, b) => {
      if (a.countRequest > b.countRequest) {
        return 1;
      }
      if (a.countRequest < b.countRequest) {
        return -1;
      }
      return 0;
    });
  }
  totales() {
    let sumaCargo = 0;
    let sumaAbono = 0;
    let sumaCargoMe = 0;
    let sumaAbonoMe = 0;
    for (let index = 0; index < this.listRequest.length; index++) {
      const element = this.listRequest[index];
      sumaCargo += element.Cargo;
      sumaAbono += element.Abono;
      sumaCargoMe += element.CargoMe;
      sumaAbonoMe += element.AbonoMe;
    }
    this.objTotales = {
      Cargo: sumaCargo,
      Abono: sumaAbono,
      CargoMe: sumaCargoMe,
      AbonoMe: sumaAbonoMe,
    };
  }
  inputFile(ev) {
    this.cargaBatch = [];

    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      let sheetName;
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        sheetName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      let arr = [] as any;

      arr = jsonData[sheetName];
      for (let index = 0; index < this.listRequest.length; index++) {
        const element1 = this.listRequest[index];
        // console.log(element);

        arr.forEach(element => {
          if (element.FechaPago === undefined) {
            // console.log(element);
          } else {
            if (element1.countRequest === element.NumeroSol) {
              const FechaPagos = this.numeroAFecha(element.FechaPago, true);
              // console.log(FechaPagos);

              const formato = 'YYYY-MM-DD';
              element.FechaPago = moment(FechaPagos).format(formato);

              // console.log(element.FechaPago);

              if (element.ReferenciaPago) {
                // console.log(element);

                let obj = element;
                this.cargaBatch.push(obj);
              }
            }
          }
        });
      }
      console.log(this.cargaBatch);
    };
    setTimeout(() => {
      reader.readAsBinaryString(file);
    }, 500);
  }
  numeroAFecha(numeroDeDias, esExcel) {
    var diasDesde1900 = esExcel ? 25567 + 1 : 25567;
    // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
    return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
  }

  exportAsXLSX(): void {
    const arr = [] as any;

    this.listRequest.forEach(element => {
      let obj = {
        NumeroSol: element.countRequest,
        Nombreproveedor: element.providerName,
        Concepto: element.concepto,
        Periodo: element.periodo,
        FechaSolicitud: element.pagoSoli,
        Importe: element.Cargo,
        TC: element.tipoCambio,
        Moneda: element.tipoMoneda,
        ReferenciaPago: '',
        FechaPago: '',
      } as any;
      arr.push(obj);
      obj = {} as any;
    });

    this.requestService.exportAsExcel(arr, 'AprobadasPago');
  }
  pagar(sol) {
    this.id = sol.id;

    const obj = {
      refPago: sol.refPago,
      fechaPagada: sol.fechaPago,
      estatus: true,
    };
    if (sol.refPago === undefined || sol.fechaPago === undefined) {
      Notiflix.Notify.Failure('Agrega un folio o fecha de pago');
    }
    if (sol.fechaPago > this.fecha.toISOString().slice(0, 10)) {
      Notiflix.Notify.Failure('La fecha no debe ser mayor al dia de hoy');
    } else {
      Notiflix.Confirm.Show(
        'ALERTA DE PAGO',
        '¿Quieres pagar esta solicitud?',
        'Pagar',
        'Cancelar',
        () => {
          this.requestService
            .updateRequest(this.idCompany, this.idProject, this.id, obj)
            .then(() => {
              Notiflix.Notify.Success('Pago Realizado');
            });
          Notiflix.Loading.Remove(3000);
        }
      );
    }
  }
  pagarMasivo() {
    Notiflix.Confirm.Show(
      'ALERTA DE PAGO',
      '¿Estas seguro de realizar el pago?',
      'Pagar',
      'Cancelar',
      () => {
        let arrBatch = [] as any;
        this.cargaBatch.forEach(element => {
          let obj = {} as any;
          // console.log(element);
          obj.countRequest = element.NumeroSol;
          obj.refPago = element.ReferenciaPago;
          obj.FechaPago = element.FechaPago;
          arrBatch.push(obj);
        });
        // console.log(arrBatch);
        arrBatch.forEach(element => {
          for (let index = 0; index < this.listRequest.length; index++) {
            const element1 = this.listRequest[index];
            if (element.countRequest === element1.countRequest) {
              // console.log(element.id);

              element.id = element1.id;
              element.estatus = true;
            }
          }
          // console.log(element);
          this.requestService.addPagoMasivo(
            this.idCompany,
            this.idProject,
            element.id,
            element
          );
        });
        Notiflix.Notify.Success('Solicitudes Pagadas Correctamente');

        this.cargaBatch = [];
        Notiflix.Loading.Remove(3000);
      }
    );
  }
}
