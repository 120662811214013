import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import {
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  AngularFirestore,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Empresa } from '../models/empresas';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userData$: Observable<firebase.User>;
  dataUser;

  constructor(
    private afsAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.userData$ = afsAuth.authState;
    this.userData$.subscribe(res => {
      this.dataUser = res;
    });
  }
  userCollection: AngularFirestoreCollection<User>;
  userDocument: AngularFirestoreDocument<User>;
  addCompanyCollection: AngularFirestoreCollection<Empresa>;
  addCompanyDocument: AngularFirestoreDocument<Empresa>;
  getCompanyCollection: AngularFirestoreCollection<Empresa>;
  userData;
  userType = {} as User;

  loginEmailUser(email: string, pass: string) {
    return this.afsAuth.signInWithEmailAndPassword(email, pass);
  }
  isLogin() {
    return new Promise(resultado => {
      return this.afsAuth.onAuthStateChanged(user => {
        if (user) {
          resultado(user);
        } else {
          this.router.navigate(['/']);
        }
      });
    });
  }
  roleUser(uidUser) {
    return this.afs
      .collection('usuarios', ref => ref.where('uid', '==', uidUser))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  logOutUser() {
    return this.afsAuth.signOut();
  }
  getUser() {
    this.userCollection = this.afs.collection('usuarios');
    this.userData = this.userCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as User;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.userData;
  }
  getUser2(uid) {
    return this.afs
      .collection('usuarios', ref => ref.where('uid', '==', uid))
      .valueChanges();
  }
  // Envio los datos a base de autenticacion FIREBASE
  addUser(email, password) {
    return this.afsAuth.createUserWithEmailAndPassword(email, password);
  }
  // almaceno los datos en la BD
  addUserDB(user: User) {
    this.userCollection = this.afs.collection('usuarios');
    return this.userCollection.add(user);
  }
  deleteUser(user) {}

  editUserDB(user: User) {
    this.userDocument = this.afs.doc(`usuarios/${user.id}`);
    return this.userDocument.update(user);
  }

  editProfile(user) {
    return this.afsAuth.currentUser.then(res => res.updateProfile(user));
  }
  emailVerification() {
    this.afsAuth.currentUser.then(res => {
      res
        .sendEmailVerification()
        .then(function () {
          // Email sent.
          alert('Te enviamos una liga de verificacion a tu correo electronico');
        })
        .catch(function (error) {
          // An error happened.
          console.log(error);
        });
    });
  }
  deleteImageUser(user: User) {
    // Create a reference to the file to delete
    const reference = this.storage.ref(user.pathImageProfile);

    // Delete the file
    reference
      .delete()
      .toPromise()
      .then(function () {
        // File deleted successfully
        console.log('Se elimino la imagen correctamente');
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
        console.log(
          'Se produjo un error al eliminar la imagen o no existia imagen'
        );
      });
  }
  updateUser(user) {
    return this.afs.collection('usuarios').doc(user.id).update(user);
  }

  getPremissions() {
    return this.afs.collection('permisos').doc('permisos').valueChanges();
  }

  addPremissions(obj) {
    this.afs.collection('permisos').doc('permisos').set(obj);
  }

  addPerfiles(obj) {
    return this.afs.collection('perfiles').add(obj);
  }
  updatePerfiles(perfiles, id) {
    const obj = Object.assign({}, perfiles);

    return this.afs.collection('perfiles').doc(id).update(obj);
  }

  getPerfiles() {
    return this.afs
      .collection('perfiles')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  getPerfil(id) {
    return this.afs
      .collection('perfiles', ref => ref.where('id', '==', id))
      .valueChanges();
  }

  deletePerfil(idDoc) {
    return this.afs.collection('perfiles').doc(idDoc).delete();
  }

  resetPass(email) {
    return this.afsAuth.sendPasswordResetEmail(email);
  }

  generateCode() {
    const obj = {
      validado: false,
    };
    return this.afs.collection('codes').add(obj);
  }

  readCode(code) {
    return this.afs.collection('codes').doc(code).valueChanges();
  }

  editCode(code, obj) {
    this.afs.collection('codes').doc(code).update(obj);
  }
}
