import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    const resultadoFiltrado = [];
    for (const solicitud of value) {
      if (
        solicitud.providerName.toLowerCase().indexOf(arg.toLowerCase()) > -1
      ) {
        resultadoFiltrado.push(solicitud);
      }
    }
    return resultadoFiltrado;
  }
}
