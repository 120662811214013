import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { ProjectService } from 'src/app/services/project.service';
import { BudgetService } from 'src/app/services/budget.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
import * as moment from 'moment';
import pdfMake from 'pdfmake';
import { RequestVersion1Component } from '../request-version1/request-version1.component';

declare var $;

@Component({
  selector: 'app-check-request',
  templateUrl: './check-request.component.html',
  styleUrls: ['./check-request.component.css'],
})
export class CheckRequestComponent implements OnInit, OnDestroy {
  // @ViewChild(EditRequestComponent) request: EditRequestComponent;
  @ViewChild(RequestVersion1Component) request: RequestVersion1Component;

  idCompany;
  idProject;
  consultarPor = 'periodo';
  periodos = [] as any;
  etapas = [] as any;
  periodoSeleccionado;
  partidaSeleccionada;
  folioSeleccionado;
  etapaSeleccionada;
  pagoSeleccionado;
  numeros = {} as any;
  objTotales = {} as any;
  company = {} as any;
  project = {} as any;
  countRequest = [] as any;
  listRequest = [] as any;
  listRequest1 = [] as any;
  partidas = [] as any;
  folios = [] as any;
  cuentas = [] as any;
  requestComplete = [] as any;
  arrComplete = [] as any;
  estatusSeleccionado = 'todas';
  budget: any;
  aprobadoSeleccionado = 'todas';
  monedaLocal: Subscription;
  budgetArrays = [] as any;

  subscriberPeriods: Subscription;
  subscriberCountRequest: Subscription;
  subscriberGetBudget: Subscription;
  subscriberProjectActual: Subscription;
  subscriberCompanyActual: Subscription;
  subscriberGetRequest: Subscription;

  constructor(
    private requestService: RequestService,
    private projectService: ProjectService,
    private budgetService: BudgetService,
    private empresasService: EmpresasService,
    private router: Router
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getperiods();
    this.getCountRequest();
    // this.getBudget();
    this.getProject();
  }
  getperiods() {
    this.subscriberPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodos = Object.assign([], res);
      });
  }
  getCountRequest() {
    this.subscriberCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe(res => {
        let count = {} as any;
        if (res === undefined) {
          count.countRequest = 1;
        }
        let contador = 0;
        for (let index = 0; index < count.countRequest; index++) {
          contador += 1;
          const element = { count: contador };
          this.countRequest.push(element);
        }
      });
  }

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.budget = arrBudget; // Este es el array que mostramos en la tablaas -->>
        }
      });
  }

  getProject() {
    this.subscriberCompanyActual = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe(res => {
        this.company = res;
        // console.log('Datos de la empresa: ', this.company);
      });
    this.subscriberProjectActual = this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        // console.log('Datos del proyecto:', this.project);
      });
  }
  filter() {
    this.listRequest = [];
    if (
      this.aprobadoSeleccionado === 'true' &&
      this.estatusSeleccionado === 'todas'
    ) {
      this.listRequest = this.arrComplete.filter(element => {
        return element.aprobada === true && element.estatus === element.estatus;
      });
    } else if (this.aprobadoSeleccionado === 'false') {
      this.listRequest = this.arrComplete.filter(element => {
        return element.aprobada === false;
      });
    } else if (
      this.aprobadoSeleccionado === 'todas' &&
      this.estatusSeleccionado === 'todas'
    ) {
      this.listRequest = this.arrComplete;
    } else if (
      this.aprobadoSeleccionado === 'todas' &&
      this.estatusSeleccionado === 'true'
    ) {
      this.listRequest = this.arrComplete.filter(element => {
        return element.aprobada === true && element.estatus === true;
      });
    } else if (
      this.aprobadoSeleccionado === 'todas' &&
      this.estatusSeleccionado === 'false'
    ) {
      this.listRequest = this.arrComplete.filter(element => {
        return (
          (element.aprobada === true || element.aprobada === false) &&
          element.estatus === false
        );
      });
    } else if (
      this.aprobadoSeleccionado === 'true' &&
      this.estatusSeleccionado === 'true'
    ) {
      this.listRequest = this.arrComplete.filter(element => {
        return element.aprobada === true && element.estatus === true;
      });
    } else if (
      this.aprobadoSeleccionado === 'true' &&
      this.estatusSeleccionado === 'false'
    ) {
      this.listRequest = this.arrComplete.filter(element => {
        return element.aprobada === true && element.estatus === false;
      });
    }
    this.totales();
  }
  getRequestPeriod() {
    this.listRequest = [];
    this.requestComplete = [];
    this.estatusSeleccionado = 'todas';
    this.aprobadoSeleccionado = 'todas';
    const periodo = this.periodoSeleccionado;
    this.requestService
      .getRequestPeriod(this.idCompany, this.idProject, periodo)
      .subscribe(res => {
        this.requestComplete = res;
        this.arrComplete = this.filterRequest(res);
        this.listRequest = this.arrComplete;
        this.totales();
      });
  }
  getRequestEtapa() {
    this.listRequest = [];
    this.requestComplete = [];
    this.estatusSeleccionado = 'todas';
    this.aprobadoSeleccionado = 'todas';
    const etapa = this.etapaSeleccionada;
    this.subscriberGetRequest = this.requestService
      .getRequestEtapa(this.idCompany, this.idProject, etapa)
      .subscribe(res => {
        this.requestComplete = res;
        this.arrComplete = this.filterRequest(res);
        this.listRequest = this.arrComplete;
        this.totales();
      });
  }
  updateRequestAprobado(request) {
    const obj = {
      aprobada: request.aprobada,
      fechaAprobada: new Date(),
    };
    this.requestService
      .updateRequest(this.idCompany, this.idProject, request.id, obj)
      .then(() => {
        Notiflix.Notify.Success('Se actualizo correctamente');
      });
  }
  // updateRequestPagado(request) {
  //   if (request.aprobada) {
  //     const obj = {
  //       estatus: request.estatus,
  //     };
  //     this.requestService
  //       .updateRequest(this.idCompany, this.idProject, request.id, obj)
  //       .then(() => {
  //         Notiflix.Notify.Success('Se actualizo correctamente');
  //       });
  //   } else {
  //     Notiflix.Notify.Failure('No puede pagarse si no esta aprobada');
  //     setTimeout(() => {
  //       request.estatus = false;
  //     }, 500);
  //   }
  // }
  updateRequestCancelada(request) {
    const obj = {
      cancelada: request.cancelada,
      fechaCancelada: new Date(),
    };
    this.requestService
      .updateRequest(this.idCompany, this.idProject, request.id, obj)
      .then(() => {
        Notiflix.Notify.Success('Solicitud Cancelada');
      });
  }
  reporteNumero() {
    this.listRequest = [];
    this.requestComplete = [];
    this.estatusSeleccionado = 'todas';
    this.aprobadoSeleccionado = 'todas';
    this.subscriberGetRequest = this.requestService
      .reporteNumero(this.idCompany, this.idProject, this.numeros)
      .subscribe(res => {
        this.requestComplete = res;
        this.arrComplete = this.filterRequest(res);
        this.listRequest = this.arrComplete;
        this.totales();
      });
  }
  reportePartidas() {
    this.partidas = [];
    this.budget.forEach(element => {
      const segmentos = element.CodigoAgrupador.split('-');
      if (segmentos.length === 3) {
        const obj: any = {};
        obj.CodigoAgrupador = element.CodigoAgrupador;
        obj.Descripcion = element.Descripcion;
        this.partidas.push(obj);
      }
    });
  }
  consultFolio() {
    this.listRequest = [];
    this.requestComplete = [];
    this.subscriberGetRequest = this.requestService
      .reportePartida(this.idCompany, this.idProject)
      .subscribe(res => {
        this.requestComplete = res;
        const solicitudes = res;
        const arr = [];
        solicitudes.forEach(element => {
          const sol = element;
          if (element.folioComp === this.folioSeleccionado) {
            arr.push(element);
          }
        });
        this.listRequest = this.filterRequest(arr);
        this.totales();
      });
  }
  consultSolicitud() {
    this.listRequest = [];
    this.requestComplete = [];
    this.subscriberGetRequest = this.requestService
      .reportePartida(this.idCompany, this.idProject)
      .subscribe(res => {
        this.requestComplete = res;
        const solicitudes = res;
        const arr = [];
        solicitudes.forEach(element => {
          const sol = element;
          if (element.movimientos) {
            element.movimientos.forEach(element2 => {
              if (element2.Cuenta === this.partidaSeleccionada) {
                arr.push(element);
              }
            });
          }
        });
        this.listRequest = this.filterRequest(arr);
        this.totales();
      });
  }
  filterRequest(arr) {
    console.log(arr);
    const arrRequest = [] as any;
    for (let index = 0; index < arr.length; index++) {
      const request = arr[index];
      let sumaCargo = 0;
      let sumaAbono = 0;
      let IVA = 0;
      let RetISR = 0;
      let RetIVA = 0;
      request.movimientos.forEach((element: any) => {
        if (element.Cargo && element.Folio) {
          // LAS QUE NO TIENEN FOLIO DE COMPROBANTE IGUAL APAREZCA EL CARGO
          sumaCargo += element.Cargo;
        } else {
          if (element.NombreCuenta === 'IVA') {
            IVA = element.Cargo;
          }
          if (element.NombreCuenta === 'Retencion de ISR') {
            RetISR = element.Abono;
          }
          if (element.NombreCuenta === 'Retencion de IVA') {
            RetIVA = element.Abono;
          }
          if (element.Abono) {
            sumaAbono += element.Abono;
          }
        }
      });
      request.IVA = IVA;
      request.RetISR = RetISR;
      request.RetIVA = RetIVA;
      request.Cargo = sumaCargo;
      request.Abono = sumaAbono;
      arrRequest.push(request);
    }
    return arrRequest.sort((a, b) => {
      if (a.countRequest > b.countRequest) {
        return 1;
      }
      if (a.countRequest < b.countRequest) {
        return -1;
      }
      return 0;
    });
  }
  totales() {
    let sumaCargo = 0;
    let sumaAbono = 0;
    let sumaCargoMe = 0;
    let sumaAbonoMe = 0;
    for (let index = 0; index < this.listRequest.length; index++) {
      const element = this.listRequest[index];
      sumaCargo += element.Cargo;
      sumaAbono += element.Abono;
      sumaCargoMe += element.CargoMe;
      sumaAbonoMe += element.AbonoMe;
    }
    this.objTotales = {
      Cargo: sumaCargo,
      Abono: sumaAbono,
      CargoMe: sumaCargoMe,
      AbonoMe: sumaAbonoMe,
    };
  }

  requestSelect(obj) {
    $('#editRequest').modal('show');
    this.request.request = obj;
    for (let index = 0; index < this.requestComplete.length; index++) {
      const elementComplete = this.requestComplete[index];
      if (obj.countRequest === elementComplete.countRequest) {
        this.request.request = elementComplete;
        this.request.countRequest = elementComplete.countRequest;
        this.request.periodoActual.nombrePeriodo = elementComplete.periodo;
        this.request.valSol = elementComplete.movimientos;

        this.request.departament.forEach(element => {
          if (elementComplete.departamento === element.Descripcion) {
            this.request.departamento = element;
          }
        });
        // this.request.getPuesto();
        // this.request.puestos.forEach(element => {
        //   if (elementComplete.puesto === element.Descripcion) {
        //     this.request.puesto = element;
        //   }
        // });
        // this.request.getCtaPres();
        this.request.totales();
        break;
      }
    }
  }

  // descarga excel
  exportAsXLSX(): void {
    const arr = [] as any;

    this.listRequest.forEach(element => {
      let obj = {
        NumeroSol: element.countRequest,
        Nombreproveedor: element.providerName,
        Concepto: element.concepto,
        Periodo: element.periodo,
        FechaSolicitud: element.pagoSoli,
        Importe: element.Cargo,
        IVA: element.IVA,
        Subtotal: element.Cargo + element.IVA,
        RetISR: element.RetISR,
        RetIVA: element.RetIVA,
        Total: element.Cargo + element.IVA - element.RetISR - element.RetIVA,
        TC: element.tipoCambio,
        Folio: element.folioComp,
        FechaFolio: element.fechaComp,
        Moneda: element.tipoMoneda,
        ReferenciaPago: element.refPago,
        FechaDePago: element.fechaPagada,
      } as any;
      arr.push(obj);
      obj = {} as any;
    });

    this.requestService.exportAsExcelRequest(arr, 'Solicitudes');
  }

  // Creacion de PDF Individual
  async generatePDF(data) {
    console.log(data);
    const docDefinition = {
      pageSize: 'LETTER',
      pageOrientation: 'portrait', // landscape
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 10,
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black',
        },
      },
      defaultStyle: {
        fontSize: 9,
      },
      // info: {
      //   title: 'awesome Document',
      //   author: 'john doe',
      //   subject: 'subject of document',
      //   keywords: 'keywords for document',
      // },
      content: [
        {
          qr: `https://enconexion.online/${this.idCompany}/${this.idProject}/${data.id}`,
          fit: '70',
          absolutePosition: { x: 530, y: 30 },
        },
        {
          text: this.company.name.toUpperCase(),
          alignment: 'center',
          style: 'header',
          // fontSize: 7,
        },
        {
          text: this.project.name.toUpperCase(),
          alignment: 'center',
          style: 'header',
          // fontSize: 7,
        },
        {
          text: 'No. ' + data.countRequest.toString().padStart(4, '0'),
          alignment: 'right',
          style: 'tableHeader',
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: 'Solicitud de pago',
                  alignment: 'center',
                  style: 'subheader',
                },
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            headerRows: 1,
            body: [
              [
                { text: 'Periodo y fecha de producción', style: 'tableHeader' },
                { text: 'De:', style: 'tableHeader' },
                { text: 'A:', style: 'tableHeader' },
              ],
              [
                data.periodo,
                moment(data.fechaIni).format('L'),
                moment(data.fechaFin).format('L'),
              ],
            ],
          },
          // layout: 'noBorders',
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            headerRows: 1,
            body: [
              [
                {
                  text: 'Fecha de elaboración',
                  style: 'tableHeader',
                },
                {
                  text: 'Fecha de pago',
                  style: 'tableHeader',
                },
                {
                  text: 'Folio de comprobante',
                  style: 'tableHeader',
                },
              ],
              [
                moment(new Date(data.fechaCreado.seconds * 1000)).format('L'),
                moment(data.pagoSoli).format('L'),
                '...' + data.folioComp.substr(-12).toUpperCase(),
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            headerRows: 1,
            body: [
              [
                {
                  text: 'Partida presupuestal',
                  style: 'tableHeader',
                },
                {
                  text: 'Importe',
                  style: 'tableHeader',
                },
                {
                  text: 'IVA',
                  style: 'tableHeader',
                },
                {
                  text: 'Retención IVA',
                  style: 'tableHeader',
                },
                {
                  text: 'Retención ISR',
                  style: 'tableHeader',
                },
                {
                  text: 'Total',
                  style: 'tableHeader',
                },
              ],
              [
                data.movimientos[0].Cuenta,
                data.movimientos[0].Cargo.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                data.movimientos[1].Cargo.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                data.movimientos[2].Abono.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                data.movimientos[3].Abono.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                (
                  data.movimientos[0].Cargo +
                  data.movimientos[1].Cargo -
                  data.movimientos[2].Abono -
                  data.movimientos[3].Abono
                ).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  rowSpan: 2,
                  text: 'Importe Total:',
                  style: 'tableHeader',
                },
                { text: 'Importe', style: 'tableHeader' },
                { text: 'Moneda', style: 'tableHeader' },
                { text: 'T.C.', style: 'tableHeader' },
              ],
              [
                '',
                (
                  data.movimientos[0].Cargo +
                  data.movimientos[1].Cargo -
                  data.movimientos[2].Abono -
                  data.movimientos[3].Abono
                ).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
                data.tipoMoneda,
                data.tipoCambio,
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: [100, '*'],
            body: [
              [{ text: 'Concepto:', style: 'tableHeader' }, data.concepto],
              [
                { text: 'Cheque a favor de:', style: 'tableHeader' },
                data.providerName,
              ],
              [{ text: 'RFC:', style: 'tableHeader' }, data.rfcProvider],
              // [
              //   { text: 'Departamento', style: 'tableHeader' },
              //   data.nameDepartament,
              // ],
              [{ text: 'Categoría:', style: 'tableHeader' }, data.departamento],
            ],
          },
          // layout: 'noBorders',
        },
        {
          text: '\n\n',
        },
        {
          text: 'Aprobación',
          style: 'tableHeader',
          alignment: 'center',
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  text: 'Productor(a)',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Supervisor(a) de producción',
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
              [
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.productor,
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.supervisor,
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  text: 'Contralor',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Contador de producción',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Administrador',
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
              [
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.contralor,
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.contProduccion,
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.administrador,
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDefinition).open();
  }

  // Creacion de PDF Masivo
  // generatePDF2() {
  //   console.log(this.listRequest);
  //   let contenido = [] as any;

  //   for (let index = 0; index < this.listRequest.length; index++) {
  //     const data = this.listRequest[index];
  //     const content = [
  //       {
  //         qr: `https://enconexion.online/${this.idCompany}/${this.idProject}/${data.id}`,
  //         fit: '70',
  //         absolutePosition: { x: 530, y: 30 },
  //       },
  //       {
  //         text: this.company.name.toUpperCase(),
  //         alignment: 'center',
  //         style: 'header',
  //         // fontSize: 7,
  //       },
  //       {
  //         text: this.project.name.toUpperCase(),
  //         alignment: 'center',
  //         style: 'header',
  //         // fontSize: 7,
  //       },
  //       {
  //         text: 'No. ' + data.countRequest.toString().padStart(4, '0'),
  //         alignment: 'right',
  //         style: 'tableHeader',
  //       },
  //       {
  //         table: {
  //           widths: ['*'],
  //           body: [
  //             [
  //               {
  //                 text: 'Solicitud de pago',
  //                 alignment: 'center',
  //                 style: 'subheader',
  //               },
  //             ],
  //           ],
  //         },
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         table: {
  //           widths: '*',
  //           headerRows: 1,
  //           body: [
  //             [
  //               { text: 'Periodo y fecha de producción', style: 'tableHeader' },
  //               { text: 'De:', style: 'tableHeader' },
  //               { text: 'A:', style: 'tableHeader' },
  //             ],
  //             [
  //               data.periodo,
  //               moment(data.fechaIni).format('L'),
  //               moment(data.fechaFin).format('L'),
  //             ],
  //           ],
  //         },
  //         // layout: 'noBorders',
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         table: {
  //           widths: '*',
  //           headerRows: 1,
  //           body: [
  //             [
  //               {
  //                 text: 'Fecha de elaboracion',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Fecha de pago',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Folio de comprobante',
  //                 style: 'tableHeader',
  //               },
  //             ],
  //             [
  //               moment(new Date(data.fechaCreado.seconds * 1000)).format('L'),
  //               moment(data.pagoSoli).format('L'),
  //               '...' + data.folioComp.substr(-12).toUpperCase(),
  //             ],
  //           ],
  //         },
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         table: {
  //           widths: '*',
  //           headerRows: 1,
  //           body: [
  //             [
  //               {
  //                 text: 'Partida presupuestal',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Importe',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'IVA',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Retencion IVA',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Retencion ISR',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Total',
  //                 style: 'tableHeader',
  //               },
  //             ],
  //             [
  //               data.movimientos[0].Cuenta,
  //               data.movimientos[0].Cargo.toLocaleString('en-US', {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //               }),
  //               data.movimientos[1].Cargo.toLocaleString('en-US', {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //               }),
  //               data.movimientos[2].Abono.toLocaleString('en-US', {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //               }),
  //               data.movimientos[3].Abono.toLocaleString('en-US', {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //               }),
  //               (
  //                 data.movimientos[0].Cargo +
  //                 data.movimientos[1].Cargo -
  //                 data.movimientos[2].Abono -
  //                 data.movimientos[3].Abono
  //               ).toLocaleString('en-US', {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //               }),
  //             ],
  //           ],
  //         },
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         table: {
  //           widths: '*',
  //           body: [
  //             [
  //               {
  //                 rowSpan: 2,
  //                 text: 'Importe Total:',
  //                 style: 'tableHeader',
  //               },
  //               { text: 'Importe', style: 'tableHeader' },
  //               { text: 'Moneda', style: 'tableHeader' },
  //               { text: 'T.C.', style: 'tableHeader' },
  //             ],
  //             [
  //               '',
  //               (
  //                 data.movimientos[0].Cargo +
  //                 data.movimientos[1].Cargo -
  //                 data.movimientos[2].Abono -
  //                 data.movimientos[3].Abono
  //               ).toLocaleString('en-US', {
  //                 minimumFractionDigits: 2,
  //                 maximumFractionDigits: 2,
  //               }),
  //               data.tipoMoneda,
  //               data.tipoCambio,
  //             ],
  //           ],
  //         },
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         table: {
  //           widths: [100, '*'],
  //           body: [
  //             [{ text: 'Concepto:', style: 'tableHeader' }, data.concepto],
  //             [
  //               { text: 'Cheque a favor de:', style: 'tableHeader' },
  //               data.providerName,
  //             ],
  //             [{ text: 'RFC:', style: 'tableHeader' }, data.rfcProvider],
  //             // [
  //             //   { text: 'Departamento', style: 'tableHeader' },
  //             //   data.nameDepartament,
  //             // ],
  //             [{ text: 'Categoria:', style: 'tableHeader' }, data.departamento],
  //           ],
  //         },
  //         // layout: 'noBorders',
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         text: 'Aprobacion',
  //         style: 'tableHeader',
  //         alignment: 'center',
  //       },
  //       {
  //         table: {
  //           widths: '*',
  //           body: [
  //             [
  //               {
  //                 text: 'Productor(a)',
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Supervisor(a) de producción',
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //             ],
  //             [
  //               {
  //                 text:
  //                   '\n\n\n________________________________\n' +
  //                   this.project.productor,
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text:
  //                   '\n\n\n________________________________\n' +
  //                   this.project.supervisor,
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //             ],
  //           ],
  //         },
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       {
  //         table: {
  //           widths: '*',
  //           body: [
  //             [
  //               {
  //                 text: 'Contralor',
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Contador de produccion',
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text: 'Administrador',
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //             ],
  //             [
  //               {
  //                 text:
  //                   '\n\n\n________________________________\n' +
  //                   this.project.contralor,
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text:
  //                   '\n\n\n________________________________\n' +
  //                   this.project.contProduccion,
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //               {
  //                 text:
  //                   '\n\n\n________________________________\n' +
  //                   this.project.administrador,
  //                 alignment: 'center',
  //                 style: 'tableHeader',
  //               },
  //             ],
  //           ],
  //         },
  //       },
  //       {
  //         text: '\n\n',
  //       },
  //       this.listRequest.length !== index + 1
  //         ? {
  //             text: '',
  //             pageBreak: 'before',
  //           }
  //         : {},
  //     ];
  //     contenido.push(content);
  //   }
  //   const arr = [];
  //   const nuevo_array = arr.concat(contenido);

  //   const dd = {
  //     pageSize: 'LETTER',
  //     pageOrientation: 'portrait', // landscape
  //     styles: {
  //       header: {
  //         fontSize: 12,
  //         bold: true,
  //         margin: [0, 0, 0, 10],
  //       },
  //       subheader: {
  //         fontSize: 10,
  //         bold: true,
  //       },
  //       tableHeader: {
  //         bold: true,
  //         fontSize: 10,
  //         color: 'black',
  //       },
  //     },
  //     defaultStyle: {
  //       fontSize: 9,
  //     },
  //     // info: {
  //     //   title: 'awesome Document',
  //     //   author: 'john doe',
  //     //   subject: 'subject of document',
  //     //   keywords: 'keywords for document',
  //     // },

  //     content: nuevo_array,
  //   };
  //   pdfMake.createPdf(dd).open();
  // }
  generatePDF2() {
    for (let index = 0; index < this.listRequest.length; index++) {
      const data = this.listRequest[index];
      const dd = {
        pageSize: 'LETTER',
        pageOrientation: 'portrait', // landscape
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 10,
            bold: true,
          },
          tableHeader: {
            bold: true,
            fontSize: 10,
            color: 'black',
          },
        },
        defaultStyle: {
          fontSize: 9,
        },
        // info: {
        //   title: 'awesome Document',
        //   author: 'john doe',
        //   subject: 'subject of document',
        //   keywords: 'keywords for document',
        // },
        content: [
          {
            qr: `https://enconexion.online/${this.idCompany}/${this.idProject}/${data.id}`,
            fit: '70',
            absolutePosition: { x: 530, y: 30 },
          },
          {
            text: this.company.name.toUpperCase(),
            alignment: 'center',
            style: 'header',
            // fontSize: 7,
          },
          {
            text: this.project.name.toUpperCase(),
            alignment: 'center',
            style: 'header',
            // fontSize: 7,
          },
          {
            text: 'No. ' + data.countRequest.toString().padStart(4, '0'),
            alignment: 'right',
            style: 'tableHeader',
          },
          {
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: 'Solicitud de pago',
                    alignment: 'center',
                    style: 'subheader',
                  },
                ],
              ],
            },
          },
          {
            text: '\n\n',
          },
          {
            table: {
              widths: '*',
              headerRows: 1,
              body: [
                [
                  {
                    text: 'Periodo y fecha de producción',
                    style: 'tableHeader',
                  },
                  { text: 'De:', style: 'tableHeader' },
                  { text: 'A:', style: 'tableHeader' },
                ],
                [
                  data.periodo,
                  moment(data.fechaIni).format('L'),
                  moment(data.fechaFin).format('L'),
                ],
              ],
            },
            // layout: 'noBorders',
          },
          {
            text: '\n\n',
          },
          {
            table: {
              widths: '*',
              headerRows: 1,
              body: [
                [
                  {
                    text: 'Fecha de elaboración',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Fecha de pago',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Folio de comprobante',
                    style: 'tableHeader',
                  },
                ],
                [
                  moment(new Date(data.fechaCreado.seconds * 1000)).format('L'),
                  moment(data.pagoSoli).format('L'),
                  '...' + data.folioComp.substr(-12).toUpperCase(),
                ],
              ],
            },
          },
          {
            text: '\n\n',
          },
          {
            table: {
              widths: '*',
              headerRows: 1,
              body: [
                [
                  {
                    text: 'Partida presupuestal',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Importe',
                    style: 'tableHeader',
                  },
                  {
                    text: 'IVA',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Retención IVA',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Retención ISR',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Total',
                    style: 'tableHeader',
                  },
                ],
                [
                  data.movimientos[0].Cuenta,
                  data.movimientos[0].Cargo.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  data.movimientos[1].Cargo.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  data.movimientos[2].Abono.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  data.movimientos[3].Abono.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  (
                    data.movimientos[0].Cargo +
                    data.movimientos[1].Cargo -
                    data.movimientos[2].Abono -
                    data.movimientos[3].Abono
                  ).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                ],
              ],
            },
          },
          {
            text: '\n\n',
          },
          {
            table: {
              widths: '*',
              body: [
                [
                  {
                    rowSpan: 2,
                    text: 'Importe Total:',
                    style: 'tableHeader',
                  },
                  { text: 'Importe', style: 'tableHeader' },
                  { text: 'Moneda', style: 'tableHeader' },
                  { text: 'T.C.', style: 'tableHeader' },
                ],
                [
                  '',
                  (
                    data.movimientos[0].Cargo +
                    data.movimientos[1].Cargo -
                    data.movimientos[2].Abono -
                    data.movimientos[3].Abono
                  ).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  data.tipoMoneda,
                  data.tipoCambio,
                ],
              ],
            },
          },
          {
            text: '\n\n',
          },
          {
            table: {
              widths: [100, '*'],
              body: [
                [{ text: 'Concepto:', style: 'tableHeader' }, data.concepto],
                [
                  { text: 'Cheque a favor de:', style: 'tableHeader' },
                  data.providerName,
                ],
                [{ text: 'RFC:', style: 'tableHeader' }, data.rfcProvider],
                // [
                //   { text: 'Departamento', style: 'tableHeader' },
                //   data.nameDepartament,
                // ],
                [
                  { text: 'Categoría:', style: 'tableHeader' },
                  data.departamento,
                ],
              ],
            },
            // layout: 'noBorders',
          },
          {
            text: '\n\n',
          },
          {
            text: 'Aprobación',
            style: 'tableHeader',
            alignment: 'center',
          },
          {
            table: {
              widths: '*',
              body: [
                [
                  {
                    text: 'Productor(a)',
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Supervisor(a) de producción',
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    text:
                      '\n\n\n________________________________\n' +
                      this.project.productor,
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                  {
                    text:
                      '\n\n\n________________________________\n' +
                      this.project.supervisor,
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                ],
              ],
            },
          },
          {
            text: '\n\n',
          },
          {
            table: {
              widths: '*',
              body: [
                [
                  {
                    text: 'Contralor',
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Contador de produccion',
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                  {
                    text: 'Administrador',
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                ],
                [
                  {
                    text:
                      '\n\n\n________________________________\n' +
                      this.project.contralor,
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                  {
                    text:
                      '\n\n\n________________________________\n' +
                      this.project.contProduccion,
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                  {
                    text:
                      '\n\n\n________________________________\n' +
                      this.project.administrador,
                    alignment: 'center',
                    style: 'tableHeader',
                  },
                ],
              ],
            },
          },
          {
            text: '\n\n',
          },
        ],
      };

      pdfMake.createPdf(dd).open();
    }
  }

  ngOnDestroy() {
    if (this.subscriberCountRequest) {
      this.subscriberCountRequest.unsubscribe();
    }
    if (this.subscriberGetBudget) {
      this.subscriberGetBudget.unsubscribe();
    }
    if (this.subscriberProjectActual) {
      this.subscriberProjectActual.unsubscribe();
    }
    if (this.subscriberCompanyActual) {
      this.subscriberCompanyActual.unsubscribe();
    }
  }
}
