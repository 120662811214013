import { Component, OnInit, ViewChild } from '@angular/core';
import { DealMemoComponent } from '../deal-memo/deal-memo.component';
import { GeneralService } from 'src/app/services/general.service';
declare var $;
@Component({
  selector: 'app-report-deal',
  templateUrl: './report-deal.component.html',
  styleUrls: ['./report-deal.component.css'],
})
export class ReportDealComponent implements OnInit {
  dealMemoSeleccionado = {} as any;
  sumaPagos = 0;
  dealMemo = [] as any;

  @ViewChild(DealMemoComponent) dealMemoComponent: DealMemoComponent;

  constructor(private generalservice: GeneralService) {}

  ngOnInit(): void {
    this.generalservice.obteniendoDeals.subscribe(res => {
      this.dealMemo = res;
      console.log(this.dealMemo);
      this.sumaPagos = 0;
      this.dealMemo.pagos.forEach(element => {
        this.sumaPagos += element.importe;
      });
      // console.log(this.sumaPagos);
      this.dealMemoComponent.dealMemo = this.dealMemo;
      this.dealMemoComponent.totales();
      this.dealMemoComponent.countDealMemo.countDealMemo =
        this.dealMemo.countDealMemo;
    });
  }
  // editDealMemo() {
  //   this.dealMemo;
  //   console.log(this.dealMemo);
  //   this.dealMemoSeleccionado = this.dealMemo;

  // }
}
