import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numSol',
})
export class ConvertPipe implements PipeTransform {
  transform(value) {
    const data = value.toString().padStart(7, '0');
    return data;
  }
}
