import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserPublic } from 'src/app/models/user';
import { EmpresasService } from 'src/app/services/empresas.service';
import Notiflix from 'notiflix-angular';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { Provider } from 'src/app/models/providers';

@Component({
  selector: 'app-public-users',
  templateUrl: './public-users.component.html',
  styleUrls: ['./public-users.component.css'],
})
export class PublicUsersComponent implements OnInit, OnDestroy {
  public user = {} as UserPublic;
  users: any;
  companys = [] as any;
  projects = [] as any;
  projectSelected = {} as any;
  grupos = [] as any;
  departamentSelected = {} as any;
  providers = [] as any;
  proveedorSeleccionado: Provider;

  getUsersPublicSubscription: Subscription;
  getCompanySubscription: Subscription;
  getProjectSubscription: Subscription;
  getProvidersSubscription: Subscription;

  constructor(
    private empresaService: EmpresasService,
    private generalService: GeneralService,
    private projectService: ProjectService,
    private providersService: ProvidersService
  ) {}

  ngOnInit(): void {
    this.getProviders();
    this.getUsers();
    this.getCompany();
    this.generatePass(8, 'alf');
  }

  getProviders() {
    this.getProvidersSubscription = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  getUsers() {
    this.getUsersPublicSubscription = this.generalService
      .getUsersPublic()
      .subscribe(res => {
        this.users = res;
      });
  }

  getCompany() {
    this.getCompanySubscription = this.empresaService
      .getCompany()
      .subscribe(res => {
        this.companys = res;
      });
  }

  getProject() {
    this.projects = [] as any;
    this.grupos = [] as any;
    this.getProjectSubscription = this.empresaService
      .getProject(this.user.idCompany)
      .subscribe(res => {
        this.projects = res;
      });
  }

  selectProject() {
    this.user.idProject = this.projectSelected.id;
    this.user.nameProject = this.projectSelected.name;
    this.grupos = [] as any;
    this.projectService
      .getGroups(this.user.idCompany, this.user.idProject)
      .subscribe(res => {
        if (res) {
          this.grupos = res;
        }
      });
  }

  generatePass(length, type) {
    let characters: string;
    switch (type) {
      case 'num':
        characters = '0123456789';
        break;
      case 'alf':
        characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        break;
      case 'rand':
        //FOR ↓
        break;
      default:
        characters =
          'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        break;
    }
    let pass = '';
    for (let i = 0; i < length; i++) {
      if (type == 'rand') {
        pass += String.fromCharCode(
          (Math.floor(Math.random() * 100) % 94) + 33
        );
      } else {
        pass += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    }
    this.user.pass = pass;
    // return pass;
  }

  saveUser() {
    this.user.departamento = this.departamentSelected.name;
    this.user.idDepartament = this.departamentSelected.id;
    if (this.validateEmail()) {
      if (
        this.user.email &&
        this.user.name &&
        this.user.pass &&
        this.user.idCompany &&
        this.user.idProject &&
        this.user.idDepartament
      ) {
        this.generalService.addUsersPublic(this.user).then(() => {
          Notiflix.Notify.Success('Agregado correctramente');
        });
        this.reset();
      } else {
        Notiflix.Notify.Failure('Llena todos los campos');
      }
    } else {
      Notiflix.Notify.Failure('Email Invalido');
    }
  }

  validateEmail() {
    // eslint-disable-next-line
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(this.user.email).toLowerCase());
  }

  reset() {
    this.user = {};
    this.generatePass(8, 'alf');
  }

  deleteUser(idUser) {
    this.generalService.deleteUserPublic(idUser).then(() => {
      Notiflix.Notify.Success('Eliminado exitosamente');
    });
  }

  selectProvider() {
    this.user.name = this.proveedorSeleccionado.nombre;
    this.user.rfc = this.proveedorSeleccionado.rfc;
    this.user.email = this.proveedorSeleccionado.email;
  }

  ngOnDestroy() {
    this.getProvidersSubscription.unsubscribe();
    this.getUsersPublicSubscription.unsubscribe();
    this.getCompanySubscription.unsubscribe();
    if (this.getProjectSubscription) {
      this.getProjectSubscription.unsubscribe();
    }
  }
}
