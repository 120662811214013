import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Empresa } from '../models/empresas';
import { Observable } from 'rxjs';
import { map, timeInterval, retry } from 'rxjs/operators';
import { Proyecto } from '../models/proyectos';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class EmpresasService {
  empresasCollection: AngularFirestoreCollection<Empresa>;
  proyectosCollection: AngularFirestoreCollection<Proyecto>;
  userCollection: AngularFirestoreCollection<User>;
  userDocument: AngularFirestoreDocument<User>;
  empresas: Observable<Empresa[]>;
  proyectos: Observable<Proyecto[]>;
  empresasDoc: AngularFirestoreDocument<Empresa>;
  proyectosDoc: AngularFirestoreDocument<Proyecto>;
  nombreProyecto: string;

  constructor(private firestore: AngularFirestore) {}

  getCompanyDashboard(id) {
    const idUser = 'users.' + id;
    this.empresasCollection = this.firestore.collection('empresas', ref =>
      ref.where(idUser, '==', true)
    );
    return this.empresasCollection.valueChanges();
  }
  getCompany() {
    this.empresasCollection = this.firestore.collection('empresas');
    this.empresas = this.empresasCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Empresa;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.empresas;
  }
  getCompanySpecific(idUser) {
    this.empresasCollection = this.firestore.collection('empresas');
    this.empresas = this.empresasCollection.snapshotChanges().pipe(
      map(actions => {
        const arr = [];
        actions.forEach(a => {
          const users = a.payload.doc.data().users;
          users.forEach(element => {
            if (element.id === idUser) {
              const data = a.payload.doc.data();
              data.id = a.payload.doc.id;
              arr.push(data);
            }
          });
        });
        return arr;
      })
    );
    return this.empresas;
  }
  addCompany(empresa: Empresa) {
    return this.empresasCollection.add(empresa);
  }
  updateCompany(empresa: Empresa) {
    return this.firestore
      .collection('empresas')
      .doc(empresa.id)
      .update(empresa);
  }
  projectExistCompany(empresa: Empresa) {
    return this.firestore.firestore
      .collection('empresas')
      .doc(empresa.id)
      .collection('proyectos')
      .get();
  }
  projecExist(idCompany, idProject, uid) {
    return this.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('usuarios', ref => ref.where('uid', '==', uid))
      .get()
      .toPromise();
  }
  userExistCompany(empresa: Empresa) {
    return this.firestore.firestore
      .collection('empresas')
      .doc(empresa.id)
      .collection('usuarios')
      .get();
  }
  deleteCompany(empresa: Empresa) {
    this.empresasDoc = this.firestore.doc(`empresas/${empresa.id}`);
    this.empresasDoc.delete();
  }
  getProjectDashboard(idCompany, idUs) {
    const idUser = 'users.' + idUs;
    this.proyectosCollection = this.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos', ref => ref.where(idUser, '==', true));
    this.proyectos = this.proyectosCollection.valueChanges();
    return this.proyectos;
  }
  getProject(id) {
    this.proyectosCollection = this.firestore
      .collection('empresas')
      .doc(id)
      .collection('proyectos');
    this.proyectos = this.proyectosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Proyecto;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
    return this.proyectos;
  }
  getProjectFil(idCompany, idUser) {
    this.proyectosCollection = this.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos');
    return this.proyectosCollection.snapshotChanges().pipe(
      map(actions => {
        const arr = [] as any;
        actions.forEach(a => {
          const users = a.payload.doc.data().users;
          users.forEach(element => {
            if (element.id === idUser) {
              const data = a.payload.doc.data() as Proyecto;
              data.id = a.payload.doc.id;
              arr.push(data);
            }
          });
        });
        return arr;
      })
    );
  }

  getProjectSpecific(idCompany, idProject) {
    return this.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .valueChanges();
  }

  addProject(proyecto: Proyecto) {
    return this.proyectosCollection.add(proyecto);
  }
  userExistProject(idCompany, idProject) {
    return this.firestore.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject.id)
      .collection('usuarios')
      .get();
  }

  editUserCompany(idCompany, obj) {
    return this.firestore
      .collection('empresas')
      .doc(idCompany)
      .update({ users: obj });
  }

  deleteProject(id, proyecto) {
    return this.firestore
      .doc(`empresas/${id}/proyectos/${proyecto.id}`)
      .delete();
  }
  updateProject(idCompany, idProject, obj) {
    return this.firestore
      .doc(`empresas/${idCompany}/proyectos/${idProject}`)
      .update(obj);
  }
  updateFilmadora(idCompany, idProject, obj) {
    return this.firestore
      .doc(`empresas/${idCompany}/proyectos/${idProject}`)
      .update(obj);
  }

  getCompanyActual(idCompany) {
    return this.firestore.collection('empresas').doc(idCompany).valueChanges();
  }
  getProjectActual(idCompany, idProject) {
    return this.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .valueChanges();
  }
  updateLockRutaUser(idCompany, idProject, obj) {
    return this.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .update(obj);
  }
}
