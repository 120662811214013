import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { DealMemo } from 'src/app/models/dealMemo';
import { Provider } from 'src/app/models/providers';
import Notiflix from 'notiflix-angular';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { PdfMakeWrapper, Txt, Table, Img } from 'pdfmake-wrapper';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-deal-memo-public',
  templateUrl: './deal-memo-public.component.html',
  styleUrls: ['./deal-memo-public.component.css'],
})
export class DealMemoPublicComponent implements OnInit, OnDestroy {
  rfc: string;
  providers = [] as any;
  persona = {} as any;
  personasQuePrestanServicio = [] as any;
  objMovimientos = {} as any;
  suma = 0;
  cuentas = {} as any;
  public provider = {} as Provider;
  public dealMemo: DealMemo = {
    personasPS: [],
    pagos: [],
    cuentasPer: [],
    documentos: [],
  };

  idCompany: string;
  idProject: string;
  periods: Array<any>;

  objDocumento = {} as any;

  nacionalidadExt: boolean;
  filterProviders;
  filterProviders2;

  subscriberProvider: Subscription;
  subscriberPeriods: Subscription;

  constructor(
    private providersService: ProvidersService,
    private dealMemoService: DealMemoService,
    private storage: AngularFireStorage,
    private router: Router,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[0].path;
    this.idProject = url.root.children.primary.segments[1].path;
    this.getPeriods();
    this.getProvider();
  }

  getPeriods() {
    this.subscriberPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        this.periods = Object.assign([], res);
      });
  }

  getProvider() {
    this.subscriberProvider = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  searchProvider() {
    if (
      this.rfc.length >= 12 &&
      this.rfc.length <= 13 &&
      this.rfc !== undefined
    ) {
      const proveedor: Provider = this.providers.find(
        element =>
          element.rfc === this.rfc.toUpperCase() ||
          element.taxId === this.rfc.toUpperCase()
      );
      if (proveedor) {
        this.dealMemo.proveedor = proveedor.numero;
        this.dealMemo.nombreProveedor = proveedor.nombre;
        this.dealMemo.rfcProveedor = proveedor.rfc;
      } else {
        Notiflix.Notify.Failure('No se encontro el RFC');
        Notiflix.Confirm.Show(
          'Proveedor no encontrado',
          '¿Quieres agregarlo?',
          'Si',
          'No',
          () => {
            $('#addProvider').modal('show');
            this.provider.rfc = this.rfc;
            this.addRFC();
          }
        );
      }
    } else {
      Notiflix.Notify.Failure('RFC invalido');
    }
  }

  personas() {
    if (this.persona.nombre && this.persona.rfc && this.persona.puesto) {
      this.dealMemo.personasPS.push(this.persona);
      this.persona = {};
      document.getElementById('nombrePS').focus();
    } else {
      Notiflix.Notify.Failure('Ingresa todos los datos');
    }
  }

  agregarMovimiento() {
    const obj = this.objMovimientos;
    if (obj.importe && obj.fechaInicio && obj.fechaFin) {
      this.dealMemo.pagos.push(this.objMovimientos);
      this.objMovimientos = {};
      document.getElementById('importe').focus();
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
    this.totales();
  }

  datosCuenta() {
    if (this.cuentas.cuentaclabe.length !== 18) {
      Notiflix.Notify.Failure('La cuenta CLABE debe contener 18 digitos');
      document.getElementById('cuentaclabe').classList.add('is-invalid');
    } else {
      document.getElementById('cuentaclabe').classList.remove('is-invalid');
      if (
        this.cuentas.nombre &&
        this.cuentas.bancoysucursal &&
        this.cuentas.numerodecuenta &&
        this.cuentas.cuentaclabe.length === 18
      ) {
        this.dealMemo.cuentasPer.push(this.cuentas);
        this.cuentas = {};
        document.getElementById('nombre').focus();
      } else {
        Notiflix.Notify.Failure('Tienes que llenar todos los campos');
      }
    }
  }

  totales() {
    this.suma = 0;
    this.dealMemo.pagos.forEach(element => {
      this.suma += element.importe;
    });
  }

  deleteArr(arr: Array<any>, index: number) {
    arr.splice(index, 1);
    this.totales();
  }

  addRFC() {
    if (this.provider.rfc.length <= 12) {
      this.provider.persona = 'Moral';
    } else {
      this.provider.persona = 'Física';
    }
  }

  addProvider() {
    if (
      this.provider.nombre &&
      this.provider.persona &&
      this.provider.nacionalidad &&
      this.provider.rfc &&
      this.provider.cp &&
      this.provider.regimen
    ) {
      this.provider.nombre = this.provider.nombre.trim();
      this.provider.persona = this.provider.persona.trim();
      this.provider.nacionalidad = this.provider.nacionalidad.trim();
      this.provider.rfc = this.provider.rfc.trim();
      this.provider.cp = this.provider.cp.trim();
      this.provider.regimen = this.provider.regimen.trim();
    }
    if (
      this.provider.nombre &&
      this.provider.persona &&
      this.provider.nacionalidad &&
      this.provider.rfc &&
      this.provider.cp &&
      this.provider.regimen
    ) {
      this.provider.rfc = this.provider.rfc.toUpperCase();
      this.provider.nombre = this.provider.nombre.toUpperCase();
      if (this.provider.nacionalidad === 'Extranjera') {
        if (!this.provider.taxId) {
          Notiflix.Notify.Failure(
            'Por favor llena todos los campos obligatorios.'
          );
        } else {
          if (
            this.provider.persona === 'Física' &&
            this.provider.rfc.length === 13
          ) {
            this.rfcInput();
          } else if (
            this.provider.persona === 'Moral' &&
            this.provider.rfc.length === 13
          ) {
            this.rfcInput();
          } else {
            Notiflix.Notify.Failure(
              'El RFC no coresponde a una persona' + this.provider.persona
            );
          }
        }
      } else if (this.provider.nacionalidad === 'Mexicana') {
        if (
          this.provider.persona === 'Física' &&
          this.provider.rfc.length === 13
        ) {
          this.rfcInput();
        } else if (
          this.provider.persona === 'Moral' &&
          this.provider.rfc.length === 12
        ) {
          this.rfcInput();
        } else {
          Notiflix.Notify.Failure(
            'El RFC tiene ' +
              this.provider.rfc.length +
              ' caracteres, no corresponde a una persona ' +
              this.provider.persona
          );
        }
      }
    } else {
      Notiflix.Notify.Failure('Por favor llena los campos obligatorios.');
    }
  }

  rfcInput() {
    const rfc = this.provider.rfc.toUpperCase();
    const rfcCorrecto = this.rfcValido(rfc);
    if (rfcCorrecto) {
      this.validateExistRfc();
    } else {
      Notiflix.Notify.Failure(
        'RFC invalido, por favor revisa que este correcto'
      );
    }
  }

  rfcValido(rfc, aceptarGenerico = true) {
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const validado = rfc.match(re);
    if (!validado) {
      return false;
    }

    // Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop();
    const rfcSinDigito = validado.slice(1).join('');
    const len = rfcSinDigito.length;
    const diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ';
    const indice = len + 1;
    let suma;
    let digitoEsperado;

    if (len === 12) {
      suma = 0;
    } else {
      suma = 481; // Ajuste para persona moral
    }

    for (let i = 0; i < len; i++) {
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
      digitoEsperado = 11 - (suma % 11);
      if (digitoEsperado === 11) {
        digitoEsperado = 0;
      } else {
        if (digitoEsperado === 10) {
          digitoEsperado = 'A';
        }
      }
    }
    if (
      digitoVerificador != digitoEsperado &&
      (!aceptarGenerico || rfcSinDigito + digitoVerificador != 'XAXX010101000')
    ) {
      return false;
    } else if (
      !aceptarGenerico &&
      rfcSinDigito + digitoVerificador === 'XEXX010101000'
    ) {
      return false;
    } else {
      return rfcSinDigito + digitoVerificador;
    }
  }

  validateExistRfc() {
    if (this.provider.nacionalidad === 'Mexicana') {
      if (this.providers.length > 0) {
        let existe: boolean;
        for (let index = 0; index < this.providers.length; index++) {
          if (this.providers[index].rfc === this.provider.rfc) {
            existe = true;
            Notiflix.Notify.Failure(
              'El proveedor ya se encuentra registrado con el nombre' +
                this.providers[index].nombre +
                ' con numero de registro ' +
                this.providers[index].numero
            );
            break;
          }
        }
        if (!existe) {
          this.provider.numero = this.providers.length + 1;
          this.providers.push(this.provider);
          const obj = Object.assign({}, this.providers);
          this.providersService.addProvider(obj);
          $('#addProvider').modal('hide');
          this.provider = {} as Provider;
        } else {
          existe = false;
        }
      } else {
        this.provider.numero = this.providers.length + 1;
        this.providers.push(this.provider);
        const obj = Object.assign({}, this.providers);
        this.providersService.addProvider(obj);
        $('#addProvider').modal('hide');
        this.provider = {} as Provider;
      }
    } else {
      if (this.providers.length > 0) {
        let existe: boolean;
        for (let index = 0; index < this.providers.length; index++) {
          if (this.providers[index].taxId === this.provider.taxId) {
            existe = true;
            Notiflix.Notify.Failure(
              'El proveedor ya se encuentra registrado con el nombre ' +
                this.providers[index].nombre +
                ' con numero de registro ' +
                this.providers[index].numero
            );
            break;
          }
        }
        if (!existe) {
          this.provider.numero = this.providers.length + 1;
          this.providers.push(this.provider);
          const obj = Object.assign({}, this.providers);
          this.providersService.addProvider(obj);
          $('#addProvider').modal('hide');
          this.provider = {} as Provider;
          (<any>document.getElementById('rfc')).disabled = false;
        } else {
          existe = false;
        }
      } else {
        this.provider.numero = this.providers.length + 1;
        this.providers.push(this.provider);
        const obj = Object.assign({}, this.providers);
        this.providersService.addProvider(obj);
        $('#addProvider').modal('hide');
        this.provider = {} as Provider;
      }
    }
  }

  valNacionalidad() {
    if (this.provider.nacionalidad === 'Extranjera') {
      this.nacionalidadExt = true;
      this.provider.rfc = 'XEXX010101000';
      (<any>document.getElementById('rfc')).disabled = true;
    } else {
      this.nacionalidadExt = false;
      (<any>document.getElementById('rfc')).disabled = false;
    }
  }

  uploadFile(event) {
    const fileInput = event.target.files[0];
    const fileType = fileInput.type;
    const fileSize = fileInput.size;
    const allowedExtensions = /(.pdf)$/i;
    let uploadF: any = '';
    if (!allowedExtensions.exec(fileType) || fileSize >= 1000000) {
      alert(
        'Por favor agrega unicamente archivos con extension .pdf y tamaño maximo de 1MB '
      );
      // document.getElementById('labelFile').innerHTML = 'Seleccionar';
      (<any>document.getElementById('inputGroupFile01')).value = '';
    } else {
      if (
        this.objDocumento.documento !== undefined &&
        this.dealMemo.proveedor
      ) {
        uploadF = event.target.files[0];
        // document.getElementById('labelFile').innerHTML = uploadF.name;
        // const id = Math.random().toString(36).substring(2);
        const filePath = `proveedores/${this.dealMemo.proveedor}/${this.idCompany}/${this.idProject}/${this.objDocumento.documento}`;
        const path: any = {};
        path.pathImageProfile = filePath;
        const ref = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, uploadF);
        Notiflix.Loading.Dots('Cargando...');
        task
          .then(res => {
            ref.getDownloadURL().subscribe(resultado => {
              this.objDocumento.link = resultado;
              this.objDocumento.path = filePath;
              this.objDocumento.name = fileInput.name;
              this.agregarDocumento();
              Notiflix.Loading.Remove();
            });
          })
          .catch(err => {
            console.error('Ocurrio un error', err);
            Notiflix.Loading.Remove();
            Notiflix.Notify.Failure(
              'Ocurrio un error al subir el archivo, intentalo de nuevo'
            );
          });
      } else {
        Notiflix.Notify.Failure(
          'Selecciona un tipo de documento y agrega un proveedor'
        );
        (<any>document.getElementById('inputGroupFile01')).value = '';
      }
    }
  }

  agregarDocumento() {
    if (this.objDocumento.documento && this.objDocumento.link) {
      this.dealMemo.documentos.push(this.objDocumento);
      this.objDocumento = {};
      (<any>document.getElementById('inputGroupFile01')).value = '';
    }
  }

  deleteDocument(index, obj) {
    // Create a reference to the file to delete
    const reference = this.storage.ref(obj.path);

    // Delete the file
    reference
      .delete()
      .toPromise()
      .then(function () {
        // File deleted successfully
        console.log('Se elimino correctamente');
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
        console.log('Se produjo un error al eliminar o no existia');
      });

    this.dealMemo.documentos.splice(index, 1);
  }

  validationData() {
    const d = this.dealMemo;
    console.log(d);
    if (
      d.proveedor &&
      d.departamento &&
      d.puesto &&
      d.nombreEnCreditos &&
      d.descripcionActividad &&
      d.fechaFirmaContrato &&
      d.tipoMoneda &&
      d.pagos.length &&
      d.cuentasPer.length &&
      d.documentos.length
    ) {
      return true;
    } else {
      return false;
    }
  }

  async generatePDF(data) {
    const pdf = new PdfMakeWrapper();
    moment.locale('es-MX');

    pdf.pageSize('LETTER');

    pdf.header(
      await new Img('../../../assets/logos/headerWebSite.png')
        .width(170)
        .alignment('center')
        .margin(20)
        .build()
    );

    pdf.add(pdf.ln(2));

    pdf.add(new Txt('DEAL MEMO').alignment('center').end);
    pdf.add(new Txt(`Folio: ${data.id}`).alignment('center').end);
    pdf.add(pdf.ln(1));
    pdf.add(new Txt('Proveedor').alignment('center').bold().end);

    pdf.add(
      new Table([
        [`Nombre`, `RFC`, `Beneficiario mortis causa`, `Representante legal`],
        [
          `${this.dealMemo.nombreProveedor}`,
          `${this.dealMemo.rfcProveedor}`,
          `${this.dealMemo.bmc}`,
          `${this.dealMemo.nomRepLeg}`,
        ],
      ]).layout('lightHorizontalLines').end
    );

    pdf.add(pdf.ln(1));

    pdf.add(new Txt('Datos de Producción').alignment('center').bold().end);

    pdf.add(
      new Table([
        [
          'Departamento',
          'Puesto',
          'Nombre en créditos',
          'Descripcion de la actividad',
          'Fecha firma del contrato',
          'Moneda',
        ],
        [
          this.dealMemo.departamento,
          this.dealMemo.puesto,
          this.dealMemo.nombreEnCreditos,
          this.dealMemo.descripcionActividad,
          moment(this.dealMemo.fechaFirmaContrato).format('L'),
          this.dealMemo.tipoMoneda,
        ],
      ])
        .layout('lightHorizontalLines')
        .widths('*').end
    );

    pdf.add(pdf.ln(1));

    pdf.add(new Txt('Pagos').alignment('center').bold().end);
    const pagos = [] as any;
    const encabezadoPagos = [
      'Pago',
      'Importe',
      'Periodo',
      'Fecha Inicio',
      'Fecha Fin',
    ];
    pagos.push(encabezadoPagos);

    for (let index = 0; index < this.dealMemo.pagos.length; index++) {
      const element = this.dealMemo.pagos[index];
      const arrTablePagos = [
        index + 1,
        element.importe,
        element.periodo,
        moment(element.fechaInicio).format('L'),
        moment(element.fechaFin).format('L'),
      ];
      pagos.push(arrTablePagos);
    }

    const footerPagos = ['Total:', this.suma, '', '', ''];
    pagos.push(footerPagos);

    pdf.add(
      new Table(pagos)
        .layout('lightHorizontalLines')
        .alignment('center')
        .widths('*').end
    );

    pdf.add(pdf.ln(1));

    pdf.add(new Txt('Datos Bancarios').alignment('center').bold().end);

    const datosBancarios = [] as any;
    const encabezadoBanco = [
      'Nombre',
      'Banco y Sucursal',
      'Número de Cuenta',
      'Cuenta CLABE',
    ];
    datosBancarios.push(encabezadoBanco);

    for (let index = 0; index < this.dealMemo.cuentasPer.length; index++) {
      const element = this.dealMemo.cuentasPer[index];
      const arrTableBanco = [
        element.nombre,
        element.bancoysucursal,
        element.numerodecuenta,
        element.cuentaclabe,
      ];
      datosBancarios.push(arrTableBanco);
    }

    pdf.add(
      new Table(datosBancarios).layout('lightHorizontalLines').widths('*').end
    );

    pdf.add(pdf.ln(6));

    pdf.add(
      new Txt('_______________________________________').alignment('center').end
    );
    pdf.add(new Txt(this.dealMemo.nomRepLeg).alignment('center').end);

    // pdf.footer('info@enconexion.com.mx');

    pdf.create().open();

    this.dealMemo = {
      personasPS: [],
      pagos: [],
      cuentasPer: [],
      documentos: [],
    };
    this.rfc = '';
    this.suma = 0;
  }

  saveDealMemo() {
    if (this.validationData()) {
      Notiflix.Loading.Dots('Cargando...');
      this.dealMemoService
        .saveDealMemoPublic(this.idCompany, this.idProject, this.dealMemo)
        .then(res => {
          console.log(res);
          this.generatePDF(res);
          Notiflix.Notify.Success('Se guardo correctamente');
          // this.dealMemo = {
          //   personasPS: [],
          //   pagos: [],
          //   cuentasPer: [],
          //   documentos: [],
          // };
          // this.rfc = '';
          // this.suma = 0;
          Notiflix.Loading.Remove(1000);
        })
        .catch(err => {
          Notiflix.Notify.Failure(
            'Ocurrio un error al guardar, vuelve a intentarlo'
          );
          console.error('Ocurrio un error', err);
          Notiflix.Loading.Remove(1000);
        });
    } else {
      Notiflix.Notify.Failure('Faltan campos por completar');
    }
  }

  changePeriod() {
    this.periods.forEach(element => {
      if (this.objMovimientos.periodo === element.nombrePeriodo) {
        this.objMovimientos.fechaInicio = element.fechaInicio;
        this.objMovimientos.fechaFin = element.fechaFin;
      }
    });
  }

  ngOnDestroy() {
    this.subscriberProvider.unsubscribe();
    this.subscriberPeriods.unsubscribe();
  }
}
