import { Component, OnInit } from '@angular/core';
import { RequestService } from '../services/request.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-utils',
  templateUrl: './utils.component.html',
  styleUrls: ['./utils.component.css'],
})
export class UtilsComponent implements OnInit {
  idCompany: string;
  idProject: string;
  arrRequest = [] as any;

  constructor(private requestService: RequestService, private router: Router) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
  }

  // Backup de solicitudes <<< -------
  backupSols() {
    let subscription = this.requestService
      .getRequestAll(this.idCompany, this.idCompany)
      .subscribe(res => {
        this.requestService
          .backupSols(this.idCompany, this.idProject, { backup: res })
          .then(() => {
            console.log('backup Creado');
          });
        subscription.unsubscribe();
      });
  }
}
// ------------------------ >>>
