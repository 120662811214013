import { EventEmitter, Injectable, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  @Output() obteniendoDeals: EventEmitter<any> = new EventEmitter();
  dataUser: any;

  constructor(private afs: AngularFirestore) {}

  getCatCtas() {
    return this.afs.collection('catCtas').doc('catCtas').valueChanges();
  }

  addCatCtas(obj) {
    return this.afs.collection('catCtas').doc('catCtas').set(obj);
  }
  addpages(obj) {
    return this.afs.collection('pages').doc('pages').set(obj);
  }
  getPages() {
    return this.afs.collection('pages').doc('pages').valueChanges();
  }
  reportForDateSol(idCompany, idProject, fInicio, fFin) {
    const inicial = new Date(fInicio + 'T00:00:00');
    const final = new Date(fFin + 'T23:59:59');
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('solicitudes', ref =>
        ref.where('fechaIni', '>=', inicial).where('fechaIni', '<=', final)
      )
      .valueChanges();
  }
  reportForDateDealMemo(idCompany, idProject, fInicio, fFin) {
    const inicial = new Date(fInicio + 'T00:00:00');
    const fechaInicio = inicial.toISOString().slice(0, 10);

    const final = new Date(fFin + 'T23:59:59');
    const fechaFinal = final.toISOString().slice(0, 10);

    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('solicitudes', ref =>
        ref
          .where('fechaIni', '==', fechaInicio)
          .where('fechaFin', '<=', fechaFinal)
      )
      .valueChanges();
  }
  deleteCatCtas() {
    this.afs
      .collection('catCtas')
      .doc('catCtas')
      .delete()
      .then(() => console.log('Catalogo de cuentas eliminado correctamente!!'));
  }
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { DealMemos: worksheet },
      SheetNames: ['DealMemos'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION
    );
  }

  getUsersPublic() {
    return this.afs
      .collection('usersPublic')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  addUsersPublic(user) {
    return this.afs.collection('usersPublic').add(user);
  }

  getUserPublic(email, pass) {
    return this.afs
      .collection('usersPublic', ref =>
        ref.where('email', '==', email).where('pass', '==', pass)
      )
      .valueChanges();
  }

  deleteUserPublic(idUser) {
    return this.afs.collection('usersPublic').doc(idUser).delete();
  }

  enviarData(data) {
    this.dataUser = data;
  }

  contactForm(obj) {
    return this.afs.collection('contactForm').add(obj);
  }
  getDevice(id) {
    return this.afs.collection('devices').doc(id).valueChanges();
  }
  getDevices() {
    return this.afs.collection('devices').valueChanges();
  }
  saveDevice(obj: object, id: string) {
    return this.afs.collection('devices').doc(id).set(obj);
  }

  assignDataCFDI(obj: any) {
    const xml = {} as any;
    if (obj['cfdi:Comprobante']) {
      xml.proveedor =
        obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

      xml.rfc = obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

      xml.regimen =
        obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

      xml.rfcReceptor =
        obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc;

      xml.lugarExpedicion =
        obj['cfdi:Comprobante']['@attributes'].LugarExpedicion;

      // Valido si es array o un objeto
      if (
        Array.isArray(
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
        )
      ) {
        // Genero un array si tiene varios conceptos <<<<<<<<<<<<
        xml.conceptos = [] as any;
        obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'].forEach(
          element => {
            element['@attributes'].ValorUnitario = parseFloat(
              element['@attributes'].ValorUnitario
            );
            xml.conceptos.push(element['@attributes']);
          }
        );
        // >>>>>>>>>>>>>>>>>>>
        xml.concepto =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
            '@attributes'
          ].Descripcion;

        xml.claveProdServ =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
            '@attributes'
          ].ClaveProdServ;

        xml.claveUnidad =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
            '@attributes'
          ].ClaveUnidad;
      } else {
        xml.concepto =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
            '@attributes'
          ].Descripcion;

        xml.claveProdServ =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
            '@attributes'
          ].ClaveProdServ;

        xml.claveUnidad =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
            '@attributes'
          ].ClaveUnidad;
      }
      // -------------------------------------

      xml.folioComprobante =
        obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
          '@attributes'
        ].UUID;

      let fecha = obj['cfdi:Comprobante']['@attributes'].Fecha;
      xml.fecha = new Date(fecha);

      xml.importe = parseFloat(obj['cfdi:Comprobante']['@attributes'].SubTotal);
      xml.descuento =
        parseFloat(obj['cfdi:Comprobante']['@attributes'].Descuento) || 0;

      xml.tipoComprobante =
        obj['cfdi:Comprobante']['@attributes'].TipoDeComprobante;

      xml.metodoPago = obj['cfdi:Comprobante']['@attributes'].MetodoPago || '';

      xml.formaPago = obj['cfdi:Comprobante']['@attributes'].FormaPago || '';

      xml.moneda = obj['cfdi:Comprobante']['@attributes'].Moneda;

      xml.total = parseFloat(obj['cfdi:Comprobante']['@attributes'].Total);

      // Validacion si tiene impuestos
      if (obj['cfdi:Comprobante']['cfdi:Impuestos']) {
        // impuestos trasladados
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados']) {
          xml.iva = parseFloat(
            obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados'][
              'cfdi:Traslado'
            ]['@attributes'].Importe
          );
        }

        // retenciones
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
          const retenciones =
            obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones'][
              'cfdi:Retencion'
            ];
          const esArrayRetenciones = Array.isArray(retenciones);
          if (esArrayRetenciones) {
            retenciones.forEach(element => {
              if (element['@attributes'].Impuesto === '002') {
                xml.retIVA = parseFloat(element['@attributes'].Importe);
              } else if (element['@attributes'].Impuesto === '001') {
                xml.retISR = parseFloat(element['@attributes'].Importe);
              }
            });
          } else {
            if (retenciones['@attributes'].Impuesto === '002') {
              xml.retIVA = parseFloat(retenciones['@attributes'].Importe);
            } else if (retenciones['@attributes'].Impuesto === '001') {
              xml.retISR = parseFloat(retenciones['@attributes'].Importe);
            }
          }
        }
      }
      return xml;
    }
  }

  getBitacoraSubscription: Subscription;
  bitacora = [] as any;

  getBitacora(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('bitacora')
      .doc('docBitacora')
      .valueChanges();
  }

  updateBitacora(idCompany, idProject, arrBitacora) {
    const obj = { bitacora: arrBitacora };
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('bitacora')
      .doc('docBitacora')
      .set(obj);
  }

  getBit(idCompany, idProject) {
    this.getBitacora(idCompany, idProject).subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.bitacora = res.bitacora;
      }
    });
  }

  updateBit(idCompany, idProject, objBitacora) {
    this.bitacora.push(objBitacora);
    const obj = { bitacora: this.bitacora };
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('bitacora')
      .doc('docBitacora')
      .set(obj);
  }
}
