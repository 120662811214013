import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {
  titulo: string;
  objPermisos = {} as any;
  arrPermisos;
  arrItems = [] as any;
  user;
  permiso = {} as any;

  menuPrincipal = '';

  routerSubscriber: Subscription;
  userSubscriber: Subscription;
  permisosSubscriber: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.routerSubscriber = this.activatedRoute.data.subscribe(
      res => {
        this.titulo = res.title;
      },
      err => {
        console.error('Ocurrio un error', err);
      }
    );
    this.userSubscriber = this.authService.userData$.subscribe(res => {
      this.user = res;
    });
    // this.getPermisos();
  }

  // getPermisos() {
  //   this.permisosSubscriber = this.authService
  //     .getPremissions()
  //     .subscribe((res: any) => {
  //       this.arrPermisos = Object.assign([], res);
  //       console.log(this.arrPermisos);
  //       this.generarPermisos();
  //     });
  // }

  // generarPermisos() {
  //   for (let index = 0; index < this.arrPermisos.length; index++) {
  //     const element = this.arrPermisos[index];
  //     this.permiso[element.page] = false;
  //     element.users.forEach(elementUsers => {
  //       if (this.user.email === elementUsers) {
  //         this.permiso[element.page] = true;
  //       }
  //     });
  //   }
  //   console.log(this.permiso);
  // }

  ngOnDestroy() {
    this.routerSubscriber.unsubscribe();
    this.userSubscriber.unsubscribe();
    // this.permisosSubscriber.unsubscribe();
  }
}
