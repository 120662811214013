import { Component, OnInit, OnDestroy } from '@angular/core';
import Notiflix from 'notiflix-angular';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/services/project.service';
import { ChecksService } from 'src/app/services/checks.service';
import { Comprobaciones } from 'src/app/models/comprobaciones';
import { GeneralService } from 'src/app/services/general.service';
import { RequestService } from 'src/app/services/request.service';
import { AngularFireStorage } from '@angular/fire/storage';

declare var $;

@Component({
  selector: 'app-checks',
  templateUrl: './checks.component.html',
  styleUrls: ['./checks.component.css'],
})
export class ChecksComponent implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;
  idUser: string;
  comprobacion = {
    comprobantes: [],
    deducible: 'si',
    archivo: {
      link: '',
      name: '',
    },
  } as Comprobaciones;
  rfcReceptor: string;
  arrXML = [] as any;
  xml = {
    inventario: 'No',
  } as any;
  dataUser = this.generalService.dataUser;
  totales = {} as any;
  cuentas = [] as any;
  idCheck: string;
  counter: number;
  departament = {} as any;
  requests = [] as any;
  comprobacionSeleccionada = {} as any;
  solicitudSeleccionada = {} as any;
  totalSolicitudes = 0;
  partidaGeneral: any;
  file = {} as any;

  subscribeGetCFDI: Subscription;
  subscribeGetDepartament: Subscription;
  SubscriberCountCheck: Subscription;
  getCompanySubscription: Subscription;
  getGXCSubscription: Subscription;

  constructor(
    private ngxXml2jsonService: NgxXml2jsonService,
    private projectService: ProjectService,
    private checksService: ChecksService,
    public generalService: GeneralService,
    public requestService: RequestService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.idCompany = this.dataUser.idCompany;
    this.idProject = this.dataUser.idProject;
    this.getXML();
    this.comprobacion.nombre = this.dataUser.name;
    this.comprobacion.proyecto = this.dataUser.nameProject;
    this.comprobacion.departamento = this.dataUser.departamento;
    this.getDepartament();
    this.getGXC();
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXMLPublic(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.arrXML = res.arrXML;
        } else {
          this.arrXML = [];
        }
      });
  }

  getDepartament() {
    this.subscribeGetDepartament = this.projectService
      .getGroup(this.idCompany, this.idProject, this.dataUser.idDepartament)
      .subscribe((res: any) => {
        this.departament = res;
        this.comprobacion.departamento = res.name;
        this.cuentas = res.cuentas;
        if (res.countCheck) {
          this.counter = res.countCheck + 1;
        } else {
          this.counter = 1;
        }
      });
  }

  getGXC() {
    this.getGXCSubscription = this.requestService
      .tipoReporteGXC(this.idCompany, this.idProject, this.dataUser.rfc)
      .subscribe((res: Array<Request>) => {
        this.requests = res.map((request: any) => {
          let importe = 0;
          request.movimientos.forEach((element: any) => {
            if (element.Cargo && element.Cuenta !== '119-01-001') {
              importe += element.Cargo;
            }
          });
          request.importe = importe;

          if (request.comprobaciones) {
            let comprobado = 0;
            request.comprobaciones.forEach((element: any) => {
              comprobado += element.importe;
            });
            request.comprobado = comprobado;
          }
          return request;
        });
        this.getGXCSubscription.unsubscribe();
      });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    (<any>document.getElementById('inputFiles')).value = '';
  }

  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.validarSiExiste(obj);
  }

  validarSiExiste(obj) {
    const folio =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === folio
    );
    const indexXMLLocal = this.comprobacion.comprobantes.findIndex(
      element => element.folioComprobante === folio
    );
    if (indexXML === -1 && indexXMLLocal === -1) {
      if (!obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
        // this.selectProvider(obj);
        this.assignData(obj);
        // this.validacionXML = true;
      } else {
        Notiflix.Notify.Failure('El CFDI no debe tener retenciones');
        // Notiflix.Notify.Failure('El RFC receptor no corresponde a la empresa');
      }
    } else {
      Notiflix.Notify.Failure(`El folio ${folio} ya se encuentra registrado.`);
    }
  }

  assignData(obj: any) {
    if (obj['cfdi:Comprobante']) {
      try {
        // this.xml.comprobacion = this.comprobacion.countCheck;
        this.xml.asociado = true;
        this.xml.proveedor =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

        this.xml.rfc =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

        this.xml.regimen =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

        this.xml.rfcReceptor =
          obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc;

        // Valido si es array o un objeto
        if (
          Array.isArray(
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
          )
        ) {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveUnidad;
        } else {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveUnidad;
        }
        // -------------------------------------

        this.xml.folioComprobante =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;

        this.xml.fecha = obj['cfdi:Comprobante']['@attributes'].Fecha;

        this.xml.subtotal = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].SubTotal
        );

        this.xml.descuento =
          parseFloat(obj['cfdi:Comprobante']['@attributes'].Descuento) || 0;

        this.xml.tipoComprobante =
          obj['cfdi:Comprobante']['@attributes'].TipoDeComprobante;

        this.xml.metodoPago =
          obj['cfdi:Comprobante']['@attributes'].MetodoPago || '';

        this.xml.formaPago =
          obj['cfdi:Comprobante']['@attributes'].FormaPago || '';

        this.xml.moneda = obj['cfdi:Comprobante']['@attributes'].Moneda;

        this.xml.total = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].Total
        );

        // Validacion si tiene impuestos
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']) {
          // impuestos trasladados
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados']) {
            this.xml.iva = parseFloat(
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados'][
                'cfdi:Traslado'
              ]['@attributes'].Importe
            );
          }

          // retenciones
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
            const retenciones =
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones'][
                'cfdi:Retencion'
              ];
            const esArrayRetenciones = Array.isArray(retenciones);
            if (esArrayRetenciones) {
              retenciones.forEach(element => {
                if (element['@attributes'].Impuesto === '002') {
                  this.xml.retIVA = parseFloat(element['@attributes'].Importe);
                } else if (element['@attributes'].Impuesto === '001') {
                  this.xml.retISR = parseFloat(element['@attributes'].Importe);
                }
              });
            } else {
              if (retenciones['@attributes'].Impuesto === '002') {
                this.xml.retIVA = parseFloat(
                  retenciones['@attributes'].Importe
                );
              } else if (retenciones['@attributes'].Impuesto === '001') {
                this.xml.retISR = parseFloat(
                  retenciones['@attributes'].Importe
                );
              }
            }
          }
        }
        // this.arrXML.push(this.xml);
        this.xml.inventario = 'No';
        this.xml.CodigoAgrupador = this.partidaGeneral;
        this.comprobacion.comprobantes.push(this.xml);
        this.xml = {};
      } catch (error) {
        console.error('Ocurrio un error', error);
      }
    }
    this.sumas();
  }

  sumas() {
    this.totales.importe = 0;
    this.totales.iva = 0;
    this.totales.total = 0;
    this.comprobacion.comprobantes.forEach(element => {
      this.totales.importe += element.subtotal;
      this.totales.iva += element.iva;
      this.totales.total += element.total;
    });
    this.comprobacion.importe = this.totales.importe;
    this.comprobacion.iva = this.totales.iva ? this.totales.iva : 0;
    this.comprobacion.total = this.totales.total;
  }

  saveComprobacion() {
    this.comprobacion.fechaCreacion = new Date();
    this.comprobacion.createBy = this.dataUser.email;
    this.comprobacion.countCheck = this.counter;
    this.comprobacion.aprobada = false;
    if (this.comprobacion.comprobantes.length > 0) {
      this.checksService
        .addCheckPublic(this.idCompany, this.idProject, this.comprobacion)
        .then(res => {
          this.saveXML(res.id);
          this.sumas();
          this.updateCountCheck();
          Notiflix.Notify.Success('Guardado Exitoso');
        });
    }
  }

  saveXML(idComprobacion) {
    if (this.comprobacion.deducible === 'si') {
      this.comprobacion.comprobantes.forEach(element => {
        element.check = idComprobacion;
      });
      this.arrXML = this.arrXML.concat(this.comprobacion.comprobantes);
      const obj = { arrXML: this.arrXML };
      this.projectService.saveXMLPublic(this.idCompany, this.idProject, obj);
    }
    this.updateSols();
  }

  updateSols() {
    this.requests.forEach(sol => {
      this.requestService.updateRequest(
        this.idCompany,
        this.idProject,
        sol.idSol,
        sol
      );
    });

    this.comprobacion = {
      comprobantes: [],
      deducible: 'si',
      nombre: this.dataUser.name,
      proyecto: this.dataUser.nameProject,
      departamento: this.departament.name,
      archivo: {
        link: '',
        name: '',
      },
    } as Comprobaciones;
    this.getGXC();
  }

  updateCountCheck() {
    this.departament.countCheck = this.counter;
    const obj = {
      countCheck: this.counter,
    };
    this.projectService.updateCountCkeckDepartament(
      this.idCompany,
      this.idProject,
      this.dataUser.idDepartament,
      obj
    );
  }

  cambioDeducible() {
    this.comprobacion.comprobantes = [];
    this.sumas();
    this.getXML();
  }

  addMovimiento() {
    if (
      this.xml.proveedor &&
      this.xml.concepto &&
      this.xml.folioComprobante &&
      this.xml.inventario &&
      this.xml.subtotal
    ) {
      this.xml.total = this.xml.subtotal;
      this.comprobacion.comprobantes.push(this.xml);
      this.xml = {
        inventario: 'No',
      };
      this.sumas();
    } else {
      Notiflix.Notify.Failure('Faltan datos');
    }
  }

  deleteMoviemiento(index) {
    this.comprobacion.comprobantes.splice(index, 1);
    this.sumas();
  }

  addSol(comprobacion) {
    $('#addSol').modal('show');
    this.comprobacionSeleccionada = comprobacion;
    if (!this.comprobacionSeleccionada.solicitudes) {
      this.comprobacionSeleccionada.solicitudes = [];
    }
    this.sumaTotalSols();
  }

  addSol2() {
    if (!this.solicitudSeleccionada.comprobaciones) {
      this.solicitudSeleccionada.comprobaciones = [] as any;
    }

    const exist = this.comprobacionSeleccionada.solicitudes.findIndex(
      element => element.solicitud === this.solicitudSeleccionada.countRequest
    );

    let sumaComprobado = 0;
    this.comprobacionSeleccionada.solicitudes.forEach(element => {
      sumaComprobado += element.importe;
    });

    const pendiente = this.comprobacionSeleccionada.total - sumaComprobado;

    let sumaSolicitud = 0;
    this.solicitudSeleccionada.comprobaciones.forEach(element => {
      sumaSolicitud += element.importe;
    });

    // Lo que se puede utilizar de la solicitud
    const remanente = this.solicitudSeleccionada.importe - sumaSolicitud;

    if (remanente > 0 && exist < 0 && pendiente > 0) {
      const objParaSol = {} as any;
      const objParaComp = {} as any;
      objParaComp.idSol = this.solicitudSeleccionada.idSol;
      objParaComp.solicitud = this.solicitudSeleccionada.countRequest;
      objParaSol.comprobacion = this.counter;
      if (remanente >= pendiente) {
        objParaSol.importe = pendiente;
        objParaComp.importe = pendiente;
      } else if (remanente <= pendiente) {
        objParaSol.importe = remanente;
        objParaComp.importe = remanente;
      }
      this.solicitudSeleccionada.comprobado += objParaSol.importe;
      this.solicitudSeleccionada.comprobaciones.push(objParaSol);
      this.comprobacionSeleccionada.solicitudes.push(objParaComp);
      this.sumaTotalSols();
    }
  }

  sumaTotalSols() {
    let sumaComprobado = 0;
    this.comprobacionSeleccionada.solicitudes.forEach(element => {
      sumaComprobado += element.importe;
    });
    this.totalSolicitudes = sumaComprobado;
  }

  changeCtaGral() {
    if (this.comprobacion.comprobantes.length > 0) {
      this.comprobacion.comprobantes.forEach(element => {
        element.CodigoAgrupador = this.partidaGeneral;
      });
    }
  }

  uploadFile(event) {
    const fileType = event.target.files[0].type;
    const fileSize = event.target.files[0].size;
    const allowedExtensions = /(.pdf)$/i;
    if (!allowedExtensions.exec(fileType) || fileSize >= 4000000) {
      alert(
        'Por favor agrega unicamente archivos con extension .pdf y tamaño maximo de 4MB '
      );
      // document.getElementById('labelFile').innerHTML = 'Seleccionar';
      (<any>document.getElementById('comprobantes')).value = '';
    } else {
      this.file.uploadF = event.target.files[0];
      document.getElementById('labelFile').innerHTML = this.file.uploadF.name;
    }
  }

  subirArchivo() {
    if (this.comprobacion.deducible === 'no') {
      // const id = Math.random().toString(36).substring(2); // idRandom
      this.file.filePath = `comprobaciones/${this.idCompany}/${this.idProject}/C${this.counter}/${this.file.uploadF.name}`;
      this.file.path = {} as any;
      this.file.path.pathImageProfile = this.file.filePath;
      const ref = this.storage.ref(this.file.filePath);
      const task = this.storage.upload(this.file.filePath, this.file.uploadF);
      Notiflix.Loading.Dots('Cargando...');
      task
        .then(res => {
          const subs = ref.getDownloadURL().subscribe(resultado => {
            this.comprobacion.archivo.link = resultado;
            this.comprobacion.archivo.path = this.file.filePath;
            this.comprobacion.archivo.name = this.file.uploadF.name;
            Notiflix.Loading.Remove();
            (<any>document.getElementById('comprobantes')).value = '';
            document.getElementById('labelFile').innerHTML = 'Choose file';
            this.saveComprobacion();
            subs.unsubscribe();
          });
        })
        .catch(err => {
          console.error('Ocurrio un error', err);
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(
            'Ocurrio un error al subir el archivo, intentalo de nuevo'
          );
          (<any>document.getElementById('comprobantes')).value = '';
        });
    } else {
      this.saveComprobacion();
    }
  }

  ngOnDestroy() {
    this.subscribeGetCFDI.unsubscribe();
    this.subscribeGetDepartament.unsubscribe();
  }
}
