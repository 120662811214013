import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Notiflix from 'notiflix-angular';

declare var $;

@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
  styleUrls: ['./equipos.component.css'],
})
export class EquiposComponent implements OnInit, OnDestroy {
  listDevices = [] as any;
  equipo = {} as any;
  newIdDevice: any;

  getDevicesSubscription: Subscription;

  constructor(private generalService: GeneralService) {}

  ngOnInit(): void {
    this.getDevices();
  }

  getDevices() {
    this.getDevicesSubscription = this.generalService
      .getDevices()
      .subscribe(res => {
        this.listDevices = res;
      });
  }

  openDevice(obj: any) {
    console.log(obj);
    this.equipo = obj;
    $('#modalIdDevice').modal('show');
  }

  saveDevice(id) {
    this.generalService.saveDevice(this.equipo, id).then(() => {
      Notiflix.Notify.Success('Guardado correctamente');
    });
  }

  ngOnDestroy(): void {
    this.getDevicesSubscription.unsubscribe();
  }
}
