import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { ProjectService } from 'src/app/services/project.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { DealMemo } from 'src/app/models/dealMemo';
import { Provider } from 'src/app/models/providers';
import { Router } from '@angular/router';
import Notiflix from 'notiflix-angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-deal-memo',
  templateUrl: './deal-memo.component.html',
  styleUrls: ['./deal-memo.component.css'],
})
export class DealMemoComponent implements OnInit, OnDestroy {
  @Input() dealPublic: any;

  fecha = new Date();
  dealMemo = {
    personasPS: [],
    pagos: [],
    pucharseOrderCtas: [],
    cuentasPer: [],
    documentos: [],
  } as DealMemo;
  providers: any[];
  providersSelect: any[];
  proveedorSelecionado: string;
  datosProveedor: any = {} as Provider;
  countDealMemo = {} as DealMemo;
  idCompany: any;
  idProject: any;
  isDealMemo: any;
  budget: any[];
  idBudget: any;
  cesionToggle = false;
  departament = [];
  puestos = [];
  ctasPresupuesto: any[];
  persona = {} as any;
  cuentas = {} as any;
  departamento;
  puesto;
  notificacion = false;

  periodos = [] as any;
  desarrollo = [];
  preparacion = [];
  rodaje = [];
  post = [];
  entrega = [];
  documental = [];
  acumulados = [] as any;
  objMovimientos = {} as any;
  objMovimientosMasivo = {} as any;
  selectEtapa = 'DESARROLLO';
  tipoDeCambio: number;
  url: any;
  user: string;
  grupos = [] as any;
  // grupoSeleccionado = {} as any;

  suma = 0;
  volver: string;
  typeOrder: string;

  idDealMemoPublic: string;

  objDocumento = {} as any;

  budgetArrays = [] as any;

  isMoral = false;
  project = {} as any;

  subscriberGetPeriods: Subscription;
  subscribeCountDealMemo: Subscription;
  subscriberGetBudget: Subscription;
  subscriberGetProviders: Subscription;
  subscriberGetProject: Subscription;
  subscriberGetUser: Subscription;
  subscribeGetDepartament: Subscription;

  constructor(
    private providersService: ProvidersService,
    private budgetService: BudgetService,
    private dealMemoService: DealMemoService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private router: Router,
    private storage: AngularFireStorage,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.url = this.router.parseUrl(this.router.url);

    this.idCompany = this.url.root.children.primary.segments[1].path;
    this.idProject = this.url.root.children.primary.segments[3].path;
    this.isDealMemo = this.url.root.children.primary.segments[4].path;
    this.subscriberGetUser = this.authService.userData$.subscribe(res => {
      this.dealMemo.user = res.email;
      this.user = res.email;
      this.fecha;
    });
    this.getCount();
    this.getPeriods();
    this.getProvider();
    this.getBudget();
    this.getProject();
    this.getDeptos();
  }

  getInfo(info) {
    this.idDealMemoPublic = info.id;
    this.dealMemo = info.dealMemo;
    this.dealMemo.folio = info.id;
    this.dealMemo.tipoCambio = this.tipoDeCambio;
    this.dealMemo.countDealMemo = this.countDealMemo.countDealMemo;
    this.dealMemo.user = this.user;
    this.dealMemo.fecha = this.fecha.toString();
    this.totales();
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.dealMemo.tipoMoneda = this.project.monedaLocal;
        this.dealMemo.tipoCambio = this.project.tipoCambioGral;
        this.tipoDeCambio = this.project.tipoCambioGral;
      });
  }

  getPeriods() {
    this.subscriberGetPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodos = Object.assign([], res);
        this.periodos.forEach(element => {
          switch (element.etapa) {
            case 'DESARROLLO':
              this.desarrollo.push(element);
              break;
            case 'PREPARACION':
              this.preparacion.push(element);
              break;
            case 'RODAJE':
              this.rodaje.push(element);
              break;
            case 'POST':
              this.post.push(element);
              break;
            case 'ENTREGA':
              this.entrega.push(element);
              break;
            case 'DOCUMENTAL':
              this.documental.push(element);
              break;
          }
        });
        const arr = [
          this.desarrollo,
          this.preparacion,
          this.rodaje,
          this.post,
          this.entrega,
          this.documental,
        ];
      });
  }

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          this.getDepartament();
        }
      });
  }

  getProvider() {
    this.subscriberGetProviders = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  getDeptos() {
    this.subscribeGetDepartament = this.projectService
      .getGroups(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.grupos = res;
      });
  }

  getDepartament() {
    this.departament = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element.Total_Estimado) {
        this.departament.push(element);
      }
    }
  }

  getPuesto() {
    this.dealMemo.departamento = this.departamento.Descripcion;
    this.puestos = [];
    this.puesto = '';
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }

  getCtaPres() {
    this.dealMemo.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];

      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 4) {
        this.ctasPresupuesto.push(element);
      }
    }
  }

  changeAddMovement() {
    this.periodos.forEach(element => {
      if (this.objMovimientos.periodo === element.nombrePeriodo) {
        this.objMovimientos.fechaInicio = element.fechaInicio;
        this.objMovimientos.fechaFin = element.fechaFin;
      }
    });
  }

  agregarMovimiento() {
    const obj = this.objMovimientos;
    if (obj.cuentaPres && obj.importe && obj.periodo) {
      this.dealMemo.pagos.push(this.objMovimientos);
      this.objMovimientos = {};
      document.getElementById('cuentaPres').focus();
      this.totales();
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
  }

  totales() {
    this.suma = 0;
    this.dealMemo.pagos.forEach(element => {
      this.suma += element.importe;
    });
    this.dealMemo.pucharseOrderCtas.forEach(element => {
      this.suma += element.importe;
    });
  }

  deleteArr(arr: Array<any>, index: number) {
    arr.splice(index, 1);
    this.totales();
  }

  changeSelectProvider() {
    const proveedor = this.providers.find(
      element =>
        element.rfc === this.proveedorSelecionado ||
        element.taxId === this.proveedorSelecionado
    );
    this.isMoral = false;
    if (proveedor) {
      this.dealMemo.proveedor = proveedor.numero;
      this.dealMemo.nombreProveedor = proveedor.nombre;
      this.dealMemo.rfcProveedor = proveedor.rfc;
      this.dealMemo.nacionalidad = proveedor.nacionalidad;
      if (proveedor.persona === 'Moral') {
        this.isMoral = true;
      } else {
        this.isMoral = false;
      }
    }
  }

  getCount() {
    if (this.isDealMemo !== 'dealMemoReport') {
      this.subscribeCountDealMemo = this.dealMemoService
        .getCountDealMemo(this.idCompany, this.idProject)
        .subscribe(res => {
          const contador: any = res;
          if (res === undefined) {
            this.countDealMemo.countDealMemo = 1;
            this.dealMemo.countDealMemo = this.countDealMemo.countDealMemo;
          } else {
            this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
            this.dealMemo.countDealMemo = this.countDealMemo.countDealMemo;
          }
        });
    }
  }

  regresar() {
    this.volver = '';
    this.typeOrder = '';
    this.dealMemo = {
      personasPS: [],
      pagos: [],
      cuentasPer: [],
      documentos: [],
    };
    this.getCount();
  }

  validateDealMemo() {
    let cuentas = true;
    this.dealMemo.pagos.forEach(element => {
      if (
        element.cuentaPres === '' ||
        element.cuentaPres === undefined ||
        element.Descripcion === 'Cuenta no valida' ||
        element.periodo === undefined ||
        element.periodo === '' ||
        element.importe === undefined ||
        element.importe === null ||
        element.importe === 0
      ) {
        cuentas = false;
      }
    });
    if (!cuentas) {
      Notiflix.Notify.Failure('Todos los pagos deben tener cuenta');
    }
    if (
      this.dealMemo.proveedor &&
      this.dealMemo.departamento &&
      this.dealMemo.puesto &&
      this.dealMemo.pagos.length > 0 &&
      cuentas &&
      this.dealMemo.cuentasPer &&
      this.dealMemo.contratoInicio &&
      this.dealMemo.contratoFin &&
      this.dealMemo.descripcionActividad &&
      this.dealMemo.solicito
      // this.dealMemo.idDepartament
    ) {
      return true;
    } else {
      return false;
    }

    // this.dealMemo.idDepartament = this.grupoSeleccionado.id;
    // this.dealMemo.nameDepartament = this.grupoSeleccionado.name;
  }

  guardarDealMemo(typeOrder) {
    // console.log(typeOrder);
    // console.log(this.dealMemo);
    const validacion = this.validateDealMemo();
    if (validacion) {
      if (this.isDealMemo === 'dealMemoReport') {
        // Se ejecuta al editar un dealMemo
        this.dealMemo.fecha = this.fecha.toString();

        this.dealMemoService
          .updateDealMemo(
            this.idCompany,
            this.idProject,
            this.dealMemo.id,
            this.dealMemo
          )
          .then(() => {
            Notiflix.Notify.Success('Se guardo correctamente el Deal Memo');
            this.datosProveedor = {};
            this.dealMemo = {
              personasPS: [],
              pagos: [],
              cuentasPer: [],
              documentos: [],
            };
            this.departamento = {};
            this.puesto = {};
            this.suma = 0;
            (<any>document.getElementById('selectProvider')).value = '';
            $('#editDeal').modal('hide');
          });
      } else if (
        this.isDealMemo === 'dealMemo' ||
        this.isDealMemo === 'checkDealMemo'
      ) {
        // Se ejecuta al crear un dealMemo nuevo
        this.dealMemo.fecha = this.fecha.toString();
        this.dealMemo.type = 'dealMemo';

        this.dealMemoService
          .addDealMemo(this.idCompany, this.idProject, this.dealMemo)
          .then(res => {
            this.dealMemoService.countDealMemo(
              this.idCompany,
              this.idProject,
              this.countDealMemo
            );
            if (this.isDealMemo === 'checkDealMemo') {
              this.dealMemoService.deleteDealMemoPublic(
                this.idCompany,
                this.idProject,
                this.idDealMemoPublic
              );
            }
            Notiflix.Notify.Success('Se guardo correctamente la Orden');
            this.datosProveedor = {};
            this.dealMemo = {
              personasPS: [],
              pagos: [],
              cuentasPer: [],
              documentos: [],
            };
            this.dealMemo.tipoMoneda = this.project.monedaLocal;
            this.dealMemo.tipoCambio = this.project.tipoCambioGral;
            this.tipoDeCambio = this.project.tipoCambioGral;
            this.departamento = {};
            this.puesto = {};
            this.suma = 0;
            (<any>document.getElementById('selectProvider')).value = '';
            $('modalAddDealMemo').modal('hide');
          })
          .catch(err => {
            console.error('Ocurrio un error: ', err);
            Notiflix.Notify.Failure('Ocurrio un error al guardar la Orden');
          });
      }
    } else if (typeOrder === 'purchaseOrder') {
      if (
        this.dealMemo.proveedor &&
        this.dealMemo.contratoInicio &&
        this.dealMemo.contratoFin &&
        this.dealMemo.descripcionActividad &&
        this.dealMemo.solicito
        // this.dealMemo.idDepartament
      ) {
        // Se ejecuta al crear un dealMemo nuevo
        this.dealMemo.fecha = this.fecha.toString();
        this.dealMemo.type = 'purchaseOrder';
        this.dealMemo.valorDeal = this.suma;

        this.dealMemoService
          .addDealMemo(this.idCompany, this.idProject, this.dealMemo)
          .then(res => {
            this.dealMemoService.countDealMemo(
              this.idCompany,
              this.idProject,
              this.countDealMemo
            );
            if (this.isDealMemo === 'checkDealMemo') {
              this.dealMemoService.deleteDealMemoPublic(
                this.idCompany,
                this.idProject,
                this.idDealMemoPublic
              );
            }
            Notiflix.Notify.Success('Se guardo correctamente la Orden');
            this.datosProveedor = {};
            this.dealMemo = {
              personasPS: [],
              pucharseOrderCtas: [],
              cuentasPer: [],
              documentos: [],
            };
            this.dealMemo.tipoMoneda = this.project.monedaLocal;
            this.dealMemo.tipoCambio = this.project.tipoCambioGral;
            this.tipoDeCambio = this.project.tipoCambioGral;
            this.departamento = {};
            this.puesto = {};
            this.suma = 0;
            (<any>document.getElementById('selectProvider')).value = '';
            $('modalAddDealMemo').modal('hide');
          })
          .catch(err => {
            console.error('Ocurrio un error: ', err);
            Notiflix.Notify.Failure('Ocurrio un error al guardar la Orden');
          });
      } else {
        Notiflix.Notify.Failure('Faltan datos por completar');
      }
    } else {
      Notiflix.Notify.Failure('Faltan datos por completar');
    }
  }

  personas() {
    if (this.persona.nombre && this.persona.rfc && this.persona.puesto) {
      this.dealMemo.personasPS.push(this.persona);
      this.persona = {};
      document.getElementById('nombrePS').focus();
    } else {
      Notiflix.Notify.Failure('Ingresa todos los datos');
    }
  }

  datosCuenta() {
    if (this.cuentas.cuentaclabe.length !== 18) {
      Notiflix.Notify.Failure('La cuenta CLABE debe contener 18 digitos');
      document.getElementById('cuentaclabe').classList.add('is-invalid');
    } else {
      document.getElementById('cuentaclabe').classList.remove('is-invalid');
      if (
        this.cuentas.nombre &&
        this.cuentas.bancoysucursal &&
        // this.cuentas.numerodecuenta &&
        this.cuentas.cuentaclabe.length === 18
      ) {
        this.dealMemo.cuentasPer.push(this.cuentas);
        this.cuentas = {};
        document.getElementById('nombre').focus();
      } else {
        Notiflix.Notify.Failure('Tienes que llenar todos los campos');
      }
    }
  }

  agregarMovimientos() {
    if (
      this.objMovimientosMasivo.cuentaPres &&
      this.objMovimientosMasivo.importe &&
      this.selectEtapa
    ) {
      let findPeriod = false;
      this.periodos.forEach(element => {
        if (this.selectEtapa === element.etapa) {
          findPeriod = true;
          let obj = {} as any;
          obj.cuentaPres = this.objMovimientosMasivo.cuentaPres;
          obj.Descripcion = this.objMovimientosMasivo.Descripcion;
          obj.importe = this.objMovimientosMasivo.importe;
          obj.periodo = element.nombrePeriodo;
          obj.fechaInicio = element.fechaInicio;
          obj.fechaFin = element.fechaFin;
          this.dealMemo.pagos.push(obj);
        }
      });
      if (findPeriod) {
        this.objMovimientosMasivo = {};
        this.totales();
      } else {
        Notiflix.Notify.Failure(
          'No existen periodos registrados en esta etapa'
        );
      }
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
  }
  agregarMovimientosPucharseOrder() {
    if (
      this.objMovimientosMasivo.cuentaPres &&
      this.objMovimientosMasivo.importe
    ) {
      let obj = {} as any;
      obj.cuentaPres = this.objMovimientosMasivo.cuentaPres;
      obj.Descripcion = this.objMovimientosMasivo.Descripcion;
      obj.importe = this.objMovimientosMasivo.importe;
      this.dealMemo.pucharseOrderCtas.push(obj);
      this.objMovimientosMasivo = {};
      this.totales();
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
  }

  uploadFile(event) {
    const fileInput = event.target.files[0];
    const fileType = fileInput.type;
    const fileSize = fileInput.size;
    const allowedExtensions = /(.pdf)$/i;
    let uploadF: any = '';
    if (!allowedExtensions.exec(fileType) || fileSize >= 1000000) {
      alert(
        'Por favor agrega unicamente archivos con extension .pdf y tamaño maximo de 1MB '
      );
      // document.getElementById('labelFile').innerHTML = 'Seleccionar';
      (<any>document.getElementById('inputGroupFile01')).value = '';
    } else {
      if (
        this.objDocumento.documento !== undefined &&
        this.dealMemo.proveedor
      ) {
        uploadF = event.target.files[0];
        // document.getElementById('labelFile').innerHTML = uploadF.name;
        // const id = Math.random().toString(36).substring(2);
        const filePath = `proveedores/${this.dealMemo.proveedor}/dealMemo/${this.objDocumento.documento}`;
        const path: any = {};
        path.pathImageProfile = filePath;
        const ref = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, uploadF);
        task.then(res => {
          ref.getDownloadURL().subscribe(resultado => {
            this.objDocumento.link = resultado;
            this.objDocumento.path = filePath;
            this.objDocumento.name = fileInput.name;
            this.agregarDocumento();
          });
        });
      } else {
        Notiflix.Notify.Failure(
          'Selecciona un tipo de documento y agrega un proveedor'
        );
        (<any>document.getElementById('inputGroupFile01')).value = '';
      }
    }
  }

  agregarDocumento() {
    if (this.objDocumento.documento && this.objDocumento.link) {
      this.dealMemo.documentos.push(this.objDocumento);
      this.objDocumento = {};
      (<any>document.getElementById('inputGroupFile01')).value = '';
    }
  }

  deleteDocument(index, obj) {
    // Create a reference to the file to delete
    const reference = this.storage.ref(obj.path);

    // Delete the file
    reference
      .delete()
      .toPromise()
      .then(function () {
        // File deleted successfully
        console.log('Se elimino correctamente');
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
        console.log('Se produjo un error al eliminar o no existia');
      });

    this.dealMemo.documentos.splice(index, 1);
  }

  getNameCta(obj) {
    const elemento = this.ctasPresupuesto.find(
      element => element.CodigoAgrupador === obj.cuentaPres
    );
    obj.Descripcion = elemento ? elemento.Descripcion : 'Cuenta no valida';
  }

  getDates(periodo) {
    const elemento = this.periodos.find(
      element => element.nombrePeriodo === periodo.periodo
    );
    periodo.fechaInicio = elemento ? elemento.fechaInicio : Date();
    periodo.fechaFin = elemento ? elemento.fechaFin : Date();
  }

  ngOnDestroy() {
    this.subscriberGetPeriods.unsubscribe();
    this.subscriberGetBudget.unsubscribe();
    this.subscriberGetProviders.unsubscribe();
    if (this.subscribeCountDealMemo) {
      this.subscribeCountDealMemo.unsubscribe();
    }
    this.subscriberGetProject.unsubscribe();
    this.subscriberGetUser.unsubscribe();
  }
}
