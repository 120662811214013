import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Notiflix from 'notiflix-angular';
import { BudgetService } from 'src/app/services/budget.service';
import { ProjectService } from 'src/app/services/project.service';
import { RequestService } from 'src/app/services/request.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-request-anexo',
  templateUrl: './request-anexo.component.html',
  styleUrls: ['./request-anexo.component.css'],
})
export class RequestAnexoComponent implements OnInit {
  idCompany: string;
  idProject: string;
  budget = [] as any;
  listRequest = [] as any;
  countRequest = [] as any;
  arrAnexosSeparados = [] as any;

  constructor(
    private budgetService: BudgetService,
    private router: Router,
    private projectService: ProjectService,
    private requestService: RequestService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCountRequest();
    this.getBudget();
  }

  getCountRequest() {
    this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res === undefined) {
          this.countRequest = [] as any;
        } else {
          this.countRequest = res.countRequest;
        }
      });
  }

  getBudget() {
    this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
        }
      });
  }

  onFileChange(ev) {
    const file = ev.target.files[0];
    console.log(file.name);
    document.getElementById('inputFile').innerText = file.name;
    const reader = new FileReader();
    let workBook = null;
    let jsonData = null;
    Notiflix.Loading.Dots('Cargando...');
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });

      // Esto lo ejecuto si solo requiero una hoja en especifico
      const jsonData = XLSX.utils.sheet_to_json(workBook.Sheets['ANEXOS'], {
        blankrows: false,
        dateNF: 0,
      });
      const sheetName = 'ANEXOS'; // Este toma una hoja en especifico del excel
      const dataTable = jsonData;

      this.listRequest = dataTable;
      Notiflix.Loading.Remove();
      this.validationData();
    };
    reader.readAsBinaryString(file);
  }

  validationData() {
    // Recorro la lista de anexos
    let validationCountRequest = false;
    let validationPartida = false;
    for (let index = 0; index < this.listRequest.length; index++) {
      const elementRequest = this.listRequest[index];
      validationCountRequest = false;
      validationPartida = false;

      for (let index = 0; index < this.budget.length; index++) {
        const elementBudget = this.budget[index];
        if (
          elementRequest.PartidaPresupuestal === elementBudget.CodigoAgrupador
        ) {
          validationPartida = true;
          break;
        }
      }

      if (!validationPartida) {
        Notiflix.Notify.Failure(
          `No se encontro la partida presupuestal ${elementRequest.PartidaPresupuestal} de la solicitud ${elementRequest.Solicitud}`
        );
        break;
      }

      // Buscando si existe el numero de la solicitud
      validationCountRequest = this.countRequest.includes(
        elementRequest.Solicitud
      );

      if (!validationCountRequest) {
        Notiflix.Notify.Failure(
          `La solicitud ${elementRequest.Solicitud} no se encuentra registrada en el sistema`
        );
        break;
      }
    }

    if (validationCountRequest && validationPartida) {
      this.separate();
    }
  }

  separate() {
    let arrAnexo = [];

    for (let index = 0; index < this.listRequest.length; index++) {
      const anexoAnterior = this.listRequest[index - 1];
      const anexo = this.listRequest[index];

      // Para la primera posición
      // Validar que es el primer elemento del array
      // Hacer push en arrAnexo
      // Validar si el siguiente elemento es el mismo numero de array
      // Si es igual continuar con el siguiente elemento
      // Si NO es igual hacer push del arrAnexo a arr

      // Para las posiciones siguientes
      // Validar si el numero del elemento es el mismo numero del elemento anterior
      // Si es igual hacer push al arrAnexo
      // Si NO es igual hacer push al arr
      // Limpiar arrAnexo
      // Hacer push a arrAnexo del elemento actual

      if (index === 0) {
        arrAnexo.push(anexo);
      } else {
        if (anexo.Solicitud === anexoAnterior.Solicitud) {
          arrAnexo.push(anexo);
          if (index === this.listRequest.length - 1) {
            this.arrAnexosSeparados.push(arrAnexo);
          }
        } else {
          this.arrAnexosSeparados.push(arrAnexo);
          arrAnexo = [];
          arrAnexo.push(anexo);
          if (index === this.listRequest.length - 1) {
            this.arrAnexosSeparados.push(arrAnexo);
          }
        }
      }
    }
  }

  callAnexos(index: number) {
    const arrRequest = this.arrAnexosSeparados[index];
    this.getRequest(arrRequest, index);
  }

  getRequest(arrRequest, index) {
    const subscriptionRequest = this.requestService
      .getRequestForNumber(
        this.idCompany,
        this.idProject,
        arrRequest[0].Solicitud
      )
      .subscribe((res: any) => {
        const request = res[0];
        subscriptionRequest.unsubscribe();
        this.addMovimientos(request, arrRequest, index);
      });
  }

  addMovimientos(request, importes, index) {
    console.log(request, importes);

    let importe = 0;
    let iva = 0;
    let isr = 0;
    let iva10 = 0;
    const movimientos = [];

    for (let indexImporte = 0; indexImporte < importes.length; indexImporte++) {
      const element = importes[indexImporte];

      let obj = {} as any;
      if (element.Importe) {
        importe += parseFloat(element.Importe.toFixed(2));
        obj.Cargo = parseFloat(element.Importe.toFixed(2));
        obj.Cuenta = element.PartidaPresupuestal;
        obj.Concepto = element.Concepto;
        obj.FechaCreado = new Date();
        obj.NombreCuenta = this.budget.find(
          elementBudget =>
            elementBudget.CodigoAgrupador === element.PartidaPresupuestal
        ).Descripcion;
      }
      movimientos.push(obj);

      if (element.IVA) {
        iva += element.IVA;
      }
      if (element.ISR) {
        isr += element.ISR;
      }
      if (element.IVA10) {
        iva10 += element.IVA10;
      }
    }

    if (iva > 0) {
      const objIVA = {
        Cargo: parseFloat(iva.toFixed(2)),
        Cuenta: '119-01-001',
        NombreCuenta: 'IVA a favor',
      };
      movimientos.push(objIVA);
    }
    if (isr > 0) {
      const objISR = {
        Abono: parseFloat(isr.toFixed(2)),
        Cuenta: '216-06-001',
        NombreCuenta: 'Retencion de ISR',
      };
      movimientos.push(objISR);
    }
    if (iva10) {
      const objIVA10 = {
        Abono: parseFloat(iva10.toFixed(2)),
        Cuenta: '216-10-001',
        NombreCuenta: 'Retencion de IVA',
      };
      movimientos.push(objIVA10);
    }

    const suma = (importe + iva - isr - iva10).toFixed(2);
    console.log(request.movimientos[0].Cargo, parseFloat(suma));

    if (request.movimientos[0].Cargo === parseFloat(suma)) {
      request.movimientos.splice(0, 1);
      const newMovimientos = movimientos.concat(request.movimientos);
      request.movimientos = newMovimientos;
      console.log(request.movimientos);
      this.updateRequest(request, index);
    } else {
      Notiflix.Notify.Failure(
        `Se detuvo el proceso por que la solicitud ${request.countRequest} no es equivalente al importe original`
      );
    }
  }

  updateRequest(request: any, index) {
    this.requestService
      .updateRequest(this.idCompany, this.idProject, request.id, {
        movimientos: request.movimientos,
      })
      .then(res => {
        Notiflix.Notify.Success(
          `Se actualizo correctamente la solicitud ${request.countRequest}`
        );
        if (index + 1 < this.arrAnexosSeparados.length) {
          this.callAnexos(index + 1);
        }
      })
      .catch(err => {
        Notiflix.Notify.Success(
          `Ocurrio un error al actualizar la solicitud ${request.countRequest}`
        );
        console.error(err);
      });
  }

  clear() {
    this.arrAnexosSeparados = [];
    (<any>document.getElementById('inputFile')).value = '';
  }
}
