import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { Period } from 'src/app/models/period';
import { Router } from '@angular/router';
import Notiflix from 'notiflix-angular';
import { EmpresasService } from 'src/app/services/empresas.service';
import { User } from 'src/app/models/user';

import { ObjectUnsubscribedError, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';

AuthService;
declare var $;

@Component({
  selector: 'app-project-config',
  templateUrl: './project-config.component.html',
  styleUrls: ['./project-config.component.css'],
})
export class ProjectConfigComponent implements OnInit, OnDestroy {
  idProject;
  idCompany;

  periodosDocumental = [];
  periodDocumental = {
    type: 'Periodo',
    etapa: 'DOCUMENTAL',
  } as Period;
  period = {
    type: 'Periodo',
    etapa: '',
  } as Period;
  periodos = [];
  dataUser = {} as User;

  numero;
  periodoSeleccionado;
  etapaSeleccionada;
  fila;
  fila2;
  tipoProyecto;
  bloquear = '';
  admin;
  rutalock = {};

  fechas = {} as any;

  subscriberRoleUser: Subscription;

  subscriberGetPeriods: Subscription;
  subscriberGetProject: Subscription;

  constructor(
    private empresasService: EmpresasService,
    private projectService: ProjectService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.subscriberGetProject = this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.rutalock = res;
        const obj: any = res;
        this.tipoProyecto = obj.tipoProyecto;
        this.bloquear = obj.rutaLock;
      });
    this.subscriberGetPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodosDocumental = Object.assign([], res);

        this.periodos = Object.assign([], res);

        // console.log(this.periodos);
      });

    this.authService.isLogin().then(result => {
      this.dataUser = result;
      this.subscriberRoleUser = this.authService
        .roleUser(this.dataUser.uid)
        .subscribe(res => {
          this.admin = res[0].userType;
          // console.log(this.admin);
        });
    });
  }

  lock() {
    const obj = {
      rutaLock: true,
    };
    this.empresasService.updateLockRutaUser(
      this.idCompany,
      this.idProject,
      obj
    );
  }
  unlock() {
    const obj = {
      rutaLock: false,
    };
    this.empresasService.updateLockRutaUser(
      this.idCompany,
      this.idProject,
      obj
    );
  }

  editRutaCritica(periodo, index) {
    $('#editRuta').modal('show');
    const formato = 'YYYY-MM-DD';
    this.periodoSeleccionado = periodo;
    console.log(this.periodoSeleccionado);
    this.period.etapa = this.periodoSeleccionado.etapa;
    this.period.nombrePeriodo = this.periodoSeleccionado.nombrePeriodo;
    this.period.fechaInicio = moment(
      this.periodoSeleccionado.fechaInicio
    ).format(formato);
    this.period.fechaFin = moment(this.periodoSeleccionado.fechaFin).format(
      formato
    );
    this.fila = index;
    // console.log(this.fila);
  }
  editRutaCriticaAbajo(periodo, index) {
    // const formato = 'YYYY-MM-DD hh:mm';
    // this.periodos.forEach(element => {
    //   element.fechaInicio = moment(element.fechaInicio).format(formato);
    //   element.fechaFin = moment(element.fechaFin).format(formato);
    // });
    $('#editRutaAbajo').modal('show');

    const formato = 'YYYY-MM-DD';
    this.periodoSeleccionado = periodo;
    // console.log(this.periodoSeleccionado);
    this.period.etapa = this.periodoSeleccionado.etapa;
    this.period.fechaInicio = moment(
      this.periodoSeleccionado.fechaInicio
    ).format(formato);
    this.period.fechaFin = moment(this.periodoSeleccionado.fechaFin).format(
      formato
    );
    this.fila = index;
    // console.log(this.fila);
  }

  addPeriodoArriba(arr, obj) {
    const formato = 'YYYY-MM-DD';

    let fechaFin = obj.fechaFin.split('-');
    let fechaInicio = obj.fechaInicio.split('-');
    let newFechaInicio = fechaFin[2] - fechaInicio[2];
    let contadorNumero = 1;
    let contadorDesarrollo = 1;
    let contadorSoftPrep = 1;
    let contadorPreparación = 1;
    let contadorEntrega = 1;
    let contadorRodaje = 1;
    let contadorPost = 1;
    let contadorHolds = 1;

    const lunes = new Date(obj.fechaInicio).getDay();
    const domingo = new Date(obj.fechaFin).getDay();
    // console.log('Número de día de la semana: ', lunes, domingo);
    if (this.periodoSeleccionado.etapa === obj.etapa || obj.etapa === 'HOLD') {
      Notiflix.Confirm.Show(
        '¿ALERTA?',
        'Las fechas se modificaran automaticamente',
        'Si',
        'No',
        () => {
          if (lunes === 0 && domingo === 6) {
            if (this.fila === 0) {
              // console.log('es el primer elemento del array');

              for (let index = 0; index < this.periodos.length; index++) {
                const element = this.periodos[index];

                if (obj.etapa === 'DESARROLLO') {
                  obj.type = 'Periodo';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'DES' + '_' + (contadorDesarrollo + 1);
                } else if (obj.etapa === 'SOFTPREP') {
                  obj.type = 'Periodo';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'SOFTPREP' + '_' + (contadorSoftPrep + 1);
                } else if (obj.etapa === 'PREPARACION') {
                  obj.type = 'Periodo';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'PREP' + '_' + (contadorPreparación + 1);
                } else if (obj.etapa === 'ENTREGA') {
                  obj.type = 'Periodo';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'ENT' + '_' + (contadorEntrega + 1);
                } else if (obj.etapa === 'RODAJE') {
                  obj.type = 'Periodo';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'ROD' + '_' + (contadorRodaje + 1);
                } else if (obj.etapa === 'POST') {
                  obj.type = 'Periodo';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'POST' + '_' + (contadorPost + 1);
                  // console.log(obj.nombrePeriodo);
                } else if (obj.etapa === 'HOLD') {
                  obj.type = 'Hold';
                  obj.numero = contadorNumero + 1;
                  obj.nombrePeriodo = 'HOLD' + '_' + (contadorHolds + 1);
                }
              }
              this.periodos.splice(0, 0, obj);
              // console.log(this.periodos);
              let mesFechaInicio = obj.fechaInicio.split('-');
              let mesFechaFin = obj.fechaFin.split('-');

              // console.log(mesFechaInicio[1]);
              // console.log(mesFechaFin[1]);
              if (mesFechaInicio[1] < mesFechaFin[1]) {
                this.fila2 = this.fila + 1;
                for (
                  let index = this.fila2;
                  index < this.periodos.length;
                  index++
                ) {
                  const element1 = this.periodos[index];

                  element1.fechaInicio = moment(element1.fechaInicio)
                    .add(newFechaInicio + 1, 'days')
                    .add(1, 'months')
                    .format(formato);

                  element1.fechaFin = moment(element1.fechaFin)
                    .add(newFechaInicio + 1, 'days')
                    .add(1, 'months')
                    .format(formato);
                }
              } else if (mesFechaInicio[1] === mesFechaFin[1]) {
                this.fila2 = this.fila + 1;
                for (
                  let index = this.fila2;
                  index < this.periodos.length;
                  index++
                ) {
                  const element1 = this.periodos[index];

                  element1.fechaInicio = moment(element1.fechaInicio)
                    .add(newFechaInicio + 1, 'days')
                    .format(formato);

                  element1.fechaFin = moment(element1.fechaFin)
                    .add(newFechaInicio + 1, 'days')
                    .format(formato);
                }
              }
              for (let index = 0; index < this.periodos.length; index++) {
                const element = this.periodos[index];
                element.numero = contadorNumero++;

                let etapa = element.nombrePeriodo.split('_');

                if (element.etapa === 'DESARROLLO') {
                  element.nombrePeriodo = etapa[0] + '_' + contadorDesarrollo++;
                } else if (element.etapa === 'SOFTPREP') {
                  element.nombrePeriodo = etapa[0] + '_' + contadorSoftPrep++;
                } else if (element.etapa === 'PREPARACION') {
                  element.nombrePeriodo =
                    etapa[0] + '_' + contadorPreparación++;
                } else if (element.etapa === 'ENTREGA') {
                  element.nombrePeriodo = etapa[0] + '_' + contadorEntrega++;
                } else if (element.etapa === 'RODAJE') {
                  element.nombrePeriodo = etapa[0] + '_' + contadorRodaje++;
                } else if (element.etapa === 'POST') {
                  element.nombrePeriodo = etapa[0] + '_' + contadorPost++;
                }
                if (element.type === 'Hold') {
                  element.nombrePeriodo = 'HOLD' + '_' + contadorHolds++;
                  element.etapa = 'HOLD';
                }
              }

              const objNew = Object.assign({}, this.periodos);
              this.projectService.addPeriods(
                this.idCompany,
                this.idProject,
                objNew
              );
            } else {
              // console.log('el array ya tiene elementos');

              const fechaFinal = this.periodos[this.fila - 1];
              const fechaPeriodoAnterior = fechaFinal.fechaFin;
              // console.log(fechaPeriodoAnterior);
              if (
                fechaPeriodoAnterior < obj.fechaInicio &&
                obj.fechaInicio < obj.fechaFin
              ) {
                for (
                  let index = this.fila;
                  index < this.periodos.length;
                  index++
                ) {
                  const element = this.periodos[index];

                  if (obj.etapa === 'DESARROLLO') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'DES' + '_' + (contadorDesarrollo + 1);
                  } else if (obj.etapa === 'SOFTPREP') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo =
                      'SOFTPREP' + '_' + (contadorSoftPrep + 1);
                  } else if (obj.etapa === 'PREPARACION') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo =
                      'PREP' + '_' + (contadorPreparación + 1);
                  } else if (obj.etapa === 'ENTREGA') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'ENT' + '_' + (contadorEntrega + 1);
                  } else if (obj.etapa === 'RODAJE') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'ROD' + '_' + (contadorRodaje + 1);
                  } else if (obj.etapa === 'POST') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'POST' + '_' + (contadorPost + 1);
                    // console.log(obj.nombrePeriodo);
                  } else if (obj.etapa === 'HOLD') {
                    obj.type = 'Hold';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'HOLD' + '_' + (contadorHolds + 1);
                  }
                }
                this.periodos.splice(this.fila, 0, obj);
                // console.log(this.periodos);

                for (let index = 0; index < this.periodos.length; index++) {
                  const element = this.periodos[index];
                  element.numero = contadorNumero++;

                  let etapa = element.nombrePeriodo.split('_');

                  if (element.etapa === 'DESARROLLO') {
                    element.nombrePeriodo =
                      etapa[0] + '_' + contadorDesarrollo++;
                  } else if (element.etapa === 'SOFTPREP') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorSoftPrep++;
                  } else if (element.etapa === 'PREPARACION') {
                    element.nombrePeriodo =
                      etapa[0] + '_' + contadorPreparación++;
                  } else if (element.etapa === 'ENTREGA') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorEntrega++;
                  } else if (element.etapa === 'RODAJE') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorRodaje++;
                  } else if (element.etapa === 'POST') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorPost++;
                  }
                  if (element.type === 'Hold') {
                    element.nombrePeriodo = 'HOLD' + '_' + contadorHolds++;
                    element.etapa = 'HOLD';
                  }
                }

                let mesFechaInicio = obj.fechaInicio.split('-');
                let mesFechaFin = obj.fechaFin.split('-');

                if (mesFechaInicio[1] < mesFechaFin[1]) {
                  this.fila2 = this.fila + 1;
                  for (
                    let index = this.fila2;
                    index < this.periodos.length;
                    index++
                  ) {
                    const element1 = this.periodos[index];

                    element1.fechaInicio = moment(element1.fechaInicio)
                      .add(newFechaInicio + 1, 'days')
                      .add(1, 'months')
                      .format(formato);

                    element1.fechaFin = moment(element1.fechaFin)
                      .add(newFechaInicio + 1, 'days')
                      .add(1, 'months')
                      .format(formato);
                  }
                } else if (mesFechaInicio[1] === mesFechaFin[1]) {
                  this.fila2 = this.fila + 1;
                  for (
                    let index = this.fila2;
                    index < this.periodos.length;
                    index++
                  ) {
                    const element1 = this.periodos[index];

                    element1.fechaInicio = moment(element1.fechaInicio)
                      .add(newFechaInicio + 1, 'days')
                      .format(formato);

                    element1.fechaFin = moment(element1.fechaFin)
                      .add(newFechaInicio + 1, 'days')
                      .format(formato);
                  }
                }
              } else {
                Notiflix.Notify.Failure(
                  'La fecha de inicio debe ser mayor a la fecha final del periodo anterior'
                );
              }

              const objNew = Object.assign({}, this.periodos);
              this.projectService.addPeriods(
                this.idCompany,
                this.idProject,
                objNew
              );
              Notiflix.Notify.Success('Agregado Correctamente');
            }
          } else if (lunes != 0) {
            Notiflix.Notify.Failure('La fecha de inicio debe ser en Lunes');
          } else if (domingo != 6) {
            Notiflix.Notify.Failure('La fecha final debe ser en Domingo');
          } else if (lunes != 0 && domingo != 6) {
            Notiflix.Notify.Failure(
              'Los periodos deben iniciar en dia LUNES y terminar en dia Domingo'
            );
          }
        }
      );
    } else {
      Notiflix.Notify.Failure(
        'El periodo debe corresponder con la etapa seleccionada'
      );
    }
    this.period = {};
  }
  addPeriodoAbajo(arr, obj) {
    const formato = 'YYYY-MM-DD';

    let fechaFin = obj.fechaFin.split('-');
    let fechaInicio = obj.fechaInicio.split('-');
    let newFechaInicio = fechaFin[2] - fechaInicio[2];
    let contadorNumero = 1;
    let contadorDesarrollo = 1;
    let contadorSoftPrep = 1;
    let contadorPreparación = 1;
    let contadorEntrega = 1;
    let contadorRodaje = 1;
    let contadorPost = 1;
    let contadorHolds = 1;

    const lunes = new Date(obj.fechaInicio).getDay();
    const domingo = new Date(obj.fechaFin).getDay();
    // console.log('Número de día de la semana: ', lunes, domingo);
    if (this.periodoSeleccionado.etapa === obj.etapa || obj.etapa === 'HOLD') {
      Notiflix.Confirm.Show(
        '¿ALERTA?',
        'Las fechas se modificaran automaticamente',
        'Si',
        'No',
        () => {
          if (lunes === 0 && domingo === 6) {
            if (this.fila === 0) {
              // console.log('es el primer elemento del array');
              const fechaFinal = this.periodos[this.fila];
              if (obj.fechaInicio > fechaFinal.fechaFin) {
                for (let index = 0; index < this.periodos.length; index++) {
                  const element = this.periodos[index];

                  if (obj.etapa === 'DESARROLLO') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'DES' + '_' + (contadorDesarrollo + 1);
                  } else if (obj.etapa === 'SOFTPREP') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo =
                      'SOFTPREP' + '_' + (contadorSoftPrep + 1);
                  } else if (obj.etapa === 'PREPARACION') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo =
                      'PREP' + '_' + (contadorPreparación + 1);
                  } else if (obj.etapa === 'ENTREGA') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'ENT' + '_' + (contadorEntrega + 1);
                  } else if (obj.etapa === 'RODAJE') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'ROD' + '_' + (contadorRodaje + 1);
                  } else if (obj.etapa === 'POST') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'POST' + '_' + (contadorPost + 1);
                    // console.log(obj.nombrePeriodo);
                  } else if (obj.etapa === 'HOLD') {
                    obj.type = 'Hold';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'HOLD' + '_' + (contadorHolds + 1);
                  }
                }
                this.fila2 = this.fila + 1;
                this.periodos.splice(this.fila2, 0, obj);
                // console.log(this.periodos);
                let mesFechaInicio = obj.fechaInicio.split('-');
                let mesFechaFin = obj.fechaFin.split('-');

                // console.log(mesFechaInicio[1]);
                // console.log(mesFechaFin[1]);
                if (mesFechaInicio[1] < mesFechaFin[1]) {
                  this.fila2 = this.fila + 2;
                  for (
                    let index = this.fila2;
                    index < this.periodos.length;
                    index++
                  ) {
                    const element1 = this.periodos[index];

                    element1.fechaInicio = moment(element1.fechaInicio)
                      .add(newFechaInicio + 1, 'days')
                      .add(1, 'months')
                      .format(formato);

                    element1.fechaFin = moment(element1.fechaFin)
                      .add(newFechaInicio + 1, 'days')
                      .add(1, 'months')
                      .format(formato);
                  }
                } else if (mesFechaInicio[1] === mesFechaFin[1]) {
                  this.fila2 = this.fila + 2;

                  for (
                    let index = this.fila2;
                    index < this.periodos.length;
                    index++
                  ) {
                    const element1 = this.periodos[index];

                    element1.fechaInicio = moment(element1.fechaInicio)
                      .add(newFechaInicio + 1, 'days')
                      .format(formato);

                    element1.fechaFin = moment(element1.fechaFin)
                      .add(newFechaInicio + 1, 'days')
                      .format(formato);
                  }
                }
                for (let index = 0; index < this.periodos.length; index++) {
                  const element = this.periodos[index];
                  element.numero = contadorNumero++;

                  let etapa = element.nombrePeriodo.split('_');

                  if (element.etapa === 'DESARROLLO') {
                    element.nombrePeriodo =
                      etapa[0] + '_' + contadorDesarrollo++;
                  } else if (element.etapa === 'SOFTPREP') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorSoftPrep++;
                  } else if (element.etapa === 'PREPARACION') {
                    element.nombrePeriodo =
                      etapa[0] + '_' + contadorPreparación++;
                  } else if (element.etapa === 'ENTREGA') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorEntrega++;
                  } else if (element.etapa === 'RODAJE') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorRodaje++;
                  } else if (element.etapa === 'POST') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorPost++;
                  }
                  if (element.type === 'Hold') {
                    element.nombrePeriodo = 'HOLD' + '_' + contadorHolds++;
                    element.etapa = 'HOLD';
                  }
                }

                const objNew = Object.assign({}, this.periodos);
                this.projectService.addPeriods(
                  this.idCompany,
                  this.idProject,
                  objNew
                );
              } else {
                Notiflix.Notify.Failure(
                  'La fecha de inicio debe ser mayor a la fecha final del periodo seleccionado'
                );
              }
            } else {
              // console.log('el array ya tiene elementos');

              this.fila2 = this.fila + 1;
              const fechaFinal = this.periodos[this.fila];
              if (obj.fechaInicio > fechaFinal.fechaFin) {
                for (
                  let index = this.fila2;
                  index < this.periodos.length;
                  index++
                ) {
                  const element = this.periodos[index];

                  if (obj.etapa === 'DESARROLLO') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'DES' + '_' + (contadorDesarrollo + 1);
                  } else if (obj.etapa === 'SOFTPREP') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo =
                      'SOFTPREP' + '_' + (contadorSoftPrep + 1);
                  } else if (obj.etapa === 'PREPARACION') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo =
                      'PREP' + '_' + (contadorPreparación + 1);
                  } else if (obj.etapa === 'ENTREGA') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'ENT' + '_' + (contadorEntrega + 1);
                  } else if (obj.etapa === 'RODAJE') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'ROD' + '_' + (contadorRodaje + 1);
                  } else if (obj.etapa === 'POST') {
                    obj.type = 'Periodo';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'POST' + '_' + (contadorPost + 1);
                    // console.log(obj.nombrePeriodo);
                  } else if (obj.etapa === 'HOLD') {
                    obj.type = 'Hold';
                    obj.numero = contadorNumero + 1;
                    obj.nombrePeriodo = 'HOLD' + '_' + (contadorHolds + 1);
                  }
                }
                this.periodos.splice(this.fila2, 0, obj);
                // console.log(this.periodos);

                for (let index = 0; index < this.periodos.length; index++) {
                  const element = this.periodos[index];
                  element.numero = contadorNumero++;

                  let etapa = element.nombrePeriodo.split('_');

                  if (element.etapa === 'DESARROLLO') {
                    element.nombrePeriodo =
                      etapa[0] + '_' + contadorDesarrollo++;
                  } else if (element.etapa === 'SOFTPREP') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorSoftPrep++;
                  } else if (element.etapa === 'PREPARACION') {
                    element.nombrePeriodo =
                      etapa[0] + '_' + contadorPreparación++;
                  } else if (element.etapa === 'ENTREGA') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorEntrega++;
                  } else if (element.etapa === 'RODAJE') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorRodaje++;
                  } else if (element.etapa === 'POST') {
                    element.nombrePeriodo = etapa[0] + '_' + contadorPost++;
                  }
                  if (element.type === 'Hold') {
                    element.nombrePeriodo = 'HOLD' + '_' + contadorHolds++;
                    element.etapa = 'HOLD';
                  }
                }

                let mesFechaInicio = obj.fechaInicio.split('-');
                let mesFechaFin = obj.fechaFin.split('-');

                if (mesFechaInicio[1] < mesFechaFin[1]) {
                  this.fila2 = this.fila + 2;

                  for (
                    let index = this.fila2;
                    index < this.periodos.length;
                    index++
                  ) {
                    const element1 = this.periodos[index];

                    element1.fechaInicio = moment(element1.fechaInicio)
                      .add(newFechaInicio + 1, 'days')
                      .add(1, 'months')
                      .format(formato);

                    element1.fechaFin = moment(element1.fechaFin)
                      .add(newFechaInicio + 1, 'days')
                      .add(1, 'months')
                      .format(formato);
                  }
                } else if (mesFechaInicio[1] === mesFechaFin[1]) {
                  this.fila2 = this.fila + 2;
                  for (
                    let index = this.fila2;
                    index < this.periodos.length;
                    index++
                  ) {
                    const element1 = this.periodos[index];
                    // console.log(element1);
                    element1.fechaInicio = moment(element1.fechaInicio)
                      .add(newFechaInicio + 1, 'days')
                      .format(formato);

                    element1.fechaFin = moment(element1.fechaFin)
                      .add(newFechaInicio + 1, 'days')
                      .format(formato);
                  }
                }

                const objNew = Object.assign({}, this.periodos);
                this.projectService.addPeriods(
                  this.idCompany,
                  this.idProject,
                  objNew
                );
                Notiflix.Notify.Success('Agregado Correctamente');
              } else {
                Notiflix.Notify.Failure(
                  'La fecha de inicio debe ser mayor a la fecha final del periodo seleccionado'
                );
              }
            }
          } else if (lunes != 0) {
            Notiflix.Notify.Failure('La fecha de inicio debe ser en Lunes');
          } else if (domingo != 6) {
            Notiflix.Notify.Failure('La fecha final debe ser en Domingo');
          } else if (lunes != 0 && domingo != 6) {
            Notiflix.Notify.Failure(
              'Los periodos deben iniciar en dia LUNES y terminar en dia Domingo'
            );
          }
        }
      );
    } else {
      Notiflix.Notify.Failure(
        'El periodo debe corresponder con la etapa seleccionada'
      );
    }

    this.period = {};
  }

  addPeriods(arr, obj) {
    // console.log(obj);

    let contadorDesarrollo = 0;
    let contadorSoftPrep = 0;
    let contadorPreparación = 0;
    let contadorEntrega = 0;
    let contadorRodaje = 0;
    let contadorPost = 0;
    let contadorHolds = 0;
    arr.forEach(element => {
      if (element.etapa === 'DESARROLLO') {
        contadorDesarrollo++;
      } else if (element.etapa === 'SOFTPREP') {
        contadorSoftPrep++;
      } else if (element.etapa === 'PREPARACION') {
        contadorPreparación++;
      } else if (element.etapa === 'ENTREGA') {
        contadorEntrega++;
      } else if (element.etapa === 'RODAJE') {
        contadorRodaje++;
      } else if (element.etapa === 'POST') {
        contadorPost++;
      } else if (element.type === 'Hold') {
        contadorHolds++;
      }
    });
    if (obj.etapa === 'DESARROLLO') {
      obj.numero = this.periodos.length + 1;
      obj.type = 'Periodo';
      obj.nombrePeriodo = 'DES' + '_' + (contadorDesarrollo + 1);

      // console.log(obj.nombrePeriodo);
    }
    if (obj.etapa === 'SOFTPREP') {
      // console.log('se ejecuta');
      obj.numero = this.periodos.length + 1;
      obj.type = 'Periodo';
      obj.nombrePeriodo = 'SOFTPREP' + '_' + (contadorSoftPrep + 1);
      // console.log(obj.nombrePeriodo);
    }
    if (obj.etapa === 'PREPARACION') {
      // console.log('se ejecuta');
      obj.numero = this.periodos.length + 1;
      obj.type = 'Periodo';
      obj.nombrePeriodo = 'PREP' + '_' + (contadorPreparación + 1);
      // console.log(obj.nombrePeriodo);
    }
    if (obj.etapa === 'ENTREGA') {
      obj.numero = this.periodos.length + 1;
      obj.type = 'Periodo';
      obj.nombrePeriodo = 'ENT' + '_' + (contadorEntrega + 1);
      // console.log(obj.nombrePeriodo);
    }
    if (obj.etapa === 'RODAJE') {
      obj.numero = this.periodos.length + 1;
      obj.type = 'Periodo';
      obj.nombrePeriodo = 'ROD' + '_' + (contadorRodaje + 1);
      // console.log(obj.nombrePeriodo);
    }
    if (obj.etapa === 'POST') {
      obj.numero = this.periodos.length + 1;
      obj.type = 'Periodo';
      obj.nombrePeriodo = 'POST' + '_' + (contadorPost + 1);
      // console.log(obj.nombrePeriodo);
    }
    if (obj.etapa === 'HOLD') {
      obj.numero = this.periodos.length + 1;
      obj.type = 'Hold';
      obj.nombrePeriodo = 'HOLD' + '_' + (contadorHolds + 1);
    }
    if (arr.length) {
      // console.log('El array ya tiene elementos');
      // if(this.period.etapa === 'DESARROLLO' ){
      //   if( arr.etapa = 'PREPARACION'){

      //     Notiflix.Notify.Failure('No puedes Agregar mas Etapas Desarrollo')
      //     console.log('se ejecuta')
      //   }else if(this.period.etapa)
      // } else{

      const fechaPeriodoAnterior = arr[arr.length - 1].fechaFin;
      if (
        fechaPeriodoAnterior < obj.fechaInicio &&
        obj.fechaInicio < obj.fechaFin
      ) {
        this.periodos.push(obj);
        const objNew = Object.assign({}, this.periodos);
        this.projectService.addPeriods(this.idCompany, this.idProject, objNew);
        this.cambioFechasInputs();
      } else {
        Notiflix.Notify.Failure(
          'La fecha de inicio debe ser mayor a la fecha final del periodo anterior'
        );
      }
      // }
    } else {
      // console.log('es el primer elemento del array');
      if (obj.fechaInicio < obj.fechaFin) {
        this.periodos.push(obj);
        const objNew = Object.assign({}, this.periodos);
        this.projectService.addPeriods(this.idCompany, this.idProject, objNew);
        this.cambioFechasInputs();
      } else {
        Notiflix.Notify.Failure(
          'La fecha de inicio debe ser mayor a la fecha final del periodo anterior'
        );
      }
    }
  }

  cambioFechasInputs() {
    // Convierto la fecha de tipo string a tipo Date.
    const fechaInicio = new Date(this.period.fechaInicio);
    const fechaFin = new Date(this.period.fechaFin);
    // ---------------->

    // Utilizo la funcion setDate() para modificar la fecha original
    // y le sumo 7 dias para que la fecha quede en el mismo dia.
    fechaInicio.setDate(fechaInicio.getDate() + 7);
    fechaFin.setDate(fechaFin.getDate() + 7);
    // ---------------->

    // Convierto de nuevo la fecha de tipo Date a tipo String y la guardo
    // en la misma variable para poderla mostrar en el mismo input tipo date.
    this.period.fechaInicio = fechaInicio.toISOString().split('T')[0];
    this.period.fechaFin = fechaFin.toISOString().split('T')[0];
    // ---------------->
  }

  addPeriodsDocumental(arr, obj, arrPeriodoAnterior) {
    console.log(obj);

    let contadorHolds = 0;
    this.periodos.forEach(element => {
      if (element.type === 'Hold') {
        contadorHolds++;
      }
    });

    let contadorPeriodos = 0;
    arr.forEach(element => {
      if (element.type === 'Periodo') {
        contadorPeriodos++;
      }
    });

    if (obj.type === 'Periodo') {
      switch (obj.etapa) {
        case 'DOCUMENTAL':
          obj.numero = this.periodos.length + 1;
          obj.nombrePeriodo =
            obj.etapa.substr(0, 3) + '_' + (contadorPeriodos + 1);
          // console.log(obj.nombrePeriodo);
          break;
      }
    } else if (obj.type === 'Hold') {
      obj.nombrePeriodo = 'HOLD' + '_' + (contadorHolds + 1);
      // console.log(obj.nombrePeriodo);
    }

    if (arr.length) {
      // console.log('El array ya tiene elementos');
      const fechaPeriodoAnterior = arr[arr.length - 1].fechaFin;
      if (
        fechaPeriodoAnterior < obj.fechaInicio &&
        obj.fechaInicio < obj.fechaFin
      ) {
        this.periodosDocumental.push(obj);
        const objNew = Object.assign({}, this.periodosDocumental);
        this.projectService.addPeriods(this.idCompany, this.idProject, objNew);
      } else {
        Notiflix.Notify.Failure(
          'La fecha de inicio debe ser mayor a la fecha final del periodo anterior'
        );
      }
    } else {
      // console.log('Es el primer elemento del array');
      if (obj.etapa === 'DOCUMENTAL') {
        if (obj.fechaInicio < obj.fechaFin) {
          this.periodosDocumental.push(obj);
          const objNew = Object.assign({}, this.periodosDocumental);
          this.projectService.addPeriods(
            this.idCompany,
            this.idProject,
            objNew
          );
        } else {
          Notiflix.Notify.Failure(
            'La fecha de inicio debe ser mayor a la fecha final del periodo anterior'
          );
        }
      } else {
        const fechaEtapaAnterior =
          arrPeriodoAnterior[arrPeriodoAnterior.length - 1].fechaFin;
        if (
          fechaEtapaAnterior < obj.fechaInicio &&
          obj.fechaInicio < obj.fechaFin
        ) {
          this.periodosDocumental.push(obj);
          const objNew = Object.assign({}, this.periodosDocumental);
          this.projectService.addPeriods(
            this.idCompany,
            this.idProject,
            objNew
          );
        } else {
          Notiflix.Notify.Failure(
            'La fecha de inicio debe ser mayor a la fecha final de la etapa o periodo anterior'
          );
        }
      }
    }
  }
  deletePeriodDocumental() {
    this.periodos.pop();
    const obj = Object.assign({}, this.periodos);
    this.projectService.deletePeriod(this.idCompany, this.idProject, obj);
    Notiflix.Notify.Success('Se elimino el periodo');
  }

  deletePeriod() {
    this.periodos.pop();
    const obj = Object.assign({}, this.periodos);
    this.projectService.deletePeriod(this.idCompany, this.idProject, obj);
    Notiflix.Notify.Success('Se elimino el periodo');
    this.period = {};
  }
  // deletePeriodSeleccionado() {
  //   this.periodos.splice(,this.fila, this.periodoSeleccionado );
  //   const obj = Object.assign({}, this.periodos);
  //   this.projectService.deletePeriod(this.idCompany, this.idProject, obj);
  //   Notiflix.Notify.Success('Se elimino el periodo');

  // }

  ngOnDestroy() {
    this.subscriberGetPeriods.unsubscribe();
    this.subscriberGetProject.unsubscribe();
  }
}
