import { Component, OnInit, Input } from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css'],
})
export class ProviderComponent implements OnInit {
  @Input() proveedor = {} as any;
  provider = {} as any;
  providerExtra;
  proveedor1;

  providerSubscription: Subscription;
  subscriberGetProviders: Subscription;
  constructor(private providersService: ProvidersService) {}

  ngOnInit(): void {
    // console.log(this.proveedor);
    this.getProvider();
    this.getProvidersExternos();
  }

  getProvider() {
    this.providersService
      .getProviderPublic(this.proveedor.id)
      .subscribe(res => {
        // console.log(res);
        this.provider = res;
      });
  }

  getProvidersExternos() {
    this.subscriberGetProviders = this.providersService
      .getProvidersPublic()
      .subscribe(res => {
        this.providerExtra = res;

        this.providerExtra.forEach(element => {
          this.proveedor1 = element;
          if (this.proveedor1.rfc === this.proveedor.rfc) {
            this.providerExtra = this.proveedor1.id;
          }
        });
        // console.log(this.providerExtra);
      });
  }

  aproveData() {
    let archivos = this.proveedor.archivos;
    let archivosRepse = this.proveedor.archivosRepse;
    // console.log(archivosRepse);

    if (
      archivosRepse === undefined &&
      archivos[0].status === 'Aprobado' &&
      archivos[1].status === 'Aprobado' &&
      archivos[2].status === 'Aprobado' &&
      archivos[3].status === 'Aprobado' &&
      archivos[4].status === 'Aprobado'
    ) {
      this.providersService.updateProviderExterno(this.providerExtra, {
        aprobado: true,
        datosProvider: true,
      });
      // console.log('todos estan aprobados');
      Notiflix.Notify.Success('Actualizado Correctamente');
    } else if (
      (archivosRepse === undefined && archivos[0].status === 'Rechazado') ||
      archivos[1].status === 'Rechazado' ||
      archivos[2].status === 'Rechazado' ||
      archivos[3].status === 'Rechazado' ||
      archivos[4].status === 'Rechazado'
    ) {
      Notiflix.Notify.Warning('Todos los documentos deben estar aprobados');
    } else if (
      (archivosRepse === undefined && archivos[0].status === 'En revisión') ||
      archivos[1].status === 'En revisión' ||
      archivos[2].status === 'En revisión' ||
      archivos[3].status === 'En revisión' ||
      archivos[4].status === 'En revisión' ||
      archivos[0].status === 'Pendiente' ||
      archivos[1].status === 'Pendiente' ||
      archivos[2].status === 'Pendiente' ||
      archivos[3].status === 'Pendiente' ||
      archivos[4].status === 'Pendiente'
    ) {
      Notiflix.Notify.Warning('Todos los documentos deben estar aprobados');
    } else if (archivosRepse) {
      if (
        archivos[0].status === 'Aprobado' &&
        archivos[1].status === 'Aprobado' &&
        archivos[2].status === 'Aprobado' &&
        archivos[3].status === 'Aprobado' &&
        archivos[4].status === 'Aprobado' &&
        archivosRepse[0].status === 'Aprobado' &&
        archivosRepse[1].status === 'Aprobado' &&
        archivosRepse[2].status === 'Aprobado' &&
        archivosRepse[3].status === 'Aprobado'
      ) {
        this.providersService.updateProviderExterno(this.providerExtra, {
          aprobado: true,
          datosProvider: true,
        });
        Notiflix.Notify.Success('Actualizado Correctamente');
        // console.log('todos estan aprobados repse');
      } else if (
        archivos[0].status === 'En revisión' ||
        archivos[1].status === 'En revisión' ||
        archivos[2].status === 'En revisión' ||
        archivos[3].status === 'En revisión' ||
        archivos[4].status === 'En revisión' ||
        archivos[0].status === 'Pendiente' ||
        archivos[1].status === 'Pendiente' ||
        archivos[2].status === 'Pendiente' ||
        archivos[3].status === 'Pendiente' ||
        archivos[4].status === 'Pendiente' ||
        archivosRepse[0].status === 'En revisión' ||
        archivosRepse[1].status === 'En revisión' ||
        archivosRepse[2].status === 'En revisión' ||
        archivosRepse[3].status === 'En revisión' ||
        archivosRepse[0].status === 'Pendiente' ||
        archivosRepse[1].status === 'Pendiente' ||
        archivosRepse[2].status === 'Pendiente' ||
        archivosRepse[3].status === 'Pendiente'
      ) {
        Notiflix.Notify.Warning('Todos los documentos deben estar aprobados');
      } else {
        this.providersService.updateProviderExterno(this.providerExtra, {
          aprobado: true,
          datosProvider: false,
        });
        // console.log('deben estar todos aprobados repse');
      }
    }
  }

  unaproveData() {
    this.providersService.updateProviderExterno(this.providerExtra, {
      aprobado: false,
      datosProvider: false,
    });
  }

  approveFiles(item, status: string) {
    if (item.status !== status) {
      Notiflix.Confirm.Show(
        'ALERTA',
        '¿Deseas aprobar este archivo?',
        'Si',
        'No',
        () => {
          item.status = status;

          this.proveedor.archivos.forEach(element => {
            // console.log(element);
            if (item.camelCase === element.camelCase) {
              // console.log(element);
              element.status = status;
            }
          });
          // console.log(this.proveedor.archivos);
          const obj = {
            archivos: this.proveedor.archivos,
            archivosAprobados: true,
          };
          // console.log(obj);
          this.providersService
            .updateProviderExterno(this.providerExtra, obj)
            .then(res => {
              Notiflix.Notify.Success('Se actualizo correctamente');
            });
        }
      );
    }
  }
  desapproveFiles(item, status: string) {
    if (item.status !== status) {
      Notiflix.Confirm.Show(
        'ALERTA',
        '¿Deseas rechazar este archivo?',
        'Si',
        'No',
        () => {
          item.status = status;
          const obj = {
            archivos: this.proveedor.archivos,
            archivosAprobados: false,
          };
          if (status === 'Rechazado') {
            this.unaproveData();
          }
          this.providersService
            .updateProviderExterno(this.providerExtra, obj)
            .then(res => {
              Notiflix.Notify.Success('Se actualizo correctamente');
            });
        }
      );
    }
  }
}
