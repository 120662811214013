import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { PurchaseOrder } from 'src/app/models/dealMemo';

import Notiflix from 'notiflix-angular';

declare var $: any;

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css'],
})
export class PurchaseOrderComponent implements OnInit {
  idCompany: any;
  idProject: any;
  fecha = new Date();

  purchaseOrder = {
    pagos: [] as any,
  } as any;
  project = {} as any;
  periodos = [] as any;
  providers = [] as any;
  budget = [] as any;
  departament = [] as any;
  puestos = [] as any;
  departamento = {} as any;
  puesto = {} as any;
  ctasPresupuesto = [] as any;
  orderCount = {} as PurchaseOrder;

  isOrder: any;
  objMovimientos = {} as any;
  proveedorSelecionado: any;
  isMoral = false;
  suma: number = 0;

  subscriberGetProject: Subscription;
  subscriberGetPeriods: Subscription;
  subscribeCountDealMemo: Subscription;
  subscriberGetProviders: Subscription;
  subscriberGetBudget: Subscription;

  constructor(
    private router: Router,
    private empresasService: EmpresasService,
    private projectService: ProjectService,
    private dealMemoService: DealMemoService,
    private providersService: ProvidersService,
    private budgetService: BudgetService
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isOrder = url.root.children.primary.segments[4].path;
    console.log(this.isOrder);
    this.purchaseOrder.fecha = this.fecha.toString();

    this.getCount();
    this.getProject();
    this.getPeriods();
    this.getProvider();
    this.getBudget();
  }

  getCount() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getOrderCount(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;

        console.log(res);

        if (res === undefined) {
          this.orderCount.orderCount = 1;
          this.purchaseOrder.orderCount = this.orderCount.orderCount;
        } else {
          this.orderCount.orderCount = contador.orderCount + 1;
          this.purchaseOrder.orderCount = this.orderCount.orderCount;
        }
      });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.purchaseOrder.tipoMoneda = this.project.monedaLocal;
      });
  }

  getPeriods() {
    this.subscriberGetPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodos = Object.assign([], res);
      });
  }
  getProvider() {
    this.subscriberGetProviders = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  changeSelectProvider() {
    const proveedor = this.providers.find(
      element =>
        element.rfc === this.proveedorSelecionado ||
        element.taxId === this.proveedorSelecionado
    );
    this.isMoral = false;
    if (proveedor) {
      this.purchaseOrder.proveedor = proveedor.numero;
      this.purchaseOrder.nombreProveedor = proveedor.nombre;
      this.purchaseOrder.rfcProveedor = proveedor.rfc;
      this.purchaseOrder.nacionalidad = proveedor.nacionalidad;
      if (proveedor.persona === 'Moral') {
        this.isMoral = true;
      } else {
        this.isMoral = false;
      }
    }
  }

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          // this.budgetArrays = budget; // este es el array que contiene hijos (children)
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          this.getDepartament();
        }
      });
  }

  getDepartament() {
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element.Total_Estimado) {
        this.departament.push(element);
      }
    }
  }

  getPuesto() {
    this.purchaseOrder.departamento = this.departamento.Descripcion;
    this.puestos = [];
    this.puesto = '';
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }

  getCtaPres() {
    this.purchaseOrder.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];

      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 4) {
        this.ctasPresupuesto.push(element);
      }
    }
  }

  getNameCta(obj) {
    const elemento = this.ctasPresupuesto.find(
      element => element.CodigoAgrupador === obj.cuentaPres
    );
    obj.Descripcion = elemento ? elemento.Descripcion : 'Cuenta no valida';
  }

  agregarMovimiento() {
    const obj = this.objMovimientos;
    if (obj.cuentaPres && obj.importe && obj.periodo) {
      this.purchaseOrder.pagos.push(this.objMovimientos);
      this.objMovimientos = {};
      document.getElementById('cuentaPres').focus();
      this.totales();
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
  }

  changeAddMovement() {
    this.periodos.forEach(element => {
      if (this.objMovimientos.periodo === element.nombrePeriodo) {
        this.objMovimientos.fechaInicio = element.fechaInicio;
        this.objMovimientos.fechaFin = element.fechaFin;
      }
    });
  }

  deleteArr(arr: Array<any>, index: number) {
    arr.splice(index, 1);
    this.totales();
  }

  getDates(periodo) {
    const elemento = this.periodos.find(
      element => element.nombrePeriodo === periodo.periodo
    );
    periodo.fechaInicio = elemento ? elemento.fechaInicio : Date();
    periodo.fechaFin = elemento ? elemento.fechaFin : Date();
  }

  totales() {
    this.suma = 0;
    this.purchaseOrder.pagos.forEach(element => {
      this.suma += element.importe;
    });
  }

  validateOrderPay() {
    let validationCtas = true;
    if (this.purchaseOrder.pagos.length > 0) {
      this.purchaseOrder.pagos.forEach(element => {
        if (element.Descripcion === 'Cuenta no valida') {
          validationCtas = false;
          Notiflix.Notify.Failure('Todos los pagos requiren de una cuenta');
        }
      });
    }
    if (
      this.purchaseOrder.proveedor &&
      this.purchaseOrder.rfcProveedor &&
      this.purchaseOrder.descripcionActividad &&
      this.purchaseOrder.contratoInicio &&
      this.purchaseOrder.contratoFin &&
      this.purchaseOrder.valorPurchaseOrder &&
      validationCtas
    ) {
      return true;
    } else {
      return false;
    }
  }

  saveOrderPay() {
    this.purchaseOrder.dateCreated = new Date();
    console.log(this.purchaseOrder);

    const validacion = this.validateOrderPay();

    if (validacion) {
      if (this.isOrder === 'consultOrdenCompra') {
        // Se ejecuta al editar una orden de compra
        this.purchaseOrder.fecha = this.fecha.toString();

        this.dealMemoService
          .updateOrderCount(
            this.idCompany,
            this.idProject,
            this.purchaseOrder.id,
            this.purchaseOrder
          )
          .then(() => {
            Notiflix.Notify.Success(
              'Se actualizo correctamente la orden de compra'
            );

            this.purchaseOrder = {
              personasPS: [],
              pagos: [],
              cuentasPer: [],
              documentos: [],
            };
            this.departamento = {};
            this.puesto = {};
            this.suma = 0;
            (<any>document.getElementById('selectProvider')).value = '';
            $('#editOrden').modal('hide');
          });
      } else if (this.isOrder === 'purchaseOrder') {
        this.purchaseOrder.fecha = this.fecha.toString();

        // Se ejecuta al crear una orden de compra nueva

        this.dealMemoService
          .addPurchaseOrder(this.idCompany, this.idProject, this.purchaseOrder)
          .then(res => {
            this.dealMemoService.orderCount(
              this.idCompany,
              this.idProject,
              this.orderCount
            );

            Notiflix.Notify.Success(
              'Se guardo correctamente la orden de compra'
            );
            this.purchaseOrder = {
              personasPS: [],
              pagos: [],
              cuentasPer: [],
              documentos: [],
            };
            this.departamento = {};
            this.puesto = {};
            this.suma = 0;
          })
          .catch(err => {
            console.error('Ocurrio un error: ', err);
            Notiflix.Notify.Failure(
              'Ocurrio un error al guardar la orden de compra'
            );
          });
      }
    } else {
      Notiflix.Notify.Failure('Faltan campos por completar');
    }
  }

  ngOnDestroy() {
    this.subscriberGetProject.unsubscribe();
    this.subscriberGetPeriods.unsubscribe();
    this.subscribeCountDealMemo.unsubscribe();
    this.subscriberGetProviders.unsubscribe();
    this.subscriberGetBudget.unsubscribe();
  }
}
