import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Router } from '@angular/router';
import { ProvidersService } from 'src/app/services/providers.service';
import Notiflix from 'notiflix-angular';
import { Provider } from 'src/app/models/providers';
import { SendEmailService } from 'src/app/services/send-email.service';

@Component({
  selector: 'app-unrelated-provider',
  templateUrl: './unrelated-provider.component.html',
  styleUrls: ['./unrelated-provider.component.css'],
})
export class UnrelatedProviderComponent implements OnInit {
  public addProvider = {} as Provider;

  providersVinculados = [] as any;

  providers = [] as any;
  proveedorDBgeneral;
  baseExterna;
  proveedor = {} as any;
  newProveedor = [] as any;
  providerDBgeneral;

  provider = {
    banks: [],
    documentos: [],
  } as Provider;

  proyecto: any;
  empresa = {} as any;

  idCompany: string;
  idProject: string;
  filterProviders = [];
  filterProviders2 = [];
  proveedorSeleccionado = { banks: [], empresas: [] } as any;
  herramientas: string;
  steps = 'step1';

  subscriberGetProviders: Subscription;

  provSelSubscription: Subscription;
  providerSubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private empresasService: EmpresasService,
    private router: Router,
    private sendEmailService: SendEmailService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    console.log(url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCompany();
    this.getProject();
    this.getProvidersPublic();
    this.getProviders();
  }

  getProject() {
    this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(proyecto => {
        this.proyecto = proyecto;
      });
  }

  getCompany() {
    this.empresasService.getCompanyActual(this.idCompany).subscribe(empresa => {
      this.empresa = empresa;
    });
  }

  upperCaseNombre() {
    this.addProvider.nombre = this.addProvider.nombre.toUpperCase();
    this.addProvider.nombre = this.addProvider.nombre.trim();
  }
  upperCaseRFC() {
    this.addProvider.rfc = this.addProvider.rfc.split(' ').join('');
    this.addProvider.rfc = this.addProvider.rfc.trim();
    this.addProvider.rfc = this.addProvider.rfc.toUpperCase();
  }
  lowerCaseEmail() {
    this.addProvider.email = this.addProvider.email.split(' ').join('');
    this.addProvider.email = this.addProvider.email.trim();
    this.addProvider.email = this.addProvider.email.toLowerCase();
  }
  rfcValido(rfc, aceptarGenerico = true) {
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const validado = rfc.match(re);
    if (!validado) {
      return false;
    }

    // Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop();
    const rfcSinDigito = validado.slice(1).join('');
    const len = rfcSinDigito.length;
    const diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ';
    const indice = len + 1;
    let suma;
    let digitoEsperado;

    if (len === 12) {
      suma = 0;
    } else {
      suma = 481; // Ajuste para persona moral
    }

    for (let i = 0; i < len; i++) {
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
      digitoEsperado = 11 - (suma % 11);
      if (digitoEsperado === 11) {
        digitoEsperado = 0;
      } else {
        if (digitoEsperado === 10) {
          digitoEsperado = 'A';
        }
      }
    }
    if (
      digitoVerificador != digitoEsperado &&
      (!aceptarGenerico || rfcSinDigito + digitoVerificador != 'XAXX010101000')
    ) {
      return false;
    } else if (
      !aceptarGenerico &&
      rfcSinDigito + digitoVerificador === 'XEXX010101000'
    ) {
      return false;
    } else {
      return rfcSinDigito + digitoVerificador;
    }
  }

  getProviders() {
    this.subscriberGetProviders = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
        this.filterProviders = this.providers;
        this.filterProviders2 = this.providers;
        this.provider.numero = this.providers.length + 1;
        // console.log(this.providers);
      });
  }
  previewAddProviders() {
    let provider = {} as any;
    // provider.typeAcount = 'proveedor';
    // provider.userType = 'Usuario';
    provider.email = this.addProvider.email.toLowerCase();
    provider.nombre = this.addProvider.nombre.toUpperCase();
    provider.rfc = this.addProvider.rfc.toUpperCase();
    provider.persona = this.addProvider.rfc.length === 13 ? 'Física' : 'Moral';

    provider.actualizado = false;
    provider.datosProvider = false;
    provider.archivosProvider = false;
    provider.providerCalendarioPagos = false;
    provider.fechaRegistro = new Date();
    provider.files = [
      {
        archivos: [],
        descripcion: 'Opinión de cumplimiento 32D',
        typeFile: 'opinionCumplimiento32D',
      },
      {
        archivos: [],
        descripcion: 'Constancia de situación fiscal',
        typeFile: 'constanciaSF',
      },
    ];
    provider.banks = [];
    provider.empresas = [
      {
        id: this.idCompany,
        name: this.empresa.name,
        proyectos: [
          {
            id: this.idProject,
            name: this.proyecto.name,
          },
        ],
      },
    ];
    provider.archivos = [
      {
        name: 'Identificación Oficial',
        camelCase: 'identificacionOficial',
        archivo: {},
        status: 'Pendiente',
        observacion: '',
      },
      {
        name: 'Caratula Estado de Cuenta',
        camelCase: 'edoCta',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'antigüedad máxima de 3 meses, en donde aparezca el nombre y logo del banco, la cuenta CLABE, nombre y datos del cuentahabiente.',
      },
      {
        name: 'Constancia de Situación Fiscal',
        camelCase: 'constanciaSF',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'Correspondiente al mes de su contratación, en donde se indique que la actividad o servicio es congruente con el objeto del contrato. Este documento tendrá que ser actualizado y enviado cada mes a contabilidad, para que proceda el pago.',
      },
      {
        name: 'Opinión de cumplimiento 32D positiva',
        camelCase: 'opinionCumplimiento32D',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'Este documento tendrá que ser actualizado y enviado cada mes a contabilidad, para que proceda el pago.',
      },
      {
        name: 'Comprobante de domicilio',
        camelCase: 'comprobanteDomicilio',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'No debe tener una antigüedad mayor a 3 meses (Luz, Agua, Teléfono, Predial.',
      },
    ];
    provider.status = 'Pendiente';
    const rfcCorrecto = this.rfcValido(provider.rfc);
    // console.log(rfcCorrecto);
    let validEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // console.log(validEmail.test(provider.email));

    this.providersService
      .findRFC(provider.rfc)
      .toPromise()
      .then(res => {
        // console.log(res.empty);
        if (res.empty === false) {
          Notiflix.Notify.Failure('El RFC ya se encuentra registrado');
          // this.addProvider = {} as Provider;
        } else {
          if (rfcCorrecto) {
            if (!provider.nombre || !provider.email) {
              Notiflix.Notify.Failure('Completa los campos de registro');
            } else {
              if (validEmail.test(provider.email) === false) {
                Notiflix.Notify.Failure(
                  'Email invalido, por favor revisa que este correcto'
                );
              } else {
                this.newProveedor.push(provider);
                // console.log(this.newProveedor);
                this.addProvider = {} as Provider;
              }
            }
          } else {
            Notiflix.Notify.Failure(
              'RFC invalido, por favor revisa que este correcto'
            );
          }
        }
      });
  }
  addDBproveedores() {
    let addProveedores = this.newProveedor;
    console.log(addProveedores);

    addProveedores.forEach(element => {
      this.baseExterna = element;
      // console.log(this.baseExterna);
      this.providersService.addProviderExterno(element).then(res => {
        Notiflix.Notify.Success('Invitaciones Enviadas');

        // aquí tenemos que enviar el correo notificándole al proveedor que
        // fue invitado con una url personalizada

        this.sendEmailService.sendEmail(element.email, element.nombre, res.id);
        this.newProveedor = [] as any;
      });
    });
    this.addProvidersDBgeneral();
  }
  addProvidersDBgeneral() {
    // FALTA VERIFICAR SI EXISTE O NO EN LA BASE DE DATOS GENERAL

    this.newProveedor.forEach(element1 => {
      // console.log(this.providers.includes(element.rfc));
      // console.log(this.providers.find(element => element.rfc === element1.rfc));
      if (this.providers.find(element => element.rfc === element1.rfc)) {
        // console.log('YA SE ENCUENTRA REGISTRADO');
      } else {
        let obj = {} as any;
        // console.log(element1);
        // console.log('no existe en la base general');
        obj.nombre = element1.nombre.toUpperCase();
        obj.email = element1.email.toLowerCase();
        obj.rfc = element1.rfc.toUpperCase();
        obj.persona = element1.rfc.length === 13 ? 'Física' : 'Moral';
        obj.fechaRegistro = new Date();
        obj.numero = this.providers.length + 1;

        this.providers.push(obj);
        // console.log(obj);
      }
    });
    const obj = Object.assign({}, this.providers);
    this.providersService.addProvider(obj);
    this.newProveedor = [] as any;

    console.log(this.providers);
  }

  // enviar invitacion a un solo proveedor
  addProviderDB() {
    // FALTA VALIDAR SI ESTA REGISTRADO O NO EL PROVEEDOR EN AMBAS BASES DE DATOS
    const provider = {} as any;

    provider.typeAcount = 'proveedor';
    provider.userType = 'Usuario';
    provider.email = this.addProvider.email.toLowerCase();
    provider.nombre = this.addProvider.nombre.toUpperCase();
    provider.rfc = this.addProvider.rfc.toUpperCase();
    provider.persona = this.addProvider.rfc.length === 13 ? 'Física' : 'Moral';
    provider.actualizado = false;
    provider.datosProvider = false;
    provider.archivosProvider = false;
    provider.providerCalendarioPagos = false;
    provider.fechaRegistro = new Date();
    provider.files = [
      {
        archivos: [],
        descripcion: 'Opinión de cumplimiento 32D',
        typeFile: 'opinionCumplimiento32D',
      },
      {
        archivos: [],
        descripcion: 'Constancia de situación fiscal',
        typeFile: 'constanciaSF',
      },
    ];
    provider.banks = [];
    provider.empresas = [
      {
        id: this.idCompany,
        name: this.empresa.name,
        proyectos: [
          {
            id: this.idProject,
            name: this.proyecto.name,
          },
        ],
      },
    ];
    provider.archivos = [
      {
        name: 'Identificación Oficial',
        camelCase: 'identificacionOficial',
        archivo: {},
        status: 'Pendiente',
        observacion: '',
      },
      {
        name: 'Caratula Estado de Cuenta',
        camelCase: 'edoCta',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'antigüedad máxima de 3 meses, en donde aparezca el nombre y logo del banco, la cuenta CLABE, nombre y datos del cuentahabiente.',
      },
      {
        name: 'Constancia de Situación Fiscal',
        camelCase: 'constanciaSF',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'Correspondiente al mes de su contratación, en donde se indique que la actividad o servicio es congruente con el objeto del contrato. Este documento tendrá que ser actualizado y enviado cada mes a contabilidad, para que proceda el pago.',
      },
      {
        name: 'Opinión de cumplimiento 32D positiva',
        camelCase: 'opinionCumplimiento32D',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'Este documento tendrá que ser actualizado y enviado cada mes a contabilidad, para que proceda el pago.',
      },
      {
        name: 'Comprobante de domicilio',
        camelCase: 'comprobanteDomicilio',
        archivo: {},
        status: 'Pendiente',
        observacion:
          'No debe tener una antigüedad mayor a 3 meses (Luz, Agua, Teléfono, Predial.',
      },
    ];
    provider.status = 'Pendiente';

    this.providersService
      .findRFC(this.addProvider.rfc)
      .toPromise()
      .then(res => {
        if (res.empty) {
          this.providersService.addProviderExterno(provider).then(res => {
            Notiflix.Notify.Success('Agregado correctamente');

            // aqui tenemos que enviar el correo notificandole al proveedor que
            // fue invitado con una url personalizada

            this.sendEmailService.sendEmail(
              provider.email,
              provider.nombre,
              res.id
            );
          });
        }
      });
  }
  //

  getProvidersPublic() {
    this.providerSubscription = this.providersService
      .getProvidersPublic()
      .subscribe(res => {
        this.providers = res;
        // this.filterProvidersNoVinculado();
      });
  }

  filterProvidersNoVinculado() {
    this.providersVinculados = [] as any;

    for (let index = 0; index < this.providers.length; index++) {
      let vinculado = false;
      const elementProvider = this.providers[index];
      elementProvider.empresas.forEach(elementEmpresas => {
        if (elementEmpresas.id === this.idCompany) {
          elementEmpresas.proyectos.forEach(elementProyectos => {
            if (elementProyectos.id === this.idProject) {
              vinculado = true;
            }
          });
        }
      });
      if (vinculado === false) {
        this.providersVinculados.push(elementProvider);
      }
    }
    // Falta filtar solo por proveedores que estan en este proyecto
  }

  ngOnDestroy(): void {
    this.subscriberGetProviders.unsubscribe();

    if (this.providerSubscription) {
      this.providerSubscription.unsubscribe();
    }

    if (this.provSelSubscription) {
      this.provSelSubscription.unsubscribe();
    }
  }
}
