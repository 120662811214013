import { Component, OnInit, Input } from '@angular/core';
import Notiflix from 'notiflix-angular';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
declare var $;

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css'],
})
export class FilesComponent implements OnInit {
  @Input() proveedor = {} as any;
  statusfiles;
  statusopinionCumplimiento32D;
  providerExtra;
  proveedor1;
  fileSeleccionado;
  motivoRechazo = '';

  subscriberGetProviders: Subscription;

  constructor(private providersService: ProvidersService) {}

  ngOnInit(): void {
    console.log(this.proveedor);
    // this.getProvidersExternos();
  }

  getProvidersExternos() {
    this.subscriberGetProviders = this.providersService
      .getProvidersPublic()
      .subscribe(res => {
        this.providerExtra = res;
        console.log(this.providerExtra);
        this.providerExtra.forEach(element => {
          this.proveedor1 = element;
          if (this.proveedor1.rfc === this.proveedor.rfc) {
            this.providerExtra = this.proveedor1.id;
          }
        });
      });
  }

  approveDesapproveFiles(item, status: string) {
    item.status = status;
    let files = this.proveedor.files;
    files.constanciaSF.forEach(element1 => {
      this.statusfiles = element1;
      if (this.statusfiles.status === 'Aprobado') {
        this.statusfiles.actualizado = true;
      }
    });
    files.opinionCumplimiento32D.forEach(element2 => {
      this.statusopinionCumplimiento32D = element2;
      if (this.statusopinionCumplimiento32D.status === 'Aprobado') {
        this.statusopinionCumplimiento32D.actualizado = true;
      }
    });

    console.log(this.providerExtra);
    this.providersService
      .updateProviderExterno(this.providerExtra, {
        files: this.proveedor.files,
      })
      .then(res => {
        Notiflix.Notify.Success('Se actualizo correctamente');
      });
    this.archivosProvider();
  }

  archivosProvider() {
    let files = this.proveedor.files;

    const constanciaSF = files.constanciaSF;
    const opinionCumplimiento32D = files.opinionCumplimiento32D;

    for (let index = 0; index < constanciaSF.length; index++) {
      this.statusfiles = constanciaSF[index];
      for (let index = 0; index < opinionCumplimiento32D.length; index++) {
        this.statusopinionCumplimiento32D = opinionCumplimiento32D[index];
      }
    }
    if (
      this.statusfiles.actualizado &&
      this.statusopinionCumplimiento32D.actualizado === true &&
      this.statusfiles.status &&
      this.statusopinionCumplimiento32D.status === 'Aprobado'
    ) {
      this.providersService
        .updateProviderExterno(this.providerExtra, {
          files: this.proveedor.files,
          archivosProvider: true,
        })
        .then(res => {
          Notiflix.Notify.Success('Archivos Completos');
        });
    } else {
      this.providersService.updateProviderExterno(this.providerExtra, {
        files: this.proveedor.files,
        archivosProvider: false,
      });
    }
  }

  aprobar(obj) {
    obj.status = 'Aprobado';
    this.updateProvider();
  }

  revision(obj) {
    obj.status = 'En revisión';
    this.updateProvider();
  }

  rechazar(obj, status: string) {
    obj.status = 'Rechazado';
    if (obj.status !== status) {
      $('#modalRechazo').modal('show');
      this.fileSeleccionado = obj;
    }
    // this.updateProvider();
  }
  confirmRechazo(obj) {
    this.fileSeleccionado.motivoRechazo = obj;
    console.log(this.fileSeleccionado);
    this.updateProvider();

    $('#modalRechazo').modal('hide');
  }

  updateProvider() {
    this.providersService
      .updateProviderExterno(this.proveedor.id, {
        files: this.proveedor.files,
      })
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
      });
  }
}
