import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProvidersService } from 'src/app/services/providers.service';
import { AuthService } from 'src/app/services/auth.service';
import { Provider, Bank } from 'src/app/models/providers';
import * as XLSX from 'xlsx';
import Notiflix from 'notiflix-angular';
import { Subscription } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxXml2jsonService } from 'ngx-xml2json';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css'],
})
export class ProvidersComponent implements OnInit, OnDestroy {
  estadosDB = [];
  providers = [];
  providersBackup = [];
  provider = {
    banks: [],
    documentos: [],
  } as Provider;
  documento = {} as Document;
  banco = {} as Bank;
  providerEdit = {
    banks: [],
    documentos: [],
  } as Provider;
  findProvider = { nacionalidad: 'Mexicana' } as Provider;
  findProviderTrue: any = [];
  typeFind: string;
  count = {};
  nacionalidadExt: boolean;
  idProviders: any;
  filterProviders = [];
  filterProviders2 = [];
  datosProveedor: any = {} as Provider;
  idCompany: any;
  idProject: any;
  dataTable;
  providersRegistrados = [];
  provedoresARegistrar = [];
  auth = false;
  roleUser;
  objDocumento = {} as any;
  nameDocument: string;
  providerSeleccionado;

  xml = {} as any;
  arrXML = [] as any;
  project = {} as any;
  public rfcReceptor = '';

  public isProject = false;

  subscriberGetProviders: Subscription;
  subscriberGetUser: Subscription;
  subscriberGetAuth: Subscription;

  constructor(
    private ngxXml2jsonService: NgxXml2jsonService,
    private providersService: ProvidersService,
    private authService: AuthService,
    private storage: AngularFireStorage
  ) {
    this.subscriberGetUser = authService.userData$.subscribe(res => {
      this.subscriberGetAuth = this.authService
        .roleUser(res.uid)
        .subscribe(resolve => {
          const objRoleUser: any = resolve;
          this.roleUser = objRoleUser[0].userType;
          if (this.roleUser === 'Administrador') {
            this.auth = true;
          } else {
            this.auth = false;
          }
        });
    });
  }

  ngOnInit() {
    this.getProviders();
  }
  getProviders() {
    this.subscriberGetProviders = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
        this.filterProviders = this.providers;
        this.filterProviders2 = this.providers;
        this.provider.numero = this.providers.length + 1;
        console.log(this.providers);
      });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    (<any>document.getElementById('inputFiles')).value = '';
  }

  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.validarSiExiste(obj);
  }

  validarSiExiste(obj) {
    // console.log(obj);
    const rfc = obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;
    const validacion = this.filterProviders2.findIndex(
      element => element.rfc === rfc
    );
    if (validacion === -1) {
      if (
        obj['cfdi:Comprobante']['cfdi:Receptor'][
          '@attributes'
        ].Rfc.toUpperCase() === this.rfcReceptor.toUpperCase() &&
        this.isProject
      ) {
        this.assignData(obj);
      } else if (!this.isProject) {
        this.assignData(obj);
      } else if (
        obj['cfdi:Comprobante']['cfdi:Receptor'][
          '@attributes'
        ].Rfc.toUpperCase() !== this.rfcReceptor.toUpperCase() &&
        this.isProject
      ) {
        if (this.project.filmadoras) {
          this.project.filmadoras.forEach(element => {
            if (
              obj['cfdi:Comprobante']['cfdi:Receptor'][
                '@attributes'
              ].Rfc.toUpperCase() === element.rfc
            ) {
              this.assignData(obj);
            }
          });
        }
      }
    } else if (validacion > -1) {
      console.log('ya existe');
      Notiflix.Notify.Failure(
        `El Proveedor con RFC ${rfc} ya se encuentra registrado.`
      );
    }
  }

  assignData(obj: any) {
    // console.log(obj);
    if (obj['cfdi:Comprobante']) {
      console.log(obj);
      try {
        this.xml.nombre =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

        this.xml.rfc =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

        this.xml.regimen =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

        this.xml.lugarExpedicion =
          obj['cfdi:Comprobante']['@attributes'].LugarExpedicion;

        console.log(this.xml.rfc.length);

        this.xml.persona = this.xml.rfc.length === 12 ? 'Moral' : 'Física';
        this.xml.nacionalidad = 'Mexicana';
        this.xml.numero = this.providers.length + 1;
        this.filterProviders2.push(this.xml);
        console.log(this.filterProviders2);

        this.xml = {};
      } catch (error) {
        console.error('Ocurrio un error');
      }
    }
  }

  editOpenProvider(provider) {
    console.log(provider);
    $('#editProvider').modal('show');
    if (!provider.banks && !provider.documentos) {
      provider.banks = [] as any;
      provider.documentos = [] as any;
    }
    this.providerEdit = provider;
    this.providerSeleccionado = provider;
    // console.log(provider);
  }
  editProvider() {
    $('#editProvider').modal('hide');

    this.providersService
      .editProvider(this.providers)
      .then(() => {
        Notiflix.Notify.Success('Se Actualizo Correctamente');
      })
      .catch(err => {
        console.error(err);
      });
  }
  deleteProvider() {
    this.filterProviders2.pop();

    console.log(this.filterProviders2);
    const obj = Object.assign({}, this.filterProviders2);
    this.providersService.addProvider(obj).then(() => {
      Notiflix.Notify.Success('Se elimino el ultimo proveedor');
    });
  }
  rfcValido(rfc, aceptarGenerico = true) {
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const validado = rfc.match(re);
    if (!validado) {
      return false;
    }

    // Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop();
    const rfcSinDigito = validado.slice(1).join('');
    const len = rfcSinDigito.length;
    const diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ';
    const indice = len + 1;
    let suma;
    let digitoEsperado;

    if (len === 12) {
      suma = 0;
    } else {
      suma = 481; // Ajuste para persona moral
    }

    for (let i = 0; i < len; i++) {
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
      digitoEsperado = 11 - (suma % 11);
      if (digitoEsperado === 11) {
        digitoEsperado = 0;
      } else {
        if (digitoEsperado === 10) {
          digitoEsperado = 'A';
        }
      }
    }
    if (
      digitoVerificador != digitoEsperado &&
      (!aceptarGenerico || rfcSinDigito + digitoVerificador != 'XAXX010101000')
    ) {
      return false;
    } else if (
      !aceptarGenerico &&
      rfcSinDigito + digitoVerificador === 'XEXX010101000'
    ) {
      return false;
    } else {
      return rfcSinDigito + digitoVerificador;
    }
  }
  addProvider() {
    if (
      this.provider.nombre &&
      this.provider.persona &&
      this.provider.nacionalidad &&
      this.provider.rfc &&
      this.provider.cp &&
      this.provider.regimen
    ) {
      this.provider.nombre = this.provider.nombre.trim();
      this.provider.persona = this.provider.persona.trim();
      this.provider.nacionalidad = this.provider.nacionalidad.trim();
      this.provider.rfc = this.provider.rfc.trim();
      this.provider.cp = this.provider.cp.trim();
      this.provider.regimen = this.provider.regimen.trim();
    }
    console.log(this.provider);
    if (
      this.provider.nombre &&
      this.provider.persona &&
      this.provider.nacionalidad &&
      this.provider.rfc &&
      this.provider.cp &&
      this.provider.regimen
    ) {
      this.provider.rfc = this.provider.rfc.toUpperCase();
      this.provider.nombre = this.provider.nombre.toUpperCase();
      if (this.provider.nacionalidad === 'Extranjera') {
        if (!this.provider.taxId) {
          Notiflix.Notify.Failure(
            'Por favor llena todos los campos obligatorios.'
          );
        } else {
          if (
            this.provider.persona === 'Física' &&
            this.provider.rfc.length === 13
          ) {
            this.rfcInput();
          } else if (
            this.provider.persona === 'Moral' &&
            this.provider.rfc.length === 13
          ) {
            this.rfcInput();
          } else {
            Notiflix.Notify.Failure(
              'El RFC no coresponde a una persona' + this.provider.persona
            );
          }
        }
      } else if (this.provider.nacionalidad === 'Mexicana') {
        console.log();
        if (
          this.provider.persona === 'Física' &&
          this.provider.rfc.length === 13
        ) {
          this.rfcInput();
        } else if (
          this.provider.persona === 'Moral' &&
          this.provider.rfc.length === 12
        ) {
          this.rfcInput();
        } else {
          Notiflix.Notify.Failure(
            'El RFC tiene ' +
              this.provider.rfc.length +
              ' caracteres, no corresponde a una persona ' +
              this.provider.persona
          );
        }
      }
    } else {
      Notiflix.Notify.Failure('Por favor llena los campos obligatorios.');
    }
  }
  rfcInput() {
    const rfc = this.provider.rfc.toUpperCase();

    const rfcCorrecto = this.rfcValido(rfc);

    if (rfcCorrecto) {
      this.validateExistRfc();
    } else {
      Notiflix.Notify.Failure(
        'RFC invalido, por favor revisa que este correcto'
      );
    }
  }
  validateExistRfc() {
    if (this.provider.nacionalidad === 'Mexicana') {
      if (this.providers.length > 0) {
        let existe: boolean;
        for (let index = 0; index < this.providers.length; index++) {
          if (this.providers[index].rfc === this.provider.rfc) {
            if (this.providers[index].rfc === 'XAXX010101000') {
              existe = false;
            } else {
              existe = true;
              Notiflix.Notify.Failure(
                'El proveedor ya se encuentra registrado con el nombre' +
                  this.providers[index].nombre +
                  ' con numero de registro ' +
                  this.providers[index].numero
              );
              break;
            }
          }
        }
        if (!existe) {
          this.providers.push(this.provider);
          const obj = Object.assign({}, this.providers);
          this.providersService.addProvider(obj);
          $('#addProvider').modal('hide');
          this.provider = {
            banks: [],
            documentos: [],
          } as Provider;
        } else {
          existe = false;
        }
      } else {
        this.providers.push(this.provider);
        const obj = Object.assign({}, this.providers);
        this.providersService.addProvider(obj);
        $('#addProvider').modal('hide');
        this.provider = {
          banks: [],
        } as Provider;
      }
    } else {
      if (this.providers.length > 0) {
        let existe: boolean;
        for (let index = 0; index < this.providers.length; index++) {
          if (this.providers[index].taxId === this.provider.taxId) {
            existe = true;
            Notiflix.Notify.Failure(
              'El proveedor ya se encuentra registrado con el nombre ' +
                this.providers[index].nombre +
                ' con numero de registro ' +
                this.providers[index].numero
            );
            break;
          }
        }
        if (!existe) {
          this.provider.numero = this.providers.length + 1;
          this.providers.push(this.provider);
          const obj = Object.assign({}, this.providers);
          this.providersService.addProvider(obj);
          $('#addProvider').modal('hide');
          this.provider = {} as Provider;
          (<any>document.getElementById('rfc')).disabled = false;
        } else {
          existe = false;
        }
      } else {
        this.provider.numero = this.providers.length + 1;
        this.providers.push(this.provider);
        const obj = Object.assign({}, this.providers);
        this.providersService.addProvider(obj);
        $('#addProvider').modal('hide');
        this.provider = {} as Provider;
      }
    }
  }
  valNacionalidad() {
    if (this.provider.nacionalidad === 'Extranjera') {
      this.nacionalidadExt = true;
      this.provider.rfc = 'XEXX010101000';
      (<any>document.getElementById('rfc')).disabled = true;
    } else {
      this.nacionalidadExt = false;
      (<any>document.getElementById('rfc')).disabled = false;
    }
  }
  filterProvider(ev) {
    this.filterProviders = [];
    const nacionalidad = ev.target.value;
    if (nacionalidad === 'Todos') {
      this.filterProviders = this.providers;
    } else {
      for (let index = 0; index < this.providers.length; index++) {
        const element: Provider = this.providers[index];
        if (element.nacionalidad === nacionalidad) {
          this.filterProviders.push(element);
        }
      }
    }
    this.filterProviders2 = this.filterProviders;
  }
  filterPovider2(ev) {
    this.filterProviders2 = [];
    const text = ev.target.value.toUpperCase();
    for (let index = 0; index < this.filterProviders.length; index++) {
      const element = this.filterProviders[index];
      if (
        this.filterProviders[index].nombre.indexOf(text) >= 0 ||
        this.filterProviders[index].rfc.indexOf(text) >= 0
      ) {
        this.filterProviders2.push(element);
      }
    }
    console.log(this.filterProviders2);
  }

  inputFile(ev) {
    this.dataTable = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      let sheetName;
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        sheetName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.dataTable = jsonData[sheetName];
      // const addDataDB = Object.assign({}, jsonData[sheetName]);
      console.log(this.dataTable);
      // console.log(addDataDB);
      Notiflix.Loading.Dots('Cargando...');
      setTimeout(() => {
        this.validateInputFile();
      }, 1000);
    };
    setTimeout(() => {
      reader.readAsBinaryString(file);
    }, 500);
  }
  validateInputFile() {
    this.providersRegistrados = [];

    for (let index = 0; index < this.dataTable.length; index++) {
      const elementFile = this.dataTable[index];
      const pos = this.filterProviders2
        .map(e => {
          if (e.rfc === 'XEXX010101000') {
            return 'Extranjero';
          } else {
            return e.rfc;
          }
        })
        .indexOf(elementFile.rfc);
      let validacion = false;
      if (
        elementFile.nombre &&
        elementFile.persona &&
        // elementFile.nacionalidad &&
        elementFile.rfc
      ) {
        validacion = true;
      }
      const rfcCorrecto = this.rfcValido(elementFile.rfc);
      // console.log(pos, validacion, rfcCorrecto);
      if (pos < 0 && validacion && rfcCorrecto) {
        elementFile.numero = this.filterProviders2.length + 1 || 1;

        this.filterProviders2.push(elementFile);
        this.provedoresARegistrar.push(elementFile);
        console.log('no se encuentra registrado');
      } else {
        // this.providersRegister.push(elementFile);
        this.providersRegistrados.push(elementFile);
        console.log(this.providersRegistrados);
        Notiflix.Notify.Failure(
          `El proveedor ${elementFile.nombre} ya se encuentra registrado o faltan datos por completar`
        );
      }
    }
    Notiflix.Loading.Remove();
  }
  saveProviders() {
    const obj = Object.assign({}, this.filterProviders2);
    this.providersService.addProvider(obj).then(() => {
      Notiflix.Notify.Success('Guardado exitosamente');
    });
  }

  addBank() {
    if (this.banco.clabe && this.banco.nombre) {
      if (this.banco.clabe.length === 18) {
        this.provider.banks.push(this.banco);
        this.banco = {} as Bank;
      } else {
        Notiflix.Notify.Failure('La cuenta CLABE debe contener 18 digitos');
      }
    }
  }
  deleteBank(index) {
    this.provider.banks.splice(index, 1);
  }

  addBankEdit() {
    if (this.banco.clabe && this.banco.nombre) {
      if (this.banco.clabe.length === 18) {
        this.providerEdit.banks.push(this.banco);
        this.banco = {} as Bank;
      } else {
        Notiflix.Notify.Failure('La cuenta CLABE debe contener 18 digitos');
      }
    }
  }

  clean() {
    this.providersRegistrados = [];
  }

  deleteBankEdit(index) {
    this.providerEdit.banks.splice(index, 1);
    // Notiflix.Notify.Success('No olvides Guardar');
  }
  deleteDocumentEdit(index) {
    this.providerEdit.documentos.splice(index, 1);
    // Notiflix.Notify.Success('No olvides Guardar');
  }

  uploadFile(event) {
    const fileInput = event.target.files[0];
    const fileType = fileInput.type;
    const fileSize = fileInput.size;
    const allowedExtensions = /(.pdf)$/i;
    let uploadF: any = '';
    if (!allowedExtensions.exec(fileType) || fileSize >= 5000000) {
      alert(
        'Por favor agrega unicamente archivos con extension .pdf y tamaño maximo de 5MB '
      );
      // document.getElementById('labelFile').innerHTML = 'Seleccionar';
      (<any>document.getElementById('inputGroupFile01')).value = '';
    } else {
      if (this.objDocumento.documento !== undefined) {
        uploadF = event.target.files[0];
        // document.getElementById('labelFile').innerHTML = uploadF.name;
        const id = Math.random().toString(36).substring(2);
        const date = new Date();
        const fecha =
          date.getDate() +
          '-' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          date.getFullYear() +
          '-' +
          id;
        const filePath = `proveedores/${this.providerEdit.numero}/documentos/${
          this.objDocumento.documento
        }/${new Date()}`;
        const path: any = {};
        path.pathImageProfile = filePath;
        const ref = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, uploadF);
        task.then(res => {
          ref.getDownloadURL().subscribe(resultado => {
            this.objDocumento.link = resultado;
            this.objDocumento.path = filePath;
            this.objDocumento.name = fileInput.name;
            this.agregarDocumento();
          });
        });
      } else {
        Notiflix.Notify.Failure('Selecciona un tipo de documento');
        (<any>document.getElementById('inputGroupFile01')).value = '';
      }
    }
  }

  agregarDocumento() {
    this.providerEdit.documentos.push(this.objDocumento);
    this.objDocumento = {};
    // this.editProvider();
    (<any>document.getElementById('inputGroupFile01')).value = '';
  }

  deleteDocument(index, obj) {
    // this.providerEdit.documentos.splice(index, 1);
    // Create a reference to the file to delete
    const reference = this.storage.ref(obj.path);

    reference.delete().subscribe(() => {
      this.providerEdit.documentos.splice(index, 1);
      // this.editProvider();
    });
  }

  generateBackup() {
    const obj = Object.assign({}, this.providers);
    console.log(obj);
    this.providersService.backupProviders(obj).then(() => {
      Notiflix.Notify.Success('Guardado exitosamente');
    });
  }

  getBackup() {
    this.providersService.getBackupProvider().subscribe(res => {
      console.log(res);
      this.providersBackup = Object.assign([], res);
    });
  }

  backup() {
    this.providers = this.providersBackup;
    const obj = Object.assign({}, this.providers);

    this.providersService
      .addProvider(obj)
      .then(() => {
        console.log('se guardo exitosamente');
      })
      .catch(err => {
        console.error(err);
      });
  }

  exportAsXLSX(): void {
    const arr = [] as any;

    this.providers.forEach(element => {
      let obj = {
        numero: element.numero || '',
        nombre: element.nombre || '',
        persona: element.persona || '',
        nacionalidad: element.nacionalidad || '',
        rfc: element.rfc || '',
        taxId: element.taxId || '',
        regimen: element.regimen || '',
        calle: element.calle || '',
        cp: element.cp || '',
        ciudad: element.ciudad || '',
        localidad: element.localidad || '',
        nombreBanco: element.banks ? element.banks[0]?.nombre : '',
        clabe: element.banks ? element.banks[0]?.clabe : '',
        telefono: element.telefono || '',
      } as any;

      arr.push(obj);
      obj = {} as any;
    });

    this.providersService.exportAsExcelFile(arr, 'reportProviders');
  }

  ngOnDestroy() {
    this.subscriberGetProviders.unsubscribe();
    this.subscriberGetUser.unsubscribe();
    this.subscriberGetAuth.unsubscribe();
  }
}
