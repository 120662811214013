import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AuthService } from 'src/app/services/auth.service';
import { Empresa } from 'src/app/models/empresas';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css'],
})
export class AddCompanyComponent implements OnInit, OnDestroy {
  empresas = [] as any;
  empresa = {
    users: [] as any,
  } as Empresa;
  editingCompany = {} as Empresa;
  file: any;
  uploadF: any;
  deleteComp = {} as Empresa;
  proyectos = [];
  errorMsg = '';
  users = [] as any;
  usersDB = [];
  errMsg: string;
  selectUser = '' as any;
  usersCompany = [] as any;
  empresaSeleccionada = {} as any;
  objTask = {} as any;

  subscriberGetCompany: Subscription;
  subscriberGetUsers: Subscription;

  constructor(
    private empresasService: EmpresasService,
    private authService: AuthService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit() {
    this.getCompanys();
    this.getUsers();
  }
  getCompanys() {
    this.subscriberGetCompany = this.empresasService
      .getCompany()
      .subscribe(empresas => {
        this.empresas = empresas;
      });
  }
  getUsers() {
    this.subscriberGetUsers = this.authService.getUser().subscribe(res => {
      this.users = res;
    });
  }
  validateInputAdd() {
    if (
      this.empresa.name === undefined ||
      this.empresa.name === '' ||
      this.empresa.rfc === undefined ||
      this.empresa.rfc === ''
    ) {
      Notiflix.Notify.Failure('Llena todos los campos requeridos');
    } else {
      $('.addCompanyModal').modal('hide');
      this.addCompany();
    }
  }
  addCompany() {
    this.empresa.users = [];
    this.empresasService
      .addCompany(this.empresa)
      .then(() => {
        this.empresa = {} as Empresa;
        this.file = (<any>document.getElementById('inputFileAdd')).value = '';
      })
      .catch(err => {
        console.error('Ocurrio un error', err);
      });
  }
  validateInputEdit() {
    if (
      this.editingCompany.name === undefined ||
      this.editingCompany.name === '' ||
      this.editingCompany.rfc === undefined ||
      this.editingCompany.rfc === ''
    ) {
      Notiflix.Notify.Failure('Llena todos los campos requeridos');
    } else {
      $('.editCompanyModal').modal('hide');
      setTimeout(() => {
        this.updateCompany();
      }, 3000);
    }
  }
  editCompany(empresa) {
    $('#editCompany').modal('show');
    this.editingCompany = empresa;
  }
  updateCompany() {
    this.empresasService.updateCompany(this.editingCompany);
    this.editingCompany = {} as Empresa;
  }
  openDelete(empresa) {
    $('#modalDelete').modal('show');
    this.deleteComp = empresa;
  }
  deleteCompany() {
    this.empresasService
      .projectExistCompany(this.deleteComp)
      .then(resProject => {
        if (resProject.empty) {
          if (this.deleteComp.users.length === 0) {
            if (this.deleteComp.refImage) {
              this.storage.ref(this.deleteComp.refImage).delete();
            }
            this.empresasService.deleteCompany(this.deleteComp);
            $('#modalDelete').modal('hide');
          } else {
            Notiflix.Notify.Failure('Esta empresa aun tiene usuarios');
            this.errorMsg = 'Esta empresa aun tiene usuarios';
            setTimeout(() => {
              this.errorMsg = '';
            }, 3000);
          }
        } else {
          Notiflix.Notify.Failure('Esta empresa aun tiene proyectos');
          this.errorMsg = 'Esta empresa aun tiene proyectos';
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000);
        }
      });
  }
  uploadFile(event) {
    const fileInput = event.target.files[0];
    const fileType = fileInput.type;
    const fileSize = fileInput.size;
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(fileType) || fileSize >= 1000000) {
      Notiflix.Notify.Failure(
        'Por favor agrega unicamente archivos con extension .jpeg/.jpg/.png/.gif y tamaño maximo de 1MB'
      );
      this.uploadF = '';
      this.file = document.getElementById('labelFileEdit').innerHTML =
        'Choose file';
      this.file = document.getElementById('labelFile').innerHTML =
        'Choose file';
      this.file = (<any>document.getElementById('inputFileAdd')).value = '';
    } else {
      if (this.editingCompany.refImage) {
        this.storage.ref(this.editingCompany.refImage).delete();
      }
      this.uploadF = event.target.files[0];
      const id = Math.random().toString(36).substring(2);
      const filePath = `empresas/${id}`;
      const ref = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.uploadF);
      task.then(() => {
        ref.getDownloadURL().subscribe(url => {
          this.empresa.image = url;
          this.empresa.refImage = filePath;
          this.editingCompany.image = url;
          this.editingCompany.refImage = filePath;
        });
      });
      this.file = document.getElementById(
        'labelFileEdit'
      ).innerHTML = this.uploadF.name;
      this.file = document.getElementById(
        'labelFile'
      ).innerHTML = this.uploadF.name;
    }
  }
  openAddUser(empresa) {
    $('#addUser').modal('show');
    this.empresaSeleccionada = empresa;
  }
  addUsers() {
    let existe = false;
    this.empresaSeleccionada.users.forEach(element => {
      if (element.id === this.selectUser.id) {
        existe = true;
      }
    });
    if (!existe) {
      this.empresaSeleccionada.users.push(this.selectUser);
      this.empresasService
        .editUserCompany(
          this.empresaSeleccionada.id,
          this.empresaSeleccionada.users
        )
        .then(() => {
          Notiflix.Notify.Success('Se agrego con exito');
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrio un error al agregar el usuario');
          console.error('Ocurrio un error', err);
        });
    } else {
      Notiflix.Notify.Failure('Ya existe el usuario');
    }
  }
  deleteUser(index) {
    this.empresaSeleccionada.users.splice(index, 1);
    this.empresasService
      .editUserCompany(
        this.empresaSeleccionada.id,
        this.empresaSeleccionada.users
      )
      .then(() => {
        Notiflix.Notify.Success('Eliminado correctamente');
      })
      .catch(err => {
        Notiflix.Notify.Failure('Ocurrio un error al eliminar el usuario');
        console.error('Ocurrio un error', err);
      });
  }

  task(company) {
    this.empresaSeleccionada = company;
    $('#modalTask').modal('show');
  }

  addTask() {
    if (this.empresaSeleccionada.tasks) {
      this.empresaSeleccionada.tasks.push(this.objTask);
    } else {
      this.empresaSeleccionada.tasks = [];
      this.empresaSeleccionada.tasks.push(this.objTask);
    }
    this.objTask = {};
    this.empresasService.updateCompany(this.empresaSeleccionada).then(() => {
      Notiflix.Notify.Success('Se agrego correctamente');
    });
  }

  deleteTask(index) {
    this.empresaSeleccionada.tasks.splice(index, 1);
    this.empresasService.updateCompany(this.empresaSeleccionada).then(() => {
      Notiflix.Notify.Success('Se elimino correctamente');
    });
  }

  ngOnDestroy() {
    this.subscriberGetCompany.unsubscribe();
    this.subscriberGetUsers.unsubscribe();
  }
}
