import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { SolV1Component } from '../sol-v1/sol-v1.component';
import Notiflix from 'notiflix-angular';

declare var $;

@Component({
  selector: 'app-consult-sol',
  templateUrl: './consult-sol.component.html',
  styleUrls: ['./consult-sol.component.css'],
})
export class ConsultSolComponent implements OnInit {
  @ViewChild(SolV1Component) request: SolV1Component;

  idCompany: string;
  idProject: string;
  arrSol = [] as any;
  arrPO = [] as any;
  requestSeleccionada = {} as any;
  importes = [] as any;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;

  constructor(
    private dealMemoService: DealMemoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);

    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;

    this.getPurchaseOrder();
  }

  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        this.arrPO = res;
        this.arrSol = [] as any;

        for (let index = 0; index < this.arrPO.length; index++) {
          const elementOrder = this.arrPO[index];
          // console.log(elementOrder);
          if (elementOrder.solicitudes.length > 0) {
            elementOrder.solicitudes.forEach(elementRequest => {
              elementRequest.nombreProveedor = elementOrder.nombreProveedor;
              elementRequest.id = elementOrder.id;
              this.arrSol.push(elementRequest);
            });
          }
        }

        this.arrSol.sort((a, b) => {
          return a.countRequest - b.countRequest;
        });
        // console.log(this.arrSol);

        this.arrSol.forEach(elementRequest => {
          let sumaImporte = 0;
          let sumaIva = 0;
          let sumaTotal = 0;
          elementRequest.valores.forEach(elementValores => {
            sumaImporte += elementValores.importe;
            sumaIva += elementValores.iva;
            sumaTotal += elementValores.total;
          });

          const objImportes = {
            importe: sumaImporte,
            iva: sumaIva,
            total: sumaTotal,
          };

          this.importes.push(objImportes);
        });
      });
  }

  solEdit(obj) {
    $('#editSolicitud').modal('show');
    console.log(obj);
    this.requestSeleccionada = obj;
    this.request.request = obj;
  }

  saveEdit(request) {
    const PO = this.arrPO.find(element => element.id === request.id);
    this.dealMemoService
      .updatePO(this.idCompany, this.idProject, PO.id, PO)
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
        $('#editSolicitud').modal('hide');
      })
      .catch(err => {
        console.error('Ocurrio un error' + err);
      });
    // Notiflix.Confirm.Show(
    //   'Actualizar Solicitud',
    //   'Estas seguro de querer actualizar',
    //   'Si',
    //   'No',
    //   () => {
    //   }
    // );
  }

  actualizarTemporal(request) {
    if (request.comprobantes) {
      console.log('tiene comprobantes');
      request.valores = request.comprobantes;
      delete request.comprobantes;
    } else {
      const valores = [
        {
          concepto: request.concepto || '',
          fechaComp: request.fechaComp || null,
          folio: request.folio || '',
          importe: request.importe,
          iva: request.iva || 0,
          retIva: request.retIva || 0,
          retIsr: request.retIsr || 0,
          total: request.total,
          nombreProveedor: request.nombreProveedor,
          partidaPres: request.partidaPres || '',
          partidaPresIva: request.partidaPresIva || null,
          fechaInicio: request.fechaInicio || null,
          fechaFin: request.fechaFin || null,
          rfc: request.rfc || '',
        },
      ];
      request.valores = valores;
    }
    this.saveEdit(request);
  }
}
