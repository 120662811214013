import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';

import { Provider } from 'src/app/models/providers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProvidersService {
  EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';

  providersCollection: AngularFirestoreCollection<Provider>;
  providersDocument: AngularFirestoreDocument<Provider>;
  autoIncrement: AngularFirestoreCollection<Provider>;
  providers: Observable<Provider[]>;
  numProvider;
  count;

  constructor(private afs: AngularFirestore) {}

  getProvider() {
    return this.afs.collection('providers').doc('providers').valueChanges();
  }
  getBackupProvider() {
    return this.afs.collection('backupProviders').doc('backup').valueChanges();
  }
  addProvider(obj) {
    return this.afs.collection('providers').doc('providers').set(obj);
  }
  editProvider(providers) {
    const obj = Object.assign({}, providers);
    return this.afs.collection('providers').doc('providers').update(obj);
  }

  deleteProvider(provider) {
    const providerDoc = this.afs.doc(`providers/${provider.id}`);
    providerDoc.delete();
  }

  filterProvider(arr, rfc) {
    for (let index = 0; index < arr.length; index++) {
      if (arr[index].rfc === rfc) {
        return true;
      } else {
        return false;
      }
    }
  }
  backupProviders(obj) {
    return this.afs.collection('backupProviders').doc('backup').set(obj);
  }

  getProvidersPublicActualizado() {
    return this.afs
      .collection('proveedoresExternos', ref =>
        ref.where('actualizado', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  getProvidersExternos(rfc) {
    return this.afs
      .collection('proveedoresExternos', ref => ref.where('rfc', '==', rfc))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  getProvidersPublicNoActualizado() {
    return this.afs
      .collection('proveedoresExternos', ref =>
        ref.where('actualizado', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  getProvidersPublic() {
    return this.afs
      .collection('proveedoresExternos')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: any = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  addProviderExterno(obj) {
    return this.afs.collection('proveedoresExternos').add(obj);
  }

  updateProviderExterno(id, obj) {
    console.log(id);
    console.log(obj);
    return this.afs.collection('proveedoresExternos').doc(id).update(obj);
  }

  desvincularProvider(id, empresa) {
    return this.afs
      .collection('proveedoresExternos')
      .doc(`${id}empresas${empresa}`);
    // provider.delete();
  }
  desvincularProvider1(id, empresa, proyecto) {
    console.log(id);
    console.log(empresa);
    console.log(proyecto);

    // provider.delete();
  }
  getProviderPublic(id: string) {
    return this.afs.collection('proveedoresExternos').doc(id).valueChanges();
  }

  findRFC(RFC) {
    return this.afs
      .collection('proveedoresExternos', ref => ref.where('rfc', '==', RFC))
      .get();
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { Proveedores: worksheet },
      SheetNames: ['Proveedores'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION
    );
  }
}
