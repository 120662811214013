import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { DealMemoComponent } from 'src/app/pages/deal-memo/deal-memo.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
import { PdfMakeWrapper, Txt, Table, Img } from 'pdfmake-wrapper';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-check-deal-memo',
  templateUrl: './check-deal-memo.component.html',
  styleUrls: ['./check-deal-memo.component.css'],
})
export class CheckDealMemoComponent implements OnInit, OnDestroy {
  dealMemos = [] as any;
  idCompany: string;
  idProject: string;
  public linkPublic: string;

  @ViewChild(DealMemoComponent) dealMemo: DealMemoComponent;

  subscriberGetDealMemo: Subscription;

  constructor(
    private dealMemoService: DealMemoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    const baseUrl = window.location.origin;
    this.linkPublic = `${baseUrl}/${this.idCompany}/${this.idProject}/dealMemo`;
    this.getDealMemoPublic();
  }

  getDealMemoPublic() {
    this.subscriberGetDealMemo = this.dealMemoService
      .getDealMemoPublic(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.dealMemos = res;
      });
  }

  selectDealMemo(obj) {
    $('#modalAddDealMemo').modal('show');
    this.dealMemo.getInfo(obj);
  }

  clipBoard() {
    let selBox = <HTMLTextAreaElement>document.getElementById('linkPublic');
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    Notiflix.Notify.Success('Copiado al portapapeles');
  }

  async generatePDF(deal) {
    const pdf = new PdfMakeWrapper();
    moment.locale('es-MX');

    pdf.pageSize('LETTER');

    pdf.header(
      await new Img('../../../assets/logos/headerWebSite.png')
        .width(170)
        .alignment('center')
        .margin(20)
        .build()
    );

    pdf.add(pdf.ln(2));

    pdf.add(new Txt('DEAL MEMO').alignment('center').end);
    pdf.add(new Txt(`Folio: ${deal.id}`).alignment('center').end);
    pdf.add(pdf.ln(1));
    pdf.add(new Txt('Proveedor').alignment('center').bold().end);

    pdf.add(
      new Table([
        [`Nombre`, `RFC`, `Beneficiario mortis causa`, `Representante legal`],
        [
          `${deal.dealMemo.nombreProveedor}`,
          `${deal.dealMemo.rfcProveedor}`,
          `${deal.dealMemo.bmc}`,
          `${deal.dealMemo.nomRepLeg}`,
        ],
      ]).layout('lightHorizontalLines').end
    );

    pdf.add(pdf.ln(1));

    pdf.add(new Txt('Datos de Producción').alignment('center').bold().end);

    pdf.add(
      new Table([
        [
          'Departamento',
          'Puesto',
          'Nombre en créditos',
          'Descripcion de la actividad',
          'Fecha firma del contrato',
          'Moneda',
        ],
        [
          deal.dealMemo.departamento,
          deal.dealMemo.puesto,
          deal.dealMemo.nombreEnCreditos,
          deal.dealMemo.descripcionActividad,
          moment(deal.dealMemo.fechaFirmaContrato).format('L'),
          deal.dealMemo.tipoMoneda,
        ],
      ])
        .layout('lightHorizontalLines')
        .widths('*').end
    );

    pdf.add(pdf.ln(1));

    pdf.add(new Txt('Pagos').alignment('center').bold().end);
    const pagos = [] as any;
    const encabezadoPagos = [
      'Pago',
      'Importe',
      'Periodo',
      'Fecha Inicio',
      'Fecha Fin',
    ];
    pagos.push(encabezadoPagos);

    for (let index = 0; index < deal.dealMemo.pagos.length; index++) {
      const element = deal.dealMemo.pagos[index];
      const arrTablePagos = [
        index + 1,
        element.importe,
        element.periodo,
        moment(element.fechaInicio).format('L'),
        moment(element.fechaFin).format('L'),
      ];
      pagos.push(arrTablePagos);
    }

    let suma = 0;
    deal.dealMemo.pagos.forEach(element => {
      suma += element.importe;
    });
    const footerPagos = ['Total:', suma, '', '', ''];
    pagos.push(footerPagos);

    pdf.add(
      new Table(pagos)
        .layout('lightHorizontalLines')
        .alignment('center')
        .widths('*').end
    );

    pdf.add(pdf.ln(1));

    pdf.add(new Txt('Datos Bancarios').alignment('center').bold().end);

    const datosBancarios = [] as any;
    const encabezadoBanco = [
      'Nombre',
      'Banco y Sucursal',
      'Número de Cuenta',
      'Cuenta CLABE',
    ];
    datosBancarios.push(encabezadoBanco);

    for (let index = 0; index < deal.dealMemo.cuentasPer.length; index++) {
      const element = deal.dealMemo.cuentasPer[index];
      const arrTableBanco = [
        element.nombre,
        element.bancoysucursal,
        element.numerodecuenta,
        element.cuentaclabe,
      ];
      datosBancarios.push(arrTableBanco);
    }

    pdf.add(
      new Table(datosBancarios).layout('lightHorizontalLines').widths('*').end
    );

    pdf.add(pdf.ln(6));

    pdf.add(
      new Txt('_______________________________________').alignment('center').end
    );
    pdf.add(new Txt(deal.dealMemo.nomRepLeg).alignment('center').end);

    // pdf.footer('info@enconexion.com.mx');

    pdf.create().open();
  }

  ngOnDestroy() {
    this.subscriberGetDealMemo.unsubscribe();
  }
}
