import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChecksService } from 'src/app/services/checks.service';
import { GeneralService } from 'src/app/services/general.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import pdfMake from 'pdfmake';

@Component({
  selector: 'app-comprobaciones',
  templateUrl: './comprobaciones.component.html',
  styleUrls: ['./comprobaciones.component.css'],
})
export class ComprobacionesComponent implements OnInit, OnDestroy {
  dataUser = this.generalService.dataUser;
  idCompany: string;
  idProject: string;
  comprobaciones = [] as any;

  getCompSubscription: Subscription;

  constructor(
    private generalService: GeneralService,
    private checksService: ChecksService
  ) {}

  ngOnInit(): void {
    this.idCompany = this.dataUser.idCompany;
    this.idProject = this.dataUser.idProject;
    moment.locale('es-MX');
    this.getComprobaciones();
  }

  getComprobaciones() {
    this.getCompSubscription = this.checksService
      .getChecksPublic(this.idCompany, this.idProject, this.dataUser.email)
      .subscribe(res => {
        console.log(res);
        this.comprobaciones = res.sort((a, b) => {
          return a.countCheck - b.countCheck;
        });
      });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  async generatePDF(data) {
    const movimientos = [] as any;
    const header = [
      { text: '#', style: 'tableHeader' },
      { text: 'Partida', style: 'tableHeader' },
      { text: 'Fecha', style: 'tableHeader' },
      { text: 'Proveedor', style: 'tableHeader' },
      { text: 'Concepto', style: 'tableHeader' },
      { text: 'RFC', style: 'tableHeader' },
      { text: 'Folio', style: 'tableHeader' },
      { text: 'Inventario', style: 'tableHeader' },
      { text: 'Importe', style: 'tableHeader' },
      { text: 'IVA', style: 'tableHeader' },
      { text: 'Total', style: 'tableHeader' },
      { text: 'Solicitud', style: 'tableHeader' },
    ];
    movimientos.push(header);
    const sumas = {
      importe: 0,
      iva: 0,
      total: 0,
    };
    for (let index = 0; index < data.movimientos.length; index++) {
      const element = data.movimientos[index];
      sumas.importe += element.subtotal;
      sumas.iva += element.iva || 0;
      sumas.total += element.total;
      const arr = [
        index + 1,
        element.CodigoAgrupador,
        moment(element.fecha).format('L') || '',
        element.proveedor.toUpperCase(),
        element.concepto.substr(0, 40) + '...',
        data.deducible === 'si' ? element.rfc.toUpperCase() : '-',
        element.folioComprobante.substr(-12).toUpperCase() || '',
        element.inventario,
        element.subtotal.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        element.iva.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 0,
        element.total.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        'Solicitud',
      ];
      movimientos.push(arr);
    }
    const foot = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Total:',
      sumas.importe.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      sumas.iva.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      sumas.total.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      '',
    ];
    movimientos.push(foot);

    const dd = {
      pageSize: 'LETTER',
      pageOrientation: 'landscape',
      styles: {
        styleTable: {
          fontSize: 6,
        },
        tableHeader: {
          bold: true,
          fontSize: 7,
          color: 'black',
        },
      },
      // info: {
      //   title: 'awesome Document',
      //   author: 'john doe',
      //   subject: 'subject of document',
      //   keywords: 'keywords for document',
      // },
      content: [
        {
          image: await this.getBase64ImageFromURL(
            '../../../assets/logos/headerWebSite.png'
          ),
          width: 130,
          alignment: 'center',
        },
        {
          text: `Comprobacion: ${data.countCheck}`,
          alignment: 'center',
          fontSize: 7,
        },
        {
          text: `Folio: ${data.id}`,
          alignment: 'center',
          fontSize: 7,
        },
        {
          text: '\n',
        },
        {
          //layout: 'lightHorizontalLines', // optional
          style: 'styleTable',
          table: {
            headerRows: 1,
            // widths: 'auto',
            body: movimientos,
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  text: 'Creado por',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Revisado por',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Autorizado por',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Responsable departamento',
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
              [
                {
                  text: '\n\n\n________________________________\n',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: '\n\n\n________________________________\n',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: '\n\n\n________________________________\n',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: '\n\n\n________________________________\n',
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
            ],
          },
        },
      ],
    };
    pdfMake.createPdf(dd).open();
  }

  ngOnDestroy() {
    this.getCompSubscription.unsubscribe();
  }
}
