import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree,
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
import Notiflix from 'notiflix-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  usuario = {} as any;
  perfil = {} as any;
  cambiosUsuario = false;
  path = '';

  constructor(private authService: AuthService, private router: Router) {}

  // getProfile() {
  //   console.log('se ejectuta getProfile()');
  //   this.authService.getPerfiles().subscribe(res => {
  //     res.forEach(element => {
  //       if (element.id === this.usuario.perfil) {
  //         this.perfil = element;
  //         console.log(this.perfil);
  //       }
  //     });
  //   });
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authService.userData$.pipe(
      map(user => {
        let acceso = false;
        this.authService.getUser2(user.uid).subscribe(res => {
          this.usuario = res[0];
          this.cambiosUsuario = true;
        });
        if (!user.emailVerified) {
          Notiflix.Notify.Failure('Tu email no a sido verificado.');
          this.router.navigate(['/']);
        }
        return user.emailVerified || acceso;
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.path = childRoute.routeConfig.path;

    let acceso = false;
    if (!this.perfil.name || this.cambiosUsuario) {
      return this.authService.getPerfiles().pipe(
        map(res => {
          res.forEach(perfil => {
            if (perfil.id === this.usuario.perfil) {
              this.perfil = perfil;
              this.perfil.pages.forEach(element => {
                if (element.page === this.path) {
                  acceso = true;
                }
              });
            }
          });

          if (!acceso) {
            Notiflix.Notify.Failure('No tienes permiso de acceder a esta ruta');
            this.perfil = {};
            // this.router.navigate(['/dashboard']);
          }

          this.cambiosUsuario = false; // reinicio a false

          return acceso;
        })
      );
    } else {
      this.perfil.pages.forEach(element => {
        if (element.page === this.path) {
          acceso = true;
        }
      });
      if (!acceso) {
        Notiflix.Notify.Failure('No tienes permiso de acceder a esta ruta');
        this.perfil = {};
        // this.router.navigate(['/dashboard']);
      }
      return acceso;
    }
  }
}
