import { Component, OnInit, OnDestroy } from '@angular/core';
import { Provider } from 'src/app/models/providers';
import { ProjectService } from 'src/app/services/project.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { BudgetService } from 'src/app/services/budget.service';
import { Request } from 'src/app/models/request';
import { Router } from '@angular/router';
import { Budget } from 'src/app/models/budget';
import { Period } from 'src/app/models/period';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
import { RequestService } from 'src/app/services/request.service';

declare var $: any;

@Component({
  selector: 'app-edit-request',
  templateUrl: './edit-request.component.html',
  styleUrls: ['./edit-request.component.css'],
})
export class EditRequestComponent implements OnInit, OnDestroy {
  xml;
  providers: any[];
  providersSelect: any[];
  proveedorSelecionado = {} as Provider;
  datosProveedor: any = {} as Provider;
  request = {
    tipoPago: 'Transferencia',
    tipoSolicitud: 'Directa',
    tipoMoneda: 'MXN',
    estatus: false,
    aprobada: false,
  } as Request;
  idCompany: any;
  idProject: any;
  isDealMemo: any;
  idBudget: any;
  valor = {} as any;

  budget: any = [] as Budget;
  ctasPresupuesto;
  periods = [];
  periodoActual = {} as Period;
  countRequest: number;
  departament;
  departamento;
  puestos;
  puesto;
  parPres: any;
  objPres;
  binding: any;
  acumuladoIVA;
  valSol = [] as any;
  valObj = {} as any;
  valTotales = {} as any;
  moneda;
  catCtas;
  CtasAfectables = [] as any;
  editVal = {} as any;
  deleteIndex;
  project = {} as any;
  tipoDeSolicitud = true;
  operacionesFiscales = {} as any;
  objDealMemo;
  indexCtaDeal;
  folios = [] as any;
  budgets = [];
  acumulados = [] as any;
  numProvider: any;
  budgetArrays = [] as any;

  subscriberProviders: Subscription;
  subscriberBudget: Subscription;
  subscriberCatCtas: Subscription;
  subscribeProject: Subscription;
  subscribeCountRequest: Subscription;
  subscribePeriods: Subscription;
  subscribeGetFolios: Subscription;
  subscribeGetIva: Subscription;
  subscribeGetAcumulado: Subscription;

  constructor(
    private requestService: RequestService,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private router: Router
  ) {}
  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isDealMemo = url.root.children.primary.segments[4].path;
    this.getBudget();
    this.getPeriods();
    this.getCatCtas();
    this.getProject();
  }
  getProject() {
    this.subscribeProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.request.tipoMoneda = this.project.monedaLocal;
        this.request.tipoCambio = this.project.tipoCambioGral;
      });
  }
  getCatCtas() {
    this.subscriberCatCtas = this.projectService
      .getCatCtas(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        const arr = this.projectService.ctasFilter(res.catalogo);
        this.catCtas = arr;
      });
  }
  joinCtas() {
    this.CtasAfectables = [];
    this.CtasAfectables = this.CtasAfectables.concat(
      this.catCtas,
      this.ctasPresupuesto
    );
  }
  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          this.getDepartament();
        }
      });
  }
  getDepartament() {
    this.departament = this.filterBudget('Total_Estimado');
  }
  getPuesto() {
    this.request.departamento = this.departamento.Descripcion;
    this.puestos = [];
    this.puesto = '';
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }
  getCtaPres() {
    this.request.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
    }
    this.ctasPresupuesto = this.ctasPresupuesto.map(res => {
      const obj = {
        CodigoAgrupador: res.CodigoAgrupador,
        Descripcion: res.Descripcion,
      };
      return obj;
    });
    this.joinCtas();
  }
  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }
  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element: Period = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        this.request.fechaIni = element.fechaInicio;
        this.request.fechaFin = element.fechaFin;
        break;
      }
    }
  }
  filterBudget(nivel) {
    const arr = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element[nivel]) {
        arr.push(element);
      }
    }
    return arr;
  }
  filterProvider() {
    const arr = [];
    for (let index = 0; index < this.providers.length; index++) {
      const element = this.providers[index];
      const nombre = element.nombre.toLowerCase();
      if (nombre.indexOf(this.proveedorSelecionado.nombre.toLowerCase()) >= 0) {
        arr.push(element);
      }
    }
    this.providersSelect = arr;
  }
  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    this.request.fechaIni = this.binding.fechaInicio;
    this.request.fechaFin = this.binding.fechaFin;
    this.request.etapa = this.binding.etapa;
  }
  validationValSol() {
    if (this.valObj.Cuenta && (this.valObj.Cargo || this.valObj.Abono)) {
      if (this.valObj.Cargo === null || this.valObj.Cargo === 0) {
        delete this.valObj.Cargo;
      } else if (this.valObj.Abono === null || this.valObj.Abono === 0) {
        delete this.valObj.Abono;
      }
      this.addValSol();
    } else {
      Notiflix.Notify.Failure('Completa los campos');
    }
  }
  addValSol() {
    let suma1 = 0;
    let suma2 = 0;
    let cuentaVerdadera = false;
    this.CtasAfectables.forEach(element => {
      if (element.CodigoAgrupador === this.valObj.Cuenta) {
        this.valObj.NombreCuenta = element.Descripcion;
        cuentaVerdadera = true;
      }
    });
    if (cuentaVerdadera) {
      this.valSol.push(this.valObj);
      this.request.movimientos = this.valSol;
      this.valSol.forEach(element => {
        this.valTotales.Cargo = suma1 += element.Cargo || 0;
        this.valTotales.Abono = suma2 += element.Abono || 0;
      });
      this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
      this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
      (<any>document.getElementById('abono')).disabled = false;
      (<any>document.getElementById('cargo')).disabled = false;
      this.valObj = {};
      $('#valSol').modal('hide');
    } else {
      Notiflix.Notify.Failure('Cuenta no valida');
    }
  }
  editCtaValSol(item) {
    this.CtasAfectables.forEach(element => {
      if (item.Cuenta === element.CodigoAgrupador) {
        item.NombreCuenta = element.Descripcion;
      }
    });
    this.totalesMovimientos();
  }
  editValSol() {
    let suma1 = 0;
    let suma2 = 0;
    let cuentaVerdadera = false;
    this.CtasAfectables.forEach(element => {
      if (element.CodigoAgrupador === this.editVal.Cuenta) {
        this.editVal.NombreCuenta = element.Descripcion;
        cuentaVerdadera = true;
      }
    });
    if (cuentaVerdadera) {
      this.request.movimientos = this.valSol;
      this.valSol.forEach(element => {
        this.valTotales.Cargo = suma1 += element.Cargo || 0;
        this.valTotales.Abono = suma2 += element.Abono || 0;
      });
      this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
      this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
      (<any>document.getElementById('abono')).disabled = false;
      (<any>document.getElementById('cargo')).disabled = false;
      this.editVal = {};
      $('#valSol').modal('hide');
    } else {
      Notiflix.Notify.Failure('Cuenta no valida');
    }
  }
  totalesMovimientos() {
    let suma1 = 0;
    let suma2 = 0;
    this.valSol.forEach(element => {
      this.valTotales.Cargo = suma1 += element.Cargo || 0;
      this.valTotales.Abono = suma2 += element.Abono || 0;
    });
    this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
    this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
  }
  validationsAddRequest() {
    const request = this.request;
    const uuid = this.request.folioComp;
    let ctasAny = true;
    this.request.movimientos.forEach((element: any) => {
      if (element.Cuenta) {
        const segments = element.Cuenta.split('-');
        if (segments[0] === '201') {
          this.request.providerName = element.NombreCuenta;
          this.request.numProvider = parseInt(segments[2]);
        }
      } else {
        ctasAny = false;
      }
    });
    this.request.fechaCreado = new Date();
    if (this.request.tipoSolicitud === 'GXC') {
      this.request.comprobado = 0;
    } else {
      delete this.request.comprobado;
    }
    if (
      request.numProvider > 0 &&
      request.concepto.length &&
      request.pagoSoli &&
      request.solicito.length &&
      request.folioComp &&
      request.departamento &&
      request.puesto &&
      request.tipoCambio > 0 &&
      ctasAny
    ) {
      if (
        request.movimientos &&
        this.valTotales.Cargo === this.valTotales.Abono
      ) {
        this.addRequest();
      } else {
        Notiflix.Notify.Failure(
          'Las sumas deben ser iguales y debe tener movimientos'
        );
      }
    } else {
      Notiflix.Notify.Failure(
        'Revisa que los campos y cuentas sean completados'
      );
    }
  }
  addRequest() {
    this.requestService
      .updateRequest(
        this.idCompany,
        this.idProject,
        this.request.id,
        this.request
      )
      .then(res => {
        $('#editRequest').modal('hide');
        this.folios.push(this.request.folioComp);
        const obj = { folios: this.folios };
        this.projectService.addFolioFiscal(this.idCompany, this.idProject, obj);
        Notiflix.Notify.Success('Guardado Exitoso');
        this.request.numProvider = 0;
        this.datosProveedor = {};
        this.request.concepto = '';
        this.request.tipoPago = 'Transferencia';
        this.request.pagoSoli = undefined;
        this.request.solicito = '';
        this.request.tipoSolicitud = 'Directa';
        this.request.folioComp = '';
        this.request.fechaComp = undefined;
        this.request.departamento = '';
        this.departamento = '';
        this.request.puesto = '';
        this.puesto = '';
        this.request.tipoMoneda = 'MXN';
        this.request.movimientos = [];
        this.valSol = [];
        this.valTotales = {
          Cargo: '0',
          Abono: '0',
        };
        $('#formRequest').removeClass('was-validated');
      })
      .catch(err => {
        console.error(err);
        Notiflix.Notify.Failure('Ocurrio un error al guardar la solicitud');
      });
  }
  deleteValSol() {
    const index = this.deleteIndex;
    let suma1 = 0;
    let suma2 = 0;
    this.valSol.splice(index, 1);
    this.request.movimientos = this.valSol;
    if (this.valSol.length === 0) {
      this.valTotales.Cargo = 0;
      this.valTotales.Abono = 0;
    } else {
      this.valSol.forEach(element => {
        this.valTotales.Cargo = suma1 += element.Cargo || 0;
        this.valTotales.Abono = suma2 += element.Abono || 0;
      });
      this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
      this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
    }
    $('#valSol').modal('hide');
  }
  changeTypeRequest() {
    if (
      this.request.tipoSolicitud === 'GXC' ||
      this.request.tipoSolicitud === 'Cheque vs factura'
    ) {
      this.tipoDeSolicitud = false;
      this.request.folioComp = '';
      this.request.folioComp = 'GXC';
      this.request.fechaComp = 'GXC';
    } else if (this.request.tipoSolicitud === 'Anexo') {
      this.tipoDeSolicitud = true;
      this.request.folioComp = 'ANEXO';
      setTimeout(() => {
        (<any>document.getElementById('foliocomp')).disabled = true;
      }, 300);
    } else {
      this.tipoDeSolicitud = true;
      this.request.folioComp = '';
      setTimeout(() => {
        (<any>document.getElementById('foliocomp')).disabled = false;
      }, 300);
    }
  }
  assignData(obj) {
    const uuid =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    const validacionFolio = this.validateFolio(uuid);
    if (validacionFolio) {
      if (this.isDealMemo === 'consultDealMemo') {
        this.request.folioComp =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;
        this.request.fechaComp = obj['cfdi:Comprobante'][
          '@attributes'
        ].Fecha.substr(0, 10);
      } else {
        // let existProvider = true;
        // const rfcProveedor =
        //   obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;
        // if (existProvider) {
        // } else {
        //   Notiflix.Notify.Failure('No existe el proveedor en la base de datos');
        // }

        if (
          Array.isArray(
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
          )
        ) {
          this.request.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0][
              '@attributes'
            ].Descripcion;
        } else {
          this.request.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].Descripcion;
        }

        this.request.folioComp =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;
        this.request.fechaComp = obj['cfdi:Comprobante'][
          '@attributes'
        ].Fecha.substr(0, 10);

        const importe = obj['cfdi:Comprobante']['@attributes'].SubTotal;
        const objImporte = {
          Cargo: parseFloat(importe),
        };
        this.valSol.push(objImporte);
        const impuestos = obj['cfdi:Comprobante']['cfdi:Impuestos'];

        const impuestosTras = impuestos['cfdi:Traslados'];
        const impuestosRet = impuestos['cfdi:Retenciones'];

        if (impuestosTras) {
          // console.log('Tiene impuestos trasladados');
          const esArr = Array.isArray(impuestosTras['cfdi:Traslado']);
          if (esArr) {
            impuestosTras['cfdi:Traslado'].forEach(element => {
              const objTras = {
                Cargo: parseFloat(element['@attributes'].Importe),
              };
              this.valSol.push(objTras);
            });
          } else {
            const objTras = {
              Cargo: parseFloat(
                impuestosTras['cfdi:Traslado']['@attributes'].Importe
              ),
            };
            this.valSol.push(objTras);
          }
        } else {
          // console.log('No tiene impuestos trasladados');
        }
        if (impuestosRet) {
          // console.log('Tiene impuestos retenidos');
          const esArr = Array.isArray(impuestosRet['cfdi:Retencion']);
          if (esArr) {
            impuestosRet['cfdi:Retencion'].forEach(element => {
              const objRet = {
                Abono: parseFloat(element['@attributes'].Importe),
              };
              this.valSol.push(objRet);
            });
          } else {
            const objRet = {
              Abono: parseFloat(
                impuestosRet['cfdi:Retencion']['@attributes'].Importe
              ),
            };
            this.valSol.push(objRet);
          }
        } else {
          // console.log('No tiene impuestos retenidos');
        }
        const objProv = {
          Abono: parseFloat(obj['cfdi:Comprobante']['@attributes'].Total),
        };
        this.valSol.push(objProv);
        this.request.movimientos = this.valSol;
        this.addValSol();
      }
    }
  }
  validateFolio(uuid) {
    let validacionFolio = true;
    this.folios.forEach(element => {
      if (element === uuid) {
        Notiflix.Notify.Failure('El folio fiscal ya se encuentra registrado');
        validacionFolio = false;
      }
    });
    return validacionFolio;
  }
  inputValue() {
    if (this.valObj.Cargo) {
      (<any>document.getElementById('abono')).disabled = true;
    } else if (this.valObj.Abono) {
      (<any>document.getElementById('cargo')).disabled = true;
    } else {
      (<any>document.getElementById('abono')).disabled = false;
      (<any>document.getElementById('cargo')).disabled = false;
    }
  }
  ngOnDestroy() {
    this.subscriberCatCtas.unsubscribe();
    this.subscriberBudget.unsubscribe();
    this.subscribePeriods.unsubscribe();
    this.subscribeProject.unsubscribe();
  }
}
