import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmpresasService } from 'src/app/services/empresas.service';

import { ProvidersService } from 'src/app/services/providers.service';

declare var $;

@Component({
  selector: 'app-aprove-providers',
  templateUrl: './aprove-providers.component.html',
  styleUrls: ['./aprove-providers.component.css'],
})
export class AproveProvidersComponent implements OnInit, OnDestroy {
  providers = [] as any;
  providersUpdate = [] as any;
  providersNoUpdate = [] as any;
  providersVinculados = [] as any;
  allProviders = [] as any;
  proveedorSeleccionado = { banks: [], empresas: [] } as any;
  boton1 = 'Siguiente';
  empresa: any;
  proyecto: any;
  provider = {} as any;
  filterProviders = [];
  statusFiles = '';
  validacionStatus = '';

  filtroSeleccionado;

  herramientas: string;

  idCompany: string;
  idProject: string;

  steps = 'step1';

  providerSubscription: Subscription;
  provSelSubscription: Subscription;

  constructor(
    private providersService: ProvidersService,
    private empresasService: EmpresasService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    // console.log(url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCompany();
    this.getProject();
    this.getProvidersPublic();
    this.getProvidersPublicUpdate();
    this.getProvidersPublicNoUpdate();
  }

  getProvidersPublic() {
    this.providerSubscription = this.providersService
      .getProvidersPublic()
      .subscribe(res => {
        console.log(res);
        this.providers = res;
        this.allProviders = res;
        // console.log(this.allProviders);
        this.filterProvidersVinculado();
        for (let index = 0; index < this.providers.length; index++) {
          const element = this.providers[index];

          this.validacionStatus = '';
          if (element.status === 'Aprobado') {
            this.validacionStatus = element.status;
            console.log(this.validacionStatus);
          } else {
            this.validacionStatus = 'Rechazado';
            console.log(this.validacionStatus);
          }
        }
      });
  }

  getProvidersPublicUpdate() {
    this.providerSubscription = this.providersService
      .getProvidersPublicActualizado()
      .subscribe(res => {
        // console.log(res);
        this.providersUpdate = res;
        // this.filterProvidersVinculado();
      });
  }

  getProvidersPublicNoUpdate() {
    this.providerSubscription = this.providersService
      .getProvidersPublicNoActualizado()
      .subscribe(res => {
        // console.log(res);
        this.providersNoUpdate = res;
        // this.filterProvidersVinculado();
      });
  }
  filterProvidersVinculado() {
    this.providersVinculados = [] as any;

    for (let index = 0; index < this.providers.length; index++) {
      let vinculado = false;
      const elementProvider = this.providers[index];
      elementProvider.empresas.forEach(elementEmpresas => {
        if (elementEmpresas.id === this.idCompany) {
          elementEmpresas.proyectos.forEach(elementProyectos => {
            if (elementProyectos.id === this.idProject) {
              vinculado = true;
              this.providersVinculados.push(elementProvider);
            }
          });
        }
      });
    }
    // console.log(this.providersVinculados);
  }
  filterPovider(ev) {
    this.filterProviders = [];
    const text = ev.target.value.toUpperCase();
    for (let index = 0; index < this.providersVinculados.length; index++) {
      const element = this.providersVinculados[index];
      if (
        this.providersVinculados[index].nombre.indexOf(text) >= 0 ||
        this.providersVinculados[index].rfc.indexOf(text) >= 0
      ) {
        this.filterProviders.push(element);
      }
    }
    // console.log(this.filterProviders);
  }

  unbin() {
    // console.log(this.proveedorSeleccionado);
    // let empresas = this.proveedorSeleccionado.empresas;

    // console.log(this.idCompany);
    // console.log(this.idProject);
    const indexCompany = this.proveedorSeleccionado.empresas.find(
      element => element.id === this.idCompany
    );
    // console.log(indexCompany);
    if (indexCompany.id === this.idCompany) {
      indexCompany.proyectos.forEach(element => {
        if (element.id === this.idProject) {
          this.providersService.desvincularProvider1(
            this.proveedorSeleccionado.id,
            indexCompany,
            element
          );
          // console.log(element);
        }
      });
    }
  }

  filtroActualizados() {
    this.providersVinculados = [] as any;

    if (this.filtroSeleccionado === 'actualizados') {
      // console.log('se ejecuta');
      this.providers = this.providersUpdate;
      // console.log(this.providers);
    } else if (this.filtroSeleccionado === 'noActualizados') {
      this.providers = this.providersNoUpdate;
      // console.log(this.providers);
    } else if (this.filtroSeleccionado === 'todos') {
      this.providers = this.allProviders;
      // console.log(this.providers);
    }
    this.filterProvidersVinculado();
  }

  getProject() {
    this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(proyecto => {
        // console.log(proyecto);
        this.proyecto = proyecto;
      });
  }
  getCompany() {
    this.empresasService.getCompanyActual(this.idCompany).subscribe(empresa => {
      // console.log(empresa);
      this.empresa = empresa;
    });
  }

  proveedor(proveedor: any) {
    this.provSelSubscription = this.providersService
      .getProviderPublic(proveedor.id)
      .subscribe(res => {
        this.proveedorSeleccionado = res;
        this.proveedorSeleccionado.id = proveedor.id;
        this.checkFiles();

        if (this.proveedorSeleccionado.empresas.length > 0) {
          const empresa = this.proveedorSeleccionado.empresas.find(
            element => element.id === this.idCompany
          );
          // console.log(empresa);

          let proyecto = undefined;
          if (empresa) {
            proyecto = empresa.proyectos.find(
              element => element.id === this.idProject
            );
          }

          // console.log(proyecto);

          if (empresa && proyecto) {
            this.steps = 'step3';
          } else {
            this.steps = 'step2';
          }
        } else {
          this.steps = 'step2';
        }
      });
  }

  aprobarProveedor() {
    const indexCompany = this.proveedorSeleccionado.empresas.findIndex(
      element => element.id === this.idCompany
    );
    // console.log(indexCompany);

    let indexProject: number | undefined;
    if (indexCompany >= 0) {
      indexProject = this.proveedorSeleccionado.empresas[
        indexCompany
      ].proyectos.findIndex(element => element.id === this.idProject);
      // console.log(indexProject);
    }

    if (indexCompany < 0) {
      this.proveedorSeleccionado.empresas.push({
        id: this.idCompany,
        name: this.empresa.name,
        proyectos: [
          {
            id: this.idProject,
            name: this.proyecto.name,
          },
        ],
      });
    } else if (indexCompany >= 0 && indexProject < 0) {
      this.proveedorSeleccionado.empresas[indexCompany].proyectos.push({
        id: this.idProject,
        name: this.proyecto.name,
      });
    }

    this.providersService
      .updateProviderExterno(
        this.proveedorSeleccionado.id,
        this.proveedorSeleccionado
      )
      .then(res => {
        // console.log('Actualizado Correctamente');
        this.steps = 'step3';
      });
  }

  regresar() {
    this.steps = 'step1';
    this.herramientas = '';
  }

  checkFiles() {
    this.statusFiles = 'Aprobado';
    for (let i = 0; i < this.proveedorSeleccionado.files.length; i++) {
      const elementTypeFile = this.proveedorSeleccionado.files[i];

      if (elementTypeFile.archivos.length === 0) {
        this.statusFiles = 'Pendiente';
        break;
      }

      const mesActual = new Date().getMonth() + 1;
      if (
        elementTypeFile.archivos[elementTypeFile.archivos.length - 1]?.mes !==
        this.mesATexto(mesActual)
      ) {
        this.statusFiles = 'Pendiente';
      }

      for (let j = 0; j < elementTypeFile.archivos.length; j++) {
        const elementFile = elementTypeFile.archivos[j];
        if (elementFile.status === 'En revisión') {
          this.statusFiles = 'En revisión';
        } else if (elementFile.status === 'Rechazado') {
          this.statusFiles = 'Rechazado';
          break;
        }
      }
      break;
    }
  }

  mesATexto(value: number) {
    let letrasMes = '';
    switch (value) {
      case 1:
        letrasMes = 'Enero';
        break;
      case 2:
        letrasMes = 'Febrero';
        break;
      case 3:
        letrasMes = 'Marzo';
        break;
      case 4:
        letrasMes = 'Abril';
        break;
      case 5:
        letrasMes = 'Mayo';
        break;
      case 6:
        letrasMes = 'Junio';
        break;
      case 7:
        letrasMes = 'Julio';
        break;
      case 8:
        letrasMes = 'Agosto';
        break;
      case 9:
        letrasMes = 'Septiembre';
        break;
      case 10:
        letrasMes = 'Octubre';
        break;
      case 11:
        letrasMes = 'Noviembre';
        break;
      case 12:
        letrasMes = 'Diciembre';
        break;
    }
    return letrasMes;
  }

  ngOnDestroy(): void {
    if (this.providerSubscription) {
      this.providerSubscription.unsubscribe();
    }

    if (this.provSelSubscription) {
      this.provSelSubscription.unsubscribe();
    }
  }
}
