import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Period } from 'src/app/models/period';
import { Request } from 'src/app/models/request';
import { AuthService } from 'src/app/services/auth.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { RequestService } from 'src/app/services/request.service';
import Notiflix from 'notiflix-angular';

@Component({
  selector: 'app-sol-anexo',
  templateUrl: './sol-anexo.component.html',
  styleUrls: ['./sol-anexo.component.css'],
})
export class SolAnexoComponent implements OnInit {
  idCompany: string;
  idProject: string;
  request = {
    movimientos: [],
    tipoPago: 'Transferencia',
    tipoSolicitud: 'Anexo',
    estatus: false,
    aprobada: false,
  } as Request;
  countRequest: number;
  periods = [] as any;
  periodoActual = {} as Period;
  company = {} as any;
  project = {} as any;
  filmadora = 'default' as any;
  providers = [] as any;
  providersNac = [] as any;
  providersEx = [] as any;
  datosProveedor = {} as any;
  binding: any;
  proveedorSeleccionado: any;
  grupos = [] as any;
  grupoSeleccionado: any;
  user: any;
  changeCountRequest = false;
  arrCountRequest = [] as any;
  changePeriod = false;

  subscribeCountRequest: Subscription;
  subscribePeriods: Subscription;
  getCompanySubscription: Subscription;
  subscribeProject: Subscription;
  subscribeGetProvider: Subscription;
  subscribeGetDepartament: Subscription;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private projectService: ProjectService,
    private empresaService: EmpresasService,
    private providersService: ProvidersService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCount();
    this.getCompany();
    this.getProject();
    this.getPeriods();
    this.getProviders();
    this.getDeptos();
    this.getUser();
  }

  getCount() {
    this.subscribeCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        console.log('CountRequest', res);
        if (res === undefined) {
          this.countRequest = 1;
          this.request.countRequest = this.countRequest;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
          this.request.countRequest = this.countRequest;
          for (let index = 0; index < res.countRequest.length; index++) {
            const element = res.countRequest[index];
            console.log(index + 1, element);
            if (element !== index + 1) {
              this.countRequest = res.countRequest[index - 1] + 1;
              this.request.countRequest = this.countRequest;
              break;
            }
          }
        }
      });
  }

  countRequestManual() {
    if (!this.subscribeCountRequest.closed) {
      this.subscribeCountRequest.unsubscribe();
    }
    for (let index = 0; index < this.arrCountRequest.length; index++) {
      const element = this.arrCountRequest[index];
      this.request.countRequest = this.countRequest;
      if (element === this.countRequest) {
        Notiflix.Notify.Failure(
          `La solicitud numero ${this.countRequest} ya existe`
        );
        this.getCount();
        break;
      }
    }
  }

  getProject() {
    this.subscribeProject = this.empresaService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.request.tipoMoneda = this.project.monedaLocal;
        this.request.tipoCambio = this.project.tipoCambioGral;
      });
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }

  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element: Period = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        this.request.fechaIni = element.fechaInicio;
        this.request.fechaFin = element.fechaFin;
        break;
      }
    }
  }

  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    this.request.fechaIni = this.binding.fechaInicio;
    this.request.fechaFin = this.binding.fechaFin;
    this.request.etapa = this.binding.etapa;
  }

  getCompany() {
    this.getCompanySubscription = this.empresaService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        this.company = res;
        this.request.filmadora = {
          name: this.company.name,
          rfc: this.company.rfc,
        };
      });
  }

  selectFilmadora() {
    if (this.filmadora === 'default') {
      this.request.filmadora = {
        name: this.company.name,
        rfc: this.company.rfc,
      };
    } else {
      this.request.filmadora = {
        name: this.filmadora.name,
        rfc: this.filmadora.rfc,
      };
    }
  }

  getProviders() {
    this.subscribeGetProvider = this.providersService
      .getProvider()
      .subscribe(res => {
        const arr = Object.assign([], res);
        let arrProviders = arr.map(element => {
          const obj = {} as any;
          obj.CodigoAgrupador =
            element.nacionalidad === 'Mexicana'
              ? '201-01-' + element.numero.toString().padStart(4, '0')
              : '201-02-' + element.numero.toString().padStart(4, '0');
          obj.rfc = element.rfc;
          obj.Descripcion = element.nombre;
          obj.nacionalidad = element.nacionalidad;
          return obj;
        });

        this.providers = arr;

        arrProviders.forEach(element => {
          if (element.nacionalidad === 'Mexicana') {
            this.providersNac.push(element);
          } else {
            this.providersEx.push(element);
          }
        });
      });
  }

  changeSelectProvider() {
    const segmentos = this.proveedorSeleccionado.split(' - ');
    const numeroProveedor = parseInt(segmentos[0]);

    const proveedor = this.providers.find(
      provider => provider.numero === numeroProveedor
    );

    if (proveedor !== undefined) {
      this.datosProveedor = proveedor;
      this.request.numProvider = proveedor.numero;
      this.request.providerName = proveedor.nombre;
      this.request.rfcProvider = proveedor.rfc;
      this.addImport();
    }
  }

  getUser() {
    const subscribeUser = this.authService.userData$.subscribe(res => {
      this.user = res;
      subscribeUser.unsubscribe();
    });
  }

  addImport() {
    if (this.datosProveedor.numero) {
      this.request.movimientos = [
        {
          NombreCuenta: 'Anexo' + '-' + this.datosProveedor.nombre,
          Cuenta:
            '8888-' + this.request.numProvider.toString().padStart(4, '0'),
          Cargo: this.request.importe || 0,
        },
        {
          NombreCuenta: this.datosProveedor.nombre || '',
          Cuenta:
            (this.datosProveedor.nacionalidad === 'Mexicana'
              ? '201-01-'
              : '201-02-') +
            this.request.numProvider.toString().padStart(4, '0'),
          Abono: this.request.importe || 0,
        },
      ];
    }
  }

  getDeptos() {
    this.subscribeGetDepartament = this.projectService
      .getGroups(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.grupos = res;
      });
  }

  validationRequest(): boolean {
    document.getElementById('formRequest').classList.add('was-validated');
    // if (this.grupoSeleccionado) {
    //   this.request.idDepartament = this.grupoSeleccionado.id;
    //   this.request.nameDepartament = this.grupoSeleccionado.name;
    // }
    if (
      this.request.rfcProvider &&
      this.request.concepto &&
      this.request.pagoSoli &&
      this.request.solicito &&
      this.request.tipoMoneda &&
      this.request.tipoCambio &&
      this.request.importe
    ) {
      return true;
    } else {
      Notiflix.Notify.Failure('Verifica que los campos sean completados');
      return false;
    }
  }

  saveRequest() {
    this.arrCountRequest.push(this.countRequest);
    if (this.validationRequest()) {
      Notiflix.Loading.Dots('Cargando...');
      this.request.comprobado = 0;
      this.request.fechaCreado = new Date();
      this.request.createdBy = this.user.email;
      this.requestService
        .addRequest(this.idCompany, this.idProject, this.request)
        .then(() => {
          this.requestService.countRequest(
            this.idCompany,
            this.idProject,
            this.arrCountRequest
          );

          this.countRequest++;

          Notiflix.Loading.Remove(1000);
          Notiflix.Notify.Success('Guardado exitosamente!');
          document
            .getElementById('formRequest')
            .classList.remove('was-validated');
          this.reset();
        })
        .catch(err => {
          console.error('Ocurrio un error', err);
          Notiflix.Notify.Failure('Ocurrio un error, intentalo nuevamente');
        });
    }
  }

  reset() {
    this.request = {
      movimientos: [],
      tipoPago: 'Transferencia',
      tipoMoneda: this.project.monedaLocal,
      tipoSolicitud: 'Anexo',
      estatus: false,
      aprobada: false,
    } as Request;
    this.datosProveedor = {} as any;
    this.proveedorSeleccionado = '';
    (<any>document.getElementById('selectProvider')).value = '';
    (<any>document.getElementById('inputFiles')).value = '';
  }

  ngOnDestroy() {
    this.subscribeCountRequest.unsubscribe();
    this.subscribePeriods.unsubscribe();
    this.subscribeProject.unsubscribe();
    this.getCompanySubscription.unsubscribe();
    this.subscribeGetProvider.unsubscribe();
  }
}
