import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import Notiflix from 'notiflix-angular';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';

declare var $;

@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css'],
})
export class EquipoComponent implements OnInit, OnDestroy {
  inputActivate = true;
  equipo = {} as any;
  accessPass = 'RU5DT05FWElPTjM2MA==';
  pass = '';
  idObject = '';
  subscribeGetDevice: Subscription;

  constructor(private generalService: GeneralService, private router: Router) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idObject = url.root.children.primary.segments[1].path;
    // this.getDevice();
    this.equipo.id = this.idObject;
  }

  getDevice() {
    this.subscribeGetDevice = this.generalService
      .getDevice(this.idObject)
      .subscribe(res => {
        if (res !== undefined) {
          this.equipo = res;
        }
      });
  }

  access() {
    if (window.btoa(this.pass) === this.accessPass) {
      this.inputActivate = false;
      $('#access').modal('hide');
    } else {
      Notiflix.Notify.Failure('Contraseña incorrecta');
    }
  }

  saveDevice() {
    this.generalService.saveDevice(this.equipo, this.idObject).then(() => {
      Notiflix.Notify.Success('Guardado correctamente');
      this.inputActivate = true;
    });
  }

  ngOnDestroy(): void {
    this.subscribeGetDevice.unsubscribe();
  }
}
