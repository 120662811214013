import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { GeneralService } from 'src/app/services/general.service';
import { Router } from '@angular/router';
import Notiflix from 'notiflix-angular';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-ctas-project',
  templateUrl: './ctas-project.component.html',
  styleUrls: ['./ctas-project.component.css'],
})
export class CtasProjectComponent implements OnInit, OnDestroy {
  providers: any;
  idCompany: any;
  idProject: any;
  providersNac = [] as any;
  providersExt = [] as any;
  deudorSelect: string;
  hasChild: any;

  subscribeGetCatCtas: Subscription;
  subscribeGetProviders: Subscription;
  subscribeLoadCtas: Subscription;

  addAcount = {} as any;
  arrCtas = {} as any;
  selection: any;
  providerSelect: string;
  nodeSelect: any;

  private _transformer = (node, level) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.CodigoAgrupador + ' / ' + node.descripcion,
      path: node.path,
      obj: node,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private projectService: ProjectService,
    private generalService: GeneralService,
    private providersService: ProvidersService,
    private router: Router
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCtasProject();
  }
  // Esta funcion trae las cuentas de la base de datos
  getCtasProject() {
    this.subscribeGetCatCtas = this.projectService
      .getCatCtas(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res) {
          this.dataSource.data = res.catalogo;
          this.treeControl.expandAll();
          this.arrCtas = res.catalogo;
          this.getProviders();
        } else {
          this.dataSource.data = [];
          this.arrCtas = [];
        }
      });
  }
  // Obtenemos los proveedores de la base de datos
  getProviders() {
    this.providersService.getProvider().subscribe(res => {
      this.providers = Object.assign([], res);
      this.filterProviders();
    });
  }
  // Fitra los proveedores por nacionales y extranjeros
  filterProviders() {
    this.providersNac = [];
    this.providersExt = [];
    this.providers.forEach(element => {
      if (element.nacionalidad === 'Mexicana') {
        this.providersNac.push(element);
      } else if (element.nacionalidad === 'Extranjera') {
        this.providersExt.push(element);
      }
    });
  }
  // Esta funcion sirve para cargar las cuentas al proyecto
  loadCtas() {
    this.subscribeLoadCtas = this.generalService.getCatCtas().subscribe(res => {
      this.projectService.addCatCtas(this.idCompany, this.idProject, res);
    });
  }
  // Esta funcion elimina el catalogo de cuentas
  deleteCatCtas() {
    Notiflix.Confirm.Show(
      'ALERTA',
      '¿Esta seguro de eliminar el catalogo de cuentas?',
      'Aceptar',
      'Cancelar',
      () => {
        this.projectService.deleteCatCtas(this.idCompany, this.idProject);
        Notiflix.Notify.Failure(
          'Se elimino correctamente el catalogo de cuentas'
        );
      }
    );
  }

  addNewItem(node) {
    console.log(node);
    this.nodeSelect = node;
    if (node.level >= 2) {
      const path = 'this.arrCtas' + node.path;
      const obj = eval(path);
      this.selection = obj;
      if (node.level === 2) {
        if (obj.CodigoAgrupador === '201-01') {
          $('#addProvider').modal('show');
        } else if (obj.CodigoAgrupador === '201-02') {
          $('#addProviderExt').modal('show');
        } else if (obj.CodigoAgrupador === '107-05') {
          $('#modalDeudor').modal('show');
        } else {
          $('#addAcount').modal('show');
          const num = (obj.children.length + 1).toString().padStart(3, 0);
          this.addAcount.CodigoAgrupador = obj.CodigoAgrupador + '-' + num;
          this.addAcount.children = [];
          this.addAcount.path = obj.path + `.children[${obj.children.length}]`;
          this.addAcount.nivel = obj.nivel + 1;
        }
      } else if (node.level > 2 && obj.afectable === 'true') {
        Notiflix.Notify.Failure(
          'Esta cuenta no puede tener subcuentas porque es afectable'
        );
      } else if (node.level > 2 && obj.afectable === 'false') {
        if (obj.CodigoAgrupador === '201-01') {
          $('#addProvider').modal('show');
        } else if (obj.CodigoAgrupador === '201-02') {
          $('#addProviderExt').modal('show');
        } else if (obj.CodigoAgrupador === '107-05') {
          $('#modalDeudor').modal('show');
        } else {
          $('#addAcount').modal('show');
          const num = (obj.children.length + 1).toString().padStart(3, 0);
          this.addAcount.CodigoAgrupador = obj.CodigoAgrupador + '-' + num;
          this.addAcount.children = [];
          this.addAcount.path = obj.path + `.children[${obj.children.length}]`;
          this.addAcount.nivel = obj.nivel + 1;
        }
      }
    }
  }

  saveNode() {
    $('#addAcount').modal('hide');
    if (
      this.addAcount.descripcion &&
      this.addAcount.moneda &&
      this.addAcount.afectable
    ) {
      this.selection.children.push(this.addAcount);
      this.projectService.updateCatCtas(this.idCompany, this.idProject, {
        catalogo: this.arrCtas,
      });
      this.addAcount = {};
    } else {
      Notiflix.Notify.Failure('Todos los campos tienen que ser llenados');
    }
  }

  addProvider() {
    $('#addProvider').modal('hide');
    $('#addProviderExt').modal('hide');
    const arr = this.providerSelect.split(' - ');
    let rfc = '';
    for (let index = 0; index < this.providers.length; index++) {
      const element = this.providers[index];
      if (element.numero === parseInt(arr[0])) {
        rfc = element.rfc;
        break;
      }
    }
    const obj = {
      CodigoAgrupador:
        this.selection.CodigoAgrupador + '-' + arr[0].padStart(3, '0'),
      descripcion: arr[1],
      rfc: rfc,
      children: [],
      path:
        this.selection.path + `.children[${this.selection.children.length}]`,
      nivel: this.selection.nivel + 1,
      afectable: 'true',
    };
    let validation = true;
    this.selection.children.forEach(element => {
      if (element.CodigoAgrupador === obj.CodigoAgrupador) {
        validation = false;
      }
    });
    if (validation) {
      this.selection.children.push(obj);
      this.projectService.updateCatCtas(this.idCompany, this.idProject, {
        catalogo: this.arrCtas,
      });
      this.providerSelect = '';
    } else {
      Notiflix.Notify.Failure('El proveedor ya se encuentra registrado');
    }
  }

  addDeudor() {
    $('#modalDeudor').modal('hide');
    const arr = this.deudorSelect.split(' - ');
    let rfc = '';
    for (let index = 0; index < this.providers.length; index++) {
      const element = this.providers[index];
      if (element.numero === parseInt(arr[0])) {
        rfc = element.rfc;
        break;
      }
    }
    const obj = {
      CodigoAgrupador:
        this.selection.CodigoAgrupador + '-' + arr[0].padStart(3, '0'),
      descripcion: arr[1],
      rfc: rfc,
      children: [],
      path:
        this.selection.path + `.children[${this.selection.children.length}]`,
      nivel: this.selection.nivel + 1,
      afectable: 'true',
    };
    let validation = true;
    this.selection.children.forEach(element => {
      if (element.CodigoAgrupador === obj.CodigoAgrupador) {
        validation = false;
      }
    });
    if (validation) {
      this.selection.children.push(obj);
      this.projectService.updateCatCtas(this.idCompany, this.idProject, {
        catalogo: this.arrCtas,
      });
      this.deudorSelect = '';
    } else {
      Notiflix.Notify.Failure('El deudor ya se encuentra registrado');
    }
  }

  ngOnDestroy() {
    this.subscribeGetCatCtas.unsubscribe();
    // this.subscribeGetProviders.unsubscribe();
    if (this.subscribeLoadCtas) {
      this.subscribeLoadCtas.unsubscribe();
    }
  }
}
