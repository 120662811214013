import { Component, OnInit, OnDestroy } from '@angular/core';
import * as XLSX from 'xlsx';
import Notiflix from 'notiflix-angular';
import { DealMemo } from '../../models/dealMemo';
import { Subscription } from 'rxjs';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { ProjectService } from 'src/app/services/project.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { BudgetService } from 'src/app/services/budget.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deal-memo-masive',
  templateUrl: './deal-memo-masive.component.html',
  styleUrls: ['./deal-memo-masive.component.css'],
})
export class DealMemoMasiveComponent implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;

  countDealMemo = {} as any;
  periodos: any;
  providers: any;
  budget: any;
  project: any;
  grupos: any;
  providerNotFound = [] as any;
  dealMemos = [] as any;

  sheetName: any;
  dataTable: any;
  addDataDB: any;

  subscribeCountDealMemo: Subscription;
  subscriberGetPeriods: Subscription;
  subscriberGetProviders: Subscription;
  subscriberGetBudget: Subscription;
  subscriberGetProject: Subscription;
  subscribeGetDepartament: Subscription;

  constructor(
    private router: Router,
    private dealMemoService: DealMemoService,
    private projectService: ProjectService,
    private providersService: ProvidersService,
    private budgetService: BudgetService,
    private empresasService: EmpresasService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCount();
    this.getPeriods();
    this.getProvider();
    this.getBudget();
    this.getProject();
    this.getGroups();
  }

  getCount() {
    this.subscribeCountDealMemo = this.dealMemoService
      .getCountDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countDealMemo.countDealMemo = 1;
        } else {
          this.countDealMemo.countDealMemo = contador.countDealMemo + 1;
        }
        // console.log('Contador Deal Memos', this.countDealMemo);
      });
  }

  getPeriods() {
    this.subscriberGetPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodos = Object.assign([], res);
        console.log('Periodos', this.periodos);
      });
  }

  getProvider() {
    this.subscriberGetProviders = this.providersService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
        // console.log('Proveedores', this.providers);
      });
  }

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          //this.budgetArrays = budget; // este es el array que contiene hijos (children)
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          // this.getDepartament();
          // console.log('Presupuesto', this.budget);
        }
      });
  }

  getProject() {
    this.subscriberGetProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        // console.log('Proyecto', this.project);
      });
  }

  getGroups() {
    this.subscribeGetDepartament = this.projectService
      .getGroups(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.grupos = res;
        // console.log('grupos', this.grupos);
      });
  }

  onFileChange(ev) {
    Notiflix.Loading.Dots('Cargando...');
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        this.sheetName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const arr = jsonData[this.sheetName];
      this.generateDealMemo(arr);
      Notiflix.Loading.Remove(3000);
    };
    setTimeout(() => {
      reader.readAsBinaryString(file);
    }, 500);
  }

  generateDealMemo(dealMemos) {
    console.log(dealMemos);
    this.providerNotFound = [] as any;
    for (let index = 0; index < dealMemos.length; index++) {
      const deal = dealMemos[index];
      console.log(deal);
      const dealMemo = {} as DealMemo;

      const proveedor = this.providers.find(
        element => element.rfc === deal.rfc
      );

      // console.log(proveedor);

      if (proveedor === undefined) {
        deal.error = 'Proveedor no valido';
        Notiflix.Notify.Failure('El proveedor no existe en la base de datos');
      }

      const codigoAgrupador = this.budget.find(
        element =>
          element.CodigoAgrupador === deal.CUENTA_DES ||
          element.CodigoAgrupador === deal.CUENTA_ROD ||
          element.CodigoAgrupador === deal.CUENTA_ENT ||
          element.CodigoAgrupador === deal.CUENTA_POST
      );
      // console.log(codigoAgrupador);

      if (codigoAgrupador === undefined) {
        deal.error = 'Partida presupuestal no valida';
        Notiflix.Notify.Failure('La cuenta no es valida');
      }

      if (proveedor && codigoAgrupador) {
        dealMemo.countDealMemo = this.countDealMemo.countDealMemo;
        dealMemo.proveedor = proveedor.numero;
        dealMemo.rfcProveedor = proveedor.rfc;
        dealMemo.nombreProveedor = proveedor.nombre;
        dealMemo.descripcionActividad = deal.descripcionActividad;
        dealMemo.contratoInicio = deal.contratoInicio;
        dealMemo.contratoFin = deal.contratoFin;
        dealMemo.solicito = deal.solicito || '';
        dealMemo.tipoCambio = this.project.tipoCambioGral;
        dealMemo.tipoMoneda = deal.moneda;
        // dealMemo.nameDepartament = this.grupos.find(
        //   element => element.name === deal.nameDepartament
        // ).name;
        // dealMemo.idDepartament = this.grupos.find(
        //   element => element.name === deal.nameDepartament
        // ).id;
        let arrPeriods = [] as any;
        // Datos del periodo
        // Genera los pagos
        for (let key in deal) {
          key = key.trim();
          let obj = {} as any;
          console.log(key);
          console.log(deal[key]);
          if (key.split('_')[0].trim() === 'DES') {
            console.log('okDes');
            if (deal[key] > 0) {
              obj.cuentaPres = deal.CUENTA_DES;
              obj.periodo = key;
              obj.importe = deal[key];
              obj.Descripcion = codigoAgrupador.Descripcion;
              obj.fechaInicio = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaInicio;
              obj.fechaFin = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaFin;
              arrPeriods.push(obj);
              obj = {} as any;
            }
          } else if (key.split('_')[0].trim() === 'SOFTPREP') {
            console.log('okSoftprep');
            if (deal[key] > 0) {
              obj.cuentaPres = deal.CUENTA_SOFTPREP;
              obj.periodo = key;
              obj.importe = deal[key];
              obj.Descripcion = codigoAgrupador.Descripcion;
              obj.fechaInicio = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaInicio;
              obj.fechaFin = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaFin;
              arrPeriods.push(obj);
              obj = {} as any;
            }
          } else if (key.split('_')[0].trim() === 'PREP') {
            console.log('okPrep');
            if (deal[key] > 0) {
              obj.cuentaPres = deal.CUENTA_PREP;
              obj.periodo = key;
              obj.importe = deal[key];
              obj.Descripcion = codigoAgrupador.Descripcion;
              obj.fechaInicio = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaInicio;
              obj.fechaFin = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaFin;
              arrPeriods.push(obj);
              obj = {} as any;
            }
          } else if (key.split('_')[0].trim() === 'ROD') {
            console.log('okRod');
            if (deal[key] > 0) {
              obj.cuentaPres = deal.CUENTA_ROD;
              obj.periodo = key;
              obj.importe = deal[key];
              obj.Descripcion = codigoAgrupador.Descripcion;
              obj.fechaInicio = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaInicio;
              obj.fechaFin = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaFin;
              arrPeriods.push(obj);
              obj = {} as any;
            }
          } else if (key.split('_')[0].trim() === 'ENT') {
            console.log('okEnt');
            if (deal[key] > 0) {
              obj.cuentaPres = deal.CUENTA_ENT;
              obj.periodo = key;
              obj.importe = deal[key];
              obj.Descripcion = codigoAgrupador.Descripcion;
              obj.fechaInicio = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaInicio;
              obj.fechaFin = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaFin;
              arrPeriods.push(obj);
              obj = {} as any;
            }
          } else if (key.split('_')[0].trim() === 'POST') {
            console.log('okPost');
            if (deal[key] > 0) {
              obj.cuentaPres = deal.CUENTA_POST;
              obj.periodo = key;
              obj.importe = deal[key];
              obj.Descripcion = codigoAgrupador.Descripcion;
              obj.fechaInicio = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaInicio;
              obj.fechaFin = this.periodos.find(
                periodo => periodo.nombrePeriodo === key
              ).fechaFin;
              arrPeriods.push(obj);
              obj = {} as any;
            }
          }
        }
        console.log(arrPeriods);

        dealMemo.pagos = arrPeriods;
        dealMemo.personasPS = [];
        dealMemo.documentos = [];
        this.dealMemos.push(dealMemo);
        this.countDealMemo.countDealMemo++;
      } else {
        this.providerNotFound.push(deal);
      }
    }
    console.log(this.dealMemos);
    // console.log(this.providerNotFound);
  }

  addDealMemos() {
    for (let index = 0; index < this.dealMemos.length; index++) {
      const dealMemo = this.dealMemos[index];
      this.dealMemoService
        .addDealMemo(this.idCompany, this.idProject, dealMemo)
        .then(() => {
          Notiflix.Notify.Success(
            `El DealMemo ${dealMemo.countDealMemo} se guardo exitosamente`
          );
        })
        .catch(err => {
          Notiflix.Notify.Failure(
            `El DealMemo ${dealMemo.countDealMemo} no se logro guardar`
          );
        });
    }
    this.dealMemoService.countDealMemo(
      this.idCompany,
      this.idProject,
      this.countDealMemo
    );
  }

  exportAsXLSX(): void {
    let obj = {
      rfc: '',
      nombreProveedor: '',
      descripcionActividad: '',
      solicito: '',
      contratoInicio: '',
      contratoFin: '',
      moneda: '',
    };

    this.periodos.forEach(element => {
      if (element.nombrePeriodo.split('_')[0] === 'DES') {
        obj['CUENTA_DES'] = '';
      } else if (element.nombrePeriodo.split('_')[0] === 'SOFTPREP') {
        obj['CUENTA_SOFTPREP'] = '';
      } else if (element.nombrePeriodo.split('_')[0] === 'PREP') {
        obj['CUENTA_PREP'] = '';
      } else if (element.nombrePeriodo.split('_')[0] === 'ROD') {
        obj['CUENTA_ROD'] = '';
      } else if (element.nombrePeriodo.split('_')[0] === 'ENT') {
        obj['CUENTA_ENT'] = '';
      } else if (element.nombrePeriodo.split('_')[0] === 'POST') {
        obj['CUENTA_POST'] = '';
      }
    });

    this.periodos.forEach(element => {
      obj[element.nombrePeriodo] = '';
    });

    const arr = [obj] as any;

    this.budgetService.exportAsExcelFile(arr, 'dealMemoMasive');
  }

  exportAsXLSXReport(): void {
    const arr = [] as any;
    this.providerNotFound.forEach(element => {
      arr.push(element);
    });
    this.budgetService.exportAsExcelFile(arr, 'reporteConErrores');
  }

  ngOnDestroy() {
    this.subscribeCountDealMemo.unsubscribe();
    this.subscriberGetPeriods.unsubscribe();
    this.subscriberGetProviders.unsubscribe();
    this.subscriberGetBudget.unsubscribe();
    this.subscriberGetProject.unsubscribe();
    this.subscribeGetDepartament.unsubscribe();
  }
}
