import { Component, OnInit, OnDestroy } from '@angular/core';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { EstimadoCierre } from 'src/app/models/costReport';
import { ChecksService } from 'src/app/services/checks.service';
import * as XLSX from 'xlsx';

import { EmpresasService } from 'src/app/services/empresas.service';
import { Subscription } from 'rxjs';
import Notiflix from 'notiflix-angular';
import * as moment from 'moment';

import { Timestamp } from 'rxjs/internal/operators/timestamp';
declare var $;

@Component({
  selector: 'app-estimado-cierre',
  templateUrl: './estimado-cierre.component.html',
  styleUrls: ['./estimado-cierre.component.css'],
})
export class EstimadoCierreComponent implements OnInit, OnDestroy {
  idEmpresa;
  idProyecto;
  subscriberGetBudget: any;
  idCompany: string;
  idProject: string;
  budget = [] as any;
  arrbudget = [] as any;
  dates = {} as EstimadoCierre;
  monedaSeleccionada;
  getBudget: any;
  purchaseOrder = [] as any;
  project = {} as any;
  cuentasTotales;
  comprobaciones = [] as any;

  ivaEstimado: any;
  ivaAcumulado = {} as any;
  ivaEstimadoPresIni: any;
  totales = {} as any;
  acumulados = [] as any;
  request = [] as any;
  pagosDealMemos = [] as any;
  pagosSolDirecta = [] as any;
  totalCuenta = [] as any;
  pagosPucharseOrder = [] as any;
  pagosComprobacion = [] as any;

  budgetArrays = [] as any;
  dealMemo = [] as any;
  productor = {} as any;
  dataProduccion = {
    history: [],
  } as any;
  totalAcumulado = 0;
  arrRecursoProd = [] as any;

  subscribeProject: Subscription;
  subscriberGetPeriod: Subscription;
  subscribergetAcumulados: any;
  subscriberGetRequest: any;
  subscriberProjectActual: Subscription;
  subscriberGetCtas: Subscription;
  subscriberGetIva: Subscription;
  subscriberGetRecProd: Subscription;
  subscriberGetComprobacion: Subscription;
  subscriberGetPucharseOrder: Subscription;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private budgetService: BudgetService,
    private checksService: ChecksService,
    private empresasService: EmpresasService,
    private dealmemoService: DealMemoService
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;

    this.getCtas();
    this.getIVA();
    this.getProject();
    this.getDealMemo();
    this.getPucharseOrder();
    this.getRequest();
    this.getComprobaciones();
  }

  getBudgets() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.getBudget = arrBudget; // Este es el array que mostramos en la tabla
        }
        this.getRecursoProductor();
        this.presupuestos();
      });
  }
  // Obtenemos el ivaEstimado
  getIVA() {
    this.subscriberGetIva = this.budgetService
      .getIvaEstimado(this.idCompany, this.idProject)
      .subscribe(res => {
        const iva: any = res;
        this.ivaEstimado = iva.estimado;
        this.ivaEstimadoPresIni = iva.estimado;
      });
  }
  getAcumulados() {
    this.subscribergetAcumulados = this.dealmemoService
      .getAcumulados(this.idCompany, this.idProject)
      .subscribe(res => {
        this.acumulados = Object.assign([], res);
      });
  }

  getRecursoProductor() {
    this.subscriberGetRecProd = this.budgetService
      .getRecursoProductor(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);
        this.arrRecursoProd = res;
      });
  }

  getProject() {
    this.subscriberProjectActual = this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.monedaSeleccionada = this.project.monedaLocal;
        // console.log(this.monedaSeleccionada);
      });
  }
  getRequest() {
    this.subscriberGetRequest = this.requestService
      .reportePartida(this.idCompany, this.idProject)
      .subscribe(res => {
        this.request = res;
        // console.log(this.request);
      });
  }
  getDealMemo() {
    this.dealmemoService
      .dealMemos(this.idCompany, this.idProject)
      .subscribe(res => {
        this.dealMemo = res;
        // console.log(this.dealMemo);
      });
  }

  getPucharseOrder() {
    this.subscriberGetPucharseOrder = this.dealmemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        this.purchaseOrder = res;
        // console.log(this.purchaseOrder);
      });
  }

  getComprobaciones() {
    this.subscriberGetComprobacion = this.checksService
      .getComprobaciones(this.idCompany, this.idProject)
      .subscribe(res => {
        this.comprobaciones = res;
        // console.log(this.comprobaciones);
      });
  }

  changeMoneda() {
    if (
      this.project.monedaLocal === 'MXN' &&
      this.monedaSeleccionada === 'USD'
    ) {
      this.ivaEstimadoPresIni = this.ivaEstimado / this.project.tipoCambioGral;
      this.ivaAcumulado.estimadoCierre =
        this.ivaAcumulado.Disponible +
        this.ivaAcumulado.Comprometido / this.project.tipoCambioGral;
      this.ivaAcumulado.variante =
        this.ivaAcumulado.estimadoCierre -
        this.ivaEstimado / this.project.tipoCambioGral;
    } else if (
      this.monedaSeleccionada === 'MXN' &&
      this.project.monedaLocal === 'USD'
    ) {
      this.ivaEstimadoPresIni = this.ivaEstimado * this.project.tipoCambioGral;
      this.ivaAcumulado.estimadoCierre =
        this.ivaAcumulado.Disponible +
        this.ivaAcumulado.Comprometido * this.project.tipoCambioGral;
      this.ivaAcumulado.variante =
        this.ivaAcumulado.estimadoCierre -
        this.ivaEstimado * this.project.tipoCambioGral;
    }

    this.presupuestos();
    // this.reportForDate();
  }

  // Obtener las cuentas de los totales
  getCtas() {
    this.subscriberGetCtas = this.budgetService
      .getCtas(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res === undefined) {
        } else {
          this.cuentasTotales = res;
        }
      });
  }

  // Reporte por fecha
  getBudgetForDate() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.getBudget = arrBudget; // Este es el array que mostramos en la tabla
        }

        this.reportForDate();
      });
  }

  reportForDate() {
    this.budgetArrays = [];
    let sumaIvaRequest = 0;
    let sumaIvaDealMemo = 0;
    let sumaIvaPurchaseOrder = 0;

    let obj = {} as any;
    const fechaInic = new Date(this.dates.fechaIni + 'T00:00:00');
    const fechaInicio = fechaInic.toISOString().slice(0, 10);
    // console.log(fechaInicio);

    const fechaFin = new Date(this.dates.fechaFin + 'T23:59:59');
    const fechaFinal = fechaFin.toISOString().slice(0, 10);
    // console.log(fechaFinal);
    this.getBudget.forEach(element => {
      const segmentos = element.CodigoAgrupador.split('-');
      obj.CodigoAgrupador = element.CodigoAgrupador;
      obj.descripcion = element.Descripcion;
      obj.productor = element.productor;
      if (this.project.monedaLocal === this.monedaSeleccionada) {
        if (
          (segmentos.length === 1 && element.Total_Estimado) ||
          element.Total_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Total_Estimado;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && element.Subtotal_Estimado) ||
          element.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Subtotal_Estimado;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Importe_Estimado;
        } else if (
          (segmentos.length === 4 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Importe_Estimado;
        }
      } else if (
        this.project.monedaLocal === 'MXN' &&
        this.monedaSeleccionada === 'USD'
      ) {
        if (
          (segmentos.length === 1 && element.Total_Estimado) ||
          element.Total_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Total_Estimado * this.project.tipoCambioGral;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && element.Subtotal_Estimado) ||
          element.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Subtotal_Estimado * this.project.tipoCambioGral;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Importe_Estimado * this.project.tipoCambioGral;
        }
      } else if (
        this.project.monedaLocal === 'USD' &&
        this.monedaSeleccionada === 'MXN'
      ) {
        if (
          (segmentos.length === 1 && element.Total_Estimado) ||
          element.Total_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Total_Estimado / this.project.tipoCambioGral;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && element.Subtotal_Estimado) ||
          element.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Subtotal_Estimado / this.project.tipoCambioGral;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Importe_Estimado / this.project.tipoCambioGral;
        }
      }

      // INICIO SUMAS SOLICITUDES
      let suma = 0;
      for (let index = 0; index < this.request.length; index++) {
        const request = this.request[index];
        const fecha = new Date(request.fechaCreado.seconds * 1000);
        const formato = 'YYYY-MM-DD';
        const fechaCreado = moment(fecha).format(formato);

        if (fechaInicio <= fechaCreado && fechaFinal >= fechaCreado) {
          request.movimientos.forEach(element3 => {
            if (element3.Cargo) {
              if (
                element.CodigoAgrupador === element3.Cuenta &&
                this.monedaSeleccionada === request.tipoMoneda &&
                !request.idDealMemo
              ) {
                suma += element3.Cargo;
                sumaIvaRequest += element3.iva;
              } else if (
                element.CodigoAgrupador === element3.Cuenta &&
                this.monedaSeleccionada === 'MXN' &&
                request.tipoMoneda === 'USD' &&
                !request.idDealMemo
              ) {
                suma += element3.Cargo / request.tipoCambio;
                sumaIvaRequest += element3.iva / request.tipoCambio;
              } else if (
                element.CodigoAgrupador === element3.Cuenta &&
                this.monedaSeleccionada === 'USD' &&
                request.tipoMoneda === 'MXN' &&
                !request.idDealMemo
              ) {
                suma += element3.Cargo * request.tipoCambio;
                sumaIvaRequest += element3.iva * request.tipoCambio;
              } else if (
                element.CodigoAgrupador === element3.Cuenta &&
                this.monedaSeleccionada === 'MXN' &&
                request.tipoMoneda === 'EUR' &&
                !request.idDealMemo
              ) {
                suma += element3.Cargo / request.tipoCambio;
                sumaIvaRequest += element3.iva / request.tipoCambio;
              } else if (
                element.CodigoAgrupador === element3.Cuenta &&
                this.monedaSeleccionada === 'USD' &&
                request.tipoMoneda === 'EUR' &&
                !request.idDealMemo
              ) {
                suma += element3.Cargo / request.tipoCambio;
                sumaIvaRequest += element3.iva / request.tipoCambio;
              }
            }
          });
        }
      }
      obj.pagosDirectos = suma;
      this.ivaAcumulado.request = sumaIvaRequest;
      // INICIO SUMAS DEALMEMO
      let suma1 = 0;
      for (let index = 0; index < this.dealMemo.length; index++) {
        const dealMemo = this.dealMemo[index];
        const formato = 'YYYY-MM-DD';
        const fechaCreado = moment(dealMemo.fecha).format(formato);
        dealMemo.pagos.forEach(element4 => {
          if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === dealMemo.tipoMoneda &&
            fechaInicio <= fechaCreado &&
            fechaFinal >= fechaCreado
          ) {
            suma1 += element4.importe;
            sumaIvaDealMemo += element4.iva;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            dealMemo.tipoMoneda === 'USD'
          ) {
            suma1 += element4.importe / dealMemo.tipoCambio;
            sumaIvaDealMemo += element4.iva / dealMemo.tipoCambio;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            dealMemo.tipoMoneda === 'MXN'
          ) {
            suma1 += element4.importe * dealMemo.tipoCambio;
            sumaIvaDealMemo += element4.iva * dealMemo.tipoCambio;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            dealMemo.tipoMoneda === 'EUR'
          ) {
            suma1 += element4.importe / dealMemo.tipoCambio;
            sumaIvaDealMemo += element4.iva / dealMemo.tipoCambio;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            dealMemo.tipoMoneda === 'EUR'
          ) {
            suma1 += element4.importe / dealMemo.tipoCambio;
            sumaIvaDealMemo += element4.iva / dealMemo.tipoCambio;
          }
        });
      }
      //INICIO SUMAS ORDEN DE COMPRA
      let suma5 = 0;
      for (let index = 0; index < this.purchaseOrder.length; index++) {
        const purchaseOrder = this.purchaseOrder[index];
        const formato = 'YYYY-MM-DD';
        const fechaCreado = moment(purchaseOrder.fecha).format(formato);
        purchaseOrder.pagos.forEach(element5 => {
          if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === purchaseOrder.tipoMoneda &&
            fechaInicio <= fechaCreado &&
            fechaFinal >= fechaCreado
          ) {
            suma5 += element5.importe;
            sumaIvaPurchaseOrder += element5.iva;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            purchaseOrder.tipoMoneda === 'USD'
          ) {
            suma5 += element5.importe / purchaseOrder.tipoCambio;
            sumaIvaPurchaseOrder += element5.iva / purchaseOrder.tipoCambio;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            purchaseOrder.tipoMoneda === 'MXN'
          ) {
            suma5 += element5.importe * purchaseOrder.tipoCambio;
            sumaIvaPurchaseOrder += element5.iva * purchaseOrder.tipoCambio;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            purchaseOrder.tipoMoneda === 'EUR'
          ) {
            suma5 += element5.importe / purchaseOrder.tipoCambio;
            sumaIvaPurchaseOrder += element5.iva / purchaseOrder.tipoCambio;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            purchaseOrder.tipoMoneda === 'EUR'
          ) {
            suma5 += element5.importe / purchaseOrder.tipoCambio;
            sumaIvaPurchaseOrder += element5.iva / purchaseOrder.tipoCambio;
          }
        });
      }

      for (let index = 0; index < this.arrRecursoProd.length; index++) {
        const recurso = this.arrRecursoProd[index];
        if (element.CodigoAgrupador === recurso.CodigoAgrupador) {
          let suma = 0;
          recurso.history.forEach(elementRecurso => {
            suma += elementRecurso.importe;
          });
          obj.productor = suma;
        }
      }

      obj.dealMemo = suma1 || 0;
      obj.ordenDeCompra = suma5 || 0;
      obj.comprobacion = 0 || 0;
      obj.totalComprometido =
        obj.dealMemo +
          obj.ordenDeCompra +
          obj.pagosDirectos +
          obj.comprobacion || 0;

      obj.disponible =
        obj.presupuestoInicial - obj.totalComprometido >= 0
          ? obj.presupuestoInicial -
            obj.totalComprometido +
            (obj.productor || 0)
          : obj.productor || 0;

      obj.estimadoCierre = obj.totalComprometido + obj.disponible || 0;
      obj.variante = obj.presupuestoInicial - obj.estimadoCierre || 0;

      this.budgetArrays.push(obj);
      obj = {};
    });
    this.ivaAcumulado.request = sumaIvaRequest;
    this.ivaAcumulado.dealMemo = sumaIvaDealMemo;
    this.ivaAcumulado.purchaseOrder = sumaIvaPurchaseOrder;

    this.ivaAcumulado.Disponible =
      this.ivaEstimadoPresIni -
      this.ivaAcumulado.request -
      this.ivaAcumulado.dealMemo;

    this.ivaAcumulado.Comprometido =
      this.ivaAcumulado.request + this.ivaAcumulado.dealMemo;
    this.ivaAcumulado.estimadoCierre =
      this.ivaAcumulado.Disponible + this.ivaAcumulado.Comprometido;
    this.ivaAcumulado.variante =
      this.ivaAcumulado.estimadoCierre - this.ivaEstimadoPresIni;
    for (let index = 0; index < this.budgetArrays.length; index++) {
      const element = this.budgetArrays[index];
      const segmentos = element.CodigoAgrupador.split('-');

      let suma1 = 0;
      let suma2 = 0;
      let suma3 = 0;
      let suma4 = 0;
      let suma5 = 0;
      let suma6 = 0;
      let suma7 = 0;
      if (segmentos.length === 2) {
        for (let index2 = 0; index2 < this.budgetArrays.length; index2++) {
          const element2 = this.budgetArrays[index2];
          const segmentos2 = element2.CodigoAgrupador.split('-');
          if (
            segmentos2.length === 3 &&
            segmentos2[0] === segmentos[0] &&
            segmentos2[1] === segmentos[1]
          ) {
            suma1 += element2.disponible;
            suma2 += element2.estimadoCierre;
            suma3 += element2.presupuestoInicial - element2.estimadoCierre;
            suma4 += element2.dealMemo;
            suma5 += element2.ordenDeCompra;
            suma6 += element2.pagosDirectos;
            suma7 += element2.comprobacion;
          }
        }

        element.disponible = suma1;
        element.estimadoCierre = suma2;
        element.variante = suma3;
        element.dealMemo = suma4;
        element.ordenDeCompra = suma5;
        element.pagosDirectos = suma6;
        element.comprobacion = suma7;
      }
    }

    for (let index = 0; index < this.budgetArrays.length; index++) {
      const element = this.budgetArrays[index];
      const segmentos = element.CodigoAgrupador.split('-');

      let suma1 = 0;
      let suma2 = 0;
      let suma3 = 0;
      let suma4 = 0;
      let suma5 = 0;
      let suma6 = 0;
      let suma7 = 0;
      if (segmentos.length === 1) {
        for (let index2 = 0; index2 < this.budgetArrays.length; index2++) {
          const element2 = this.budgetArrays[index2];
          const segmentos2 = element2.CodigoAgrupador.split('-');
          if (segmentos2.length === 2 && segmentos[0] === segmentos2[0]) {
            suma1 += element2.disponible;
            suma2 += element2.estimadoCierre;
            suma3 += element2.presupuestoInicial - element2.estimadoCierre;
            suma4 += element2.dealMemo;
            suma5 += element2.ordenDeCompra;
            suma6 += element2.pagosDirectos;
            suma7 += element2.comprobacion;
          }
        }

        element.disponible = suma1;
        element.estimadoCierre = suma2;
        element.variante = suma3;
        element.dealMemo = suma4;
        element.ordenDeCompra = suma5;
        element.pagosDirectos = suma6;
        element.comprobacion = suma7;
      }
    }
    this.totalFunction();
  }
  clean() {
    this.budgetArrays = [];
  }
  // REPORTE GENERAL
  presupuestos() {
    this.budgetArrays = [];
    let sumaIvaRequest = 0;
    let sumaIvaDealMemo = 0;
    let sumaIvaPurchaseOrder = 0;
    let sumaIvaComprobaciones = 0;

    let obj = {} as any;
    this.getBudget.forEach(element => {
      const segmentos = element.CodigoAgrupador.split('-');
      obj.CodigoAgrupador = element.CodigoAgrupador;
      obj.descripcion = element.Descripcion;
      obj.productor = element.productor;
      if (this.project.monedaLocal === this.monedaSeleccionada) {
        if (
          (segmentos.length === 1 && element.Total_Estimado) ||
          element.Total_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Total_Estimado;

          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && element.Subtotal_Estimado) ||
          element.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Subtotal_Estimado;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Importe_Estimado;
        } else if (
          (segmentos.length === 4 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial = element.Importe_Estimado;
        }
      } else if (
        this.project.monedaLocal === 'MXN' &&
        this.monedaSeleccionada === 'USD'
      ) {
        if (
          (segmentos.length === 1 && element.Total_Estimado) ||
          element.Total_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Total_Estimado / this.project.tipoCambioGral;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && element.Subtotal_Estimado) ||
          element.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Subtotal_Estimado / this.project.tipoCambioGral;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Importe_Estimado / this.project.tipoCambioGral;
        }
      } else if (
        this.project.monedaLocal === 'USD' &&
        this.monedaSeleccionada === 'MXN'
      ) {
        if (
          (segmentos.length === 1 && element.Total_Estimado) ||
          element.Total_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Total_Estimado * this.project.tipoCambioGral;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && element.Subtotal_Estimado) ||
          element.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Subtotal_Estimado * this.project.tipoCambioGral;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && element.Importe_Estimado) ||
          element.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            element.Importe_Estimado * this.project.tipoCambioGral;
        }
      }

      // INICIO SUMAS SOLICITUDES
      let suma = 0;
      for (let index = 0; index < this.request.length; index++) {
        const request = this.request[index];
        request.movimientos.forEach(element3 => {
          if (element3.Cargo) {
            if (
              element.CodigoAgrupador === element3.Cuenta &&
              this.monedaSeleccionada === request.tipoMoneda &&
              !request.idDealMemo
            ) {
              suma += element3.Cargo;
              sumaIvaRequest += element3.iva;
            } else if (
              element.CodigoAgrupador === element3.Cuenta &&
              this.monedaSeleccionada === 'USD' &&
              request.tipoMoneda === 'MXN' &&
              !request.idDealMemo
            ) {
              suma += element3.Cargo / this.project.tipoCambioGral;
              sumaIvaRequest += element3.iva / this.project.tipoCambioGral;
            } else if (
              element.CodigoAgrupador === element3.Cuenta &&
              this.monedaSeleccionada === 'MXN' &&
              request.tipoMoneda === 'USD' &&
              !request.idDealMemo
            ) {
              suma += element3.Cargo * this.project.tipoCambioGral;
              sumaIvaRequest += element3.iva * this.project.tipoCambioGral;
            } else if (
              element.CodigoAgrupador === element3.Cuenta &&
              this.monedaSeleccionada === 'MXN' &&
              request.tipoMoneda === 'EUR' &&
              !request.idDealMemo
            ) {
              suma += element3.Cargo / this.project.tipoCambioGral;
              sumaIvaRequest += element3.iva / this.project.tipoCambioGral;
            } else if (
              element.CodigoAgrupador === element3.Cuenta &&
              this.monedaSeleccionada === 'USD' &&
              request.tipoMoneda === 'EUR' &&
              !request.idDealMemo
            ) {
              suma += element3.Cargo / this.project.tipoCambioGral;
              sumaIvaRequest += element3.iva / this.project.tipoCambioGral;
            }
          }
        });
      }
      obj.pagosDirectos = suma;
      this.ivaAcumulado.request = sumaIvaRequest;

      // INICIO SUMAS DEALMEMO
      let suma1 = 0;
      for (let index = 0; index < this.dealMemo.length; index++) {
        const dealMemo = this.dealMemo[index];
        dealMemo.pagos.forEach(element4 => {
          if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === dealMemo.tipoMoneda
          ) {
            suma1 += element4.importe;
            sumaIvaDealMemo += element4.iva;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            dealMemo.tipoMoneda === 'MXN'
          ) {
            suma1 += element4.importe / this.project.tipoCambioGral;
            sumaIvaDealMemo += element4.iva / this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            dealMemo.tipoMoneda === 'USD'
          ) {
            suma1 += element4.importe * this.project.tipoCambioGral;
            sumaIvaDealMemo += element4.iva * this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            dealMemo.tipoMoneda === 'EUR'
          ) {
            suma1 += element4.importe / this.project.tipoCambioGral;
            sumaIvaDealMemo += element4.iva / this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element4.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            dealMemo.tipoMoneda === 'EUR'
          ) {
            suma1 += element4.importe / this.project.tipoCambioGral;
            sumaIvaDealMemo += element4.iva / this.project.tipoCambioGral;
          }
        });
      }
      //INICIO SUMAS ORDEN DE COMPRA
      let suma5 = 0;
      for (let index = 0; index < this.purchaseOrder.length; index++) {
        const purchaseOrder = this.purchaseOrder[index];

        purchaseOrder.pagos.forEach(element5 => {
          if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === purchaseOrder.tipoMoneda
          ) {
            suma5 += element5.importe;
            sumaIvaPurchaseOrder += element5.iva;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            purchaseOrder.tipoMoneda === 'MXN'
          ) {
            suma5 += element5.importe / this.project.tipoCambioGral;
            sumaIvaPurchaseOrder += element5.iva / this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            purchaseOrder.tipoMoneda === 'USD'
          ) {
            suma5 += element5.importe * this.project.tipoCambioGral;
            sumaIvaPurchaseOrder += element5.iva * this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'MXN' &&
            purchaseOrder.tipoMoneda === 'EUR'
          ) {
            suma5 += element5.importe / this.project.tipoCambioGral;
            sumaIvaPurchaseOrder += element5.iva / this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element5.cuentaPres &&
            this.monedaSeleccionada === 'USD' &&
            purchaseOrder.tipoMoneda === 'EUR'
          ) {
            suma5 += element5.importe / this.project.tipoCambioGral;
            sumaIvaPurchaseOrder += element5.iva / this.project.tipoCambioGral;
          }
        });
      }
      //INICIO SUMAS COMPROBACIONES
      let suma3 = 0;
      for (let index = 0; index < this.comprobaciones.length; index++) {
        // console.log('se ejecuta');
        const comprobacion = this.comprobaciones[index];
        comprobacion.comprobantes.forEach(element5 => {
          if (
            element.CodigoAgrupador === element5.CodigoAgrupador &&
            this.monedaSeleccionada === element5.moneda
          ) {
            suma3 += element5.total;
            sumaIvaComprobaciones += element5.iva;
          } else if (
            element.CodigoAgrupador === element5.CodigoAgrupador &&
            this.monedaSeleccionada === 'USD' &&
            element5.moneda === 'MXN'
          ) {
            suma3 += element5.total / this.project.tipoCambioGral;
            sumaIvaComprobaciones += element5.iva / this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element5.CodigoAgrupador &&
            this.monedaSeleccionada === 'MXN' &&
            element5.moneda === 'USD'
          ) {
            suma3 += element5.total * this.project.tipoCambioGral;
            sumaIvaComprobaciones += element5.iva * this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element5.CodigoAgrupador &&
            this.monedaSeleccionada === 'MXN' &&
            element5.moneda === 'EUR'
          ) {
            suma3 += element5.total / this.project.tipoCambioGral;
            sumaIvaComprobaciones += element5.iva / this.project.tipoCambioGral;
          } else if (
            element.CodigoAgrupador === element5.CodigoAgrupador &&
            this.monedaSeleccionada === 'USD' &&
            element5.moneda === 'EUR'
          ) {
            suma3 += element5.total / this.project.tipoCambioGral;
            sumaIvaComprobaciones += element5.iva / this.project.tipoCambioGral;
          }
        });
      }

      for (let index = 0; index < this.arrRecursoProd.length; index++) {
        const recurso = this.arrRecursoProd[index];
        if (element.CodigoAgrupador === recurso.CodigoAgrupador) {
          let suma = 0;
          recurso.history.forEach(elementRecurso => {
            suma += elementRecurso.importe;
          });
          obj.productor = suma;
        }
      }

      obj.dealMemo = suma1 || 0;
      obj.ordenDeCompra = suma5 || 0;
      obj.comprobacion = suma3 || 0;
      obj.totalComprometido =
        obj.dealMemo +
          obj.ordenDeCompra +
          obj.pagosDirectos +
          obj.comprobacion || 0;

      obj.disponible =
        obj.presupuestoInicial - obj.totalComprometido >= 0
          ? obj.presupuestoInicial -
            obj.totalComprometido +
            (obj.productor || 0)
          : obj.productor || 0;

      obj.estimadoCierre = obj.totalComprometido + obj.disponible || 0;
      obj.variante = obj.presupuestoInicial - obj.estimadoCierre || 0;

      this.budgetArrays.push(obj);
      obj = {};
    });
    this.ivaAcumulado.request = sumaIvaRequest;
    this.ivaAcumulado.dealMemo = sumaIvaDealMemo;
    this.ivaAcumulado.purchaseOrder = sumaIvaPurchaseOrder;
    this.ivaAcumulado.comprobaciones = sumaIvaComprobaciones;

    this.ivaAcumulado.Disponible =
      this.ivaEstimadoPresIni -
      this.ivaAcumulado.request -
      this.ivaAcumulado.dealMemo;

    this.ivaAcumulado.Comprometido =
      this.ivaAcumulado.request + this.ivaAcumulado.dealMemo;
    this.ivaAcumulado.estimadoCierre =
      this.ivaAcumulado.Disponible + this.ivaAcumulado.Comprometido;
    this.ivaAcumulado.variante =
      this.ivaAcumulado.estimadoCierre - this.ivaEstimadoPresIni;
    for (let index = 0; index < this.budgetArrays.length; index++) {
      const element = this.budgetArrays[index];
      const segmentos = element.CodigoAgrupador.split('-');

      let suma1 = 0;
      let suma2 = 0;
      let suma3 = 0;
      let suma4 = 0;
      let suma5 = 0;
      let suma6 = 0;
      let suma7 = 0;
      if (segmentos.length === 2) {
        for (let index2 = 0; index2 < this.budgetArrays.length; index2++) {
          const element2 = this.budgetArrays[index2];
          const segmentos2 = element2.CodigoAgrupador.split('-');
          if (
            segmentos2.length === 3 &&
            segmentos2[0] === segmentos[0] &&
            segmentos2[1] === segmentos[1]
          ) {
            suma1 += element2.disponible;
            suma2 += element2.estimadoCierre;
            suma3 += element2.presupuestoInicial - element2.estimadoCierre;
            suma4 += element2.dealMemo;
            suma5 += element2.ordenDeCompra;
            suma6 += element2.pagosDirectos;
            suma7 += element2.comprobacion;
          }
        }

        element.disponible = suma1;
        element.estimadoCierre = suma2;
        element.variante = suma3;
        element.dealMemo = suma4;
        element.ordenDeCompra = suma5;
        element.pagosDirectos = suma6;
        element.comprobacion = suma7;
      }
    }

    for (let index = 0; index < this.budgetArrays.length; index++) {
      const element = this.budgetArrays[index];
      const segmentos = element.CodigoAgrupador.split('-');

      let suma1 = 0;
      let suma2 = 0;
      let suma3 = 0;
      let suma4 = 0;
      let suma5 = 0;
      let suma6 = 0;
      let suma7 = 0;
      if (segmentos.length === 1) {
        for (let index2 = 0; index2 < this.budgetArrays.length; index2++) {
          const element2 = this.budgetArrays[index2];
          const segmentos2 = element2.CodigoAgrupador.split('-');
          if (segmentos2.length === 2 && segmentos[0] === segmentos2[0]) {
            suma1 += element2.disponible;
            suma2 += element2.estimadoCierre;
            suma3 += element2.presupuestoInicial - element2.estimadoCierre;
            suma4 += element2.dealMemo;
            suma5 += element2.ordenDeCompra;
            suma6 += element2.pagosDirectos;
            suma7 += element2.comprobacion;
          }
        }

        element.disponible = suma1;
        element.estimadoCierre = suma2;
        element.variante = suma3;
        element.dealMemo = suma4;
        element.ordenDeCompra = suma5;
        element.pagosDirectos = suma6;
        element.comprobacion = suma7;
      }
    }
    this.totalFunction();
  }

  // Aqui sacamos los totales del reporte
  totalFunction() {
    this.totales = {
      presIniA: 0,
      presIniB: 0,
      presIniC: 0,
      presIniD: 0,
      dealMemoA: 0,
      dealMemoB: 0,
      dealMemoC: 0,
      dealMemoD: 0,
      ordenDeCompraA: 0,
      ordenDeCompraB: 0,
      ordenDeCompraC: 0,
      ordenDeCompraD: 0,
      pagosDirectosA: 0,
      pagosDirectosB: 0,
      pagosDirectosC: 0,
      pagosDirectosD: 0,
      comprobacionA: 0,
      comprobacionB: 0,
      comprobacionC: 0,
      comprobacionD: 0,
      disponibleA: 0,
      disponibleB: 0,
      disponibleC: 0,
      disponibleD: 0,
      estimadoCierreA: 0,
      estimadoCierreB: 0,
      estimadoCierreC: 0,
      estimadoCierreD: 0,
      varianteA: 0,
      varianteB: 0,
      varianteC: 0,
      varianteD: 0,
    };
    this.arrbudget.forEach(element => {
      const segmento = element.CodigoAgrupador.split('-');
      if (segmento.length === 1) {
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TATLDE &&
          element.CodigoAgrupador <= this.cuentasTotales.TATLA
        ) {
          this.totales.presIniA += element.presupuestoInicial;
          this.totales.dealMemoA += element.dealMemo;
          this.totales.ordenDeCompraA += element.ordenDeCompra;
          this.totales.pagosDirectosA += element.pagosDirectos;
          this.totales.comprobacionA += element.comprobacion;
          this.totales.disponibleA += element.disponible;
          this.totales.estimadoCierreA += element.estimadoCierre;

          this.totales.varianteA += element.variante;
        }
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TBTLPDE &&
          element.CodigoAgrupador <= this.cuentasTotales.TBTLPA
        ) {
          this.totales.presIniB += element.presupuestoInicial;
          this.totales.dealMemoB += element.dealMemo;
          this.totales.ordenDeCompraB += element.ordenDeCompra;
          this.totales.pagosDirectosB += element.pagosDirectos;
          this.totales.comprobacionB += element.comprobacion;
          this.totales.disponibleB += element.disponible;
          this.totales.estimadoCierreB += element.estimadoCierre;

          this.totales.varianteB += element.variante;
        }
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TBTLPOSTDE &&
          element.CodigoAgrupador <= this.cuentasTotales.TBTLPOSTA
        ) {
          this.totales.presIniC += element.presupuestoInicial;
          this.totales.dealMemoC += element.dealMemo;
          this.totales.ordenDeCompraC += element.ordenDeCompra;
          this.totales.pagosDirectosC += element.pagosDirectos;
          this.totales.comprobacionC += element.comprobacion;
          this.totales.disponibleC += element.disponible;
          this.totales.estimadoCierreC += element.estimadoCierre;

          this.totales.varianteC += element.variante;
        }
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TBTLODE &&
          element.CodigoAgrupador <= this.cuentasTotales.TBTLOA
        ) {
          this.totales.presIniD += element.presupuestoInicial;
          this.totales.dealMemoD += element.dealMemo;
          this.totales.ordenDeCompraD += element.ordenDeCompra;
          this.totales.pagosDirectosD += element.pagosDirectos;
          this.totales.comprobacionD += element.comprobacion;
          this.totales.disponibleD += element.disponible;
          this.totales.estimadoCierreD += element.estimadoCierre;

          this.totales.varianteD += element.variante;
        }
      }
    });
  }

  selectCta(data) {
    console.log(data);
    this.totalAcumulado = 0;
    if (data.CodigoAgrupador.split('-').length > 2) {
      $('#modalAddProductor').modal('show');
      const obj = this.arrRecursoProd.find(
        element => element.CodigoAgrupador === data.CodigoAgrupador
      );
      console.log(obj);
      if (obj !== undefined) {
        obj.history.forEach(elementHistory => {
          elementHistory.fechaConvertida = elementHistory.fecha.toDate();
          this.totalAcumulado += elementHistory.importe;
        });
        this.dataProduccion = obj;
      } else {
        this.dataProduccion = {
          CodigoAgrupador: data.CodigoAgrupador,
          history: [],
        } as any;
      }
    }
  }

  addArrRecursoProd() {
    if (this.productor.importe && this.productor.descripcion) {
      this.productor.fecha = new Date();
      this.dataProduccion.history.push(this.productor);
      this.budgetService
        .addRecursoProductor(
          this.idCompany,
          this.idProject,
          this.dataProduccion
        )
        .then(() => {
          $('#modalAddProductor').modal('hide');
          Notiflix.Notify.Success('Agregado correctamente');
          this.productor = {} as any;
          this.totalAcumulado = 0;
          this.presupuestos();
        });
    }
  }

  dealMemoSelect(obj) {
    $('#viewdealMemo').modal('show');
    this.totalCuenta = [];
    this.pagosDealMemos = [];
    let pagos;
    let newObj = {} as any;

    // console.log(obj);
    const segmentos = obj.CodigoAgrupador.split('-');
    if (segmentos.length === 1) {
      if (obj.dealMemo === 0) {
      } else {
        for (let index = 0; index < this.dealMemo.length; index++) {
          const element = this.dealMemo[index];

          if (obj.descripcion === element.departamento) {
            // console.log('se ejecuta');
            newObj.CodigoAgrupador = obj.CodigoAgrupador;
            newObj.Descripcion = element.departamento;
            newObj.dealMemo = obj.dealMemo;
          }
        }
        this.totalCuenta.push(newObj);
      }
    } else if (segmentos.length === 2) {
      if (obj.dealMemo === 0) {
      } else {
        for (let index = 0; index < this.dealMemo.length; index++) {
          const element = this.dealMemo[index];

          if (obj.descripcion === element.puesto) {
            newObj.CodigoAgrupador = obj.CodigoAgrupador;
            newObj.Descripcion = element.departamento;
            newObj.dealMemo = obj.dealMemo;
          }
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 3) {
      if (obj.dealMemo === 0) {
      } else {
        for (let index = 0; index < this.dealMemo.length; index++) {
          const deal = this.dealMemo[index];
          deal.pagos.forEach(element => {
            if (obj.CodigoAgrupador === element.cuentaPres) {
              pagos = element;
              element.countDealMemo = deal.countDealMemo;
              element.dealMemo = obj.dealMemo;
            }
          });
        }
        this.pagosDealMemos.push(pagos);
      }
    }
  }

  pucharseOrderSelect(obj) {
    $('#viewOrdenCompra').modal('show');
    this.pagosPucharseOrder = [];
    this.totalCuenta = [];
    let newObj = {} as any;
    console.log(obj);

    let pagos;
    const segmentos = obj.CodigoAgrupador.split('-');
    if (segmentos.length === 1) {
      if (obj.ordenDeCompra === 0) {
      } else {
        for (let index = 0; index < this.purchaseOrder.length; index++) {
          const element = this.purchaseOrder[index];

          if (obj.descripcion === element.departamento) {
            newObj.CodigoAgrupador = obj.CodigoAgrupador;
            newObj.Descripcion = element.departamento;
            newObj.ordenDeCompra = obj.ordenDeCompra;
          }
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 2) {
      if (obj.ordenDeCompra === 0) {
      } else {
        for (let index = 0; index < this.purchaseOrder.length; index++) {
          const element = this.purchaseOrder[index];

          if (obj.descripcion === element.puesto) {
            newObj.CodigoAgrupador = obj.CodigoAgrupador;
            newObj.Descripcion = element.puesto;
            newObj.ordenDeCompra = obj.ordenDeCompra;
          }
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 3) {
      if (obj.ordenDeCompra === 0) {
      } else {
        for (let index = 0; index < this.purchaseOrder.length; index++) {
          const purchaseOrder = this.purchaseOrder[index];
          purchaseOrder.pagos.forEach(element => {
            if (obj.descripcion === element.Descripcion) {
              pagos = element;
              element.orderCount = purchaseOrder.orderCount;
              element.ordenDeCompra = obj.ordenDeCompra;
            }
          });
        }
        this.pagosPucharseOrder.push(pagos);
        console.log(pagos);
      }
    }
  }

  solicitudDirectaSelect(obj) {
    $('#viewSolDirecta').modal('show');
    this.pagosSolDirecta = [];
    this.totalCuenta = [];
    let newObj = {} as any;
    console.log(obj);
    let pagos;
    const segmentos = obj.CodigoAgrupador.split('-');
    if (segmentos.length === 1) {
      if (obj.pagosDirectos === 0) {
      } else {
        for (let index = 0; index < this.request.length; index++) {
          const element = this.request[index];

          if (obj.descripcion === element.departamento) {
            newObj.CodigoAgrupador = obj.CodigoAgrupador;
            newObj.Descripcion = element.departamento;
            newObj.pagosDirectos = obj.pagosDirectos;
          }
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 2) {
      if (obj.pagosDirectos === 0) {
      } else {
        for (let index = 0; index < this.request.length; index++) {
          const element = this.request[index];

          if (obj.descripcion === element.puesto) {
            newObj.CodigoAgrupador = obj.CodigoAgrupador;
            newObj.Descripcion = element.puesto;
            newObj.pagosDirectos = obj.pagosDirectos;
          }
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 3) {
      if (obj.pagosDirectos === 0) {
      } else {
        for (let index = 0; index < this.request.length; index++) {
          const request = this.request[index];
          request.movimientos.forEach(element => {
            if (obj.CodigoAgrupador === element.Cuenta) {
              pagos = element;
              element.countRequest = request.countRequest;
              element.pagosDirectos = obj.pagosDirectos;
            }
          });
        }
        this.pagosSolDirecta.push(pagos);
        // console.log(this.pagosSolDirecta);
      }
    }
  }
  comprobacionSelect(obj) {
    $('#viewComprobacion').modal('show');
    this.pagosComprobacion = [];
    this.totalCuenta = [];
    let newObj = {} as any;
    // console.log(obj);
    let pagos;
    const segmentos = obj.CodigoAgrupador.split('-');
    if (segmentos.length === 1) {
      if (obj.comprobacion === 0) {
      } else {
        for (let index = 0; index < this.comprobaciones.length; index++) {
          const comprobaciones = this.comprobaciones[index];
          comprobaciones.comprobantes.forEach(element => {
            newObj.total = comprobaciones.total;
            newObj.CodigoAgrupador = element.CodigoAgrupador;
            newObj.descripcion = obj.descripcion;
          });
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 2) {
      if (obj.comprobacion === 0) {
      } else {
        for (let index = 0; index < this.comprobaciones.length; index++) {
          const comprobaciones = this.comprobaciones[index];
          comprobaciones.comprobantes.forEach(element => {
            newObj.total = comprobaciones.total;
            newObj.CodigoAgrupador = element.CodigoAgrupador;
            newObj.descripcion = obj.descripcion;
          });
        }
        this.totalCuenta.push(newObj);
        // console.log(this.totalCuenta);
      }
    } else if (segmentos.length === 3) {
      if (obj.comprobacion === 0) {
      } else {
        for (let index = 0; index < this.comprobaciones.length; index++) {
          const comprobaciones = this.comprobaciones[index];
          comprobaciones.comprobantes.forEach(element => {
            if (obj.CodigoAgrupador === element.CodigoAgrupador) {
              newObj.total = comprobaciones.total;
              newObj.countCheck = comprobaciones.countCheck;
              newObj.descripcion = obj.descripcion;
              newObj.CodigoAgrupador = element.CodigoAgrupador;
            }
          });
        }
        this.pagosComprobacion.push(newObj);
        // console.log(this.pagosComprobacion);
      }
    }
  }
  exportTableToExcel(id) {
    var type = 'xlsx';
    var elt = document.getElementById(id);
    var wb = XLSX.utils.table_to_book(elt /*{sheet:"Sheet JS"}*/);
    return XLSX.writeFile(
      wb,
      undefined || 'Estimado Cierre.' + (type || 'xlsx')
    );
  }

  ngOnDestroy() {
    if (this.subscriberGetBudget) {
      this.subscriberGetBudget.unsubscribe();
    }
    if (this.subscriberGetCtas) {
      this.subscriberGetCtas.unsubscribe();
    }
    if (this.subscriberGetPeriod) {
      this.subscriberGetPeriod.unsubscribe();
    }
    if (this.subscriberGetRequest) {
      this.subscriberGetRequest.unsubscribe();
    }
    if (this.subscribeProject) {
      this.subscribeProject.unsubscribe();
    }
    if (this.subscriberGetRecProd) {
      this.subscriberGetRecProd.unsubscribe();
    }
  }
}
