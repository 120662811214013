import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProjectsComponent } from 'src/app/pages/projects/projects.component';
import { HomeComponent } from './home.component';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { HeaderComponent } from 'src/app/common/components/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { BudgetComponent } from 'src/app/pages/budget/budget.component';
import { MenuAdminComponent } from '../menu-admin/menu-admin.component';
import { MainAdminComponent } from 'src/app/common/components/main-admin/main-admin.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectConfigComponent } from 'src/app/pages/project-config/project-config.component';
import { SlidebarComponent } from 'src/app/common/components/slidebar/slidebar.component';
import { HomeRoutingModule } from './home-routing.module';
import { ProvidersComponent } from 'src/app/pages/providers/providers.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WelcomeComponent } from 'src/app/pages/welcome/welcome.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HomeComponent,
    HeaderComponent,
    ProjectsComponent,
    BudgetComponent,
    MenuAdminComponent,
    MainAdminComponent,
    ProjectConfigComponent,
    SlidebarComponent,
    MenuComponent,
    ProvidersComponent,
    WelcomeComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgbModule,
    HomeRoutingModule,
    ScrollingModule,
    MatTabsModule,
    BrowserAnimationsModule,
  ],
  exports: [RouterModule],
})
export class HomeModule {}
