import { Component, OnInit } from '@angular/core';
import Notiflix from 'notiflix-angular';
import Typed from 'typed.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  finLoading = false;
  deleteElement = true;

  ngOnInit() {
    Notiflix.Notify.Init({
      position: 'right-bottom',
      fontSize: '15px',
      timeout: 4000,
    });
    Notiflix.Loading.Init({
      svgColor: '#009fab',
    });
    // const options0 = {
    //   strings: ['EN CONEXIÓN'],
    //   showCursor: false,
    //   typeSpeed: 60,
    //   smartBackspace: true,
    //   loop: false,
    // };
    // const options1 = {
    //   strings: ['ASESORIA CORPORATIVA'],
    //   showCursor: false,
    //   typeSpeed: 40,
    //   smartBackspace: true,
    //   loop: false,
    // };
    // setTimeout(() => {
    //   const text0 = new Typed('#welcome', options0);
    //   text0.start();
    // }, 500);
    // setTimeout(() => {
    //   const text1 = new Typed('#welcome2', options1);
    //   text1.start();
    // }, 1000);
    // setTimeout(() => {
    //   document.getElementById('loading').classList.add('ocultar');
    //   this.finLoading = true;
    //   setTimeout(() => {
    //     this.deleteElement = false;
    //   }, 1000);
    // }, 3500);
  }
}
