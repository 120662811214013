import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import Notiflix from 'notiflix-angular';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  addUser = {} as User;
  user: string;
  pass: string;
  validation: string;
  errorMsg = '';

  email = '';
  password = '';
  btnToggle = true;
  passAcces: string;

  login = true;
  access = false;
  register = false;

  emailReset: string;

  fecha = new Date().getFullYear();

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {}

  onLogin() {
    this.authService
      .loginEmailUser(this.email, this.password)
      .then(res => {
        this.router.navigate(['dashboard']);
      })
      .catch(err => {
        if (err.code === 'auth/invalid-email') {
          this.errorMsg = 'Verifica el usuario';
          document.getElementById('user').classList.add('is-invalid');
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000);
        } else {
          this.errorMsg = 'Verifica la contraseña';
          document
            .getElementById('user')
            .classList.replace('is-invalid', 'is-valid');
          document.getElementById('password').classList.add('is-invalid');
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000);
        }
      });
  }

  onSignIn() {
    if (this.addUser.email.trim() === '') {
      document.getElementById('email').classList.add('is-invalid');
    } else {
      document
        .getElementById('email')
        .classList.replace('is-invalid', 'is-valid');
      if (this.addUser.password.trim() === '') {
        document.getElementById('pass').classList.add('is-invalid');
      } else {
        document
          .getElementById('pass')
          .classList.replace('is-invalid', 'is-valid');
        if (this.addUser.confirmPass.trim() === '') {
          document.getElementById('pass2').classList.add('is-invalid');
        } else {
          if (this.addUser.password === this.addUser.confirmPass) {
            // Envio los datos al servicio y me regresa el uid del usuario
            this.authService
              .addUser(this.addUser.email, this.addUser.password)
              .then(res => {
                this.authService.emailVerification();
                // guardo el uid en el objeto que voy a mandar al servicio
                const objUser: User = {};
                objUser.uid = res.user.uid;
                objUser.userType = 'Usuario';
                objUser.email = res.user.email;
                // envio el uid a la base de datos
                this.authService.addUserDB(objUser).then(() => {
                  const objCode = {
                    validado: true,
                  };
                  this.authService.editCode(this.passAcces, objCode);
                  this.passAcces = '';
                  this.cancel();
                  this.addUser = {} as User;
                  this.btnToggle = true;
                });
              })
              .catch(error => {
                console.error(error);
                if (error.code === 'auth/auth/email-already-in-use') {
                  document.getElementById('email').classList.add('is-invalid');
                  Notiflix.Notify.Failure(
                    'El Email ya se encuentra registrado'
                  );
                } else if (error.code === 'auth/weak-password') {
                  Notiflix.Notify.Failure(
                    'La contraseña debe contener minimo 6 caracteres'
                  );
                } else {
                  document.getElementById('email').classList.add('is-invalid');
                }
              });
          } else {
            document.getElementById('pass2').classList.add('is-invalid');
          }
        }
      }
    }
  }

  openModalReset() {
    $('#restartPass').modal('show');
  }

  resetPass() {
    const validateEmail = () => {
      // eslint-disable-next-line
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(String(this.emailReset).toLowerCase());
    };
    if (validateEmail()) {
      this.authService
        .resetPass(this.emailReset)
        .then(() => {
          $('#restartPass').modal('hide');
          Notiflix.Report.Success(
            'Se envio correctamente',
            'Se te envio una liga de restablecimiento a tu correo electronico',
            'Ok'
          );
        })
        .catch(err => {
          if (err.code === 'auth/user-not-found') {
            Notiflix.Report.Failure(
              'Email incorrecto',
              'El email no se encuentra registrado',
              'Ok'
            );
          }
        });
    } else {
      Notiflix.Notify.Failure('El email es incorrecto');
    }
  }

  toggle() {
    if (this.register) {
      this.login = true;
      this.access = false;
      this.register = false;
    } else {
      this.login = false;
      this.access = true;
    }
  }

  cancel() {
    this.login = true;
    this.access = false;
    this.register = false;
  }

  generatePass() {
    const subscriptionCode = this.authService
      .readCode(this.passAcces)
      .subscribe((res: any) => {
        if (res) {
          if (res.validado === false) {
            this.access = false;
            this.register = true;
          } else {
            Notiflix.Notify.Failure(
              'El codigo ya fue usado contacta a un administrador'
            );
          }
        } else {
          Notiflix.Notify.Failure('Codigo Invalido');
        }
        subscriptionCode.unsubscribe();
      });
  }
}
