import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import * as moment from 'moment';
import pdfMake from 'pdfmake';
import { Subscription } from 'rxjs';
import { EmpresasService } from 'src/app/services/empresas.service';

@Component({
  selector: 'app-approve-request',
  templateUrl: './approve-request.component.html',
  styleUrls: ['./approve-request.component.css'],
})
export class ApproveRequestComponent implements OnInit {
  idCompany: string;
  idProject: string;
  requests = [] as any;
  total: number;
  company = {} as any;
  project = {} as any;

  subscriberCompanyActual: Subscription;
  subscriberProjectActual: Subscription;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private empresasService: EmpresasService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getRequests();
    this.getProject();
  }

  getRequests() {
    this.requestService
      .getRequestNotApprove(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);

        const arr = res;

        this.total = arr.reduce(
          (acc, obj) => acc + obj.costo_producto * obj.cantidad_producto,
          0
        );

        arr.forEach(e => {
          let sumaCargos = 0;
          let sumaAbonos = 0;
          e.movimientos.forEach(e2 => {
            sumaCargos += e2.Cargo ? e2.Cargo : 0;
            sumaAbonos += e2.Abono ? e2.Abono : 0;
          });
          e.Cargo = sumaCargos;
          e.Abono = sumaAbonos;
          e.select = false;
        });

        this.requests = arr.sort((a, b) => {
          return a.countRequest - b.countRequest;
        });
      });
  }

  getProject() {
    this.subscriberCompanyActual = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe(res => {
        this.company = res;
        console.log('Datos de la empresa: ', this.company);
      });
    this.subscriberProjectActual = this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        console.log('Datos del proyecto:', this.project);
      });
  }
  exportAsXLSX(): void {
    const arr = [] as any;

    this.requests.forEach(element => {
      console.log(element.select);
      if (element.select) {
        let obj = {
          NumSol: element.countRequest,
          Proveedor: element.providerName,
          RFC: element.rfcProvider,
          Concepto: element.concepto,
          Moneda: element.tipoMoneda,
          TipoCambio: element.tipoCambio,
          Cargo: element.Cargo,
          Abono: element.Abono,
        } as any;
        arr.push(obj);

        obj = {} as any;
      }
    });

    this.requestService.exportAsExcelFile(arr, 'solicitudes');
  }

  async generatePDF() {
    const solsSeleccionadas = [];
    const encabezado = [
      {
        text: 'Sol',
        style: 'tableHeader',
      },
      {
        text: 'Proveedor',
        style: 'tableHeader',
      },
      {
        text: 'RFC',
        style: 'tableHeader',
      },
      {
        text: 'Concepto',
        style: 'tableHeader',
      },
      {
        text: 'Moneda',
        style: 'tableHeader',
      },
      {
        text: 'TipoCambio',
        style: 'tableHeader',
      },
      {
        text: 'Cargo',
        style: 'tableHeader',
      },
      {
        text: 'Abono',
        style: 'tableHeader',
      },
    ];

    solsSeleccionadas.push(encabezado);

    this.requests.forEach(element => {
      if (element.select) {
        const arr = [
          { text: element.countRequest },
          { text: element.providerName },
          { text: element.rfcProvider },
          { text: element.concepto },
          { text: element.tipoMoneda },
          { text: element.tipoCambio },
          { text: element.Cargo },
          { text: element.Abono },
        ];
        solsSeleccionadas.push(arr);
      }
    });

    console.log(solsSeleccionadas);

    const docDefinition = {
      pageSize: 'LETTER',
      pageOrientation: 'landscape', // landscape, portrait
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 10,
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: 'black',
        },
      },
      defaultStyle: {
        fontSize: 9,
      },
      // info: {
      //   title: 'awesome Document',
      //   author: 'john doe',
      //   subject: 'subject of document',
      //   keywords: 'keywords for document',
      // },
      content: [
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: 'Solicitud de aprobacion',
                  alignment: 'center',
                  style: 'subheader',
                },
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            headerRows: 1,
            body: solsSeleccionadas,
          },
        },
        {
          text: '\n\n',
        },
        {
          text: 'Aprobacion',
          style: 'tableHeader',
          alignment: 'center',
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  text: 'Productor(a)',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Supervisor(a) de producción',
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
              [
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.productor,
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.supervisor,
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  text: 'Contralor',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Contador de produccion',
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text: 'Administrador',
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
              [
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.contralor,
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.contProduccion,
                  alignment: 'center',
                  style: 'tableHeader',
                },
                {
                  text:
                    '\n\n\n________________________________\n' +
                    this.project.administrador,
                  alignment: 'center',
                  style: 'tableHeader',
                },
              ],
            ],
          },
        },
      ],
    };

    pdfMake.createPdf(docDefinition).open();
  }
}
