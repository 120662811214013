import { Component, OnInit } from '@angular/core';
declare var JQuery;
declare var $;

@Component({
  selector: 'app-main-admin',
  templateUrl: './main-admin.component.html',
  styleUrls: ['./main-admin.component.css']
})
export class MainAdminComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  toggle() {
    $('#sidebar').toggleClass('active');
    $('.overlay').toggleClass('active');
  }
}
