import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Disponible } from 'src/app/models/costReport';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { EmpresasService } from 'src/app/services/empresas.service';

@Component({
  selector: 'app-available',
  templateUrl: './available.component.html',
  styleUrls: ['./available.component.css'],
})
export class AvailableComponent implements OnInit {
  idCompany: string;
  idProject: string;

  getBudget: any;
  budgetArrays = [] as any;
  arrPurchaseOrder = [] as any;
  filterOrders = [] as any;
  dataTable = [];
  dates = {} as Disponible;
  monedaSeleccionada;
  filterSolicitudes = [] as any;
  project = {} as any;

  // Subscriptions
  getPurchaseOrderSubscription: Subscription;
  subscriberGetBudget: Subscription;
  subscriberProjectActual: Subscription;

  constructor(
    private router: Router,
    private budgetService: BudgetService,
    private dealMemoService: DealMemoService,
    private empresasService: EmpresasService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getPurchaseOrder();
    this.getBudgets();
    this.getProject();
  }

  getProject() {
    this.subscriberProjectActual = this.empresasService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        // console.log(this.project);

        this.monedaSeleccionada = this.project.monedaLocal;
        // console.log(this.monedaSeleccionada);
      });
  }

  getBudgets() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.budgetService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.getBudget = arrBudget; // Este es el array que mostramos en la tabla
        }
        // console.log(this.getBudget);
        // this.reporteDisponible();
      });
  }
  getPurchaseOrder() {
    this.getPurchaseOrderSubscription = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);

        this.arrPurchaseOrder = res.sort((a, b) => {
          return a.orderCounter - b.orderCounter;
        });
        // console.log(this.arrPurchaseOrder);
      });
  }

  changeMoneda() {
    // console.log(this.filterOrders);
    // console.log(this.monedaSeleccionada);

    this.reportForDate();
  }

  // reporteDisponible() {
  //   let obj = {} as any;

  //   this.getBudget.forEach(elementBugget => {
  //     const segmentos = elementBugget.CodigoAgrupador.split('-');
  //     obj.CodigoAgrupador = elementBugget.CodigoAgrupador;
  //     obj.descripcion = elementBugget.Descripcion;
  //     if (
  //       (segmentos.length === 1 && elementBugget.Total_Estimado) ||
  //       elementBugget.Total_Estimado === 0
  //     ) {
  //       obj.presupuestoInicial = elementBugget.Total_Estimado;
  //       obj.color = 'azul';
  //     } else if (
  //       (segmentos.length === 2 && elementBugget.Subtotal_Estimado) ||
  //       elementBugget.Subtotal_Estimado === 0
  //     ) {
  //       obj.presupuestoInicial = elementBugget.Subtotal_Estimado;
  //       obj.color = 'amarillo';
  //     } else if (
  //       (segmentos.length === 3 && elementBugget.Importe_Estimado) ||
  //       elementBugget.Importe_Estimado === 0
  //     ) {
  //       obj.presupuestoInicial = elementBugget.Importe_Estimado;
  //     } else if (
  //       (segmentos.length === 4 && elementBugget.Importe_Estimado) ||
  //       elementBugget.Importe_Estimado === 0
  //     ) {
  //       obj.presupuestoInicial = elementBugget.Importe_Estimado;
  //     }

  //     // SUMA Solicitudes Pagadas
  //     let sumaSolicitudes = 0;
  //     for (let index = 0; index < this.arrPurchaseOrder.length; index++) {
  //       const element = this.arrPurchaseOrder[index];
  //       element.solicitudes.forEach(elementSolicitudes => {
  //         elementSolicitudes.valores.forEach(elementValores => {
  //           if (elementBugget.CodigoAgrupador === elementValores.partidaPres) {
  //             if (elementSolicitudes.pagado) {
  //               sumaSolicitudes += elementValores.importe;
  //             }
  //           }
  //         });
  //       });
  //     }
  //     //

  //     // SUMA COMPROMETIDOS
  //     let sumaComprometidos = 0;
  //     for (let index = 0; index < this.arrPurchaseOrder.length; index++) {
  //       const element = this.arrPurchaseOrder[index];
  //       element.comprometidos.forEach(elementComprometidos => {
  //         if (
  //           elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
  //         ) {
  //           sumaComprometidos += elementComprometidos.importe;
  //         }
  //       });
  //     }

  //     obj.pagado = sumaSolicitudes;
  //     obj.comprometidos = sumaComprometidos;
  //     obj.actualComprometido = obj.comprometidos + obj.pagado;
  //     obj.disponible =
  //       obj.presupuestoInicial - obj.actualComprometido < 0
  //         ? 0
  //         : obj.presupuestoInicial - obj.actualComprometido;
  //     obj.estimadoCierre = obj.actualComprometido + obj.disponible;
  //     obj.variante = obj.presupuestoInicial - obj.estimadoCierre;

  //     this.dataTable.push(obj);
  //     obj = {};
  //   });
  //   // console.log(this.dataTable);
  // }

  // REPORTE POR FECHA
  reportForDate() {
    // console.log(this.monedaSeleccionada);
    this.dataTable = [];
    this.filterOrders = [];
    this.filterSolicitudes = [];
    let obj = {} as any;

    // Convertir fechaInicio y fechaFin a milisegundos
    const fechaInic = new Date(this.dates.fechaIni + 'T00:00:00');
    const fechaInicio = fechaInic.getTime(); // Milisegundos desde la fecha de inicio

    const fechaFinal = new Date(this.dates.fechaFin + 'T23:59:59');

    const fechaFin = fechaFinal.getTime(); // Milisegundos hasta la fecha final
    // console.log('Fecha Fin:', new Date(fechaFin)); // Solo para verificar

    // Filtrarmos las órdenes de compra por rango de fechas
    this.arrPurchaseOrder.forEach(elementOrders => {
      const seconds = elementOrders.fechaCreado.seconds;
      const fechaCreado = new Date(seconds * 1000).getTime(); // Convertir fechaCreado a milisegundos

      // Comprobar si fechaCreado está dentro del rango de fechas
      if (fechaCreado > fechaInicio && fechaCreado < fechaFin) {
        this.filterOrders.push(elementOrders);
      }
    });
    // console.log(this.filterOrders, 'ORDENES');

    for (let index = 0; index < this.filterOrders.length; index++) {
      const element = this.filterOrders[index];
      element.solicitudes.forEach(elementSol => {
        const secondsSol = elementSol.fechaSol.seconds;
        const fechaSolCreate = new Date(secondsSol * 1000).getTime();
        if (fechaSolCreate > fechaInicio && fechaSolCreate < fechaFin) {
          if (elementSol.pagado) {
            // console.log(elementSol);
            this.filterSolicitudes.push(elementSol);
          }
        }
      });
    }
    // console.log(
    //   this.filterSolicitudes,
    //   'SOLICITUDES Pagadas en el rango elegido de fechas'
    // );

    //  inicia funcion para el reporte disponible
    this.getBudget.forEach(elementBugget => {
      const segmentos = elementBugget.CodigoAgrupador.split('-');
      obj.CodigoAgrupador = elementBugget.CodigoAgrupador;
      obj.descripcion = elementBugget.Descripcion;
      if (this.project.monedaLocal === this.monedaSeleccionada) {
        if (
          (segmentos.length === 1 && elementBugget.Total_Estimado) ||
          elementBugget.Total_Estimado === 0
        ) {
          obj.presupuestoInicial = elementBugget.Total_Estimado;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && elementBugget.Subtotal_Estimado) ||
          elementBugget.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial = elementBugget.Subtotal_Estimado;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && elementBugget.Importe_Estimado) ||
          elementBugget.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.color = 'treeSegmentsWhitChild';
        } else if (
          (segmentos.length === 4 && elementBugget.Importe_Estimado) ||
          elementBugget.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial = elementBugget.Importe_Estimado;
          obj.color = 'verde';
        }
      } else if (
        this.project.monedaLocal === 'USD' &&
        this.monedaSeleccionada === 'MXN'
      ) {
        if (
          (segmentos.length === 1 && elementBugget.Total_Estimado) ||
          elementBugget.Total_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Total_Estimado * this.project.tipoCambioGral;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && elementBugget.Subtotal_Estimado) ||
          elementBugget.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Subtotal_Estimado * this.project.tipoCambioGral;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && elementBugget.Importe_Estimado) ||
          elementBugget.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado * this.project.tipoCambioGral;
          obj.color = 'treeSegmentsWhitChild';
        } else if (
          (segmentos.length === 4 && elementBugget.Importe_Estimado) ||
          elementBugget.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado * this.project.tipoCambioGral;
          obj.color = 'verde';
        }
      } else if (
        this.project.monedaLocal === 'MXN' &&
        this.monedaSeleccionada === 'USD'
      ) {
        if (
          (segmentos.length === 1 && elementBugget.Total_Estimado) ||
          elementBugget.Total_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Total_Estimado / this.project.tipoCambioGral;
          obj.color = 'azul';
        } else if (
          (segmentos.length === 2 && elementBugget.Subtotal_Estimado) ||
          elementBugget.Subtotal_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Subtotal_Estimado / this.project.tipoCambioGral;
          obj.color = 'amarillo';
        } else if (
          (segmentos.length === 3 && elementBugget.Importe_Estimado) ||
          elementBugget.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado / this.project.tipoCambioGral;
          obj.color = 'treeSegmentsWhitChild';
        } else if (
          (segmentos.length === 4 && elementBugget.Importe_Estimado) ||
          elementBugget.Importe_Estimado === 0
        ) {
          obj.presupuestoInicial =
            elementBugget.Importe_Estimado / this.project.tipoCambioGral;
          obj.color = 'verde';
        }
      }

      // SUMA Solicitudes Pagadas
      let sumaSolicitudes = 0;

      this.filterSolicitudes.forEach(elementSolicitudes => {
        if (
          elementSolicitudes.moneda === 'USD' &&
          this.project.monedaLocal === 'USD'
        ) {
          elementSolicitudes.valores.forEach(elementValores => {
            if (elementBugget.CodigoAgrupador === elementValores.partidaPres) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaSolicitudes +=
                  elementValores.importe * elementSolicitudes.TC;
              } else {
                sumaSolicitudes += elementValores.importe;
              }
            }
          });
        } else if (
          elementSolicitudes.moneda === 'MXN' &&
          this.project.monedaLocal === 'USD'
        ) {
          elementSolicitudes.valores.forEach(elementValores => {
            if (elementBugget.CodigoAgrupador === elementValores.partidaPres) {
              if (this.monedaSeleccionada === 'USD') {
                sumaSolicitudes +=
                  elementValores.importe / elementSolicitudes.TC;
              } else {
                sumaSolicitudes +=
                  elementValores.importe * elementSolicitudes.TC;
              }
            }
          });
        }
      });
      //

      // SUMA COMPROMETIDOS
      let sumaComprometidos = 0;
      for (let index = 0; index < this.filterOrders.length; index++) {
        const element = this.filterOrders[index];
        if (element.moneda === 'USD' && this.project.monedaLocal === 'USD') {
          element.comprometidos.forEach(elementComprometidos => {
            if (
              elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
            ) {
              if (this.monedaSeleccionada === 'MXN') {
                sumaComprometidos += elementComprometidos.importe * element.TC;
              } else {
                sumaComprometidos += elementComprometidos.importe;
              }
            }
          });
        } else if (
          element.moneda === 'MXN' &&
          this.project.monedaLocal === 'USD'
        ) {
          element.comprometidos.forEach(elementComprometidos => {
            if (
              elementBugget.CodigoAgrupador === elementComprometidos.partidaPres
            ) {
              if (this.monedaSeleccionada === 'USD') {
                sumaComprometidos += elementComprometidos.importe / element.TC;
              } else {
                sumaComprometidos += elementComprometidos.importe * element.TC;
              }
            }
          });
        }
      }

      obj.pagado = sumaSolicitudes;
      obj.comprometidos = sumaComprometidos;
      obj.actualComprometido = obj.comprometidos + obj.pagado;
      obj.disponible =
        obj.presupuestoInicial - obj.actualComprometido < 0
          ? 0
          : obj.presupuestoInicial - obj.actualComprometido;
      obj.estimadoCierre = obj.actualComprometido + obj.disponible;
      obj.variante = obj.presupuestoInicial - obj.estimadoCierre;

      this.dataTable.push(obj);
      obj = {};
    });
  }
}
