import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firebase } from 'firebase/firebase.js';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DealMemoService {
  EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  constructor(private afs: AngularFirestore) {}

  addDealMemo(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo')
      .add(obj);
  }
  reporteDealMemo(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo')
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  dealMemos(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo')
      .valueChanges();
  }
  deal(idCompany, idProject, CodigoAgrupador) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo', ref =>
        ref.where('cuentaPres', '==', CodigoAgrupador)
      )
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  reporteNumProveedor(idCompany, idProject, numeros) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo', ref => ref.where('proveedor', '==', numeros))
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  reporteRFC(idCompany, idProject, rfc) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo', ref => ref.where('rfcProveedor', '==', rfc))
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  getCountDealMemo(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados')
      .doc('countDealMemo')
      .valueChanges();
  }
  countDealMemo(idCompany, idProject, obj) {
    this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados')
      .doc('countDealMemo')
      .set(obj);
  }
  orderCount(idCompany, idProject, obj) {
    this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados')
      .doc('orderCount')
      .set(obj);
  }
  getOrderCount(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados')
      .doc('orderCount')
      .valueChanges();
  }
  updateOrderCount(idCompany, idProject, idPucharseOrder, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder')
      .doc(idPucharseOrder)
      .update(obj);
  }
  getPurchaseOrderRFC(idCompany, idProject, rfc) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder', ref => ref.where('rfc', '==', rfc))
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  getPurchaseOrderNum(idCompany, idProject, num) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder', ref => ref.where('orderCounter', '==', num))
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  addPurchaseOrder(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder')
      .add(obj);
  }

  findOrders(idCompany, idProject, cuenta) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder', ref =>
        ref.where('cuentas', 'array-contains', cuenta)
      )
      .valueChanges();
  }

  updateDealMemo(idCompany, idProject, idDealMemo, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo')
      .doc(idDealMemo)
      .set(obj);
  }
  updateDealMemo2(idCompany, idProject, idDealMemo, obj) {
    this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemo')
      .doc(idDealMemo)
      .update(obj);
  }
  getAcumulados(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados2')
      .doc('compromisos')
      .valueChanges();
  }
  getPurchaseOrder(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder')
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }

  // reporteNum(idCompany, idProject, numeros) {
  //   return this.afs
  //     .collection('empresas')
  //     .doc(idCompany)
  //     .collection('proyectos')
  //     .doc(idProject)
  //     .collection('purchaseOrder', ref => ref.where('proveedor', '==', numeros))
  //     .snapshotChanges()
  //     .pipe(
  //       map(action => {
  //         return action.map(a => {
  //           const data = a.payload.doc.data();
  //           data.id = a.payload.doc.id;
  //           return data;
  //         });
  //       })
  //     );
  // }

  updatePurchaseOrder(idCompany, idProject, id, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder')
      .doc(id)
      .update(obj);
  }
  updatePO(idCompany, idProject, id, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('purchaseOrder')
      .doc(id)
      .set(obj);
  }

  addAcumulados(idCompany, idProject, obj) {
    this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados2')
      .doc('compromisos')
      .set(obj);
  }

  saveDealMemoPublic(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemoPublic')
      .add(obj);
  }

  getDealMemoPublic(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemoPublic')
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(element => {
            const data = {} as any;
            data.dealMemo = element.payload.doc.data();
            data.id = element.payload.doc.id;
            return data;
          });
        })
      );
  }
  deleteDealMemoPublic(idCompany, idProject, idDoc) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('dealMemoPublic')
      .doc(idDoc)
      .delete();
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { DealMemos: worksheet },
      SheetNames: ['DealMemos'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION
    );
  }
}
