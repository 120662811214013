import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BudgetService } from 'src/app/services/budget.service';
import { ProjectService } from 'src/app/services/project.service';
import Notiflix from 'notiflix-angular';
import { DealMemo } from 'src/app/models/dealMemo';
import { ProvidersService } from 'src/app/services/providers.service';
import { EmpresasService } from 'src/app/services/empresas.service';

declare var $;

@Component({
  selector: 'app-payment-calendar',
  templateUrl: './payment-calendar.component.html',
  styleUrls: ['./payment-calendar.component.css'],
})
export class PaymentCalendarComponent implements OnInit {
  @Input() proveedor = {} as any;

  idCompany: string;
  idProject: string;

  budgetArrays = [] as any;
  budget = [] as any;
  departament = [] as any;
  ctasPresupuesto: any[];
  selectEtapa: any;
  objMovimientos = {} as any;
  periodos = [] as any;
  public dealMemo = {
    pagos: [],
  } as DealMemo;
  suma = 0;
  departamento;
  providerExtra;
  proveedor1;
  puesto;
  puestos = [];
  periodo = {} as any;
  dealSeleccionado: any;
  agregarCalendario = false;
  pagoSeleccionado = {} as any;
  pagosActualizado = {} as any;
  rfcReceptor: string;

  objMovimientosMasivo = {} as any;

  subscriberGetBudget: Subscription;
  subscriberGetPeriods: Subscription;
  subscriberGetProviders: Subscription;
  getCompanySubscription: Subscription;

  constructor(
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private providersService: ProvidersService,
    private empresaService: EmpresasService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getPeriods();
    this.getBudget();
    this.getCompany();
    console.log(this.proveedor);
    this.getProvidersExternos();
  }

  getCompany() {
    this.getCompanySubscription = this.empresaService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        this.rfcReceptor = res.rfc.toUpperCase();
        this.getCompanySubscription.unsubscribe();
      });
  }

  getProvidersExternos() {
    this.subscriberGetProviders = this.providersService
      .getProvidersPublic()
      .subscribe(res => {
        console.log('Este es el proveedorExtra', res);
        this.providerExtra = res;

        this.providerExtra.forEach(element => {
          this.proveedor1 = element;
          if (this.proveedor1.rfc === this.proveedor.rfc) {
            this.providerExtra = this.proveedor1.id;
          }
        });
      });
  }

  getPeriods() {
    this.subscriberGetPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodos = Object.assign([], res);
        this.periodos.forEach(element => {
          switch (element.etapa) {
            case 'DESARROLLO':
              this.periodo.desarrollo?.push(element);
              break;
            case 'PREPARACION':
              this.periodo.preparacion?.push(element);
              break;
            case 'RODAJE':
              this.periodo.rodaje?.push(element);
              break;
            case 'POST':
              this.periodo.post?.push(element);
              break;
            case 'ENTREGA':
              this.periodo.entrega?.push(element);
              break;
            case 'DOCUMENTAL':
              this.periodo.documental?.push(element);
              break;
          }
        });
        const arr = [
          this.periodo.desarrollo,
          this.periodo.preparacion,
          this.periodo.rodaje,
          this.periodo.post,
          this.periodo.entrega,
          this.periodo.documental,
        ];
      });
  }

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          this.getDepartament();
        }
      });
  }

  getDepartament() {
    this.departament = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element.Total_Estimado) {
        this.departament.push(element);
      }
    }
  }

  getPuesto() {
    // this.dealMemo.departamento = this.departamento.Descripcion;
    this.puestos = [];
    this.puesto = '';
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }

  getCtaPres() {
    // this.dealMemo.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];

      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 4) {
        this.ctasPresupuesto.push(element);
      }
    }
  }

  getNameCta(obj) {
    const elemento = this.ctasPresupuesto.find(
      element => element.CodigoAgrupador === obj.cuentaPres
    );
    obj.Descripcion = elemento ? elemento.Descripcion : 'Cuenta no valida';
  }

  changeAddMovement() {
    this.periodos.forEach(element => {
      if (this.objMovimientos.periodo === element.nombrePeriodo) {
        this.objMovimientos.fechaInicio = element.fechaInicio;
        this.objMovimientos.fechaFin = element.fechaFin;
      }
    });
  }

  agregarMovimiento() {
    const obj = this.objMovimientos;
    if (obj.cuentaPres && obj.importe && obj.periodo) {
      this.dealMemo.pagos.push(this.objMovimientos);
      this.objMovimientos = {};
      document.getElementById('cuentaPres').focus();
      this.totales();
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
  }

  totales() {
    this.suma = 0;
    this.dealMemo.pagos.forEach(element => {
      this.suma += element.importe;
    });
  }

  agregarMovimientos() {
    if (
      this.objMovimientosMasivo.cuentaPres &&
      this.objMovimientosMasivo.importe &&
      this.selectEtapa
    ) {
      let findPeriod = false;
      this.periodos.forEach(element => {
        if (this.selectEtapa === element.etapa) {
          findPeriod = true;
          let obj = {} as any;
          obj.cuentaPres = this.objMovimientosMasivo.cuentaPres;
          obj.Descripcion = this.objMovimientosMasivo.Descripcion;
          obj.importe = this.objMovimientosMasivo.importe;
          obj.periodo = element.nombrePeriodo;
          obj.fechaInicio = element.fechaInicio;
          obj.fechaFin = element.fechaFin;
          obj.status = 'Pendiente';
          this.dealMemo.pagos.push(obj);
        }
      });
      if (findPeriod) {
        this.objMovimientosMasivo = {};
        this.totales();
      } else {
        Notiflix.Notify.Failure(
          'No existen periodos registrados en esta etapa'
        );
      }
    } else {
      Notiflix.Notify.Failure('Completa todos los campos');
    }
  }

  deleteArr(arr: Array<any>, index: number) {
    arr.splice(index, 1);
    this.totales();
  }

  save() {
    if (this.dealMemo.pagos.length > 0) {
      this.dealMemo.idCompany = this.idCompany;
      this.dealMemo.idProject = this.idProject;
      this.dealMemo.rfcFilmadora = this.rfcReceptor;
      if (this.proveedor.dealMemos) {
        this.proveedor.dealMemos.push(this.dealMemo);
      } else {
        this.proveedor.dealMemos = [] as any;
        this.proveedor.dealMemos.push(this.dealMemo);
        this.dealMemo = {
          pagos: [],
        } as DealMemo;
        this.suma = 0;
      }

      console.log(this.proveedor);

      this.providersService
        .updateProviderExterno(this.proveedor.id, {
          dealMemos: this.proveedor.dealMemos,
        })
        .then(() => {
          Notiflix.Notify.Success('Actualizado correctamente');
        })
        .catch(err => {
          // console.error(err);
          Notiflix.Notify.Failure('Ocurrio un error');
        });
    } else {
      Notiflix.Notify.Failure('Agrega pagos antes de guardar');
    }
  }

  aproveFiles(item) {
    // console.log(item);
    item.status = 'Aprobado';
    if (item.motivo) {
      delete item.motivo;
    }
    this.providersService
      .updateProviderExterno(this.proveedor.id, {
        dealMemos: this.proveedor.dealMemos,
      })
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
      })
      .catch(err => {
        console.error(err);
        Notiflix.Notify.Failure('Ocurrio un error');
      });
    let dealmemos = this.proveedor.dealMemos;
    // console.log(dealmemos);
    for (let index = 0; index < dealmemos.length; index++) {
      const element = dealmemos[index];
      element.pagos.forEach(element1 => {
        this.pagosActualizado = element1;
        // console.log(this.pagosActualizado);
        if (this.pagosActualizado.actualizado) {
          this.CalendarioPagos();
          // console.log('todos los elementos estan actualizados');
        } else {
          console.log('todos los elementos deben estar actualizados');
        }
      });
    }
  }
  CalendarioPagos() {
    // console.log('providerCalendarioPagos === True');
    this.providersService
      .updateProviderExterno(this.proveedor.id, {
        providerCalendarios: true,
      })
      .then(() => {
        Notiflix.Notify.Success('Calendario de Pagos Completo');
      })
      .catch(err => {
        // console.error(err);
        Notiflix.Notify.Failure('Ocurrio un error');
      });
  }
  aprobar(obj) {
    obj.status = 'Aprobado';
    this.updateProvider();
  }

  revision(obj) {
    obj.status = 'En revisión';
    this.updateProvider();
  }

  rechazar(obj) {
    obj.status = 'Rechazado';
    this.updateProvider();
  }

  updateProvider() {
    this.providersService
      .updateProviderExterno(this.proveedor.id, {
        dealMemos: this.proveedor.dealMemos,
      })
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
      });
  }

  declineFiles(item) {
    // console.log(item);
    item.status = 'Rechazado';
    this.providersService
      .updateProviderExterno(this.proveedor.id, {
        dealMemos: this.proveedor.dealMemos,
      })
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
        $('#modalDecline').modal('hide');
      })
      .catch(err => {
        // console.error(err);
        Notiflix.Notify.Failure('Ocurrio un error');
      });
  }
}
