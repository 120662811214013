import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresasService } from 'src/app/services/empresas.service';
import { ProjectService } from 'src/app/services/project.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/services/general.service';
import { Proyecto } from 'src/app/models/proyectos';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Notiflix from 'notiflix-angular';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css'],
})
export class AddProjectComponent implements OnInit, OnDestroy {
  constructor(
    private empresasService: EmpresasService,
    private projectService: ProjectService,
    private authService: AuthService,
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: AngularFireStorage
  ) {}
  proyecto = {
    users: [] as any,
  } as Proyecto;
  proyectos = [] as any;
  editingProject = {} as Proyecto;
  index;

  file: any;
  uploadF: any;
  filePath: any;
  filmadoraSeleccionada;
  deletePro = {} as Proyecto;
  users = [] as any;
  usersDB = [] as any;
  idCompany;
  errMsg: string;
  filmadora = {} as any;

  proyectoSeleccionado = [] as any;
  usuarioSeleccionado = {} as any;

  monedaLocal = [
    {
      nombre: 'Peso Mexicano',
      codigo: 'MXN',
    },
    {
      nombre: 'Dólar estadounidense',
      codigo: 'USD',
    },
    {
      nombre: 'Euro',
      codigo: 'EUR',
    },
  ];

  subscriberGetProjects: Subscription;
  subscriberGetUsers: Subscription;

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[3].path;
    this.getProjects();
    this.getUsers();
  }

  getProjects() {
    this.subscriberGetProjects = this.empresasService
      .getProject(this.idCompany)
      .subscribe((proyectos: any) => {
        this.proyectos = proyectos;
      });
  }

  getUsers() {
    this.subscriberGetUsers = this.authService.getUser().subscribe(res => {
      this.users = res;
    });
  }

  validateInputAdd() {
    if (this.proyecto.name === undefined || this.proyecto.name === '') {
      Notiflix.Notify.Failure('Completa los campos requeridos');
    } else {
      if (
        this.proyecto.tipoCambioGral === undefined ||
        this.proyecto.tipoCambioGral === 0
      ) {
        this.proyecto.tipoCambioGral = 1;
      }
      $('.addProjectModal').modal('hide');
      this.addProject();
    }
  }
  addProject() {
    this.proyecto.users = [];
    this.empresasService.addProject(this.proyecto).then(res => {
      const path = res.path;
      const pathArr = path.split('/');
      const idEmpresa = pathArr[1];
      const idProyecto = pathArr[3];
      this.generalService.getCatCtas().subscribe(res2 => {
        const catCtas = res2;
        this.projectService.addCatCtas(idEmpresa, idProyecto, catCtas);
      });
    });
    this.proyecto = {
      users: [] as any,
    } as Proyecto;
    (<any>document.getElementById('image')).value = '';
    (<any>document.getElementById('labelFile')).innerHTML = 'Choose File';
  }

  openDelete(proyecto) {
    $('#modalDelete').modal('show');
    this.deletePro = proyecto;
  }
  deleteProject() {
    if (this.deletePro.users.length === 0) {
      $('#modalDelete').modal('hide');
      if (this.deletePro.refImage) {
        this.storage.ref(this.deletePro.refImage).delete();
      }
      this.empresasService.deleteProject(this.idCompany, this.deletePro);
    } else {
      Notiflix.Notify.Failure('Este proyecto tiene usuarios activos');
    }
  }
  validateInputEdit() {
    if (
      this.editingProject.name === undefined ||
      this.editingProject.name === ''
    ) {
      Notiflix.Notify.Failure('Completa los campos requeridos');
    } else {
      if (
        this.editingProject.tipoCambioGral === undefined ||
        this.editingProject.tipoCambioGral === 0
      ) {
        this.editingProject.tipoCambioGral = 1;
      }
      $('.editProjectModal').modal('hide');
      this.updateProject();
    }
  }
  editProject(proyecto) {
    $('#editProject').modal('show');
    this.editingProject = proyecto;
  }
  updateProject() {
    this.empresasService.updateProject(
      this.idCompany,
      this.editingProject.id,
      this.editingProject
    );
    (<any>document.getElementById('image')).value = '';
    (<any>document.getElementById('labelFileEdit')).innerHTML = 'Choose File';
    this.editingProject = {
      users: [] as any,
    };
  }
  uploadFile(event) {
    const fileInput = event.target.files[0];
    const fileType = fileInput.type;
    const fileSize = fileInput.size;
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(fileType) || fileSize >= 1000000) {
      Notiflix.Notify.Failure(
        'Por favor agrega unicamente archivos con extension .jpeg/.jpg/.png/.gif y tamaño maximo de 1MB'
      );
      this.uploadF = '';
      this.file = document.getElementById('labelFileEdit').innerHTML =
        'Choose file';
      this.file = document.getElementById('labelFile').innerHTML =
        'Choose file';
      this.file = (<any>document.getElementById('image')).value = '';
    } else {
      if (this.editingProject.refImage) {
        this.storage.ref(this.editingProject.refImage).delete();
      }
      this.uploadF = event.target.files[0];
      this.file = document.getElementById('labelFile').innerHTML =
        this.uploadF.name;
      this.file = document.getElementById('labelFileEdit').innerHTML =
        this.uploadF.name;
      const id = Math.random().toString(36).substring(2);
      const filePath = `proyectos/${id}`;
      const ref = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.uploadF);
      task.then(() => {
        const subs = ref.getDownloadURL().subscribe(url => {
          this.proyecto.image = url;
          this.proyecto.refImage = filePath;
          this.editingProject.image = url;
          this.editingProject.refImage = filePath;
          subs.unsubscribe();
        });
      });
      this.file = document.getElementById('labelFileEdit').innerHTML =
        this.uploadF.name;
      this.file = document.getElementById('labelFile').innerHTML =
        this.uploadF.name;
    }
  }
  openAddUser(proyecto) {
    this.proyectoSeleccionado = proyecto;
    $('#addUser').modal('show');
  }
  addUser() {
    let existe = false;
    this.proyectoSeleccionado.users.forEach(element => {
      if (element.email === this.usuarioSeleccionado.email) {
        existe = true;
      }
    });
    if (!existe) {
      this.proyectoSeleccionado.users.push(this.usuarioSeleccionado);
      const obj = {
        users: this.proyectoSeleccionado.users,
      };
      this.empresasService
        .updateProject(this.idCompany, this.proyectoSeleccionado.id, obj)
        .then(() => {
          Notiflix.Notify.Success('Se guardo exitosamente');
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrio un error al guardar el usuario');
          console.error('Ocurrio un error: ', err);
        });
    } else {
      Notiflix.Notify.Failure('Ya existe el usuario');
    }
  }
  deleteUser(index) {
    this.proyectoSeleccionado.users.splice(index, 1);
    const obj = {
      users: this.proyectoSeleccionado.users,
    };
    this.empresasService
      .updateProject(this.idCompany, this.proyectoSeleccionado.id, obj)
      .then(() => {
        Notiflix.Notify.Success('Se elimino exitosamente');
      })
      .catch(err => {
        Notiflix.Notify.Failure('Ocurrio un error al eliminar el usuario');
        console.error('Ocurrio un error: ', err);
      });
  }

  openFilmadora(proyecto) {
    this.proyectoSeleccionado = proyecto;
    $('#addFilmadora').modal('show');
  }
  addFilmadora() {
    if (this.filmadora.name && this.filmadora.rfc) {
      if (this.proyectoSeleccionado.filmadoras) {
        this.filmadora.rfc = this.filmadora.rfc.toUpperCase();
        this.filmadora.edit = false;

        this.proyectoSeleccionado.filmadoras.push(this.filmadora);
        this.filmadora = {};
      } else {
        this.proyectoSeleccionado.filmadoras = [] as any;
        this.proyectoSeleccionado.filmadoras.push(this.filmadora);
        console.log(this.proyectoSeleccionado.filmadoras);
        this.filmadora = {};
      }
      const obj = {
        filmadoras: this.proyectoSeleccionado.filmadoras,
      };
      this.empresasService
        .updateProject(this.idCompany, this.proyectoSeleccionado.id, obj)
        .then(() => {
          Notiflix.Notify.Success('Se guardo exitosamente');
        })
        .catch(err => {
          Notiflix.Notify.Failure('Ocurrio un error al guardar el usuario');
          console.error('Ocurrio un error: ', err);
        });
    } else {
      Notiflix.Notify.Failure('Completa el formulario ');
    }
  }
  editFilmadora(filmadora: any) {
    filmadora.edit = true;
    this.filmadoraSeleccionada = filmadora;
  }
  updateFilmadora(obj) {
    obj.edit = false;
    obj.rfc = obj.rfc.toUpperCase();

    this.empresasService
      .updateFilmadora(this.idCompany, this.proyectoSeleccionado.id, {
        filmadoras: this.proyectoSeleccionado.filmadoras,
      })
      .then(() => {
        Notiflix.Notify.Success('Se actualizo correctamente');
      })
      .catch(err => {
        Notiflix.Notify.Failure('Ocurrio un error al borrar la filmadora');
        console.error('Ocurrio un error: ', err);
      });
  }
  deleteFilmadoras(index) {
    this.proyectoSeleccionado.filmadoras.splice(index, 1);
    const obj = {
      filmadoras: this.proyectoSeleccionado.filmadoras,
    };
    this.empresasService
      .updateProject(this.idCompany, this.proyectoSeleccionado.id, obj)
      .then(() => {
        Notiflix.Notify.Success('Se Borro exitosamente');
      })
      .catch(err => {
        Notiflix.Notify.Failure('Ocurrio un error al borrar la filmadora');
        console.error('Ocurrio un error: ', err);
      });
  }

  ngOnDestroy() {
    this.subscriberGetProjects.unsubscribe();
  }
}
