import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

interface Firma {
  nombre?: string;
  puesto?: string;
  tel?: string;
  email?: string;
}

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css'],
})
export class FirmaComponent implements OnInit {
  users = [
    {
      nombre: 'Jorge Eduardo Grijalva Estrada',
      puesto: 'TI',
      tel: '(55) 6368 2544',
      email: 'jgrijalva@enconexion.com.mx',
    },
    {
      nombre: 'Alejandro Álvarez Cerón',
      puesto: 'Socio',
      tel: '(55) 2729 1263',
      email: 'aalvarez@enconexion.com.mx',
    },
    {
      nombre: 'Alam Canales Ramírez',
      puesto: 'Contabilidad de Producción',
      tel: '(55) 5161 1084',
      email: 'acanales@enconexion.com.mx',
    },
    {
      nombre: 'Juan Arturo Sosa Mercado',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'asosa@enconexion.com.mx',
    },
    {
      nombre: 'Cristian Alberto Álvarez Guadarrama',
      puesto: 'Contabilidad de Producción',
      tel: '(55) 5161 1084',
      email: 'calvarez@enconexion.com.mx',
    },
    {
      nombre: 'Diego Armando Uribe Pérez',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'duribe@enconexion.com.mx',
    },
    {
      nombre: 'Elizabeth Álvarez Grijalva',
      puesto: 'Abogada',
      tel: '(55) 3421 3753',
      email: 'ealvarez@enconexion.com.mx',
    },
    {
      nombre: 'Eduardo Paz Concha',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'epaz@enconexion.com.mx',
    },
    {
      nombre: 'Info',
      puesto: '',
      tel: '(55) 5161 1084',
      email: 'info@enconexion.com.mx',
    },
    {
      nombre: 'Janeth Itzel Díaz Martínez',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'jdiaz@enconexion.com.mx',
    },
    {
      nombre: 'Jessica Sarai Hernández Chaires',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'jhernandez@enconexion.com.mx',
    },
    {
      nombre: 'Jacqueline López Torres',
      puesto: 'Contabilidad de Producción',
      tel: '(55) 5161 1084',
      email: 'jlopez@enconexion.com.mx',
    },
    {
      nombre: 'Jaime Masso Cesáreo',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'jmasso@enconexion.com.mx',
    },
    {
      nombre: 'Montserrat Escamilla Sandoval',
      puesto: 'Contabilidad de Producción',
      tel: '(55) 5161 1084',
      email: 'mescamilla@enconexion.com.mx',
    },
    {
      nombre: 'Nashely Alejandra Rueda Vargas',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'nrueda@enconexion.com.mx',
    },
    {
      nombre: 'Páris García Navas',
      puesto: 'Contabilidad de Producción',
      tel: '(55) 5161 1084',
      email: 'paris@enconexion.com.mx',
    },
    {
      nombre: 'Juan Pablo Florentino Espinosa',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'pflorentino@enconexion.com.mx',
    },
    {
      nombre: 'Paola Peña Rivera',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'privera@enconexion.com.mx',
    },
    {
      nombre: 'Ruth Ruiz García',
      puesto: 'Contabilidad de Producción',
      tel: '(55) 5161 1084',
      email: 'rruiz@enconexion.com.mx',
    },
    {
      nombre: 'Veronica Cuanalo',
      puesto: 'Contable y Fiscal',
      tel: '(55) 5161 1084',
      email: 'vcuanalo@enconexion.com.mx',
    },
  ];

  public dataUser = {} as Firma;

  firma = false;

  private addData = {} as any;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const email = this.router.parseUrl(this.router.url).root.children.primary
      .segments[1].path;
    const index = this.users.findIndex(val => val.email === email);
    if (index >= 0) {
      this.dataUser = this.users[index];
      this.firma = true;
    } else {
      this.firma = false;
      this.addData.email = email;
    }
  }

  generar() {
    this.addData.tel = '(55) 5161 1084';
    this.dataUser = this.addData;
    this.firma = true;
  }
}
