import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { PurchaseOrderComponent } from '../purchase-order/purchase-order.component';

declare var $;

@Component({
  selector: 'app-consult-orden-compra',
  templateUrl: './consult-orden-compra.component.html',
  styleUrls: ['./consult-orden-compra.component.css'],
})
export class ConsultOrdenCompraComponent implements OnInit {
  idCompany: string;
  idProject: string;
  purchaseOrder: Array<any>;
  numeros = {} as any;
  dealMemoSeleccionado = {} as any;
  purchaseOrderSeleccionado = {} as any;
  consultarPor: any;
  sumaPagos = 0;

  subscriptionGetDealMemo: Subscription;

  @ViewChild(PurchaseOrderComponent)
  purchaseOrderComponent: PurchaseOrderComponent;
  constructor(
    private dealMemoService: DealMemoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
  }

  getOrdenCompra() {
    this.purchaseOrder = [];
    this.subscriptionGetDealMemo = this.dealMemoService
      .getPurchaseOrder(this.idCompany, this.idProject)
      .subscribe(res => {
        this.purchaseOrder = this.purchaseOrder = res.sort((a, b) => {
          return a.orderCount - b.orderCount;
        });
      });
  }
  // reporteNumero() {
  //   this.purchaseOrder = [];
  //   this.subscriptionGetDealMemo = this.dealMemoService
  //     .reporteNum(this.idCompany, this.idProject, this.numeros)
  //     .subscribe(res => {
  //       this.purchaseOrder = this.purchaseOrder = res.sort((a, b) => {
  //         return a.orderCount - b.orderCount;
  //       });
  //       for (let index = 0; index < this.purchaseOrder.length; index++) {
  //         const order = this.purchaseOrder[index];
  //         let suma = 0;
  //         order.pagos.forEach(element => {
  //           suma += element.importe;
  //         });
  //         order.valorDeal = suma;
  //       }
  //     });
  // }

  editOrdenCompra(ordenCompra) {
    console.log(ordenCompra);
    this.purchaseOrderSeleccionado = ordenCompra;
    this.sumaPagos = 0;
    ordenCompra.pagos.forEach(element => {
      this.sumaPagos += element.importe;
    });
    this.purchaseOrderComponent.purchaseOrder = ordenCompra;
    this.purchaseOrderComponent.totales();
    this.purchaseOrderComponent.orderCount = ordenCompra.orderCount;
    $('#editOrden').modal('show');
  }

  pagos(dealMemo) {
    console.log(dealMemo);
    $('#pagos').modal('show');
    this.dealMemoSeleccionado = dealMemo;
    this.sumaPagos = 0;
    dealMemo.pagos.forEach(element => {
      this.sumaPagos += element.importe;
    });
  }
  prueba(pago) {
    console.log(pago);
  }
}
