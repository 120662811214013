import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Comprobaciones } from 'src/app/models/comprobaciones';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectService } from 'src/app/services/project.service';
import { NgxXml2jsonService } from 'ngx-xml2json';
import Notiflix from 'notiflix-angular';

@Component({
  selector: 'app-reembolso',
  templateUrl: './reembolso.component.html',
  styleUrls: ['./reembolso.component.css'],
})
export class ReembolsoComponent implements OnInit {
  dataUser = this.generalService.dataUser;
  idCompany = this.dataUser.idCompany;
  idProject = this.dataUser.idProject;
  xml = {} as any;
  comprobacion = {
    comprobantes: [],
    deducible: 'si',
  } as Comprobaciones;
  arrXML = [] as any;
  departament = {} as any;
  cuentas = [] as any;
  counter = 0;
  totales = {} as any;
  partidaGeneral: any;

  subscribeGetCFDI: Subscription;
  subscribeGetDepartament: Subscription;

  constructor(
    private generalService: GeneralService,
    private projectService: ProjectService,
    private ngxXml2jsonService: NgxXml2jsonService
  ) {}

  ngOnInit(): void {
    this.idCompany = this.dataUser.idCompany;
    this.idProject = this.dataUser.idProject;
    this.getXML();
    this.comprobacion.nombre = this.dataUser.name;
    this.comprobacion.proyecto = this.dataUser.nameProject;
    this.comprobacion.departamento = this.dataUser.departamento;
    this.getDepartament();
    // this.getGXC();
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXMLPublic(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.arrXML = res.arrXML;
        } else {
          this.arrXML = [];
        }
      });
  }

  getDepartament() {
    this.subscribeGetDepartament = this.projectService
      .getGroup(this.idCompany, this.idProject, this.dataUser.idDepartament)
      .subscribe((res: any) => {
        console.log(res);
        this.departament = res;
        this.comprobacion.departamento = res.name;
        this.cuentas = res.cuentas;
        if (res.countCheck) {
          this.counter = res.countCheck + 1;
        } else {
          this.counter = 1;
        }
      });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    (<any>document.getElementById('inputFiles')).value = '';
  }

  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.validarSiExiste(obj);
  }

  validarSiExiste(obj) {
    const folio =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === folio
    );
    const indexXMLLocal = this.comprobacion.comprobantes.findIndex(
      element => element.folioComprobante === folio
    );
    if (indexXML === -1 && indexXMLLocal === -1) {
      if (!obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
        // this.selectProvider(obj);
        this.assignData(obj);
        // this.validacionXML = true;
      } else {
        Notiflix.Notify.Failure('El CFDI no debe tener retenciones');
        // Notiflix.Notify.Failure('El RFC receptor no corresponde a la empresa');
      }
    } else {
      Notiflix.Notify.Failure(`El folio ${folio} ya se encuentra registrado.`);
    }
  }

  assignData(obj: any) {
    if (obj['cfdi:Comprobante']) {
      try {
        // this.xml.comprobacion = this.comprobacion.countCheck;
        this.xml.asociado = true;
        this.xml.proveedor =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

        this.xml.rfc =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

        this.xml.regimen =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

        this.xml.rfcReceptor =
          obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc;

        // Valido si es array o un objeto
        if (
          Array.isArray(
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
          )
        ) {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveUnidad;
        } else {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveUnidad;
        }
        // -------------------------------------

        this.xml.folioComprobante =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;

        this.xml.fecha = obj['cfdi:Comprobante']['@attributes'].Fecha;

        this.xml.subtotal = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].SubTotal
        );

        this.xml.descuento =
          parseFloat(obj['cfdi:Comprobante']['@attributes'].Descuento) || 0;

        this.xml.tipoComprobante =
          obj['cfdi:Comprobante']['@attributes'].TipoDeComprobante;

        this.xml.metodoPago =
          obj['cfdi:Comprobante']['@attributes'].MetodoPago || '';

        this.xml.formaPago =
          obj['cfdi:Comprobante']['@attributes'].FormaPago || '';

        this.xml.moneda = obj['cfdi:Comprobante']['@attributes'].Moneda;

        this.xml.total = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].Total
        );

        // Validacion si tiene impuestos
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']) {
          // impuestos trasladados
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados']) {
            this.xml.iva = parseFloat(
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados'][
                'cfdi:Traslado'
              ]['@attributes'].Importe
            );
          }

          // retenciones
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
            const retenciones =
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones'][
                'cfdi:Retencion'
              ];
            const esArrayRetenciones = Array.isArray(retenciones);
            if (esArrayRetenciones) {
              retenciones.forEach(element => {
                if (element['@attributes'].Impuesto === '002') {
                  this.xml.retIVA = parseFloat(element['@attributes'].Importe);
                } else if (element['@attributes'].Impuesto === '001') {
                  this.xml.retISR = parseFloat(element['@attributes'].Importe);
                }
              });
            } else {
              if (retenciones['@attributes'].Impuesto === '002') {
                this.xml.retIVA = parseFloat(
                  retenciones['@attributes'].Importe
                );
              } else if (retenciones['@attributes'].Impuesto === '001') {
                this.xml.retISR = parseFloat(
                  retenciones['@attributes'].Importe
                );
              }
            }
          }
        }

        this.xml.solicitudes = [] as any;

        // this.arrXML.push(this.xml);
        this.xml.inventario = 'No';
        this.xml.CodigoAgrupador = this.partidaGeneral;
        this.comprobacion.comprobantes.push(this.xml);
        this.xml = {};
      } catch (error) {
        console.error('Ocurrio un error', error);
      }
    }
    console.log(this.comprobacion);
    this.sumas();
    this.totalesFunction();
  }

  totalesFunction() {
    this.totales.totalImporte = 0;
    this.totales.totalComprobado = 0;
  }

  sumas() {
    this.totales.importe = 0;
    this.totales.iva = 0;
    this.totales.total = 0;
    this.comprobacion.comprobantes.forEach(element => {
      this.totales.importe += element.subtotal;
      this.totales.iva += element.iva;
      this.totales.total += element.total;
    });
    this.comprobacion.importe = this.totales.importe;
    this.comprobacion.iva = this.totales.iva ? this.totales.iva : 0;
    this.comprobacion.total = this.totales.total;
  }

  changeCtaGral() {
    if (this.comprobacion.comprobantes.length > 0) {
      this.comprobacion.comprobantes.forEach(element => {
        element.CodigoAgrupador = this.partidaGeneral;
      });
    }
  }

  ngOnDestroy() {
    this.subscribeGetCFDI.unsubscribe();
    this.subscribeGetDepartament.unsubscribe();
  }
}
