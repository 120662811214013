import { Component, OnInit, OnDestroy } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import * as XLSX from 'xlsx';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';

var TREE_DATA = [
  {
    name: 'Fruit',
    children: [{ name: 'Apple' }, { name: 'Banana' }, { name: 'Fruit loops' }],
  },
  {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [{ name: 'Broccoli' }, { name: 'Brussels sprouts' }],
      },
      {
        name: 'Orange',
        children: [{ name: 'Pumpkins' }, { name: 'Carrots' }],
      },
    ],
  },
];

@Component({
  selector: 'app-cat-ctas',
  templateUrl: './cat-ctas.component.html',
  styleUrls: ['./cat-ctas.component.css'],
})
export class CatCtasComponent implements OnInit, OnDestroy {
  sheetName: any;
  dataTable: any;
  getCtas: any = [];
  arr = [];
  hasChild: any;

  subscriberCatCtas;

  private _transformer = (node, level) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.CodigoAgrupador + '  ' + node.descripcion,
      obj: node,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private generalService: GeneralService) {}

  ngOnInit() {
    this.getCatCtas();
  }
  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        this.sheetName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.dataTable = jsonData[this.sheetName];
      console.log(this.dataTable);
      // this.getCtas = this.dataTable;
      this.asignarCodigoAgrupador(this.dataTable);
    };
    setTimeout(() => {
      reader.readAsBinaryString(file);
    }, 500);
  }

  asignarCodigoAgrupador(arr) {
    const newArr = [];
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.nivel === 1) {
        element.children = [];
        element.path = `${newArr.length}`;
        const segments = element.CodigoAgrupadorSAT.toString().split('.');
        if (segments.length > 1) {
          element.CodigoAgrupador =
            segments[0] + '-' + segments[1].padEnd(2, 0);
        } else {
          element.CodigoAgrupador = segments[0];
        }
        for (let index2 = index + 1; index2 < arr.length; index2++) {
          const element2 = arr[index2];
          const segments2 = element2.CodigoAgrupadorSAT.toString().split('.');
          if (segments2.length > 1) {
            element2.CodigoAgrupador =
              segments2[0] + '-' + segments2[1].padEnd(2, 0);
          } else {
            element2.CodigoAgrupador = segments2[0];
          }
          if (element2.nivel === 2) {
            element2.children = [];
            element2.path = `[${element.path}].children[${element.children.length}]`;
            for (let index3 = index2 + 1; index3 < arr.length; index3++) {
              const element3 = arr[index3];
              const segments3 = element3.CodigoAgrupadorSAT.toString().split(
                '.'
              );
              if (segments3.length > 1) {
                element3.CodigoAgrupador =
                  segments3[0] + '-' + segments3[1].padEnd(2, 0);
              } else {
                element3.CodigoAgrupador = segments3[0];
              }
              if (segments3[0] === segments2[0]) {
                element3.children = [];
                element3.path = `[${element.path}].children[${element.children.length}].children[${element2.children.length}]`;
                element2.children.push(element3);
              }
            }
            if (segments2[0] < parseInt(segments[0]) + 100) {
              element.children.push(element2);
            }
          }
        }
        newArr.push(element);
      }
    }
    console.log(newArr);
    this.saveCatCtas({ catalogo: newArr });
  }

  getCatCtas() {
    this.subscriberCatCtas = this.generalService
      .getCatCtas()
      .subscribe((res: any) => {
        // console.log(res);
        if (res) {
          this.dataSource.data = res.catalogo;
        } else {
          this.dataSource.data = [];
        }
      });
  }

  saveCatCtas(obj) {
    this.generalService
      .addCatCtas(obj)
      .then(() => {
        console.log('guardado exitoso');
      })
      .catch(err => console.error(err));
  }
  deleteCatCtas() {
    this.generalService.deleteCatCtas();
  }

  ngOnDestroy() {
    this.subscriberCatCtas.unsubscribe();
  }
}
