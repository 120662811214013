import { Component, OnInit, OnDestroy } from '@angular/core';
import Notiflix from 'notiflix-angular';
import * as XLSX from 'xlsx';
import { ProvidersService } from 'src/app/services/providers.service';
import { Subscription } from 'rxjs';
import { Request } from '../../models/request';
import { BudgetService } from 'src/app/services/budget.service';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-request-massive',
  templateUrl: './request-massive.component.html',
  styleUrls: ['./request-massive.component.css'],
})
export class RequestMassiveComponent implements OnInit, OnDestroy {
  sheetName;
  dataTable;
  addDataDB;
  providers = [] as any;
  request: Request;
  listRequest = [] as any;
  budget;
  periods;
  countRequest: number;
  folios = [] as any;

  idCompany: string;
  idProject: string;

  budgetArrays = [] as any;
  arrCountRequest = [] as any;

  subscriberProvider: Subscription;
  subscriberBudget: Subscription;
  subscriberPeriods: Subscription;
  subscriberCountRequest: Subscription;
  subscriberFolios: Subscription;

  constructor(
    private router: Router,
    private providerService: ProvidersService,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private requestService: RequestService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getProviders();
    this.getBudget();
    this.getPeriods();
    this.getCountRequest();
    this.getXML();
  }

  getProviders() {
    this.subscriberProvider = this.providerService
      .getProvider()
      .subscribe(res => {
        this.providers = Object.assign([], res);
      });
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
        }
      });
  }

  getPeriods() {
    this.subscriberPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
      });
  }

  getCountRequest() {
    this.subscriberCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res === undefined) {
          this.countRequest = 1;
          // this.request.countRequest = this.countRequest;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
        }
      });
  }

  getXML() {
    this.subscriberFolios = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.folios = res.arrXML;
        }
      });
  }

  onFileChange(ev) {
    this.dataTable = [];
    const file = ev.target.files[0];
    const reader = new FileReader();
    let workBook = null;
    let jsonData = null;
    if (file.name === 'Carga Batch Solicitudes ENCONEXION.xlsx') {
      Notiflix.Loading.Dots('Cargando...');
      reader.onload = event => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });

        // Esto lo ejecuto si solo requiero una hoja en especifico
        const jsonData = XLSX.utils.sheet_to_json(
          workBook.Sheets['Solicitudes'],
          {
            blankrows: false,
            dateNF: 0,
          }
        );
        this.sheetName = 'Solicitudes'; // Este toma una hoja en especifico del excel
        this.dataTable = jsonData;

        // <-----------------------------
        // Esto lo ejecuto si requiero todas las hojas del Excel
        // jsonData = workBook.SheetNames.reduce((initial, name) => {
        //   console.log(name);
        //   // this.sheetName = name; Este toma la ultima hoja del excel
        //   this.sheetName = 'Solicitudes'; // Este toma una hoja en especifico del excel
        //   const sheet = workBook.Sheets[name];
        //   initial[name] = XLSX.utils.sheet_to_json(sheet);
        //   console.log(initial);
        //   return initial;
        // }, {});
        // ---------------------------------->

        // this.dataTable = jsonData[this.sheetName]; // Aqui guardo solo la hoja que requiero
        console.log(this.dataTable);
        Notiflix.Loading.Remove();
        this.validationData();

        // if (
        //   this.dataTable[0].numeroSolicitud > this.countRequest &&
        //   this.dataTable[0].numeroSolicitud === this.countRequest + 1
        // ) {
        // } else {
        //   (<any>document.getElementById('inputFile')).value = '';
        //   Notiflix.Notify.Failure(
        //     'Ya existen solicitudes con el mismo numero o no es consecutivo'
        //   );
        // }
      };
    } else {
      Notiflix.Notify.Failure('No se permite otro archivo');
      (<any>document.getElementById('inputFile')).value = '';
    }
    setTimeout(() => {
      reader.readAsBinaryString(file);
    }, 500);
  }

  validationData() {
    this.listRequest = [];
    for (let index = 0; index < this.dataTable.length; index++) {
      const elementRequest = this.dataTable[index];

      let validationCountRequest = true;
      let validationProvider = false;
      let validationCuenta = false;
      let validationPeriod = false;
      let validationFolio = false;

      // Busco el numero de solicitud en el array countRequest de las solicitudess ya registradas
      for (let indexCR = 0; indexCR < this.arrCountRequest.length; indexCR++) {
        const elementCR = this.arrCountRequest[indexCR];
        if (elementRequest.numeroSolicitud === elementCR) {
          validationCountRequest = false;
          Notiflix.Notify.Failure(
            `La solicitud ${elementCR} ya se encuentra registrada`
          );
          break;
        }
      }

      // Busco el proveedor en la base general
      for (let index2 = 0; index2 < this.providers.length; index2++) {
        const elementProvider = this.providers[index2];
        if (
          elementRequest.rfc === elementProvider.rfc ||
          elementRequest.rfc === elementProvider.taxId
        ) {
          if (
            elementRequest.rfc !== 'XEXX010101000' ||
            elementRequest.rfc !== 'XAXX010101000'
          ) {
            validationProvider = true;
            elementRequest.numeroAcreedor = elementProvider.numero;
            elementRequest.providerName = elementProvider.nombre;
            elementRequest.nacionalidad = elementProvider.nacionalidad;
            break;
          } else {
            validationProvider = true;
            elementRequest.numeroAcreedor = elementProvider.numero;
            elementRequest.providerName = elementProvider.nombre;
            elementRequest.nacionalidad = elementProvider.nacionalidad;
          }
        }
      }

      if (!validationProvider) {
        Notiflix.Report.Failure(
          'Ocurrio un error',
          `No se encontro el proveedor de la solicitud ${elementRequest.numeroSolicitud}`,
          'Cerrar'
        );
        break;
      }

      // busco la cuenta
      if (
        elementRequest.tipoSolicitud === 'GXC' ||
        elementRequest.codigoAgrupador === 'CANCELADO' ||
        elementRequest.tipoSolicitud === 'Anexo'
      ) {
        validationCuenta = true;
      } else {
        const segmentsCtaRequest = elementRequest.codigoAgrupador.split('-');
        if (segmentsCtaRequest.length === 3) {
          for (let index3 = 0; index3 < this.budget.length; index3++) {
            const elementBudget = this.budget[index3];
            const segments = elementBudget.CodigoAgrupador.split('-');
            let departamento;
            if (elementBudget.CodigoAgrupador === segments[0]) {
              departamento = elementBudget;
              elementRequest.departamento = departamento.Descripcion;
            }
            let puesto;
            if (
              elementBudget.CodigoAgrupador ===
              segments[0] + '-' + segments[1]
            ) {
              puesto = elementBudget;
              elementRequest.puesto = puesto.Descripcion;
            }
            if (
              elementRequest.codigoAgrupador === elementBudget.CodigoAgrupador
            ) {
              elementRequest.nombreCuenta = elementBudget.Descripcion;
              validationCuenta = true;
              break;
            }
          }
        }

        if (!validationCuenta) {
          Notiflix.Report.Failure(
            'Ocurrio un error',
            `No se encontro la cuenta a nivel detalle de la solicitud ${elementRequest.numeroSolicitud}`,
            'Cerrar'
          );
          break;
        }
      }

      // Busco el periodo
      for (let index4 = 0; index4 < this.periods.length; index4++) {
        const elementPeriods = this.periods[index4];
        if (elementRequest.periodo === elementPeriods.nombrePeriodo) {
          validationPeriod = true;
          elementRequest.fechaIni = elementPeriods.fechaInicio;
          elementRequest.fechaFin = elementPeriods.fechaFin;
          elementRequest.etapa = elementPeriods.etapa;
          break;
        }
      }
      if (!validationPeriod) {
        Notiflix.Report.Failure(
          'Ocurrio un error',
          `No se encontro el periodo de la solicitud ${elementRequest.numeroSolicitud}`,
          'Cerrar'
        );
        break;
      }

      console.log(
        'Proveedor: ' + validationProvider,
        'Cuenta: ' + validationCuenta,
        'Periodo: ' + validationPeriod,
        'Folio: ' + validationFolio,
        'CountRequest: ' + validationCountRequest
      );

      // Valido que todo sea true.
      if (
        validationProvider &&
        validationCuenta &&
        validationPeriod &&
        // validationFolio &&
        validationCountRequest
      ) {
        this.listRequest.push(elementRequest);
      } else {
        (<any>document.getElementById('inputFile')).value = '';
      }
    }
  }

  // Convertidor de numero a Fecha (Excel envia un numero equivalente a una fecha).
  numeroAFecha(numeroDeDias, esExcel) {
    var diasDesde1900 = esExcel ? 25567 + 1 : 25567;
    // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
    return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
  }

  clear() {
    (<any>document.getElementById('inputFile')).value = '';
    this.listRequest = [];
  }

  confirmLoadRequest() {
    Notiflix.Confirm.Show(
      'Confirmacion de carga masiva',
      `Se cargaran ${this.listRequest.length} solicitudes`,
      'Aceptar',
      'Cancelar',
      () => {
        this.transformRequest();
      }
    );
  }

  transformRequest() {
    Notiflix.Loading.Dots('Cargando...');
    for (let index = 0; index < this.listRequest.length; index++) {
      const e = this.listRequest[index];
      const obj = {} as Request;
      obj.countRequest = e.numeroSolicitud;
      obj.numProvider = e.numeroAcreedor;
      obj.providerName = e.providerName;
      obj.concepto = e.concepto;
      obj.fechaCreado = e.fechaSolicitud;
      obj.fechaCreado = this.numeroAFecha(e.fechaSolicitud, 1);
      obj.fechaIni = e.fechaIni;
      obj.fechaFin = e.fechaFin;
      obj.tipoPago = e.formaDePago;
      obj.solicito = e.solicito;
      obj.tipoSolicitud = e.tipoSolicitud;
      obj.folioComp = e.folioDelComprobante || '';
      if (e.fechaPagado) {
        obj.fechaComp = e.fechaPagado;
        obj.fechaComp = this.numeroAFecha(e.fechaPagado, 1);
      }
      obj.periodo = e.periodo;
      obj.etapa = e.etapa;
      obj.departamento = e.departamento || '';
      obj.puesto = e.puesto || '';
      obj.tipoMoneda = e.moneda;
      obj.tipoCambio = e.tipoDeCambio;
      obj.aprobada = e.estatus === 'PAGADA' ? true : false;
      obj.estatus = e.estatus === 'PAGADA' ? true : false;
      if (e.numeroTransferencia) {
        obj.numTransferencia = e.numeroTransferencia;
      }
      obj.movimientos = [] as any;
      const objImporte = {} as any;

      if (e.tipoSolicitud === 'GXC') {
        objImporte.Cargo = parseFloat(e.importe.toFixed(2));
        objImporte.Cuenta =
          '9999 -' + e.numeroAcreedor.toString().padStart(4, '0');
        objImporte.NombreCuenta = 'GXC';
      } else if (e.tipoSolicitud === 'Anexo') {
        objImporte.Cargo = parseFloat(e.importe.toFixed(2));
        objImporte.Cuenta = 'ANEXO';
        objImporte.NombreCuenta = 'ANEXO';
      } else if (e.codigoAgrupador === 'CANCELADO') {
        objImporte.Cargo = parseFloat(e.importe.toFixed(2));
        objImporte.Cuenta = '0000';
        objImporte.NombreCuenta = 'CANCELADO';
      } else {
        objImporte.Cargo = parseFloat(e.importe.toFixed(2));
        objImporte.Cuenta = e.codigoAgrupador;
        objImporte.NombreCuenta = e.nombreCuenta;
      }
      obj.movimientos.push(objImporte);
      if (e.iva) {
        const objIva = {} as any;
        objIva.Cargo = parseFloat(e.iva.toFixed(2));
        objIva.Cuenta = '119-01-001';
        objIva.NombreCuenta = 'IVA a favor';
        obj.movimientos.push(objIva);
      }
      if (e.retIsr) {
        const objIsr = {} as any;
        objIsr.Abono = parseFloat(e.retIsr.toFixed(2));
        objIsr.Cuenta = '216-06-001';
        objIsr.NombreCuenta = 'Retencion de ISR';
        obj.movimientos.push(objIsr);
      }
      if (e.retIva) {
        const objRetIva = {} as any;
        objRetIva.Abono = parseFloat(e.retIva.toFixed(2));
        objRetIva.Cuenta = '216-10-001';
        objRetIva.NombreCuenta = 'Retencion de IVA';
        obj.movimientos.push(objRetIva);
      }
      // if (e.total) {
      const objTotal = {} as any;
      objTotal.Abono = parseFloat(e.total.toFixed(2));
      objTotal.Cuenta =
        e.nacionalidad === 'Mexicana'
          ? `201-01-${e.numeroAcreedor.toString().padStart(3, '0')}`
          : `201-02-${e.numeroAcreedor.toString().padStart(3, '0')}`;
      objTotal.NombreCuenta = e.providerName;
      obj.movimientos.push(objTotal);
      // }
      console.log(obj);
      this.loadRequests(obj);
      this.arrCountRequest.push(obj.countRequest);
    }
    const tiempo = this.listRequest.length * 200;
    this.listRequest = [];
    Notiflix.Loading.Remove(tiempo);
    this.updateCountRequest();
  }

  loadRequests(request: Request) {
    this.requestService
      .addRequest(this.idCompany, this.idProject, request)
      .then(res => {})
      .catch(err => {
        console.error('Ocurrio un error ', err);
      });
  }

  updateCountRequest() {
    this.requestService.countRequest(
      this.idCompany,
      this.idProject,
      this.arrCountRequest
    );

    // Si esta desuscrito se vuelve a subscribir
    if (this.subscriberCountRequest) {
      this.getCountRequest();
    }
  }

  updateFoliosFiscales() {
    const objFolios = { arrXML: this.folios };
    this.projectService.saveXML(this.idCompany, this.idProject, objFolios);
  }

  ngOnDestroy() {
    this.subscriberProvider.unsubscribe();
    this.subscriberBudget.unsubscribe();
    this.subscriberPeriods.unsubscribe();
    this.subscriberCountRequest.unsubscribe();
    this.subscriberFolios.unsubscribe();
  }
}
