import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Subscription } from 'rxjs';
declare var JQuery;
declare var $;

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  dataUser = {} as User;
  proyectos = [];
  isAdmin: boolean;
  idCompany;
  ruta;

  imgProy = [];
  sinProyectos = false;
  userType;

  subscriberURL: Subscription;
  subscriberRoleUser: Subscription;
  subscriberGetProjects: Subscription;
  subscriberGetDashboard: Subscription;

  constructor(
    private empresasService: EmpresasService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.ruta = this.router.parseUrl(this.router.url);
    this.subscriberURL = this.route.params.subscribe(params => {
      this.idCompany = params['id'];
    });

    this.authService.isLogin().then(result => {
      this.dataUser = result;
      this.subscriberRoleUser = this.authService
        .roleUser(this.dataUser.uid)
        .subscribe(res => {
          const idUser = res[0].id;
          this.userType = res[0].userType;
          if (this.userType === 'Administrador') {
            this.subscriberGetProjects = this.empresasService
              .getProject(this.idCompany)
              .subscribe(proyectos => {
                this.sinProyectos = true;
                this.proyectos = proyectos;
              });
          } else {
            this.subscriberGetDashboard = this.empresasService
              .getProjectFil(this.idCompany, idUser)
              .subscribe(proyectos => {
                if (proyectos.length === 0) {
                  this.sinProyectos = false;
                } else {
                  this.sinProyectos = true;
                  this.proyectos = proyectos;
                }
              });
          }
        });
    });
  }
  toggle() {
    $('#sidebar').toggleClass('active');
    $('.overlay').toggleClass('active');
  }
  ngOnDestroy() {
    if (this.subscriberURL) {
      this.subscriberURL.unsubscribe();
    }
    if (this.subscriberRoleUser) {
      this.subscriberRoleUser.unsubscribe();
    }
    if (this.subscriberGetProjects) {
      this.subscriberGetProjects.unsubscribe();
    }
    if (this.subscriberGetDashboard) {
      this.subscriberGetDashboard.unsubscribe();
    }
  }
}
