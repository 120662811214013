import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeModule } from './layout/home/home.module';
import { RequestComponent } from './pages/request/request.component';
import { AddCompanyComponent } from './pages/add-company/add-company.component';
import { AddProjectComponent } from './pages/add-project/add-project.component';
import { UsersComponent } from './pages/users/users.component';
import { CatCtasComponent } from './pages/cat-ctas/cat-ctas.component';
import { CostReportComponent } from './pages/cost-report/cost-report.component';
import { CtasProjectComponent } from './pages/ctas-project/ctas-project.component';
import { PriorRequestComponent } from './pages/prior-request/prior-request.component';
import { CheckRequestComponent } from './pages/check-request/check-request.component';
import { ChecksComponent } from './website/checks/checks.component';
import { ChecksReportComponent } from './pages/checks-report/checks-report.component';
import { ConsultDealMemoComponent } from './pages/consult-deal-memo/consult-deal-memo.component';
import { DisponibleComponent } from './pages/disponible/disponible.component';
import { EstimadoCierreComponent } from './pages/estimado-cierre/estimado-cierre.component';
import localeEs from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

registerLocaleData(localeEs);

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

// firebase
import { environment } from 'src/environments/environment';
import {
  AngularFirestoreModule,
  AngularFirestore,
} from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { EmpresasService } from './services/empresas.service';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { RequestVersion1Component } from './pages/request-version1/request-version1.component';
import { PremissionsComponent } from './pages/premissions/premissions.component';
import { EditRequestComponent } from './pages/edit-request/edit-request.component';
import { FilterPipe } from './pipes/filter.pipe';
import { RequestMassiveComponent } from './pages/request-massive/request-massive.component';
import { ConvertPipe } from './pipes/convert.pipe';
import { WebsiteModule } from './layout/website/website.module';
import { DealMemoPublicComponent } from './website/deal-memo-public/deal-memo-public.component';
import { CheckDealMemoComponent } from './pages/check-deal-memo/check-deal-memo.component';
import { DealMemoComponent } from './pages/deal-memo/deal-memo.component';

import { NgxTypedJsModule } from 'ngx-typed-js';
import { DealMemoReportComponent } from './pages/deal-memo-report/deal-memo-report.component';
import { TareasComponent } from './pages/tareas/tareas.component';
import { AvisoPrivacidadComponent } from './website/aviso-privacidad/aviso-privacidad.component';
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ComprobacionComponent } from './pages/comprobacion/comprobacion.component';
import { ImportadorComponent } from './website/importador/importador.component';
import { MenuComponent } from './website/menu/menu.component';
import { PublicUsersComponent } from './pages/public-users/public-users.component';
import { ComprobacionesComponent } from './website/comprobaciones/comprobaciones.component';
import { DepartamentosComponent } from './pages/departamentos/departamentos.component';
import { GxcComponent } from './website/gxc/gxc.component';
import { FirmaComponent } from './website/firma/firma.component';
import { SolGxcComponent } from './pages/sol-gxc/sol-gxc.component';
import { ReembolsoComponent } from './website/reembolso/reembolso.component';
import { Reembolso2Component } from './pages/reembolso/reembolso.component';
import { DealMemoMasiveComponent } from './pages/deal-memo-masive/deal-memo-masive.component';
import { ApproveRequestComponent } from './pages/approve-request/approve-request.component';
import { CheckComprobacionComponent } from './pages/check-comprobacion/check-comprobacion.component'; // fonts provided for pdfmake
import { HttpClientModule } from '@angular/common/http';
import { ConsultOrdenCompraComponent } from './pages/consult-orden-compra/consult-orden-compra.component';
import { ReportDealComponent } from './pages/report-deal/report-deal.component';
import { PurchaseOrderComponent } from './pages/purchase-order/purchase-order.component';
import { RequestAprovedComponent } from './pages/request-aproved/request-aproved.component';
import { AproveProvidersComponent } from './pages/aprove-providers/aprove-providers.component';
import { ProviderComponent } from './pages/extSuppliers/provider/provider.component';
import { FilesComponent } from './pages/extSuppliers/files/files.component';
import { UnrelatedProviderComponent } from './pages/unrelated-provider/unrelated-provider.component';
import { PaymentCalendarComponent } from './pages/extSuppliers/payment-calendar/payment-calendar.component';
import { RequestAnexoComponent } from './pages/request-anexo/request-anexo.component';
import { SolAnexoComponent } from './pages/sol-anexo/sol-anexo.component';
import { AddAnexosComponent } from './pages/add-anexos/add-anexos.component';
import { EquiposComponent } from './dispositivos/equipos/equipos.component';
import { EquipoComponent } from './dispositivos/equipo/equipo.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UtilsComponent } from './utils/utils.component';
import { PurchaseOrder1Component } from './pages/purchase-order1/purchase-order1.component';
import { SolV1Component } from './pages/sol-v1/sol-v1.component';
import { PoGlobalComponent } from './pages/po-global/po-global.component';
import { PoGxcComponent } from './pages/po-gxc/po-gxc.component';
import { SolRembolsoComponent } from './pages/sol-rembolso/sol-rembolso.component';
import { ConsultPO1Component } from './pages/consult-po1/consult-po1.component';
import { SolGxcv2Component } from './pages/sol-gxcv2/sol-gxcv2.component';
import { ConsultSolComponent } from './pages/consult-sol/consult-sol.component';
import { PoReportComponent } from './pages/po-report/po-report.component';
import { FechaTimeStampPipe } from './pipes/fecha-time-stamp.pipe';
import { AvailableComponent } from './pages/available/available.component';

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RequestComponent,
    AddCompanyComponent,
    AddProjectComponent,
    UsersComponent,
    CatCtasComponent,
    CostReportComponent,
    CtasProjectComponent,
    PriorRequestComponent,
    CheckRequestComponent,
    ChecksComponent,
    ChecksReportComponent,
    ConsultDealMemoComponent,
    RequestVersion1Component,
    DisponibleComponent,
    EstimadoCierreComponent,
    PremissionsComponent,
    EditRequestComponent,
    FilterPipe,
    RequestMassiveComponent,
    ConvertPipe,
    DealMemoPublicComponent,
    CheckDealMemoComponent,
    DealMemoComponent,
    DealMemoReportComponent,
    TareasComponent,
    AvisoPrivacidadComponent,
    ComprobacionComponent,
    ImportadorComponent,
    MenuComponent,
    PublicUsersComponent,
    ComprobacionesComponent,
    DepartamentosComponent,
    GxcComponent,
    FirmaComponent,
    SolGxcComponent,
    ReembolsoComponent,
    DealMemoMasiveComponent,
    ApproveRequestComponent,
    CheckComprobacionComponent,
    ConsultOrdenCompraComponent,
    Reembolso2Component,
    ReportDealComponent,
    PurchaseOrderComponent,
    RequestAprovedComponent,
    AproveProvidersComponent,
    ProviderComponent,
    FilesComponent,
    UnrelatedProviderComponent,
    PaymentCalendarComponent,
    RequestAnexoComponent,
    SolAnexoComponent,
    AddAnexosComponent,
    EquiposComponent,
    EquipoComponent,
    PageNotFoundComponent,
    UtilsComponent,
    PurchaseOrder1Component,
    SolV1Component,
    PoGlobalComponent,
    PoGxcComponent,
    SolRembolsoComponent,
    ConsultPO1Component,
    SolGxcv2Component,
    ConsultSolComponent,
    PoReportComponent,
    FechaTimeStampPipe,
    AvailableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTabsModule,
    MatTreeModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    WebsiteModule,
    NgxTypedJsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ScrollingModule,
  ],
  providers: [
    EmpresasService,
    AngularFirestore,
    AngularFireAuth,
    { provide: LOCALE_ID, useValue: 'es-MX' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
