import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  idUser: string;
  idCompany: string;
  idProject: string;
  user = {} as any;

  access = false;

  constructor(private router: Router, private generalService: GeneralService) {}

  ngOnInit(): void {
    // const url = this.router.parseUrl(this.router.url);
    // console.log(url);
    // this.idUser = url.root.children.primary.segments[1].path;
    // this.idCompany = url.root.children.primary.segments[1].path;
    // this.idProject = url.root.children.primary.segments[2].path;
  }

  login() {
    this.generalService
      .getUserPublic(this.user.email, this.user.pass)
      .subscribe(res => {
        const data = res[0];
        if (data) {
          this.access = true;
          this.generalService.enviarData(data);
        }
      });
  }

  signOut() {
    this.access = false;
  }
}
