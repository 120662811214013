import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Budget } from '../models/budget';
import { map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import Notiflix from 'notiflix-angular';

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  arr = [];
  budget: Observable<Budget>;

  constructor(private afs: AngularFirestore) {}

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { budget: worksheet },
      SheetNames: ['budget'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + ' ' + new Date() + this.EXCEL_EXTENSION);
  }

  // <-- Presupuesto -->

  addPresupuesto2(objPres, idEmpresa, idProyecto) {
    return this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('presupuesto')
      .doc('presupuesto')
      .set(objPres);
  }

  getBudget(idEmpresa, idProyecto) {
    return this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('presupuesto')
      .doc('presupuesto')
      .valueChanges();
  }
  ctasFilter2(budget) {
    const array = [] as any;
    budget.forEach(element => {
      array.push(element);
      if (element.children) {
        if (element.children.length > 0) {
          fase1(element.children);
          delete element.children;
        }
      }
    });

    function fase1(arr) {
      arr.forEach(element => {
        array.push(element);
        if (element.children) {
          if (element.children.length > 0) {
            fase2(element.children);
            delete element.children;
          }
        }
      });
    }

    function fase2(arr) {
      arr.forEach(element => {
        array.push(element);
        if (element.children) {
          if (element.children.length > 0) {
            fase1(element.children);
            delete element.children;
          }
        }
      });
    }

    return array;
  }

  modifyBudget(idEmpresa, idProyecto, idBudget, editBudget) {
    return this.afs.firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('presupuesto')
      .doc(idBudget)
      .set(editBudget);
  }
  modifyBudget2(idEmpresa, idProyecto, idBudget, objBudget) {
    return this.afs.firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('presupuesto')
      .doc(idBudget)
      .update(objBudget);
  }
  addIva(idEmpresa, idProyecto, obj) {
    this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('acumulados')
      .doc('IVA')
      .set(obj)
      .then(() => console.log('Iva guardado exitosamente'))
      .catch(err => console.log('Error: ', err));
  }
  getIvaEstimado(idEmpresa, idProyecto) {
    return this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('acumulados')
      .doc('IVA')
      .valueChanges();
  }
  getIvaAcumulado(idEmpresa, idProyecto) {
    return this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('acumulados')
      .doc('acumuladoIVA')
      .valueChanges();
  }
  buscarCtaPadre(getBudget, cuentaPadre) {
    let budgetPadre: Budget = {};
    let sumaImporte = 0;
    let sumaSubtotal = 0;
    let sumaTotal = 0;
    // let sumaGranTotal = 0;
    let idx = 0;
    for (let i = 0; i < getBudget.length; i++) {
      const budget: Budget = getBudget[i];
      const cuentaP = budget.Cuenta_Padre;
      const cuenta = budget.Cuenta;
      if (cuenta === cuentaPadre) {
        budgetPadre = budget;
        idx = i;
      }
      if (cuentaP === cuentaPadre) {
        sumaImporte = sumaImporte + budget.Importe_Estimado;
        sumaSubtotal = sumaSubtotal + budget.Subtotal_Estimado;
        sumaTotal = sumaTotal + budget.Total_Estimado;
      }
    }
    const regresoDatos = [
      sumaImporte,
      idx,
      budgetPadre,
      sumaSubtotal,
      sumaTotal,
    ];
    return regresoDatos;
  }
  deleteBudget(idEmpresa, idProyecto) {
    return this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('presupuesto')
      .doc('presupuesto')
      .delete();
  }
  getCtas(idEmpresa, idProyecto) {
    return this.afs
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('acumulados')
      .doc('totalesCtas')
      .valueChanges();
  }
  saveSumaCtas(idEmpresa, idProyecto, obj) {
    return this.afs.firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('proyectos')
      .doc(idProyecto)
      .collection('acumulados')
      .doc('totalesCtas')
      .set(obj);
  }

  getRecursoProductor(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('ajustes')
      .valueChanges();
  }

  addRecursoProductor(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('ajustes')
      .doc(obj.CodigoAgrupador)
      .set(obj);
  }
}
