import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CostReportService {
  constructor(private afs: AngularFirestore) {}

  reportForDate(idCompany, idProject, fInicio, fFin) {
    const inicial = new Date(fInicio + 'T00:00:00');
    const final = new Date(fFin + 'T23:59:59');
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('solicitudes', ref =>
        ref.where('fechaIni', '>=', inicial).where('fechaIni', '<=', final)
      )
      .valueChanges();
  }
  reportForPeriod(idCompany, idProject, fInicio, fFin) {
    const inicial = new Date(fInicio + 'T00:00:00');
    const final = new Date(fFin + 'T23:59:59');
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('solicitudes', ref =>
        ref.where('fechaIni', '>=', inicial).where('fechaIni', '<=', final)
      )
      .valueChanges();
  }
}
