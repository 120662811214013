import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-add-anexos',
  templateUrl: './add-anexos.component.html',
  styleUrls: ['./add-anexos.component.css'],
})
export class AddAnexosComponent implements OnInit {
  idCompany: string;
  idProject: string;
  arrRequest = [] as any;

  constructor(private router: Router, private requestService: RequestService) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getRequest();
  }

  getRequest() {
    this.requestService
      .getAnexos(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        this.arrRequest = res;
      });
  }
}
