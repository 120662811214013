import { Component, OnInit, OnDestroy } from '@angular/core';
import { CostReport } from 'src/app/models/costReport';
import { CostReportService } from 'src/app/services/cost-report.service';
import { ProjectService } from 'src/app/services/project.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { BudgetService } from 'src/app/services/budget.service';
import { RequestService } from 'src/app/services/request.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DealMemoService } from 'src/app/services/deal-memo.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-cost-report',
  templateUrl: './cost-report.component.html',
  styleUrls: ['./cost-report.component.css'],
})
export class CostReportComponent implements OnInit, OnDestroy {
  dates = {} as CostReport;
  idCompany;
  idProject;
  periods;
  period;
  budget: any;
  arrReport = [] as any;
  moneda = 'MXN';
  tipoDeCambio = 1;
  description = '';
  nivel = '1';
  arrReportTable = [] as any;
  ivaAcumulado = {} as any;
  ivaEstimado: any;
  ivaAprobado: any;
  actualToDate = [] as any;
  actualThisPeriod = [] as any;
  arrActualToDate: any;
  cuentasTotales;
  totales = {} as any;
  timpoCambioGral;
  acumulados;
  project;
  budgetArrays = [] as any;

  subscriberGetPeriod: Subscription;
  subscriberGetBudget: Subscription;
  subscriberGetCtas: Subscription;
  subscriberGetRequest: Subscription;
  subscriberGetIva: Subscription;
  subscribeProject: Subscription;
  subscribeGetAcumulado: Subscription;

  constructor(
    private costReportService: CostReportService,
    private requestService: RequestService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private dealMemoService: DealMemoService,
    private budgetService: BudgetService,
    private router: Router
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getPeriod();
    this.getBudget();
    this.getCtas();
    this.getIVA();
    this.getProject();
  }
  // Obtenemos el presupuesto

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          console.log(budget);
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
        }
      });
  }
  // Obtenemos el iva estimado y aprobado
  getIVA() {
    this.subscriberGetIva = this.budgetService
      .getIvaEstimado(this.idCompany, this.idProject)
      .subscribe(res => {
        const iva: any = res;
        this.ivaEstimado = iva.estimado;
        this.ivaAprobado = iva.aprobado;
      });
  }
  // Obtenemos los periodos
  getPeriod() {
    this.subscriberGetPeriod = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
      });
  }
  //Reporte por fecha
  reportForDate() {
    // this.description = this.period.nombrePeriodo;
    console.log(
      new Date(this.dates.fechaIni + 'T00:00:00'),
      new Date(this.dates.fechaFin + 'T23:59:59')
    );

    const fechaIni = new Date(this.dates.fechaIni + 'T00:00:00');
    const fechaFin = new Date(this.dates.fechaFin + 'T23:59:59');
    this.subscriberGetRequest = this.costReportService
      .reportForDate(this.idCompany, this.idProject, fechaIni, fechaFin)
      .subscribe(res => {
        this.actualToDate = res;
        console.log(res);
        // res.forEach(element => {
        //   if (element.periodo === this.period.nombrePeriodo) {
        //     this.actualThisPeriod.push(element);
        //   }
        // });
        this.createReport();
      });
  }
  // Reporte por periodo
  reportForPeriod() {
    this.description = this.period.nombrePeriodo;
    this.subscriberGetRequest = this.requestService
      .reporteFecha(this.idCompany, this.idProject, this.period.fechaInicio)
      .subscribe(res => {
        this.actualToDate = res;
        res.forEach(element => {
          if (element.periodo === this.period.nombrePeriodo) {
            this.actualThisPeriod.push(element);
          }
        });
        this.createReport();
      });
  }
  // Crear el reporte
  createReport() {
    this.arrReport = [];
    let sumaAcumuladoIvaPeriod = 0;
    let sumaAcumuladoIvaToDate = 0;
    let sumaCommitedIvaToDate = 0;
    for (let index = 0; index < this.budget.length; index++) {
      let sumaAcumuladoPeriod = 0;
      let sumaAcumuladoToDate = 0;
      let sumaCommitedToDate = 0;
      let obj = {} as any;
      const elementBudget = this.budget[index];
      console.log(elementBudget);
      this.actualToDate.forEach(elementSol => {
        elementSol.movimientos.forEach(elementMovimientos => {
          if (
            elementBudget.CodigoAgrupador === elementMovimientos.Cuenta &&
            elementSol.tipoSolicitud !== 'GXC'
          ) {
            const iva = elementSol.movimientos.find(
              mov => mov.Cuenta === '119-01-001'
            );

            if (elementMovimientos.Cargo | elementMovimientos.importe) {
              if (this.moneda === elementSol.tipoMoneda) {
                if (elementSol.estatus) {
                  sumaAcumuladoToDate +=
                    elementMovimientos.Cargo | elementMovimientos.importe;
                  sumaAcumuladoIvaToDate += iva.Cargo;
                } else {
                  sumaCommitedToDate +=
                    elementMovimientos.Cargo | elementMovimientos.importe;
                  sumaCommitedIvaToDate += iva.Cargo;
                }
              } else if (
                this.moneda === 'MXN' &&
                elementSol.tipoMoneda === 'USD'
              ) {
                if (elementSol.estatus) {
                  sumaAcumuladoToDate +=
                    (elementMovimientos.Cargo | elementMovimientos.importe) *
                    elementSol.tipoCambio;
                  sumaAcumuladoIvaToDate += iva.Cargo * elementSol.tipoCambio;
                } else {
                  sumaCommitedToDate +=
                    (elementMovimientos.Cargo | elementMovimientos.importe) *
                    elementSol.tipoCambio;
                  sumaCommitedIvaToDate += iva.Cargo * elementSol.tipoCambio;
                }
              } else if (
                this.moneda === 'USD' &&
                elementSol.tipoMoneda === 'MXN'
              ) {
                if (elementSol.estatus) {
                  sumaAcumuladoToDate +=
                    (elementMovimientos.Cargo | elementMovimientos.importe) /
                    elementSol.tipoCambio;
                  sumaAcumuladoIvaToDate += iva.Cargo / elementSol.tipoCambio;
                } else {
                  sumaCommitedToDate +=
                    (elementMovimientos.Cargo | elementMovimientos.importe) /
                    elementSol.tipoCambio;
                  sumaCommitedIvaToDate += iva.Cargo / elementSol.tipoCambio;
                }
              }
              if (
                elementSol.periodo === this.period.nombrePeriodo &&
                elementSol.estatus
              ) {
                if (this.moneda === elementSol.tipoMoneda) {
                  sumaAcumuladoPeriod +=
                    elementMovimientos.Cargo | elementMovimientos.importe;
                  sumaAcumuladoIvaPeriod += iva.Cargo;
                } else if (
                  this.moneda === 'MXN' &&
                  elementSol.tipoMoneda === 'USD'
                ) {
                  sumaAcumuladoPeriod +=
                    (elementMovimientos.Cargo | elementMovimientos.importe) *
                    elementSol.tipoCambio;
                  sumaAcumuladoIvaPeriod += iva.Cargo * elementSol.tipoCambio;
                } else if (
                  this.moneda === 'USD' &&
                  elementSol.tipoMoneda === 'MXN'
                ) {
                  sumaAcumuladoPeriod +=
                    (elementMovimientos.Cargo | elementMovimientos.importe) /
                    elementSol.tipoCambio;
                  sumaAcumuladoIvaPeriod += iva.Cargo / elementSol.tipoCambio;
                }
              }
            }
          }
        });
      });
      this.ivaAcumulado.ActualThisPeriod = sumaAcumuladoIvaPeriod;
      this.ivaAcumulado.ActualToDate = sumaAcumuladoIvaToDate;
      this.ivaAcumulado.CommitedToDate = sumaCommitedIvaToDate;
      obj.CodigoAgrupador = elementBudget.CodigoAgrupador;
      obj.Descripcion = elementBudget.Descripcion;
      obj.AcumuladoPeriodo = sumaAcumuladoPeriod; // Actual del periodo
      obj.AcumuladoToDate = sumaAcumuladoToDate; // Actual a la fecha
      obj.CommitedToDate = sumaCommitedToDate; // Comprometido a la fecha
      obj.Variance = elementBudget.Variante;
      if (this.project.monedaLocal === this.moneda) {
        if (
          elementBudget.Total_Estimado ||
          elementBudget.Total_Estimado === 0
        ) {
          obj.PresEstimado = elementBudget.Total_Estimado;
          obj.PresAproved = elementBudget.Total_Aprobado;
        } else if (
          elementBudget.Subtotal_Estimado ||
          elementBudget.Subtotal_Estimado === 0
        ) {
          obj.PresEstimado = elementBudget.Subtotal_Estimado;
          obj.PresAproved = elementBudget.Subtotal_Aprobado;
        } else if (
          elementBudget.Importe_Estimado ||
          elementBudget.Importe_Estimado === 0
        ) {
          obj.PresEstimado = elementBudget.Importe_Estimado;
          obj.PresAproved = elementBudget.Importe_Aprobado;
        }
      } else if (this.project.monedaLocal === 'MXN' && this.moneda === 'USD') {
        if (
          elementBudget.Total_Estimado ||
          elementBudget.Total_Estimado === 0
        ) {
          obj.PresEstimado =
            elementBudget.Total_Estimado / this.project.tipoCambioGral;
          obj.PresAproved =
            elementBudget.Total_Aprobado / this.project.tipoCambioGral;
        } else if (
          elementBudget.Subtotal_Estimado ||
          elementBudget.Subtotal_Estimado === 0
        ) {
          obj.PresEstimado =
            elementBudget.Subtotal_Estimado / this.project.tipoCambioGral;
          obj.PresAproved =
            elementBudget.Subtotal_Aprobado / this.project.tipoCambioGral;
        } else if (
          elementBudget.Importe_Estimado ||
          elementBudget.Importe_Estimado === 0
        ) {
          obj.PresEstimado =
            elementBudget.Importe_Estimado / this.project.tipoCambioGral;
          obj.PresAproved =
            elementBudget.Importe_Aprobado / this.project.tipoCambioGral;
        }
      } else if (this.project.monedaLocal === 'USD' && this.moneda === 'MXN') {
        if (
          elementBudget.Total_Estimado ||
          elementBudget.Total_Estimado === 0
        ) {
          obj.PresEstimado =
            elementBudget.Total_Estimado * this.project.tipoCambioGral;
          obj.PresAproved =
            elementBudget.Total_Aprobado * this.project.tipoCambioGral;
        } else if (
          elementBudget.Subtotal_Estimado ||
          elementBudget.Subtotal_Estimado === 0
        ) {
          obj.PresEstimado =
            elementBudget.Subtotal_Estimado * this.project.tipoCambioGral;
          obj.PresAproved =
            elementBudget.Subtotal_Aprobado * this.project.tipoCambioGral;
        } else if (
          elementBudget.Importe_Estimado ||
          elementBudget.Importe_Estimado === 0
        ) {
          obj.PresEstimado =
            elementBudget.Importe_Estimado * this.project.tipoCambioGral;
          obj.PresAproved =
            elementBudget.Importe_Aprobado * this.project.tipoCambioGral;
        }
      }
      this.arrReport.push(obj);
      obj = {};
    }
    this.sumaCuentasPadre();
  }
  // Sumamos las cuentas hijos para sacar los padre
  sumaCuentasPadre() {
    for (let index = 0; index < this.arrReport.length; index++) {
      const element1 = this.arrReport[index];
      const segmentos = element1.CodigoAgrupador.split('-');
      if (segmentos.length === 2) {
        let sumaAcumuladoPeriod = 0;
        let sumaAcumuladoToDate = 0;
        let sumaCommitedtoDate = 0;
        for (let index2 = 0; index2 < this.arrReport.length; index2++) {
          const element2 = this.arrReport[index2];
          const segmentos2 = element2.CodigoAgrupador.split('-');
          if (
            segmentos[0] === segmentos2[0] &&
            segmentos[1] === segmentos2[1]
          ) {
            sumaAcumuladoPeriod += element2.AcumuladoPeriodo;
            sumaAcumuladoToDate += element2.AcumuladoToDate;
            sumaCommitedtoDate += element2.CommitedToDate;
          }
        }
        element1.AcumuladoPeriodo = sumaAcumuladoPeriod;
        element1.AcumuladoToDate = sumaAcumuladoToDate;
        element1.CommitedToDate = sumaCommitedtoDate;
      }
    }
    for (let index = 0; index < this.arrReport.length; index++) {
      const element = this.arrReport[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (segmentos.length === 1) {
        let suma = 0;
        let suma2 = 0;
        let suma3 = 0;
        for (let index1 = 0; index1 < this.arrReport.length; index1++) {
          const element1 = this.arrReport[index1];
          const segmentos1 = element1.CodigoAgrupador.split('-');
          if (segmentos1.length === 2 && segmentos[0] === segmentos1[0]) {
            suma += element1.AcumuladoPeriodo;
            suma2 += element1.AcumuladoToDate;
            suma3 += element1.CommitedToDate;
          }
        }
        element.AcumuladoPeriodo = suma;
        element.AcumuladoToDate = suma2;
        element.CommitedToDate = suma3;
      }
    }
    for (let index = 0; index < this.arrReport.length; index++) {
      const element = this.arrReport[index];
      element.TotalComited = element.CommitedToDate + element.AcumuladoToDate;
      element.EstimadoToComplete =
        element.PresEstimado - element.AcumuladoToDate;
    }
    this.totalFunction();
  }
  // Obtener las cuentas de los totales
  getCtas() {
    this.subscriberGetCtas = this.budgetService
      .getCtas(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res === undefined) {
        } else {
          this.cuentasTotales = res;
        }
      });
  }
  // Aqui sacamos los totales del reporte
  totalFunction() {
    this.totales = {
      actualThisPeriodA: 0,
      actualToDateA: 0,
      actualThisPeriodTempA: 0,
      actualToDateTempA: 0,
      commitedToDateA: 0,
      totalPlusCommitedA: 0,
      estimatedToCompleteA: 0,
      currentEFCA: 0,
      currentBudgetA: 0,
      varianceA: 0,
      actualThisPeriodB: 0,
      actualToDateB: 0,
      actualThisPeriodTempB: 0,
      actualToDateTempB: 0,
      commitedToDateB: 0,
      totalPlusCommitedB: 0,
      estimatedToCompleteB: 0,
      currentEFCB: 0,
      currentBudgetB: 0,
      varianceB: 0,
      actualThisPeriodC: 0,
      actualToDateC: 0,
      actualThisPeriodTempC: 0,
      actualToDateTempC: 0,
      commitedToDateC: 0,
      totalPlusCommitedC: 0,
      estimatedToCompleteC: 0,
      currentEFCC: 0,
      currentBudgetC: 0,
      varianceC: 0,
      actualThisPeriodD: 0,
      actualToDateD: 0,
      actualThisPeriodTempD: 0,
      actualToDateTempD: 0,
      commitedToDateD: 0,
      totalPlusCommitedD: 0,
      estimatedToCompleteD: 0,
      currentEFCD: 0,
      currentBudgetD: 0,
      varianceD: 0,
    };
    this.arrReport.forEach(element => {
      const segmento = element.CodigoAgrupador.split('-');
      if (segmento.length === 1) {
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TATLDE &&
          element.CodigoAgrupador <= this.cuentasTotales.TATLA
        ) {
          this.totales.actualThisPeriodA += element.AcumuladoPeriodo;
          this.totales.actualToDateA += element.AcumuladoToDate;
          this.totales.actualThisPeriodTempA += element.AcumuladoPeriodoTemp;
          this.totales.actualToDateTempA += element.AcumuladoToDateTemp;
          this.totales.commitedToDateA += element.CommitedToDate;
          this.totales.totalPlusCommitedA += element.TotalComited;
          this.totales.estimatedToCompleteA += element.EstimadoToComplete;
          this.totales.currentEFCA += element.PresEstimado;
          this.totales.currentBudgetA += element.PresAproved;
          this.totales.varianceA += element.Variance;
        }
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TBTLPDE &&
          element.CodigoAgrupador <= this.cuentasTotales.TBTLPA
        ) {
          this.totales.actualThisPeriodB += element.AcumuladoPeriodo;
          this.totales.actualToDateB += element.AcumuladoToDate;
          this.totales.actualThisPeriodTempB += element.AcumuladoPeriodoTemp;
          this.totales.actualToDateTempB += element.AcumuladoToDateTemp;
          this.totales.commitedToDateB += element.CommitedToDate;
          this.totales.totalPlusCommitedB += element.TotalComited;
          this.totales.estimatedToCompleteB += element.EstimadoToComplete;
          this.totales.currentEFCB += element.PresEstimado;
          this.totales.currentBudgetB += element.PresAproved;
          this.totales.varianceB += element.Variance;
        }
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TBTLPOSTDE &&
          element.CodigoAgrupador <= this.cuentasTotales.TBTLPOSTA
        ) {
          this.totales.actualThisPeriodC += element.AcumuladoPeriodo;
          this.totales.actualToDateC += element.AcumuladoToDate;
          this.totales.actualThisPeriodTempC += element.AcumuladoPeriodoTemp;
          this.totales.actualToDateTempC += element.AcumuladoToDateTemp;
          this.totales.commitedToDateC += element.CommitedToDate;
          this.totales.totalPlusCommitedC += element.TotalComited;
          this.totales.estimatedToCompleteC += element.EstimadoToComplete;
          this.totales.currentEFCC += element.PresEstimado;
          this.totales.currentBudgetC += element.PresAproved;
          this.totales.varianceC += element.Variance;
        }
        if (
          element.CodigoAgrupador >= this.cuentasTotales.TBTLODE &&
          element.CodigoAgrupador <= this.cuentasTotales.TBTLOA
        ) {
          this.totales.actualThisPeriodD += element.AcumuladoPeriodo;
          this.totales.actualToDateD += element.AcumuladoToDate;
          this.totales.actualThisPeriodTempD += element.AcumuladoPeriodo;
          this.totales.actualToDateTempD += element.AcumuladoToDate;
          this.totales.commitedToDateD += element.CommitedToDate;
          this.totales.totalPlusCommitedD += element.TotalComited;
          this.totales.estimatedToCompleteD += element.EstimadoToComplete;
          this.totales.currentEFCD += element.PresEstimado;
          this.totales.currentBudgetD += element.PresAproved;
          this.totales.varianceD += element.Variance;
        }
      }
    });
    this.changeNivel();
  }
  exportTableToExcel(idTable) {
    let filename = 'CostReport' + '-' + new Date().getTime();
    let downloadLink;
    const dataType = 'application/vnd.ms-excel';
    // const dataType = 'application/vnd.ms-excel';
    const tableSelect = document.getElementById(idTable);
    const tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + '.xls' : 'excel_data.xls';

    // Create download link element
    downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      const blob = new Blob(['ufeff', tableHTML], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      // triggering the function
      downloadLink.click();
    }
  }
  clearReport() {
    this.arrReport = false;
    this.arrReportTable = false;
  }
  getProject() {
    this.subscribeProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        const obj: any = res;
        this.project = res;
        this.timpoCambioGral = obj.tipoCambioGral;
        this.moneda = this.project.monedaLocal;
      });
  }
  changeMoneda() {
    if (this.arrReportTable.length) {
      this.createReport();
    }
  }
  changeNivel() {
    this.arrReportTable = [];
    const nivel = parseInt(this.nivel, 10);
    for (let index = 0; index < this.arrReport.length; index++) {
      const element = this.arrReport[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (segmentos.length <= nivel) {
        this.arrReportTable.push(element);
      }
    }
  }
  ngOnDestroy() {
    if (this.subscriberGetBudget) {
      this.subscriberGetBudget.unsubscribe();
    }
    if (this.subscriberGetCtas) {
      this.subscriberGetCtas.unsubscribe();
    }
    if (this.subscriberGetIva) {
      this.subscriberGetIva.unsubscribe();
    }
    if (this.subscriberGetPeriod) {
      this.subscriberGetPeriod.unsubscribe();
    }
    if (this.subscriberGetRequest) {
      this.subscriberGetRequest.unsubscribe();
    }
    if (this.subscribeProject) {
      this.subscribeProject.unsubscribe();
    }
  }
}
