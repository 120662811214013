import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/models/providers';
import { ProvidersService } from 'src/app/services/providers.service';
import { ProjectService } from 'src/app/services/project.service';
import { BudgetService } from 'src/app/services/budget.service';
import { RequestService } from 'src/app/services/request.service';
import { Request } from 'src/app/models/request';
import { Router } from '@angular/router';
import { Budget } from 'src/app/models/budget';
import { Period } from 'src/app/models/period';
import { NgxXml2jsonService } from 'ngx-xml2json';
import Notiflix from 'notiflix-angular';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-prior-request',
  templateUrl: './prior-request.component.html',
  styleUrls: ['./prior-request.component.css']
})
export class PriorRequestComponent implements OnInit {
  xml;
  providers: any[];
  providersSelect: any[];
  proveedorSelecionado = {} as Provider;
  datosProveedor: any = {} as Provider;
  request = {
    tipoPago: 'Transferencia',
    tipoSolicitud: 'Directa',
    fechaCreado: new Date(),
    importe: 0,
    iva: 0,
    subtotal: 0,
    retIva: 0,
    retIsr: 0,
    total: 0
  } as Request;
  idCompany: any;
  idProject: any;
  idBudget: any;
  budget: any = [] as Budget;
  ctasPresupuesto;
  periods = [];
  periodoActual = {} as Period;
  countRequest = {} as Request;
  departament;
  departamento;
  puestos;
  puesto;
  parPres: any;
  objPres;
  binding: any;
  loading = true;
  parIva: any;
  acumulado9999;
  acumuladoIVA;
  valSol = [] as any;
  valObj = {} as any;
  valTotales = {} as any;

  constructor(
    private providersService: ProvidersService,
    private budgetService: BudgetService,
    private projecService: ProjectService,
    private requestService: RequestService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getCount();
    this.getProvider();
    this.getBudget();
    this.getPeriods();
    this.getAcumulado9999();
    this.getAcumuladoIVA();
    this.loading = false;
  }
  getAcumulado9999() {
    this.requestService
      .getAcumulado9999(this.idCompany, this.idProject)
      .subscribe(res => {
        const obj: any = res;
        if (res === undefined) {
          this.acumulado9999 = 0;
        } else {
          this.acumulado9999 = obj.acumulado;
        }
      });
  }
  getAcumuladoIVA() {
    this.requestService
      .getAcumuladoIVA(this.idCompany, this.idProject)
      .subscribe(res => {
        const obj: any = res;
        if (res === undefined) {
          this.acumuladoIVA = 0;
        } else {
          this.acumuladoIVA = obj.acumulado;
        }
      });
  }
  getProvider() {
    this.providersService.getProvider().subscribe(res => {
      this.providers = Object.assign([], res[0]);
    });
  }
  getBudget() {
    this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        this.budget = Object.assign([], res[0]);
        this.idBudget = this.budget.id;
        this.getDepartament();
      });
  }
  getDepartament() {
    this.departament = this.filterBudget('Total_Estimado');
  }
  getPuesto() {
    this.puestos = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (this.departamento.Cuenta === element.Cuenta_Padre) {
        this.puestos.push(element);
      }
    }
  }
  getCtaPres() {
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (this.puesto.Cuenta === element.Cuenta_Padre) {
        this.ctasPresupuesto.push(element);
      }
    }
  }
  selectCtaPres() {
    this.request.partidaPres =
      this.objPres.Cuenta_Padre + '-' + this.objPres.Subcuenta;
  }
  getPeriods() {
    this.projecService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res[0]);
        this.filtrarperiodo();
      });
  }
  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element: Period = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        break;
      }
    }
  }
  getCount() {
    this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe(res => {
        const contador: any = res;
        if (res === undefined) {
          this.countRequest.countRequest = 1;
          // this.countRequest = res.data();
        } else {
          this.countRequest.countRequest = contador.countRequest + 1;
        }
      });
  }
  filterBudget(nivel) {
    const arr = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element[nivel]) {
        arr.push(element);
      }
    }
    return arr;
  }
  filterProvider() {
    const arr = [];
    for (let index = 0; index < this.providers.length; index++) {
      const element = this.providers[index];
      const nombre = element.nombre.toLowerCase();
      if (nombre.indexOf(this.proveedorSelecionado.nombre.toLowerCase()) >= 0) {
        arr.push(element);
      }
    }
    this.providersSelect = arr;
    this.changeSelectProvider();
  }
  changeSelectProvider() {
    const proveedor = (<any>document.getElementById('selectProvider')).value;
    const numProveedor = parseInt(proveedor, 10);
    for (let index = 0; index < this.providers.length; index++) {
      const element: Provider = this.providers[index];
      if (element.numero === numProveedor) {
        this.datosProveedor = element;
        break;
      }
    }
  }
  operaciones() {
    switch (this.datosProveedor.regimen) {
      case 'General de ley persona moral':
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        this.request.iva = this.request.importe * 0.16;
        this.request.subtotal = this.request.importe + this.request.iva;
        this.request.retIva = 0;
        this.request.retIsr = 0;
        this.request.total =
          this.request.subtotal - this.request.retIsr - this.request.retIva;
        break;
      case 'Arrendamiento':
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        this.request.iva = this.request.importe * 0.16;
        this.request.subtotal = this.request.importe + this.request.iva;
        this.request.retIva = this.request.importe * 0.106666;
        this.request.retIsr = this.request.importe * 0.1;
        this.request.total =
          this.request.subtotal - this.request.retIsr - this.request.retIva;
        break;
      case 'Ingresos por dividendos':
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = false;
        this.request.iva = 0;
        this.request.subtotal = this.request.importe + this.request.iva;
        this.request.retIva = 0;
        this.request.total =
          this.request.subtotal - this.request.retIsr - this.request.retIva;
        break;
      case 'Personas fisicas con actividades empresariales y profesionales':
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        this.request.iva = this.request.importe * 0.16;
        this.request.subtotal = this.request.importe + this.request.iva;
        this.request.retIva = this.request.importe * 0.106666;
        this.request.retIsr = this.request.importe * 0.1;
        this.request.total =
          this.request.subtotal - this.request.retIsr - this.request.retIva;
        break;
      case 'Incorporacion fiscal':
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        this.request.iva = this.request.importe * 0.16;
        this.request.subtotal = this.request.importe + this.request.iva;
        this.request.retIva = this.request.importe * 0.106666;
        this.request.retIsr = 0;
        this.request.total =
          this.request.subtotal - this.request.retIsr - this.request.retIva;
        break;
      case 'Otro':
        (<any>document.getElementById('iva')).disabled = false;
        (<any>document.getElementById('retIva')).disabled = false;
        (<any>document.getElementById('retIsr')).disabled = false;
        this.request.subtotal = this.request.importe + this.request.iva;
        this.request.total =
          this.request.subtotal - this.request.retIsr - this.request.retIva;
        break;
    }
  }
  validations() {
    if (
      this.datosProveedor.nombre &&
      this.request.concepto &&
      this.request.partidaPres &&
      this.request.importe > 0
    ) {
      this.request.concepto = this.request.concepto.trim();
      this.request.partidaPres = this.request.partidaPres.trim();
      this.request.providerName = this.datosProveedor.nombre;
      this.request.numProvider = this.datosProveedor.numero;
      this.request.fechaIni = new Date(
        this.periodoActual.fechaInicio + 'T00:00:00'
      );
      this.request.fechaFin = new Date(
        this.periodoActual.fechaFin + 'T23:59:59'
      );
      this.request.countRequest = this.countRequest.countRequest;
      this.request.periodo = this.periodoActual.nombrePeriodo;
      console.log('Se cumple primer validacion');
    } else {
      console.log('No se cumple primer validacion');
    }
    if (
      this.datosProveedor.nombre &&
      this.request.concepto &&
      this.request.partidaPres &&
      this.request.importe > 0
    ) {
      console.log('Si se cumple la segunda validacion');
      this.modifyBudget();
    } else {
      console.log('No se cumple la segunda validacion');
      alert('Por favor revisa los campos');
    }
  }
  addRequest() {
    if (this.request.partidaPres === '9999') {
      const obj: any = {};
      obj.acumulado = this.acumulado9999 + this.request.importe;
      this.requestService.acumulado9999(this.idCompany, this.idProject, obj);
    }
    if (this.request.iva > 0) {
      const obj: any = {};
      obj.acumulado = this.acumuladoIVA + this.request.iva;
      this.requestService.acumuladoIVA(this.idCompany, this.idProject, obj);
    }
    this.requestService.countRequest(
      this.idCompany,
      this.idProject,
      this.countRequest
    );

    if (this.departamento && this.puesto) {
      this.request.departamento = this.departamento.Descripcion;
      this.request.puesto = this.puesto.Descripcion;
    }
    this.requestService
      .addRequest(this.idCompany, this.idProject, this.request)
      .then(res => {
        this.loading = false;
        Notiflix.Notify.Success('Guardado Exitosamente');
        this.request = {};
        (<any>document.getElementById('selectProvider')).value = '';
        (<any>document.getElementById('inputGroupFile01')).value = '';
        this.datosProveedor = {};
        this.departamento = '';
        this.puesto = '';
        this.filtrarperiodo();
      });
  }
  modifyBudget() {
    this.loading = true;
    this.parPres = {};
    const puesto = this.request.partidaPres.split('-');
    if (this.request.partidaPres === '9999') {
      this.addRequest();
    } else {
      for (let index = 0; index < this.budget.length; index++) {
        const element = this.budget[index];
        if (
          element.Cuenta_Padre === parseInt(puesto[0], 10) &&
          element.Subcuenta === parseInt(puesto[1], 10)
        ) {
          this.parPres = element;
          this.parPres.index = index;
        }
      }
      const valor = this.parPres.Acumulado + this.request.importe;
      if (this.parPres.Importe_Estimado >= valor) {
        const num = this.parPres.index;
        const ruta = num + '.Acumulado';
        const objBudget = { [ruta]: valor };
        this.budgetService
          .modifyBudget2(
            this.idCompany,
            this.idProject,
            this.idBudget,
            objBudget
          )
          .then(res => {
            console.log(
              'Acumulado guardado exitoso!!!! Se modifico el presupuesto.'
            );
            this.addRequest();
          });
      } else {
        this.loading = false;
        Notiflix.Notify.Failure(
          'Esta solicitud excede el limite del presupuesto, el limite es de: ' +
            this.parPres.Importe_Estimado +
            ' y la suma de esta solicitud es de: ' +
            valor
        );
      }
    }
  }
  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.fechaCreado = new Date(this.binding.fechaFin + 'T12:00:00');
  }
  onFileChange(ev) {
    const archivo = ev.target.files[0];
    const lector = new FileReader();
    lector.onload = e => {
      this.xmlToJson(e);
    };
    lector.readAsText(archivo);
  }
  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.assignData(obj);
  }
  assignData(obj: any) {
    this.request.concepto =
      obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
        '@attributes'
      ].Descripcion;
    this.request.folioComp =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    this.request.fechaComp = obj['cfdi:Comprobante'][
      '@attributes'
    ].Fecha.substr(0, 10);
    this.request.importe = parseInt(
      obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']['@attributes']
        .Importe,
      10
    );
    this.request.iva = parseInt(
      obj['cfdi:Comprobante']['cfdi:Impuestos']['@attributes']
        .TotalImpuestosTrasladados,
      10
    );
    this.request.subtotal = this.request.importe + this.request.iva;
    this.request.retIsr = parseInt(
      obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
        'cfdi:Impuestos'
      ]['cfdi:Retenciones']['cfdi:Retencion'][0]['@attributes'].Importe,
      10
    );
    // const isr =
    //   obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
    //     'cfdi:Impuestos'
    //   ]['cfdi:Retenciones']['cfdi:Retencion'];
    // const isr2 = Object.assign([], isr);
    this.request.retIva = parseInt(
      obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
        'cfdi:Impuestos'
      ]['cfdi:Retenciones']['cfdi:Retencion'][1]['@attributes'].Importe,
      10
    );
    this.request.total =
      this.request.subtotal - this.request.retIsr - this.request.retIva;
  }
  validationValSol() {
    if (
      this.valObj.Tipo &&
      this.valObj.Cuenta &&
      (this.valObj.CargoME ||
        this.valObj.AbonoME ||
        this.valObj.Cargo ||
        this.valObj.Abono)
    ) {
      console.log('ok');
      this.addValSol();
      $('#valSol').modal('hide');
    }
  }
  addValSol() {
    this.valSol.push(this.valObj);
    this.valObj = {};
    let suma1 = 0;
    let suma2 = 0;
    let suma3 = 0;
    let suma4 = 0;
    this.valSol.forEach(element => {
      this.valTotales.Cargo = suma1 += element.Cargo || 0;
      this.valTotales.Abono = suma2 += element.Abono || 0;
      this.valTotales.CargoME = suma3 += element.CargoME || 0;
      this.valTotales.AbonoME = suma4 += element.AbonoME || 0;
    });
  }
}
