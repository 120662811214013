import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { User } from 'src/app/models/user';
import { ProjectService } from 'src/app/services/project.service';
import { Period } from 'src/app/models/period';
import Notiflix from 'notiflix-angular';

import { FirebaseApp } from '@angular/fire';
import { EmpresasService } from 'src/app/services/empresas.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
  titulo: string;
  dataUser = {} as User;
  periodoActual = {} as Period;

  objPermisos = {} as any;
  arrPermisos;
  arrItems = [] as any;
  user;
  permiso = {} as any;
  project = {} as any;
  empresas = [] as any;

  idCompany = '';
  idProject = '';
  periods = [];

  time: any;

  menuPrincipal = '';
  usuario = {} as any;

  routerSubscriber: Subscription;
  userSubscriber: Subscription;
  subscribePeriods: Subscription;

  permisosSubscriber: Subscription;
  subscriberProjectActual: Subscription;
  subscriberRoleUser: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private empresaService: EmpresasService,
    private projectService: ProjectService,

    private router: Router,

    private authService: AuthService
  ) {}

  ngOnInit() {
    // this.authService.isLogin().then(result => {
    // console.log(result);
    //   this.dataUser = result;
    //   this.subscriberRoleUser = this.authService
    //     .roleUser(this.dataUser.uid)
    //     .subscribe(res => {
    //       res.forEach(element => {
    //         this.usuario = element;
    //       });
    //     });
    // });
    // let url = this.router.parseUrl(this.router.url).root.children.primary
    //   .segments;
    // this.idCompany = url[1].path;
    // this.idProject = url[3].path;
    // this.getNameProject2(this.idCompany, this.idProject);
    // this.getCompany();
    // this.routerSubscriber = this.activatedRoute.data.subscribe(
    //   res => {
    //     this.titulo = res.title;
    //   },
    //   err => {
    //     console.error('Ocurrio un error', err);
    //   }
    // );
    // this.userSubscriber = this.authService.userData$.subscribe(res => {
    //   this.user = res;
    // });
    // this.startTime();
    // this.getPeriods();
    // this.notificaciones();
    // this.getPermisos();
  }
  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }
  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element: Period = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        // console.log(this.periodoActual);

        break;
      }
    }
  }
  getNameProject2(idCompany, idProject) {
    this.subscriberProjectActual = this.empresaService
      .getProjectActual(idCompany, idProject)
      .subscribe(res => {
        this.project = res;
      });
  }
  getCompany() {
    this.empresaService.getCompanyActual(this.idCompany).subscribe(empresa => {
      this.empresas = empresa;
    });
  }
  startTime() {
    var today = new Date();
    var hr = today.getHours();
    var min = today.getMinutes();
    var sec = today.getSeconds();
    hr = hr == 0 ? 12 : hr;
    hr = hr > 12 ? hr - 12 : hr;
    //Add a zero in front of numbers<10
    hr = this.checkTime(hr);
    min = this.checkTime(min);
    sec = this.checkTime(sec);
    document.getElementById('clock').innerHTML =
      hr + ':' + min + ':' + sec + ' ';

    var months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Deciembre',
    ];
    var days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    var curWeekDay = days[today.getDay()];
    var curDay = today.getDate();
    var curMonth = months[today.getMonth()];
    var curYear = today.getFullYear();
    var date = curWeekDay + ', ' + curDay + ' ' + curMonth + ' ' + curYear;
    document.getElementById('date').innerHTML = date;

    this.time = setTimeout(() => {
      this.startTime();
    }, 500);
  }

  checkTime(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  // getPermisos() {
  //   this.permisosSubscriber = this.authService
  //     .getPremissions()
  //     .subscribe((res: any) => {
  //       this.arrPermisos = Object.assign([], res);
  //       console.log(this.arrPermisos);
  //       this.generarPermisos();
  //     });
  // }

  // generarPermisos() {
  //   for (let index = 0; index < this.arrPermisos.length; index++) {
  //     const element = this.arrPermisos[index];
  //     this.permiso[element.page] = false;
  //     element.users.forEach(elementUsers => {
  //       if (this.user.email === elementUsers) {
  //         this.permiso[element.page] = true;
  //       }
  //     });
  //   }
  //   console.log(this.permiso);
  // }
  notificaciones() {
    const dateActual = new Date();
    this.empresas.forEach(elementEmpresas => {
      console.log(elementEmpresas);
      let tareasVencidas = false;
      if (elementEmpresas.tasks) {
        elementEmpresas.tasks.forEach(elementTask => {
          const dateTask = new Date(elementTask.dateMax + 'T23:59:59');
          if (dateActual > dateTask && !elementTask.file) {
            tareasVencidas = true;
          }
        });
      }
      if (tareasVencidas) {
        // Notiflix.Report.Warning(
        //   'Alerta',
        //   `La empresa ${elementEmpresas.name} tiene tareas vencidas`,
        //   'Ok'
        // );
        Notiflix.Notify.Warning(
          `La empresa ${elementEmpresas.name} tiene tareas vencidas`
        );
      }
    });
  }

  ngOnDestroy() {
    // this.routerSubscriber.unsubscribe();
    // this.userSubscriber.unsubscribe();
    // this.subscribePeriods.unsubscribe();
    // this.permisosSubscriber.unsubscribe();
  }
}
