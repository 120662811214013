import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { NgxXml2jsonService } from 'ngx-xml2json';
import Notiflix from 'notiflix-angular';
import { Subscription } from 'rxjs';
import { EmpresasService } from 'src/app/services/empresas.service';
import { GeneralService } from 'src/app/services/general.service';
import { ProjectService } from 'src/app/services/project.service';
declare var $: any;

@Component({
  selector: 'app-importador',
  templateUrl: './importador.component.html',
  styleUrls: ['./importador.component.css'],
})
export class ImportadorComponent implements OnInit, OnDestroy {
  dataUser = this.generalService.dataUser;
  xml = {} as any;
  arrXML = [] as any;
  idCompany: string;
  idProject: string;
  project = {} as any;
  fileError = [] as any;
  dataUrlXMl = [] as any;
  dataXmlExternos = [] as any;
  fechas = [] as any;

  objDocumento = {} as any;

  public rfcReceptor = '';
  public isProject = false;

  getaddXmlSubscription: Subscription;
  getXMLSubscription: Subscription;
  getCompanySubscription: Subscription;
  subscriberGetProject: Subscription;

  constructor(
    private ngxXml2jsonService: NgxXml2jsonService,
    public router: Router,
    private projectService: ProjectService,
    private empresaService: EmpresasService,
    public storage: AngularFireStorage,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);

    // console.log(url.root.children.primary.segments.length);
    if (url.root.children.primary.segments.length > 2) {
      this.idCompany = url.root.children.primary.segments[1].path;
      this.idProject = url.root.children.primary.segments[3].path;

      this.isProject = true;
      this.getCompany();
      this.getProject();
      this.getXML();
    } else if (this.router.url === '/coords/importador') {
      this.idCompany = this.dataUser.idCompany;
      this.idProject = this.dataUser.idProject;
      this.getCompany();
      this.getProject();
      this.getXML();
    } else if (url.root.children.primary.segments.length === 2) {
      const id = url.root.children.primary.segments[1].path;
      this.getIdRfc(id);
    }
    this.getXmlExernoLink();
  }

  getIdRfc(id) {
    this.projectService.getIdRfc(id).subscribe((res: any) => {
      // console.log(res);
      if (res) {
        this.rfcReceptor = res.rfcReceptor;
      } else {
        Notiflix.Report.Failure(
          'Acceso denegado',
          `Solicita acceso a un administrador`,
          'Ok'
        );
        this.router.navigateByUrl('/');
      }
    });
  }
  getXmlExernoLink() {
    this.getXMLSubscription = this.projectService
      .getXmlExernoLink()
      .subscribe(res => {
        this.dataUrlXMl = res;
        this.dataUrlXMl.forEach(element => {
          if (this.idCompany === element.idCompany) {
            this.dataXmlExternos = element;
          }
        });
        // console.log(this.dataXmlExternos);
      });
  }

  getCompany() {
    this.getCompanySubscription = this.empresaService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        // console.log(res);
        this.rfcReceptor = res.rfc.toUpperCase();
      });
  }

  getProject() {
    this.subscriberGetProject = this.empresaService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        // console.log(res);
        this.project = res;
      });
  }

  getXML() {
    this.getXMLSubscription = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        // console.log(res);
        if (res !== undefined) {
          this.arrXML = res.arrXML;
        }
        this.arrXML.forEach(element => {
          console.log(element);
          const fecha = new Date(element.fecha.seconds * 1000);
          this.fechas.push(fecha.toISOString().split('T')[0]);
        });
      });
  }
  linkCargaXML() {
    let obj = {
      idCompany: this.idCompany,
      idProject: this.idProject,
      rfcReceptor: this.rfcReceptor,
    };
    // this.dataUrlXMl.push(obj);

    this.projectService.addXmlExernoLink(obj).then(id => {
      let newObj = {
        idCompany: this.idCompany,
        idProject: this.idProject,
        rfcReceptor: this.rfcReceptor,
        id: id,
      };
      this.projectService.updateXmlLink(id, newObj).then(res => {
        Notiflix.Notify.Success('se agrego exitosamente ');
      });
      this.dataUrlXMl = newObj;
    });

    // console.log(this.dataUrlXMl);
  }
  exportAsXLSX(): void {
    const arr = [] as any;

    this.arrXML.forEach(element => {
      let obj = {
        Proveedor: element.proveedor,
        RFC: element.rfc,
        Regimen: element.regimen,
        Concepto: element.concepto,
        Folio: element.folioComprobante,
        Fecha: element.fecha,
        Importe: element.subtotal,
        Descuento: element.descuento,
        IVA: element.iva,
        Subtotal: element.total,
        Total: element.total,
        ClaveUnidad: element.claveUnidad,
        ClaveProd: element.claveProdServ,
        Tipo: element.tipoComprobante,
        Metododepago: element.metodoPago,
        Formadepago: element.formaPago,
        Moneda: element.moneda,
        RFCReceptor: element.rfcReceptor,
      } as any;

      arr.push(obj);
      obj = {} as any;
    });

    this.generalService.exportAsExcelFile(arr, 'importador');
  }

  saveXML() {
    const obj = { arrXML: this.arrXML };
    this.projectService
      .saveXML(this.idCompany, this.idProject, obj)
      .then(res => {
        Notiflix.Notify.Success('Se guardo exitosamente');
      });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e, archivo);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    (<any>document.getElementById('inputFiles')).value = '';
  }

  xmlToJson(lector, archivo) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.validarSiExiste(obj, archivo);
  }

  validarSiExiste(obj, archivo) {
    console.log(obj);
    let folio: any;
    try {
      folio =
        obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
          '@attributes'
        ].UUID;
    } catch (error) {
      Notiflix.Notify.Failure(
        `El archivo ${archivo.name} contiene un error y no puede ser leído`
      );
      this.fileError.push(archivo.name);
    }
    const validacion = this.arrXML.findIndex(
      element => element.folioComprobante === folio
    );
    if (validacion === -1) {
      if (
        obj['cfdi:Comprobante']['cfdi:Receptor'][
          '@attributes'
        ].Rfc.toUpperCase() === this.rfcReceptor.toUpperCase()
      ) {
        this.assignData(obj);
      } else {
        Notiflix.Notify.Failure(
          'Agrega unicamente xml que correspondan al RFC receptor'
        );
      }
      // else if (!this.isProject) {
      //   this.assignData(obj);
      // }
      // else if (
      //   obj['cfdi:Comprobante']['cfdi:Receptor'][
      //     '@attributes'
      //   ].Rfc.toUpperCase() !== this.rfcReceptor.toUpperCase() &&
      //   this.isProject
      // ) {
      //   if (this.project.filmadoras) {
      //     this.project.filmadoras.forEach(element => {
      //       if (
      //         obj['cfdi:Comprobante']['cfdi:Receptor'][
      //           '@attributes'
      //         ].Rfc.toUpperCase() === element.rfc
      //       ) {
      //         this.assignData(obj);
      //       }
      //     });
      //   }
      // }
    } else if (validacion > -1) {
      console.log('ya existe');
      Notiflix.Notify.Failure(`El folio ${folio} ya se encuentra registrado.`);
    }
  }

  assignData(obj: any) {
    console.log(obj);
    if (obj['cfdi:Comprobante']) {
      console.log(obj);
      try {
        this.xml.asociado = false;
        this.xml.proveedor =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

        this.xml.rfc =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

        this.xml.regimen =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

        this.xml.rfcReceptor =
          obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc;

        // Valido si es array o un objeto
        if (
          Array.isArray(
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
          )
        ) {
          // Genero un array si tiene varios conceptos <<<<<<<<<<<<
          this.xml.conceptos = [] as any;
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'].forEach(
            element => {
              element['@attributes'].ValorUnitario = parseFloat(
                element['@attributes'].ValorUnitario
              );
              this.xml.conceptos.push(element['@attributes']);
            }
          );
          // >>>>>>>>>>>>>>>>>>>
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveUnidad;
        } else {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveUnidad;
        }
        // -------------------------------------

        this.xml.folioComprobante =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;

        this.xml.fecha = new Date(obj['cfdi:Comprobante']['@attributes'].Fecha);
        this.xml.fecha = obj['cfdi:Comprobante']['@attributes'].Fecha;

        this.xml.importe = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].SubTotal
        );
        this.xml.descuento =
          parseFloat(obj['cfdi:Comprobante']['@attributes'].Descuento) || 0;

        this.xml.tipoComprobante =
          obj['cfdi:Comprobante']['@attributes'].TipoDeComprobante;

        this.xml.metodoPago =
          obj['cfdi:Comprobante']['@attributes'].MetodoPago || '';

        this.xml.formaPago =
          obj['cfdi:Comprobante']['@attributes'].FormaPago || '';

        this.xml.moneda = obj['cfdi:Comprobante']['@attributes'].Moneda;

        this.xml.total = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].Total
        );

        // Validacion si tiene impuestos
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']) {
          // impuestos trasladados
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados']) {
            this.xml.iva = parseFloat(
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados'][
                'cfdi:Traslado'
              ]['@attributes'].Importe
            );
          }

          // retenciones
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
            const retenciones =
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones'][
                'cfdi:Retencion'
              ];
            const esArrayRetenciones = Array.isArray(retenciones);
            if (esArrayRetenciones) {
              retenciones.forEach(element => {
                if (element['@attributes'].Impuesto === '002') {
                  this.xml.retIVA = parseFloat(element['@attributes'].Importe);
                } else if (element['@attributes'].Impuesto === '001') {
                  this.xml.retISR = parseFloat(element['@attributes'].Importe);
                }
              });
            } else {
              if (retenciones['@attributes'].Impuesto === '002') {
                this.xml.retIVA = parseFloat(
                  retenciones['@attributes'].Importe
                );
              } else if (retenciones['@attributes'].Impuesto === '001') {
                this.xml.retISR = parseFloat(
                  retenciones['@attributes'].Importe
                );
              }
            }
          }
        }
        this.arrXML.push(this.xml);
        console.log(this.arrXML);
        this.xml = {};
      } catch (error) {
        console.error('Ocurrio un error');
      }
    }
  }

  async uploadFile(event) {
    const files = Object.assign([], event.target.files);

    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      const fileInput = element;
      const fileType = fileInput.type;
      const fileSize = fileInput.size;
      const allowedExtensions = /(.pdf)$/i;
      if (!allowedExtensions.exec(fileType) || fileSize >= 1000000) {
        alert(
          'Por favor agrega unicamente archivos con extension .pdf y tamaño maximo de 1MB '
        );
        // document.getElementById('labelFile').innerHTML = 'Seleccionar';
        (<any>document.getElementById('inputGroupFile01')).value = '';
      } else {
        const XMLEncontrado = this.arrXML.find(
          XML => XML.folioComprobante === element.name.split('.')[0]
        );
        if (XMLEncontrado) {
          const filePath = `CFDIs/PDF/${element.name}`;
          const path: any = {};
          path.pathImageProfile = filePath;
          XMLEncontrado.path = filePath;
          // const ref = this.storage.ref(filePath);
          // console.log(ref);
          const task = this.storage.upload(filePath, element);
          // task.snapshotChanges().subscribe(res => {
          //   console.log(res.bytesTransferred);
          //   Notiflix.Notify.Success('Se guardo correctamente el PDF');
          // });
          // task.then(res => {
          //   console.log(res);
          //   Notiflix.Notify.Success('Se guardo correctamente el PDF');
          // });
        }
      }
    }
    this.saveXML();
  }

  // downloadPDF(element) {
  //   console.log(element.path);
  //   if (element.path) {
  //     const ref = this.storage.ref(element.path);
  //     const subscriptionURL = ref.getDownloadURL().subscribe(
  //       res => {
  //         window.open(res, '_blank');
  //         subscriptionURL.unsubscribe();
  //       },
  //       error => {
  //         console.error(error);
  //         switch (error.code) {
  //           case 'storage/object-not-found':
  //             // File doesn't exist
  //             break;
  //           case 'storage/unauthorized':
  //             // User doesn't have permission to access the object
  //             break;
  //           case 'storage/canceled':
  //             // User canceled the upload
  //             break;
  //           case 'storage/unknown':
  //             // Unknown error occurred, inspect the server response
  //             break;
  //         }
  //       }
  //     );
  //   }
  // }

  selectXML(XML) {
    console.log(XML);
  }

  ngOnDestroy() {
    if (this.isProject) {
      this.getXMLSubscription.unsubscribe();
      this.getCompanySubscription.unsubscribe();
    }
  }
}
