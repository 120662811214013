import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {
  dataUser = {} as User;
  userType = {} as User;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.isLogin().then(result => {
      this.dataUser = result;
      this.authService.roleUser(this.dataUser.uid).subscribe(res => {
        this.userType = res[0];
        if (this.userType.userType === 'Administrador') {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          return false;
        }
      });
    });
  }

  ngOnInit() {}
}
