import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Provider } from 'src/app/models/providers';
import { ProvidersService } from 'src/app/services/providers.service';
import { ProjectService } from 'src/app/services/project.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { BudgetService } from 'src/app/services/budget.service';
import { RequestService } from 'src/app/services/request.service';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Request } from 'src/app/models/request';
import { Router } from '@angular/router';
import { Budget } from 'src/app/models/budget';
import { Period } from 'src/app/models/period';
import { NgxXml2jsonService } from 'ngx-xml2json';
import Notiflix from 'notiflix-angular';
import { ImportadorComponent } from 'src/app/website/importador/importador.component';
import { Subscription } from 'rxjs';
import { SendEmailService } from 'src/app/services/send-email.service';

declare var $: any;

@Component({
  selector: 'app-reembolso',
  templateUrl: './reembolso.component.html',
  styleUrls: ['./reembolso.component.css'],
})
export class Reembolso2Component implements OnInit {
  xml = {} as any;
  providers: any[];
  providersNac = [] as any;
  providersEx = [] as any;
  providersSelect: any[];
  proveedorSeleccionado: any;
  proveedorSelecionadoCtas = {} as any;
  datosProveedor: any = {} as Provider;
  request = {
    tipoPago: 'Transferencia',
    tipoSolicitud: 'Reembolso',
    estatus: false,
    aprobada: false,
  } as Request;
  idCompany: any;
  idProject: any;
  isDealMemo: any;
  idBudget: any;
  budget: any = [] as Budget;
  ctasPresupuesto;
  periods = [];
  periodoActual = {} as Period;
  countRequest: number;
  departament;
  departamento;
  puestos;
  puesto;
  parPres: any;
  objPres;
  binding: any;
  parIva: any;
  acumulado9999;
  acumuladoIVA;
  valSol = [] as any;
  valObj = {} as any;
  valTotales = {} as any;
  moneda;
  catCtas;
  CtasAfectables = [] as any;
  editVal = {} as any;
  deleteIndex;
  project = {} as any;
  company = {} as any;
  tipoDeSolicitud = true;
  operacionesFiscales = {} as any;
  objDealMemo;
  indexCtaDeal;
  arrXML = [] as any;
  budgetArrays = [] as any;
  rfcReceptor: string;
  validacionXML = false;
  grupos = [] as any;
  // grupoSeleccionado = {} as any;
  filmadora = 'default' as any;
  cuentasPrecargadas = {
    nombre: '',
    cuentas: [],
  };
  cuentas = [] as any;
  valueCtas: any;
  ultimaCuenta: number;
  sumaCtaIVA = 0;
  sumaCtaProveedor = 0;
  changeCountRequest = false;
  arrCountRequest = [] as any;
  changePeriod = false;
  ctaGralObject = {} as any;

  @ViewChild(ImportadorComponent) importadorComponent: ImportadorComponent;

  subscriberProviders: Subscription;
  subscriberBudget: Subscription;
  subscriberCatCtas: Subscription;
  subscribeProject: Subscription;
  subscribeCountRequest: Subscription;
  subscribePeriods: Subscription;
  subscribeGetCFDI: Subscription;
  getCompanySubscription: Subscription;
  subscribeGetDepartament: Subscription;
  subscriptionGetCtas: Subscription;

  constructor(
    private providersService: ProvidersService,
    private budgetService: BudgetService,
    private projectService: ProjectService,
    private empresasService: EmpresasService,
    private requestService: RequestService,
    private dealMemoService: DealMemoService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService,
    private empresaService: EmpresasService,
    private sendEmailService: SendEmailService
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.isDealMemo = url.root.children.primary.segments[4].path;
    this.getCount();
    this.getBudget();
    this.getPeriods();
    this.getCatCtas();
    this.getProviders();
    this.getProject();
    this.getXML();
    this.getCompany();
    // this.getDeptos();
    this.getCuentas();
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        if (res !== undefined) {
          this.arrXML = res.arrXML;
        }
      });
  }

  getCompany() {
    this.getCompanySubscription = this.empresaService
      .getCompanyActual(this.idCompany)
      .subscribe((res: any) => {
        this.company = res;
        this.request.filmadora = {
          name: this.company.name,
          rfc: this.company.rfc,
        };
        this.rfcReceptor = res.rfc.toUpperCase();
      });
  }

  selectFilmadora() {
    if (this.filmadora === 'default') {
      this.request.filmadora = {
        name: this.company.name,
        rfc: this.company.rfc,
      };
    } else {
      this.request.filmadora = {
        name: this.filmadora.name,
        rfc: this.filmadora.rfc,
      };
    }
  }

  getReporDeal(event) {
    this.objDealMemo = event;
    const fechaActual = new Date();
    const arr = event.ctas;
    this.valObj = {};
    this.valSol = [];

    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (
        element.Descripcion === event.departamento &&
        element.Total_Estimado
      ) {
        this.departamento = element;
        this.getPuesto();
      }
      if (element.Descripcion === event.puesto && element.Subtotal_Estimado) {
        this.puesto = element;
        this.getCtaPres();
        break;
      }
    }

    let datoImporte = 0;
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      const fechaInicio = new Date(element.fechaIni + 'T00:00:00');
      const fechaFin = new Date(element.fechaFin + 'T23:59:59');
      if (fechaInicio <= fechaActual && fechaFin >= fechaActual) {
        this.indexCtaDeal = index;
        datoImporte = element.importe;
        this.valObj.Cuenta = '115-01-' + element.cuentaPres;
        this.valObj.Cargo = element.importe;
        this.valObj.CargoME = this.valObj.Cargo / this.request.tipoCambio;
        this.validationValSol();
        break;
      }
    }

    (<any>document.getElementById('selectProvider')).value = event.proveedor;
    this.request.concepto = event.descripcionActividad;
    this.operacionesFiscales = this.projectService.operaciones(
      this.datosProveedor.regimen,
      datoImporte
    );
  }

  getProject() {
    this.subscribeProject = this.empresasService
      .getProjectSpecific(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        console.log(res);
        this.request.tipoMoneda = this.project.monedaLocal;
        this.request.tipoCambio = this.project.tipoCambioGral;
      });
  }

  getCatCtas() {
    this.subscriberCatCtas = this.projectService
      .getCatCtas(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        const arr = this.projectService.ctasFilter(res.catalogo);
        this.catCtas = arr;
      });
  }

  getProviders() {
    this.providersService.getProvider().subscribe(res => {
      const arr = Object.assign([], res);
      let arrProviders = arr.map(element => {
        const obj = {} as any;
        obj.CodigoAgrupador =
          element.nacionalidad === 'Mexicana'
            ? '201-01-' + element.numero.toString().padStart(4, '0')
            : '201-02-' + element.numero.toString().padStart(4, '0');
        obj.rfc = element.rfc;
        obj.Descripcion = element.nombre;
        obj.nacionalidad = element.nacionalidad;
        return obj;
      });

      this.providers = arr;

      arrProviders.forEach(element => {
        if (element.nacionalidad === 'Mexicana') {
          this.providersNac.push(element);
        } else {
          this.providersEx.push(element);
        }
      });
    });
  }

  getDeptos() {
    this.subscribeGetDepartament = this.projectService
      .getGroups(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.grupos = res;
      });
  }

  changeSelectProvider() {
    const segmentos = this.proveedorSeleccionado.split(' - ');
    const numeroProveedor = parseInt(segmentos[0]);

    const proveedor = this.providers.find(
      provider => provider.numero === numeroProveedor
    );

    if (proveedor !== undefined) {
      this.datosProveedor = proveedor;
      this.request.numProvider = proveedor.numero;
      this.request.providerName = proveedor.nombre;
      this.request.rfcProvider = proveedor.rfc;
      this.joinCtas();
    }
  }

  joinCtas() {
    this.CtasAfectables = [];

    if (this.ctasPresupuesto) {
      this.CtasAfectables = this.CtasAfectables.concat(
        this.catCtas,
        this.ctasPresupuesto
      );
    }

    console.log(this.datosProveedor);

    if (this.datosProveedor.numero) {
      const numeroCuenta = this.datosProveedor.numero
        .toString()
        .padStart(3, '0');

      const proveedor = {
        CodigoAgrupador:
          this.datosProveedor.nacionalidad === 'Mexicana'
            ? '201-01-' + numeroCuenta
            : '201-02-' + numeroCuenta,
        Descripcion: this.datosProveedor.nombre,
      };
      this.CtasAfectables.push(proveedor);
    }

    if (this.request.tipoSolicitud === 'GXC') {
      const tipo = {} as any;
      tipo.CodigoAgrupador = '9999';
      tipo.Descripcion = 'GXC';
      this.CtasAfectables.push(tipo);
    }
  }

  getBudget() {
    this.subscriberBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          this.getDepartament();
        }
      });
  }

  getDepartament() {
    this.departament = this.filterBudget('Total_Estimado');
  }

  getPuesto() {
    this.request.departamento = this.departamento.Descripcion;
    this.puestos = [] as any;
    this.puesto = {} as any;
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }

  getCtaPres() {
    this.request.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 4) {
        this.ctasPresupuesto.push(element);
      }
    }
    this.ctasPresupuesto = this.ctasPresupuesto.map(res => {
      const obj = {
        CodigoAgrupador: res.CodigoAgrupador,
        Descripcion: res.Descripcion,
      };
      return obj;
    });
    this.joinCtas();
  }

  ctaGral() {
    for (let index = 0; index < this.valSol.length; index++) {
      const element = this.valSol[index];
      if (element.CuentaPresupuestal) {
        element.NombreCuenta = this.ctaGralObject.Descripcion;
        element.Cuenta = this.ctaGralObject.CodigoAgrupador;
      }
    }
  }

  getPeriods() {
    this.subscribePeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        this.filtrarperiodo();
      });
  }

  filtrarperiodo() {
    const fecha = new Date();
    for (let index = 0; index < this.periods.length; index++) {
      const element: Period = this.periods[index];
      const inicio = new Date(element.fechaInicio + 'T00:00:00');
      const fin = new Date(element.fechaFin + 'T23:59:59');
      if (inicio <= fecha && fin >= fecha) {
        this.periodoActual = element;
        this.request.periodo = element.nombrePeriodo;
        this.request.etapa = element.etapa;
        this.request.fechaIni = element.fechaInicio;
        this.request.fechaFin = element.fechaFin;
        this.request.pagoSoli = element.fechaFin;
        break;
      }
    }
  }

  getCount() {
    this.subscribeCountRequest = this.requestService
      .getCountRequest(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        console.log('CountRequest', res);
        if (res === undefined) {
          this.countRequest = 1;
          this.request.countRequest = this.countRequest;
        } else {
          this.arrCountRequest = res.countRequest;
          this.countRequest = this.arrCountRequest.length + 1;
          this.request.countRequest = this.countRequest;
          for (let index = 0; index < res.countRequest.length; index++) {
            const element = res.countRequest[index];
            console.log(index + 1, element);
            if (element !== index + 1) {
              this.countRequest = res.countRequest[index - 1] + 1;
              this.request.countRequest = this.countRequest;
              break;
            }
          }
        }
      });
  }

  countRequestManual() {
    if (!this.subscribeCountRequest.closed) {
      this.subscribeCountRequest.unsubscribe();
    }
    for (let index = 0; index < this.arrCountRequest.length; index++) {
      const element = this.arrCountRequest[index];
      this.request.countRequest = this.countRequest;
      if (element === this.countRequest) {
        Notiflix.Notify.Failure(
          `La solicitud numero ${this.countRequest} ya existe`
        );
        this.getCount();
        break;
      }
    }
  }

  filterBudget(nivel) {
    const arr = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element[nivel]) {
        arr.push(element);
      }
    }
    return arr;
  }

  filterProvider() {
    const arr = [];
    for (let index = 0; index < this.providers.length; index++) {
      const element = this.providers[index];
      const nombre = element.nombre.toLowerCase();
      if (
        nombre.indexOf(this.proveedorSeleccionado.nombre.toLowerCase()) >= 0
      ) {
        arr.push(element);
      }
    }
    this.providersSelect = arr;
  }

  cambiarPeriodoActual() {
    this.periodoActual = this.binding;
    this.request.periodo = this.binding.nombrePeriodo;
    this.request.fechaIni = this.binding.fechaInicio;
    this.request.fechaFin = this.binding.fechaFin;
    this.request.pagoSoli = this.binding.fechaFin;
    this.request.etapa = this.binding.etapa;
  }

  validationValSol() {
    if (this.valObj.Cuenta && (this.valObj.Cargo || this.valObj.Abono)) {
      if (this.valObj.Cargo === null || this.valObj.Cargo === 0) {
        delete this.valObj.Cargo;
      } else if (this.valObj.Abono === null || this.valObj.Abono === 0) {
        delete this.valObj.Abono;
      }
      this.addValSol();
    } else {
      Notiflix.Notify.Failure('Completa los campos');
    }
  }

  addValSol() {
    let suma1 = 0;
    let suma2 = 0;
    let cuentaVerdadera = false;
    this.CtasAfectables.forEach(element => {
      if (element.CodigoAgrupador === this.valObj.Cuenta) {
        this.valObj.NombreCuenta = element.Descripcion;
        cuentaVerdadera = true;
      }
    });
    if (cuentaVerdadera) {
      this.valSol.push(this.valObj);
      this.request.movimientos = this.valSol;
      this.valSol.forEach(element => {
        this.valTotales.Cargo = suma1 += element.Cargo || 0;
        this.valTotales.Abono = suma2 += element.Abono || 0;
      });
      this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
      this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
      (<any>document.getElementById('abono')).disabled = false;
      (<any>document.getElementById('cargo')).disabled = false;
      this.valObj = {};
      $('#valSol').modal('hide');
    } else {
      Notiflix.Notify.Failure('Cuenta no valida');
    }
  }

  editValSol() {
    let suma1 = 0;
    let suma2 = 0;
    let cuentaVerdadera = false;
    this.CtasAfectables.forEach(element => {
      if (element.CodigoAgrupador === this.editVal.Cuenta) {
        this.editVal.NombreCuenta = element.Descripcion;
        cuentaVerdadera = true;
      }
    });
    if (cuentaVerdadera) {
      this.request.movimientos = this.valSol;
      this.valSol.forEach(element => {
        this.valTotales.Cargo = suma1 += element.Cargo || 0;
        this.valTotales.Abono = suma2 += element.Abono || 0;
      });
      this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
      this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
      (<any>document.getElementById('abono')).disabled = false;
      (<any>document.getElementById('cargo')).disabled = false;
      this.editVal = {};
      $('#valSol').modal('hide');
    } else {
      Notiflix.Notify.Failure('Cuenta no valida');
    }
  }

  validationsAddRequest() {
    // this.request.idDepartament = this.grupoSeleccionado.id;
    // this.request.nameDepartament = this.grupoSeleccionado.name;

    const request = this.request;
    let ctasAny = true;

    // Busco que tengan cuentas presupuestales el calendario de pagos
    this.request.movimientos.forEach((element: any) => {
      if (element.Cuenta) {
        const segments = element.Cuenta.split('-');
        if (segments[0] === '201') {
          this.request.providerName = element.NombreCuenta;
          this.request.numProvider = parseInt(segments[2]);
        }
      } else {
        ctasAny = false;
      }
    });
    // ----------------------------------------

    this.request.fechaCreado = new Date();
    if (this.request.tipoSolicitud === 'GXC') {
      this.request.comprobado = 0;
    } else {
      delete this.request.comprobado;
    }
    if (
      request.numProvider > 0 &&
      request.concepto.length &&
      request.pagoSoli &&
      request.solicito.length &&
      request.departamento &&
      request.puesto &&
      request.tipoCambio > 0 &&
      ctasAny
    ) {
      if (
        request.movimientos &&
        this.valTotales.Cargo === this.valTotales.Abono
      ) {
        this.addRequest();
      } else {
        Notiflix.Notify.Failure(
          'Las sumas deben ser iguales y debe tener movimientos'
        );
      }
    } else {
      Notiflix.Notify.Failure(
        'Revisa que los campos y cuentas sean completados'
      );
    }
  }

  validateDealMemo() {
    if (this.isDealMemo === 'consultDealMemo') {
      if (
        this.objDealMemo.ctas[this.indexCtaDeal].semanas >
        this.objDealMemo.ctas[this.indexCtaDeal].contadorPagos
      ) {
        this.addRequest();
      } else {
        Notiflix.Notify.Failure(
          'Ya no puedes crear mas solicitudes del Deal Memo en esta etapa'
        );
      }
    } else {
      this.addRequest();
    }
  }

  addRequest() {
    this.arrCountRequest.push(this.countRequest);
    this.requestService
      .addRequest(this.idCompany, this.idProject, this.request)
      .then(res => {
        this.requestService.countRequest(
          this.idCompany,
          this.idProject,
          this.arrCountRequest
        );

        // Si esta desuscrito se vuelve a subscribir
        // if (this.subscribeCountRequest) {
        //   this.getCount();
        // }

        this.countRequest++;

        console.log(res);

        // Enviamos email
        // if (true) {
        //   const email = 'jgrijalva10@outlook.com';
        //   const id = res.id;
        //   this.sendEmailService.sendEmail(email, id);
        // }

        // Actualizamos deal memo
        if (this.isDealMemo === 'consultDealMemo') {
          this.objDealMemo.ctas[this.indexCtaDeal].contadorPagos += 1;
          this.dealMemoService.updateDealMemo(
            this.idCompany,
            this.idProject,
            this.objDealMemo.idDocument,
            this.objDealMemo
          );
        }
        // Guardamos XML
        const obj = { arrXML: this.arrXML };
        this.projectService.saveXML(this.idCompany, this.idProject, obj);
        $('#modalRequest').modal('hide');
        Notiflix.Notify.Success('Guardado Exitoso');
        this.request = {
          tipoPago: 'Transferencia',
          tipoSolicitud: 'Reembolso',
          estatus: false,
          aprobada: false,
          tipoCambio: this.project.tipoCambioGral,
          tipoMoneda: this.project.monedaLocal,
          movimientos: [],
        } as Request;
        this.datosProveedor = {};
        this.departamento = '';
        this.puesto = '';
        this.valSol = [];
        this.valTotales = {
          Cargo: '0',
          Abono: '0',
        };
        this.validacionXML = false;
        this.proveedorSeleccionado = '';
        (<any>document.getElementById('selectProvider')).value = '';
        (<any>document.getElementById('inputFiles')).value = '';
        $('#formRequest').removeClass('was-validated');
      })
      .catch(err => {
        console.error(err);
        Notiflix.Notify.Failure('Ocurrio un error al guardar la solicitud');
      });
  }

  selectValSol(obj, index) {
    $('#valSol').modal('show');
    this.editVal = obj;
    this.deleteIndex = index;
  }

  deleteValSol() {
    const index = this.deleteIndex;
    let suma1 = 0;
    let suma2 = 0;
    this.valSol.splice(index, 1);
    this.request.movimientos = this.valSol;
    if (this.valSol.length === 0) {
      this.valTotales.Cargo = 0;
      this.valTotales.Abono = 0;
    } else {
      this.valSol.forEach(element => {
        this.valTotales.Cargo = suma1 += element.Cargo || 0;
        this.valTotales.Abono = suma2 += element.Abono || 0;
      });
      this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
      this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
    }
    $('#valSol').modal('hide');
  }

  onFileChange(ev) {
    this.ultimaCuenta = ev.target.files.length - 1;
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e, index);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    (<any>document.getElementById('inputFiles')).value = '';
  }

  xmlToJson(lector, index) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.validarSiExiste(obj, index);
  }

  validarSiExiste(obj, index) {
    console.log(obj);
    const folio =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    // Regresa el index si encuentra el folio
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === folio
    );
    console.log(indexXML);
    const XML = this.arrXML[indexXML];

    // -------------------------

    // Buscar rfc de empresa receptora
    const rfcReceptor =
      obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc.toUpperCase();

    let buscarRfc = -1;
    if (this.project.filmadoras) {
      buscarRfc = this.project.filmadoras.findIndex(
        element => element.rfc === rfcReceptor
      );
    }
    // --------------------------
    if (indexXML === -1) {
      if (
        obj['cfdi:Comprobante']['cfdi:Receptor'][
          '@attributes'
        ].Rfc.toUpperCase() === this.rfcReceptor.toUpperCase() ||
        buscarRfc > -1
      ) {
        this.validacionXML = true;
        this.selectProvider(obj, index);
      } else {
        Notiflix.Notify.Failure('El RFC receptor no corresponde a la empresa');
      }
    } else if (indexXML > -1) {
      console.log('ya existe');
      if (XML.asociado) {
        console.log('y ya esta asociado');
        Notiflix.Notify.Failure(
          `El folio ${folio} ya se encuentra asociado con la solicitud ${XML.request}.`
        );
      } else {
        console.log('pero no esta asociado');
        this.validacionXML = false;
        XML.asociado = true;
        XML.request = this.countRequest;
        this.selectProvider(obj, index);
      }
    }
  }

  selectProvider(obj, indexFile) {
    const rfc = obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;
    const index = this.providers.findIndex(provider => provider.rfc === rfc);

    if (index > -1) {
      const proveedor = this.providers[index];
      this.proveedorSeleccionado = proveedor.numero + ' - ' + proveedor.nombre;
      this.datosProveedor = proveedor;
      this.request.folioComp =
        obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
          '@attributes'
        ].UUID;
      this.request.fechaComp = obj['cfdi:Comprobante'][
        '@attributes'
      ].Fecha.slice(0, 10);
      this.request.tipoMoneda = obj['cfdi:Comprobante']['@attributes'].Moneda;
      // Valido si es array o un objeto
      if (
        Array.isArray(
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
        )
      ) {
        this.request.concepto =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
            '@attributes'
          ].Descripcion;
      } else {
        this.request.concepto =
          obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
            '@attributes'
          ].Descripcion;
      }
      // -------------------------------------
      this.request.providerName = proveedor.nombre;
      this.request.rfcProvider = proveedor.rfc;
      this.request.numProvider = proveedor.numero;
      this.assignData(obj, indexFile);
    } else {
      Notiflix.Notify.Failure('El proveedor no se encuentra registrado');
      // ------
      Notiflix.Confirm.Show(
        'Agregar Proveedor',
        'Deseas agregar el proveedor a la base de datos?',
        'Si',
        'No',
        () => {
          const provider = {} as any;
          provider.nombre =
            obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

          provider.rfc =
            obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

          provider.regimen =
            obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

          provider.lugarExpedicion =
            obj['cfdi:Comprobante']['@attributes'].LugarExpedicion;

          provider.persona = provider.rfc.length === 12 ? 'Moral' : 'Física';
          provider.nacionalidad = 'Mexicana';
          provider.numero = this.providers.length + 1;
          this.providers.push(provider);
          const objProviders = Object.assign({}, this.providers);
          this.providersService.addProvider(objProviders).then(() => {
            Notiflix.Notify.Success('Guardado exitosamente');
            this.selectProvider(obj, indexFile);
          });
        },
        () => {}
      );
    }
  }

  assignData(obj: any, indexFile: number) {
    console.log(obj);
    if (obj['cfdi:Comprobante']) {
      try {
        this.xml.request = this.countRequest;
        this.xml.asociado = true;
        this.xml.proveedor =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

        this.xml.rfc =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

        this.xml.regimen =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

        this.xml.rfcReceptor =
          obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc;

        // Valido si es array o un objeto
        if (
          Array.isArray(
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
          )
        ) {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveUnidad;
        } else {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveUnidad;
        }
        // -------------------------------------

        // this.xml.folioComprobante =
        //   obj['cfdi:Comprobante']['cfdi:Complemento'][
        //     'tfd:TimbreFiscalDigital'
        //   ]['@attributes'].UUID;

        // this.xml.fecha = obj['cfdi:Comprobante']['@attributes'].Fecha;

        this.xml.subtotal = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].SubTotal
        );

        this.xml.descuento =
          parseFloat(obj['cfdi:Comprobante']['@attributes'].Descuento) || 0;

        this.xml.tipoComprobante =
          obj['cfdi:Comprobante']['@attributes'].TipoDeComprobante;

        this.xml.metodoPago =
          obj['cfdi:Comprobante']['@attributes'].MetodoPago || '';

        this.xml.formaPago =
          obj['cfdi:Comprobante']['@attributes'].FormaPago || '';

        this.xml.moneda = obj['cfdi:Comprobante']['@attributes'].Moneda;

        this.xml.total = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].Total
        );

        // Validacion si tiene impuestos
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']) {
          // impuestos trasladados
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados']) {
            this.xml.iva = parseFloat(
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados'][
                'cfdi:Traslado'
              ]['@attributes'].Importe
            );
          }

          // retenciones
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
            const retenciones =
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones'][
                'cfdi:Retencion'
              ];
            const esArrayRetenciones = Array.isArray(retenciones);
            if (esArrayRetenciones) {
              retenciones.forEach(element => {
                if (element['@attributes'].Impuesto === '002') {
                  this.xml.retIVA = parseFloat(element['@attributes'].Importe);
                } else if (element['@attributes'].Impuesto === '001') {
                  this.xml.retISR = parseFloat(element['@attributes'].Importe);
                }
              });
            } else {
              if (retenciones['@attributes'].Impuesto === '002') {
                this.xml.retIVA = parseFloat(
                  retenciones['@attributes'].Importe
                );
              } else if (retenciones['@attributes'].Impuesto === '001') {
                this.xml.retISR = parseFloat(
                  retenciones['@attributes'].Importe
                );
              }
            }
          }
        }
        const numeroCuenta = this.datosProveedor.numero
          .toString()
          .padStart(3, '0');
        const proveedor = {
          CodigoAgrupador:
            this.datosProveedor.nacionalidad === 'Mexicana'
              ? '201-01-' + numeroCuenta
              : '201-02-' + numeroCuenta,
          Descripcion: this.datosProveedor.nombre,
        };

        this.sumaCtaProveedor += this.xml.subtotal;
        this.sumaCtaIVA += this.xml.iva;

        console.log(this.sumaCtaProveedor);

        const cuenta = {
          Cargo: this.xml.subtotal,
          Cuenta: 'PENDIENTE',
          NombreCuenta: 'PENDIENTE',
          CuentaPresupuestal: true,
        };

        this.valSol.splice(0, 0, cuenta);

        if (indexFile === this.ultimaCuenta) {
          this.valSol.push(
            {
              Cargo: this.sumaCtaIVA,
              Cuenta: '119-01-001',
              NombreCuenta: 'IVA',
            },
            {
              Abono: this.xml.retISR || 0,
              Cuenta: '216-06-001',
              NombreCuenta: 'Retencion de ISR',
            },
            {
              Abono: this.xml.retIVA || 0,
              Cuenta: '216-10-001',
              NombreCuenta: 'Retencion de IVA',
            },
            {
              Abono:
                this.sumaCtaProveedor +
                this.sumaCtaIVA -
                (this.xml.retISR || 0) -
                (this.xml.retIVA || 0),
              Cuenta: proveedor.CodigoAgrupador,
              NombreCuenta: proveedor.Descripcion,
            }
          );
        }
        this.totales();
        this.request.movimientos = this.valSol;

        if (this.validacionXML) {
          this.arrXML.push(this.xml);
          this.xml = {};
        }
        console.log(this.arrXML);
      } catch (error) {
        console.error('Ocurrio un error', error);
      }
    }
  }

  totales() {
    let suma1 = 0;
    let suma2 = 0;
    this.valSol.forEach(element => {
      this.valTotales.Cargo = suma1 += element.Cargo || 0;
      this.valTotales.Abono = suma2 += element.Abono || 0;
    });
    this.valTotales.Cargo = this.valTotales.Cargo.toFixed(3);
    this.valTotales.Abono = this.valTotales.Abono.toFixed(3);
  }

  inputValue() {
    if (this.valObj.Cargo) {
      (<any>document.getElementById('abono')).disabled = true;
    } else if (this.valObj.Abono) {
      (<any>document.getElementById('cargo')).disabled = true;
    } else {
      (<any>document.getElementById('abono')).disabled = false;
      (<any>document.getElementById('cargo')).disabled = false;
    }
  }

  guardarCuentas() {
    console.log(this.request);

    if (this.request.movimientos && this.cuentasPrecargadas.nombre) {
      this.cuentasPrecargadas.cuentas = this.request.movimientos;
      this.requestService.addCtas(
        this.idCompany,
        this.idProject,
        this.cuentasPrecargadas
      );
    } else {
      Notiflix.Notify.Failure('Agrega Cuentas y un nombre');
    }

    console.log(this.cuentasPrecargadas);
  }

  getCuentas() {
    this.subscriptionGetCtas = this.requestService
      .getCtas(this.idCompany, this.idProject)
      .subscribe(res => {
        console.log(res);
        this.cuentas = res;
      });
  }

  selectCtas() {
    console.log(this.valueCtas);
    this.request.movimientos = this.valueCtas.cuentas;
    this.valSol = this.valueCtas.cuentas;
    this.totales();
  }

  deleteCtas(idDoc) {
    this.requestService.deleteCtas(this.idCompany, this.idProject, idDoc);
  }

  ngOnDestroy() {
    this.subscriberCatCtas.unsubscribe();
    this.subscriberBudget.unsubscribe();
    this.subscribeCountRequest.unsubscribe();
    this.subscribePeriods.unsubscribe();
    this.subscribeProject.unsubscribe();
    this.subscribeGetCFDI.unsubscribe();
    this.getCompanySubscription.unsubscribe();
    this.subscriptionGetCtas.unsubscribe();
  }
}
