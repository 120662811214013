import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/services/request.service';
import { Request } from 'src/app/models/request';
import Notiflix from 'notiflix-angular';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { ProjectService } from 'src/app/services/project.service';
import { Comprobaciones } from 'src/app/models/comprobaciones';
import { ChecksService } from 'src/app/services/checks.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comprobacion',
  templateUrl: './comprobacion.component.html',
  styleUrls: ['./comprobacion.component.css'],
})
export class ComprobacionComponent implements OnInit {
  dataUser = {} as any;
  idCompany: string;
  idProject: string;
  requests: Array<any>;
  arrXML = [] as any;
  xml = {} as any;
  noDeducible = {} as any;
  comprobacion = {
    comprobantes: [],
    deducible: '',
  } as Comprobaciones;
  partidaGeneral: any;
  departament = {} as any;
  cuentas = [] as any;
  counter = 0;

  totales = {} as any;

  deudores = [] as any;

  arrRequest = [] as any;

  rfcSeleccionado = '' as any;
  deudorSeleccionado = {} as any;

  getGXCSubscription: Subscription;
  subscribeGetCFDI: Subscription;
  subscribeGetDepartament: Subscription;

  constructor(
    private requestService: RequestService,
    private projectService: ProjectService,
    private checksService: ChecksService,
    private ngxXml2jsonService: NgxXml2jsonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getXML();
    this.getGXC();
  }

  getGXC() {
    this.getGXCSubscription = this.requestService
      .getGXC(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        this.deudores = res;

        // this.arrRequest = res.solicitudes.map(request => {
        //   let importe = 0;
        //   request.movimientos.forEach((element: any) => {
        //     if (element.Cargo && element.Cuenta !== '119-01-001') {
        //       importe += element.Cargo;
        //     }
        //   });
        //   request.importe = importe;
        //   return request;
        // });

        // let objDuplicados: any = this.arrRequest.map((item: any) => {
        //   return [item.rfcProvider, item];
        // });
        // var arrObj = new Map(objDuplicados); // Pares de clave y valor

        // this.deudores = [...arrObj.values()]; // Conversión a un array

        console.log(this.deudores);
      });
  }

  selectDeudor() {
    const arrFiltrado = [] as any;

    this.arrRequest = this.deudorSeleccionado.solicitudes;

    this.deudorSeleccionado.solicitudes.forEach(elementSolicitudes => {
      let suma = 0;
      elementSolicitudes.comprobantes.forEach(elementComprobantes => {
        suma += elementComprobantes.total;
      });
      elementSolicitudes.comprobado = suma;
    });

    console.log(this.arrRequest);

    this.requests = this.arrRequest.sort((a: any, b: any) => {
      return a.countRequest - b.countRequest;
    });
    // this.getDepartament();
    // this.getCountUser();
    // this.totalesFunction();
  }

  getCountUser() {
    this.projectService
      .getCountUser(
        this.idCompany,
        this.idProject,
        this.deudorSeleccionado.rfcProvider
      )
      .subscribe((res: any) => {
        if (res) {
          this.counter = res.countCheck + 1;
        } else {
          this.counter = 1;
        }
      });
  }

  getDepartament() {
    this.subscribeGetDepartament = this.projectService
      .getGroup(
        this.idCompany,
        this.idProject,
        this.deudorSeleccionado.idDepartament
      )
      .subscribe((res: any) => {
        if (res) {
          this.departament = res;
          this.comprobacion.departamento = res.name;
          this.cuentas = res.cuentas;
        }
      });
  }

  getXML() {
    this.subscribeGetCFDI = this.projectService
      .getXML(this.idCompany, this.idProject)
      .subscribe((res: any) => {
        // XML
        console.log(res);
        if (res !== undefined) {
          this.arrXML = res.arrXML;
        } else {
          this.arrXML = [];
        }
      });
  }

  totalesFunction() {
    this.totales.totalImporte = 0;
    this.totales.totalComprobado = 0;
    this.requests.forEach(element => {
      this.totales.totalImporte += element.importe;
      this.totales.totalComprobado += element.comprobado;
    });
  }

  onFileChange(ev) {
    for (let index = 0; index < ev.target.files.length; index++) {
      const archivo = ev.target.files[index];
      if (archivo.type === 'text/xml') {
        const lector = new FileReader();
        lector.onload = e => {
          this.xmlToJson(e);
        };
        lector.readAsText(archivo);
      } else {
        Notiflix.Notify.Failure(
          `El archivo ${archivo.name} no es un archivo XML`
        );
      }
    }
    (<any>document.getElementById('inputFiles')).value = '';
  }

  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.validarSiExiste(obj);
  }

  validarSiExiste(obj) {
    const folio =
      obj['cfdi:Comprobante']['cfdi:Complemento']['tfd:TimbreFiscalDigital'][
        '@attributes'
      ].UUID;
    const indexXML = this.arrXML.findIndex(
      element => element.folioComprobante === folio
    );
    console.log(this.arrXML[indexXML]);
    const indexXMLLocal = this.comprobacion.comprobantes.findIndex(
      element => element.folioComprobante === folio
    );
    if (
      (indexXML === -1 && indexXMLLocal === -1) ||
      (indexXML > -1 && indexXMLLocal === -1 && !this.arrXML[indexXML].asociado)
    ) {
      if (!obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
        // this.selectProvider(obj);
        this.assignData(obj);
        // this.validacionXML = true;
      } else {
        Notiflix.Notify.Failure('El CFDI no debe tener retenciones');
        // Notiflix.Notify.Failure('El RFC receptor no corresponde a la empresa');
      }
    } else {
      Notiflix.Notify.Failure(`El folio ${folio} ya se encuentra asociado.`);
    }
  }

  assignData(obj: any) {
    if (obj['cfdi:Comprobante']) {
      try {
        // this.xml.comprobacion = this.comprobacion.countCheck;
        this.xml.asociado = true;
        this.xml.proveedor =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Nombre;

        this.xml.rfc =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].Rfc;

        this.xml.regimen =
          obj['cfdi:Comprobante']['cfdi:Emisor']['@attributes'].RegimenFiscal;

        this.xml.rfcReceptor =
          obj['cfdi:Comprobante']['cfdi:Receptor']['@attributes'].Rfc;

        // Valido si es array o un objeto
        if (
          Array.isArray(
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto']
          )
        ) {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][0 || 1][
              '@attributes'
            ].ClaveUnidad;
        } else {
          this.xml.concepto =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].Descripcion;

          this.xml.claveProdServ =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveProdServ;

          this.xml.claveUnidad =
            obj['cfdi:Comprobante']['cfdi:Conceptos']['cfdi:Concepto'][
              '@attributes'
            ].ClaveUnidad;
        }
        // -------------------------------------

        this.xml.folioComprobante =
          obj['cfdi:Comprobante']['cfdi:Complemento'][
            'tfd:TimbreFiscalDigital'
          ]['@attributes'].UUID;

        this.xml.fecha = obj['cfdi:Comprobante']['@attributes'].Fecha;

        this.xml.subtotal = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].SubTotal
        );

        this.xml.descuento =
          parseFloat(obj['cfdi:Comprobante']['@attributes'].Descuento) || 0;

        this.xml.tipoComprobante =
          obj['cfdi:Comprobante']['@attributes'].TipoDeComprobante;

        this.xml.metodoPago =
          obj['cfdi:Comprobante']['@attributes'].MetodoPago || '';

        this.xml.formaPago =
          obj['cfdi:Comprobante']['@attributes'].FormaPago || '';

        this.xml.moneda = obj['cfdi:Comprobante']['@attributes'].Moneda;

        this.xml.total = parseFloat(
          obj['cfdi:Comprobante']['@attributes'].Total
        );

        // Validacion si tiene impuestos
        if (obj['cfdi:Comprobante']['cfdi:Impuestos']) {
          // impuestos trasladados
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados']) {
            this.xml.iva = parseFloat(
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Traslados'][
                'cfdi:Traslado'
              ]['@attributes'].Importe
            );
          }

          // retenciones
          if (obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones']) {
            const retenciones =
              obj['cfdi:Comprobante']['cfdi:Impuestos']['cfdi:Retenciones'][
                'cfdi:Retencion'
              ];
            const esArrayRetenciones = Array.isArray(retenciones);
            if (esArrayRetenciones) {
              retenciones.forEach(element => {
                if (element['@attributes'].Impuesto === '002') {
                  this.xml.retIVA = parseFloat(element['@attributes'].Importe);
                } else if (element['@attributes'].Impuesto === '001') {
                  this.xml.retISR = parseFloat(element['@attributes'].Importe);
                }
              });
            } else {
              if (retenciones['@attributes'].Impuesto === '002') {
                this.xml.retIVA = parseFloat(
                  retenciones['@attributes'].Importe
                );
              } else if (retenciones['@attributes'].Impuesto === '001') {
                this.xml.retISR = parseFloat(
                  retenciones['@attributes'].Importe
                );
              }
            }
          }
        }

        this.xml.solicitudes = [] as any;

        if (
          this.xml.total >
          this.totales.totalImporte - this.totales.totalComprobado
        ) {
          Notiflix.Notify.Failure(
            `El folio ${this.xml.folioComprobante} excede el limite por comprobar`
          );
          throw new Error(
            'Error intentionally created to halt process. Not an actual error.'
          );
        }

        let aplicado = 0;
        for (let index = 0; index < this.requests.length; index++) {
          const request = this.requests[index];
          request.comprobaciones
            ? request.comprobaciones
            : (request.comprobaciones = [] as any);

          const disponibleSolicitud = request.importe - request.comprobado; //disponible para usar
          if (this.xml.total - aplicado <= disponibleSolicitud) {
            const objSol = {
              idSol: 'request.idSol',
              importe: this.xml.total - aplicado,
              solicitud: request.countRequest,
            };
            this.xml.solicitudes.push(objSol);
            const objComp = {
              comprobacion: this.counter,
              importe: this.xml.total - aplicado,
            };
            request.comprobaciones.push(objComp);
            request.comprobado += this.xml.total - aplicado;
            break;
          } else if (
            this.xml.total - aplicado >= disponibleSolicitud &&
            disponibleSolicitud !== 0
          ) {
            aplicado += disponibleSolicitud;
            const objSol = {
              idSol: 'request.idSol',
              importe: aplicado,
              solicitud: request.countRequest,
            };
            this.xml.solicitudes.push(objSol);
            const objComp = {
              comprobacion: this.counter,
              importe: aplicado,
            };
            request.comprobaciones.push(objComp);
            request.comprobado += aplicado;
          }
        }

        // this.arrXML.push(this.xml);
        this.xml.inventario = 'No';
        this.xml.CodigoAgrupador = this.partidaGeneral;
        this.comprobacion.comprobantes.push(this.xml);
        this.xml = {};
      } catch (error) {
        console.error('Ocurrio un error', error);
      }
    }
    this.sumas();
    this.totalesFunction();
  }

  sumas() {
    this.totales.importe = 0;
    this.totales.iva = 0;
    this.totales.total = 0;
    this.comprobacion.comprobantes.forEach(element => {
      this.totales.importe += element.subtotal;
      this.totales.iva += element.iva;
      this.totales.total += element.total;
    });
    this.comprobacion.importe = this.totales.importe;
    this.comprobacion.iva = this.totales.iva ? this.totales.iva : 0;
    this.comprobacion.total = this.totales.total;
  }

  changeCtaGral() {
    if (this.comprobacion.comprobantes.length > 0) {
      this.comprobacion.comprobantes.forEach(element => {
        element.CodigoAgrupador = this.partidaGeneral;
      });
    }
  }

  saveComprobacion() {
    this.comprobacion.fechaCreacion = new Date();
    // this.comprobacion.createBy = this.dataUser.email;
    this.comprobacion.deudor = this.deudorSeleccionado.providerName;
    this.comprobacion.rfcProvider = this.deudorSeleccionado.rfcProvider;
    this.comprobacion.countCheck = this.counter;
    this.comprobacion.aprobada = false;

    console.log(this.comprobacion);
    if (this.comprobacion.comprobantes.length > 0) {
      this.checksService
        .addCheck(this.idCompany, this.idProject, this.comprobacion)
        .then(res => {
          this.saveXML(res.id);
          this.sumas();
          this.updateCountCheck();
          Notiflix.Notify.Success('Guardado Exitoso');
        });
    }
  }

  updateCountCheck() {
    this.departament.countCheck = this.counter;
    const obj = {
      countCheck: this.counter,
    };
    this.projectService.updateCountCkeckUser(
      this.idCompany,
      this.idProject,
      this.deudorSeleccionado.rfcProvider,
      obj
    );
  }

  saveXML(idComprobacion) {
    if (this.comprobacion.deducible === 'si') {
      this.comprobacion.comprobantes.forEach(element => {
        element.check = idComprobacion;
      });
      this.arrXML = this.arrXML.concat(this.comprobacion.comprobantes);
      const obj = { arrXML: this.arrXML };
      this.projectService.saveXML(this.idCompany, this.idProject, obj);
    }
    this.updateSols();
  }

  updateSols() {
    this.requests.forEach(sol => {
      this.requestService.updateRequest(
        this.idCompany,
        this.idProject,
        sol.idSol,
        sol
      );
    });

    this.comprobacion = {
      comprobantes: [],
      deducible: 'si',
      nombre: this.dataUser.name,
      proyecto: this.dataUser.nameProject,
      departamento: this.departament.name,
    } as Comprobaciones;
    this.getGXC();
  }

  addMovimiento() {
    let aplicado = 0;
    console.log(this.requests);

    if (
      this.xml.total >
      this.totales.totalImporte - this.totales.totalComprobado
    ) {
      Notiflix.Notify.Failure(
        `El folio ${this.xml.folioComprobante} excede el limite por comprobar`
      );
      throw new Error(
        'Error intentionally created to halt process. Not an actual error.'
      );
    }

    this.xml.solicitudes = [] as any;
    for (let index = 0; index < this.requests.length; index++) {
      const request = this.requests[index];
      request.comprobaciones
        ? request.comprobaciones
        : (request.comprobaciones = [] as any);

      const disponibleSolicitud = request.importe - request.comprobado; //disponible para usar
      if (this.xml.total - aplicado <= disponibleSolicitud) {
        const objSol = {
          idSol: request.idSol,
          importe: this.xml.total - aplicado,
          solicitud: request.countRequest,
        };
        console.log(objSol);
        console.log(this.xml);
        this.xml.solicitudes.push(objSol);
        const objComp = {
          comprobacion: this.counter,
          importe: this.xml.total - aplicado,
        };
        request.comprobaciones.push(objComp);
        request.comprobado += this.xml.total - aplicado;
        break;
      } else if (
        this.xml.total - aplicado >= disponibleSolicitud &&
        disponibleSolicitud !== 0
      ) {
        aplicado += disponibleSolicitud;
        const objSol = {
          idSol: 'request.idSol',
          importe: aplicado,
          solicitud: request.countRequest,
        };
        this.xml.solicitudes.push(objSol);
        const objComp = {
          comprobacion: this.counter,
          importe: aplicado,
        };
        request.comprobaciones.push(objComp);
        request.comprobado += aplicado;
      }
    }
    this.comprobacion.comprobantes.push(this.xml);
    this.xml = {};
    this.sumas();
    this.totalesFunction();
  }

  ngOnDestroy() {
    this.getGXCSubscription.unsubscribe();
    this.subscribeGetCFDI.unsubscribe();
    if (this.subscribeGetDepartament) {
      this.subscribeGetDepartament.unsubscribe();
    }
  }
}
