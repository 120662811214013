import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DealMemoService } from 'src/app/services/deal-memo.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { ProjectService } from 'src/app/services/project.service';
import { BudgetService } from 'src/app/services/budget.service';
import { DealMemo } from 'src/app/models/dealMemo';
import Notiflix from 'notiflix-angular';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';

declare var $;

@Component({
  selector: 'app-deal-memo-report',
  templateUrl: './deal-memo-report.component.html',
  styleUrls: ['./deal-memo-report.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DealMemoReportComponent implements OnInit, OnDestroy {
  idCompany: string;
  idProject: string;
  dealMemos: Array<any>;
  dealMemo = {
    personasPS: [],
    pagos: [],
    cuentasPer: [],
    documentos: [],
  } as DealMemo;
  numeros = {} as any;
  dealMemoSeleccionado = {
    personasPS: [],
    pagos: [],
    cuentasPer: [],
    documentos: [],
  } as any;
  consultarPor: any;
  sumaPagos = 0;
  valorDeal: any;
  periodos = [] as any;

  departament = [];
  puestos = [];
  ctasPresupuesto: any[];
  persona = {} as any;
  cuentas = {} as any;
  departamento;
  puesto;
  budget = [] as any;
  lock = false;
  dataUser: any;
  request = [] as any;
  validacionPagos = false;

  dealMemoClon: any;
  dealMemoOriginal: any;

  subscriptionGetDealMemo: Subscription;
  subscriberGetPeriods: Subscription;
  subscriberGetBudget: Subscription;
  subscriberRoleUser: Subscription;

  constructor(
    private dealMemoService: DealMemoService,
    private projectService: ProjectService,
    private budgetService: BudgetService,
    private authService: AuthService,
    private requestService: RequestService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getPeriods();
    this.getBudget();
    this.getUser();
  }

  getRequest() {
    Notiflix.Confirm.Show(
      'Confirmación',
      'Deseas validar los pagos.',
      'Si',
      'No',
      () => {
        this.validacionPagos = true;
        this.requestService
          .getRequestAll(this.idCompany, this.idProject)
          .subscribe(res => {
            this.request = res;
            this.buscaPagos();
          });
      },
      () => {}
    );
  }

  buscaPagos() {
    this.dealMemos.forEach(orden => {
      if (orden.type === 'dealMemo') {
        // Con calendario de pagos -----------------------------
        let suma = 0;
        let sumaPagos = 0;
        orden.pagos.forEach(elementPagos => {
          let sumaXCta = 0;
          sumaPagos += elementPagos.importe;
          this.request.forEach(elementRequest => {
            if (
              elementRequest.countRequest === elementPagos.numberSol &&
              elementRequest.estatus
            ) {
              suma += elementPagos.importe;
              sumaXCta += elementPagos.importe;
            }
          });
          elementPagos.pagado = sumaXCta;
        });
        orden.valorDeal = sumaPagos;
        orden.pagado = suma;
      } else {
        // Sin calendario de pagos ------------------------------
        let suma = 0;
        let sumaPagos = 0;
        orden.pucharseOrderCtas.forEach(elementCta => {
          sumaPagos += elementCta.importe;
          let sumaXCta = 0;
          if (elementCta.acumulado) {
            elementCta.acumulado.forEach(acumulado => {
              this.request.forEach(elementRequest => {
                if (
                  elementRequest.countRequest === acumulado.numberSol &&
                  elementRequest.estatus
                ) {
                  suma += acumulado.importe;
                  sumaXCta += acumulado.importe;
                }
              });
            });
            elementCta.pagado = sumaXCta;
          }
        });
        orden.valorDeal = sumaPagos;
        orden.pagado = suma;
      }
    });
    console.log(this.dealMemos);
  }

  // asignarIdDeals() {
  //   for (let index = 0; index < this.dealMemos.length; index++) {
  //     const elementDealMemos = this.dealMemos[index];
  //     elementDealMemos.pagos.forEach(elementPagos => {
  //       if (elementPagos.asociado) {
  //         this.request.forEach(elementRequest => {
  //           if (elementPagos.numberSol === elementRequest.countRequest) {
  //             this.requestService
  //               .updateRequest(
  //                 this.idCompany,
  //                 this.idProject,
  //                 elementRequest.id,
  //                 {
  //                   idDealMemo: elementDealMemos.id,
  //                 }
  //               )
  //               .then(() => console.log('Actualizado correctamente'));
  //           }
  //         });
  //       }
  //     });
  //   }
  // }

  // backupSols() {
  //   this.requestService
  //     .backupSols(this.idCompany, this.idProject, { backup: this.request })
  //     .then(() => {
  //       console.log('backup Creado');
  //     });
  // }

  getUser() {
    this.authService.isLogin().then(resultado => {
      this.dataUser = resultado;
    });
  }

  getPeriods() {
    this.subscriberGetPeriods = this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periodos = Object.assign([], res);
      });
  }

  getBudget() {
    this.subscriberGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          // this.budgetArrays = budget; // este es el array que contiene hijos (children)
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budget = arrBudget; // Este es el array que mostramos en la tabla
          this.getDepartament();
        }
      });
  }

  getDealMemos() {
    this.dealMemos = [];
    this.subscriptionGetDealMemo = this.dealMemoService
      .reporteDealMemo(this.idCompany, this.idProject)
      .subscribe(res => {
        this.dealMemos = this.dealMemos = res.sort((a, b) => {
          return a.countDealMemo - b.countDealMemo;
        });

        // Sacar suma de pagos
        for (let index = 0; index < this.dealMemos.length; index++) {
          const deal = this.dealMemos[index];
          if (deal.type === 'dealMemo') {
            deal.pagos.forEach(element => {});
          }
        }
        console.log(this.dealMemos);
      });
  }
  reporteNumero() {
    this.dealMemos = [];
    this.subscriptionGetDealMemo = this.dealMemoService
      .reporteNumProveedor(this.idCompany, this.idProject, this.numeros)
      .subscribe(res => {
        this.dealMemos = res;
        for (let index = 0; index < this.dealMemos.length; index++) {
          const deal = this.dealMemos[index];
          let suma = 0;
          deal.pagos.forEach(element => {
            suma += element.importe;
          });
          deal.valorDeal = suma;
          // console.log(this.dealMemos);
        }
      });
  }

  editDealMemo(dealMemo) {
    console.log(dealMemo);
    this.dealMemoSeleccionado = this.clonarObj(dealMemo);
    this.dealMemoOriginal = this.clonarObj(dealMemo);

    this.sumaPagos = 0;
    dealMemo.pagos.forEach(element => {
      this.sumaPagos += element.importe;
    });
    // console.log(this.sumaPagos);
    // this.dealMemoComponent.dealMemo = dealMemo;
    // this.dealMemoComponent.totales();
    // this.dealMemoComponent.countDealMemo.countDealMemo = dealMemo.countDealMemo;
    $('#editDeal').modal('show');
  }

  saveEditDealMemo() {
    console.log(this.dealMemoSeleccionado);
    Notiflix.Confirm.Show(
      'Actualizar Deal Memo',
      'Estas seguro de actualizar el Deal Memo',
      'Si',
      'No',
      () => {
        if (this.dealMemoSeleccionado.historial) {
          this.dealMemoSeleccionado.historial.push(this.dealMemoOriginal);
        } else {
          this.dealMemoSeleccionado.historial = [] as any;
          this.dealMemoSeleccionado.historial.push(this.dealMemoOriginal);
        }
        this.dealMemoSeleccionado.editFor = this.dataUser.email;
        this.dealMemoService
          .updateDealMemo(
            this.idCompany,
            this.idProject,
            this.dealMemoSeleccionado.id,
            this.dealMemoSeleccionado
          )
          .then(() => {
            console.log('Actualizado correctamente');
          });
      }
    );
  }

  pagos(dealMemo) {
    console.log(dealMemo);
    $('#pagos').modal('show');
    this.dealMemoSeleccionado = dealMemo;
    this.sumaPagos = 0;
    dealMemo.pagos.forEach(element => {
      this.sumaPagos += element.importe;
    });
  }

  exportTableToExcel(id) {
    var type = 'xlsx';
    var elt = document.getElementById(id);
    var wb = XLSX.utils.table_to_book(elt /*{sheet:"Sheet JS"}*/);
    return XLSX.writeFile(
      wb,
      undefined || 'reportDealMemos.' + (type || 'xlsx')
    );
  }

  exportAsXLSX(): void {
    const arr = [] as any;

    this.dealMemos.forEach(element => {
      let obj = {
        NumeroDeal: element.countDealMemo,
        proveedor: element.proveedor,
        nombre: element.nombreProveedor,
        RFC: element.rfcProveedor,
        descripcion: element.descripcionActividad,
      } as any;

      const arrdesarrollo = element.pagos.filter(
        res => res.periodo.split('_')[0] === 'DES'
      );
      const arrsoftprep = element.pagos.filter(
        res => res.periodo.split('_')[0] === 'SOFTPREP'
      );
      const arrpreparacion = element.pagos.filter(
        res => res.periodo.split('_')[0] === 'PREP'
      );
      const arrrodaje = element.pagos.filter(
        res => res.periodo.split('_')[0] === 'ROD'
      );
      const arrentrega = element.pagos.filter(
        res => res.periodo.split('_')[0] === 'ENT'
      );
      const arrpost = element.pagos.filter(
        res => res.periodo.split('_')[0] === 'POST'
      );

      const des = arrdesarrollo.every(
        currentValue => currentValue.cuentaPres === arrdesarrollo[0].cuentaPres
      );
      if (des && arrdesarrollo.length > 0) {
        obj.DESARROLLO = arrdesarrollo[0].cuentaPres;
      } else if (!des && arrdesarrollo.length > 0) {
        obj.DESARROLLO = 'Diferentes cuentas';
      } else {
        obj.DESARROLLO = '';
      }

      const soft = arrsoftprep.every(
        currentValue => currentValue.cuentaPres === arrsoftprep[0].cuentaPres
      );
      if (soft && arrsoftprep.length > 0) {
        obj.SOFTPREP = arrsoftprep[0].cuentaPres;
      } else if (!soft && arrsoftprep.length > 0) {
        obj.SOFTPREP = 'Diferentes cuentas';
      } else {
        obj.SOFTPREP = '';
      }

      const prep = arrpreparacion.every(
        currentValue => currentValue.cuentaPres === arrpreparacion[0].cuentaPres
      );
      if (prep && arrpreparacion.length > 0) {
        obj.PREPARACION = arrpreparacion[0].cuentaPres;
      } else if (!prep && arrpreparacion.length > 0) {
        obj.PREPARACION = 'Diferentes cuentas';
      } else {
        obj.PREPARACION = '';
      }

      const rod = arrrodaje.every(
        currentValue => currentValue.cuentaPres === arrrodaje[0].cuentaPres
      );
      if (rod && arrrodaje.length > 0) {
        obj.RODAJE = arrrodaje[0].cuentaPres;
      } else if (!rod && arrrodaje.length > 0) {
        obj.RODAJE = 'Diferentes cuentas';
      } else {
        obj.RODAJE = '';
      }

      const ent = arrentrega.every(
        currentValue => currentValue.cuentaPres === arrentrega[0].cuentaPres
      );
      if (ent && arrentrega.length > 0) {
        obj.ENTREGA = arrentrega[0].cuentaPres;
      } else if (!ent && arrentrega.length > 0) {
        obj.ENTREGA = 'Diferentes cuentas';
      } else {
        obj.ENTREGA = '';
      }

      const post = arrpost.every(
        currentValue => currentValue.cuentaPres === arrpost[0].cuentaPres
      );
      if (post && arrpost.length > 0) {
        obj.POST = arrpost[0].cuentaPres;
      } else if (!post && arrpost.length > 0) {
        obj.POST = 'Diferentes cuentas';
      } else {
        obj.POST = '';
      }

      this.periodos.forEach(periodos => {
        obj[periodos.nombrePeriodo] = 0;
        element.pagos.forEach(pagos => {
          if (pagos.periodo === periodos.nombrePeriodo) {
            obj[periodos.nombrePeriodo] = pagos.importe;
          }
        });
      });

      arr.push(obj);
      obj = {} as any;
    });

    this.dealMemoService.exportAsExcelFile(arr, 'reportDealMemo');
  }

  resultado = [] as any;

  exportAsXLSX2(): void {
    // ---------------------------------------- Paso 1

    // Solucion 2

    const newArray = [] as any;
    this.dealMemos.forEach(element => {
      const sumarPorCuenta = arr => {
        // Usamos reduce para crear un objeto donde la clave sea la cuenta
        const agrupado = arr.reduce((acc, obj) => {
          // Si la cuenta no existe en el acumulador, la inicializamos
          if (!acc[obj.cuentaPres]) {
            acc[obj.cuentaPres] = { pagado: 0, importe: 0 };
          }
          // Sumamos el importe al acumulador
          acc[obj.cuentaPres].pagado += obj.pagado;
          acc[obj.cuentaPres].importe += obj.importe;
          return acc;
        }, {});

        console.log(agrupado);

        // Convertimos el objeto agrupado en un array de objetos
        return Object.keys(agrupado).map(cuentaPres => ({
          orden: element.countDealMemo,
          proveedor: element.nombreProveedor,
          rfc: element.rfcProveedor,
          descripcion: element.descripcionActividad,
          cuentaPres,
          importe: agrupado[cuentaPres].importe,
          pagado: agrupado[cuentaPres].pagado,
          pendiente: agrupado[cuentaPres].importe - agrupado[cuentaPres].pagado,
        }));
      };

      if (element.type === 'dealMemo') {
        const resultado = sumarPorCuenta(element.pagos);
        newArray.push(resultado);
      } else {
        const resultado = sumarPorCuenta(element.pucharseOrderCtas);
        newArray.push(resultado);
      }
    });

    // Concatenamos todos los arrays generados
    const result = newArray.reduce((acc, currentValue) =>
      acc.concat(currentValue)
    );

    console.log(result);
    this.resultado = result;

    // ------------------------------------------ Paso 2 Generar Archivo XLSX
    this.dealMemoService.exportAsExcelFile(result, 'reportDealMemo');
  }

  getDepartament() {
    this.departament = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      if (element.Total_Estimado) {
        this.departament.push(element);
      }
    }
  }

  getPuesto() {
    this.dealMemo.departamento = this.departamento.Descripcion;
    this.puestos = [];
    this.puesto = '';
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      if (
        this.departamento.CodigoAgrupador === segmentos[0] &&
        segmentos.length === 2
      ) {
        this.puestos.push(element);
      }
    }
  }

  getCtaPres() {
    this.dealMemo.puesto = this.puesto.Descripcion;
    this.ctasPresupuesto = [];
    for (let index = 0; index < this.budget.length; index++) {
      const element = this.budget[index];
      const segmentos = element.CodigoAgrupador.split('-');
      const codigo = segmentos[0] + '-' + segmentos[1];

      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 3) {
        this.ctasPresupuesto.push(element);
      }
      if (this.puesto.CodigoAgrupador === codigo && segmentos.length === 4) {
        this.ctasPresupuesto.push(element);
      }
    }
  }

  clonarObj(obj) {
    const clone = JSON.parse(JSON.stringify(obj));
    return clone;
  }

  getDates(periodo) {
    const elemento = this.periodos.find(
      element => element.nombrePeriodo === periodo.periodo
    );
    periodo.fechaInicio = elemento ? elemento.fechaInicio : Date();
    periodo.fechaFin = elemento ? elemento.fechaFin : Date();
  }

  deleteArr(arr: Array<any>, index: number) {
    Notiflix.Confirm.Show(
      'Eliminar Pago',
      'Estas seguro de eliminar el pago',
      'Si',
      'No',
      () => {
        alert('Thank you.');
        arr.splice(index, 1);
        // this.totales();
      }
    );
  }

  ngOnDestroy() {
    if (this.subscriptionGetDealMemo) {
      this.subscriptionGetDealMemo.unsubscribe();
    }
    if (this.subscriberGetPeriods) {
      this.subscriberGetPeriods.unsubscribe();
    }
    if (this.subscriberGetBudget) {
      this.subscriberGetBudget.unsubscribe();
    }
  }
}
