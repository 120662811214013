import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { GeneralService } from 'src/app/services/general.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  bitacora = [];

  getBitacoraSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private generalService: GeneralService
  ) {
    moment.locale('es-MX');
  }

  ngOnInit() {
    // const url = this.router.parseUrl(this.router.url);
    // const idCompany = url.root.children.primary.segments[1].path;
    // const idProject = url.root.children.primary.segments[3].path;
    // this.generalService.getBitacora(idCompany, idProject).subscribe((res: any) => {
    //   console.log(res);
    //   this.bitacora = res.bitacora;
    // });
  }
}
