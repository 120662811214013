import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  constructor(private http: HttpClient) {}

  sendEmail(dest, nombreProveedor, idProvider) {
    const url = `https://us-central1-enconexion-4a1e8.cloudfunctions.net/sendMail?dest=${dest}&nombreProveedor=${nombreProveedor}&idProvider=${idProvider}`;
    this.http.head(url).subscribe(res => {
      console.log(res);
    });
  }
}
