import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private afs: AngularFirestore) {}

  getPeriods(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('periodos')
      .doc('periodos')
      .valueChanges();
  }
  addPeriods(idCompany, idProject, obj) {
    this.afs.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('periodos')
      .doc('periodos')
      .set(obj);
  }
  deletePeriod(idCompany, idProject, obj) {
    this.afs.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('periodos')
      .doc('periodos')
      .set(obj);
  }
  addCatCtas(idCompany, idProject, obj) {
    this.afs.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('catCtas')
      .doc('catCtasProject')
      .set(obj)
      .then(() => console.log('Se guardo correctamente!!!'));
  }
  updateCatCtas(idCompany, idProject, obj) {
    this.afs.firestore
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('catCtas')
      .doc('catCtasProject')
      .update(obj)
      .then(() => console.log('Se actualizo correctamente!!!'));
  }
  getCatCtas(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('catCtas')
      .doc('catCtasProject')
      .valueChanges();
  }
  deleteCatCtas(idCompany, idProject) {
    this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('catCtas')
      .doc('catCtasProject')
      .delete()
      .then(() => console.log('Cuentas eliminadas correctamente!!'))
      .catch(err =>
        console.error('Ocurrio un error al eliminar las cuentas', err)
      );
  }
  operaciones(regimen, datoImporte) {
    const operacionesFiscales = {
      importe: datoImporte,
    } as any;
    switch (regimen) {
      case 'General de ley persona moral':
      case '601':
      case 601:
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        operacionesFiscales.iva = operacionesFiscales.importe * 0.16;
        operacionesFiscales.subtotal =
          operacionesFiscales.importe + operacionesFiscales.iva;
        operacionesFiscales.retIva = 0;
        operacionesFiscales.retIsr = 0;
        operacionesFiscales.total =
          operacionesFiscales.subtotal -
          operacionesFiscales.retIsr -
          operacionesFiscales.retIva;
        return operacionesFiscales;
      case 'Arrendamiento':
      case '606':
      case 606:
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        operacionesFiscales.iva = operacionesFiscales.importe * 0.16;
        operacionesFiscales.subtotal =
          operacionesFiscales.importe + operacionesFiscales.iva;
        operacionesFiscales.retIva = operacionesFiscales.importe * 0.106666;
        operacionesFiscales.retIsr = operacionesFiscales.importe * 0.1;
        operacionesFiscales.total =
          operacionesFiscales.subtotal -
          operacionesFiscales.retIsr -
          operacionesFiscales.retIva;
        return operacionesFiscales;
      case 'Ingresos por dividendos':
      case '611':
      case 611:
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = false;
        operacionesFiscales.iva = 0;
        operacionesFiscales.subtotal =
          operacionesFiscales.importe + operacionesFiscales.iva;
        operacionesFiscales.retIva = 0;
        operacionesFiscales.total =
          operacionesFiscales.subtotal - operacionesFiscales.retIsr;
        return operacionesFiscales;
      case 'Personas fisicas con actividades empresariales y profesionales':
      case '612':
      case 612:
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        operacionesFiscales.iva = operacionesFiscales.importe * 0.16;
        operacionesFiscales.subtotal =
          operacionesFiscales.importe + operacionesFiscales.iva;
        operacionesFiscales.retIva = operacionesFiscales.importe * 0.106666;
        operacionesFiscales.retIsr = operacionesFiscales.importe * 0.1;
        operacionesFiscales.total =
          operacionesFiscales.subtotal -
          operacionesFiscales.retIsr -
          operacionesFiscales.retIva;
        return operacionesFiscales;
      case 'Incorporacion Fiscal':
      case '621':
      case 621:
        (<any>document.getElementById('iva')).disabled = true;
        (<any>document.getElementById('retIva')).disabled = true;
        (<any>document.getElementById('retIsr')).disabled = true;
        operacionesFiscales.iva = operacionesFiscales.importe * 0.16;
        operacionesFiscales.subtotal =
          operacionesFiscales.importe + operacionesFiscales.iva;
        operacionesFiscales.retIva = operacionesFiscales.importe * 0.106666;
        operacionesFiscales.retIsr = 0;
        operacionesFiscales.total =
          operacionesFiscales.subtotal -
          operacionesFiscales.retIsr -
          operacionesFiscales.retIva;
        return operacionesFiscales;
      case 'Otro':
      case '0':
      case 0:
        (<any>document.getElementById('iva')).disabled = false;
        (<any>document.getElementById('retIva')).disabled = false;
        (<any>document.getElementById('retIsr')).disabled = false;
        operacionesFiscales.iva;
        operacionesFiscales.subtotal =
          operacionesFiscales.importe + operacionesFiscales.iva;
        operacionesFiscales.total =
          operacionesFiscales.subtotal -
          operacionesFiscales.retIsr -
          operacionesFiscales.retIva;
        return operacionesFiscales;
    }
  }
  getFoliosFiscales(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('foliosFiscales')
      .doc('folios')
      .valueChanges();
  }
  addFolioFiscal(idCompany, idProject, obj) {
    this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('foliosFiscales')
      .doc('folios')
      .set(obj);
  }

  ctasFilter(arrCtas) {
    const array = [] as any;
    arrCtas.forEach(element => {
      array.push(element);
      if (element.children.length > 0) {
        fase1(element.children);
      }
    });

    function fase1(arr) {
      arr.forEach(element => {
        array.push(element);
        if (element.children.length > 0) {
          fase2(element.children);
        }
      });
    }

    function fase2(arr) {
      arr.forEach(element => {
        array.push(element);
        if (element.children.length > 0) {
          fase1(element.children);
        }
      });
    }

    const arrFilter = array.filter(res => {
      const segments = res.CodigoAgrupador.split('-');
      if (res.afectable === 'true') {
        return res;
      }
    });

    const newArr = arrFilter.map(el => {
      const obj = {
        CodigoAgrupador: el.CodigoAgrupador,
        Descripcion: el.descripcion,
      };
      return obj;
    });

    return newArr;
  }
  ctasFilter2(budget) {
    const array = [] as any;
    budget.forEach(element => {
      array.push(element);
      if (element.children) {
        if (element.children.length > 0) {
          fase1(element.children);
          // delete element.children;
        }
      }
    });

    function fase1(arr) {
      arr.forEach(element => {
        array.push(element);
        if (element.children) {
          if (element.children.length > 0) {
            fase2(element.children);
            // delete element.children;
          }
        }
      });
    }

    function fase2(arr) {
      arr.forEach(element => {
        array.push(element);
        if (element.children) {
          if (element.children.length > 0) {
            fase1(element.children);
            // delete element.children;
          }
        }
      });
    }

    array.forEach(element => {
      const segments = element.CodigoAgrupador.split('-');
      if (segments.length < 3) {
        delete element.children;
      }
    });

    return array;
  }
  getXML(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('XML')
      .doc('XML')
      .valueChanges();
  }
  getXMLPublic(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('XMLPublic')
      .doc('XML')
      .valueChanges();
  }

  saveXML(idCompany, idProject, data) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('XML')
      .doc('XML')
      .set(data);
  }
  saveXMLPublic(idCompany, idProject, data) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('XMLPublic')
      .doc('XML')
      .set(data);
  }

  getCountUser(idCompany, idProject, rfc) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('deudores')
      .doc(rfc)
      .valueChanges();
  }

  updateCountCkeckUser(idCompany, idProject, rfc, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('deudores')
      .doc(rfc)
      .set(obj);
  }

  saveGroup(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('departaments')
      .add(obj);
  }

  getGroups(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('departaments')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  getGroup(idCompany, idProject, idDepartament) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('departaments')
      .doc(idDepartament)
      .valueChanges();
  }
  updateCountCkeckDepartament(idCompany, idProject, idDepartament, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('departaments')
      .doc(idDepartament)
      .update(obj);
  }

  deleteGroup(idCompany, idProject, idDepartament) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('departaments')
      .doc(idDepartament)
      .delete();
  }

  getIdRfc(id) {
    return this.afs.collection('importadorExterno').doc(id).valueChanges();
  }
  addXmlExernoLink(obj) {
    return this.afs
      .collection('importadorExterno')
      .add(obj)
      .then(docRef => {
        // console.log('ID del documento:', docRef.id);
        return docRef.id; // Devuelve el ID del documento;
      })
      .catch(error => {
        console.error('Error al agregar el documento: ', error);
        throw error; // Propaga el error si ocurre
      });
  }
  updateXmlLink(id, obj) {
    return this.afs.collection('importadorExterno').doc(id).update(obj);
  }
  getXmlExernoLink() {
    return this.afs.collection('importadorExterno').valueChanges();
  }
}
