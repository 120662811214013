import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Subscription } from 'rxjs';
import { Empresa } from 'src/app/models/empresas';
import { AngularFireStorage } from '@angular/fire/storage';
import Notiflix from 'notiflix-angular';
import { timeInterval } from 'rxjs/operators';

declare var $;

@Component({
  selector: 'app-tareas',
  templateUrl: './tareas.component.html',
  styleUrls: ['./tareas.component.css'],
})
export class TareasComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private empresasService: EmpresasService,
    private storage: AngularFireStorage
  ) {}
  idCompany: string;
  company = {} as any;
  file = {} as any;
  tareaSeleccionada = {} as any;
  resultado: number;
  objAddTask = {} as any;
  autoTime: any;

  subscriptionGetCompanyActual: Subscription;

  ngOnInit(): void {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.getCompany();
    this.autoTime = setInterval(() => {
      this.validationDate(this.company);
    }, 1000 * 60);
  }

  getCompany() {
    this.subscriptionGetCompanyActual = this.empresasService
      .getCompanyActual(this.idCompany)
      .subscribe((empresa: Empresa) => {
        if (empresa.tasks) {
          empresa.tasks.forEach(tarea => {
            if (tarea.file) {
              tarea.file.date = new Date(tarea.file.date.seconds * 1000);
            }
          });
        }
        if (empresa.tasks) {
          this.validationDate(empresa);
        }
      });
  }

  validationDate(empresa) {
    for (let index = 0; index < empresa.tasks.length; index++) {
      const element: any = empresa.tasks[index];
      const style = (element.style = {} as any);
      const dateActual = new Date();
      const dateTask = new Date(element.dateMax + 'T23:59:59');
      const fechaAVencer = dateTask.getTime() - dateActual.getTime();
      this.resultado = fechaAVencer / (1000 * 60 * 60 * 24);
      // console.log(this.resultado);
      if (this.resultado < 0 && !element.file) {
        // console.log('La tarea ' + element.name + ' ya vencio');
        element.style.background = 'table-danger';
        element.style.alerta = 'fa-exclamation-circle';
        element.style.title = 'Vencido';
        // Notiflix.Notify.Warning(`La tarea ${element.name} a expirado`);
      } else if (this.resultado > 0 && this.resultado < 3 && !element.file) {
        // console.log('La tarea ' + element.name + ' esta a punto de vencer');
        element.style.background = '';
        element.style.alerta = 'fa-exclamation-triangle';
        element.style.title = 'Proximo a vencer';
      } else if (this.resultado < 0 && element.file) {
        element.style.background = 'table-success';
        element.style.alerta = 'fa-check';
        element.style.title = 'Listo';
      } else if (this.resultado > 0 && this.resultado < 3 && element.file) {
        // console.log('La tarea ' + element.name + ' esta a punto de vencer');
        element.style.background = 'table-success';
        element.style.alerta = 'fa-check';
        element.style.title = 'Listo';
      } else {
        if (element.file) {
          element.style.background = 'table-success';
          element.style.alerta = 'fa-check';
          element.style.title = 'Listo';
        } else {
          element.style.alerta = 'fa-calendar-plus';
          element.style.background = '';
          element.style.title = 'En tiempo';
        }
        // element.alerta = 'fa-calendar-plus';
        // console.log('La tarea ' + element.name + ' todavia no vence');
      }
    }
    this.company = empresa;
  }

  uploadFile(event, tarea, index) {
    const fileInput = event.target.files[0];
    this.file = event.target.files[0];
    this.tareaSeleccionada = tarea;
    // const fileType = fileInput.type;
    const fileSize = fileInput.size;
    // const allowedExtensions = /(.pdf)$/i;
    if (fileSize >= 1000000) {
      alert(
        'Por favor agrega unicamente archivos con extension .pdf y tamaño maximo de 1MB '
      );
      document.getElementById(`labelFile${index}`).innerHTML = 'Seleccionar';
      (<any>document.getElementById(`inputFile${index}`)).value = '';
    } else {
      document.getElementById(`labelFile${index}`).innerHTML = fileInput.name;
    }
  }
  guardar() {
    Notiflix.Confirm.Show(
      'Guardar Archivo',
      '¿Estas seguro de guardar?',
      'Si',
      'No',
      () => {
        if (this.file.name) {
          let uploadF: any = '';
          uploadF = this.file;
          const date = new Date();
          const filePath = `companys/${this.company.name}/${this.tareaSeleccionada.name}/${this.file.name}`;
          const ref = this.storage.ref(filePath);
          const task = this.storage.upload(filePath, uploadF);
          task.then(res => {
            ref.getDownloadURL().subscribe(resultado => {
              const objFile = {} as any;
              objFile.url = resultado;
              objFile.filePath = filePath;
              objFile.date = date;
              objFile.name = this.file.name;
              this.tareaSeleccionada.file = objFile;
              this.tareaSeleccionada.style = {};
              this.empresasService.updateCompany(this.company).then(() => {
                Notiflix.Notify.Success('Guardado correctamente');
                this.file = {};
              });
            });
          });
        } else {
          Notiflix.Notify.Failure('Agrega un archivo');
        }
      }
    );
  }

  deleteFile(tarea) {
    // Delete the file
    Notiflix.Confirm.Show(
      'Eliminar Archivo',
      '¿Estas seguro de eliminar?',
      'Si',
      'No',
      () => {
        // Create a reference to the file to delete
        const reference = this.storage.ref(tarea.file.filePath);
        reference
          .delete()
          .toPromise()
          .then(() => {
            // File deleted successfully
            // console.log('Se elimino correctamente');
            delete tarea.file;
            tarea.style = {};
            delete tarea.commentUser;
            this.empresasService.updateCompany(this.company).then(() => {
              Notiflix.Notify.Success('Eliminado correctamente');
            });
          })
          .catch(error => {
            // Uh-oh, an error occurred!
            console.log('Se produjo un error al eliminar o no existia:', error);
          });
      }
    );

    // this.dealMemo.documentos.splice(index, 1);
  }

  addTask() {
    if (this.objAddTask.name && this.objAddTask.dateMax) {
      this.objAddTask.style = {} as any;
      if (this.company.tasks) {
        this.company.tasks.push(this.objAddTask);
      } else {
        this.company.tasks = [];
        this.company.tasks.push(this.objAddTask);
      }
      this.objAddTask = {};
      this.company.tasks.style = {};
      this.empresasService.updateCompany(this.company).then(() => {
        Notiflix.Notify.Success('Se agrego correctamente');
      });
    } else {
      Notiflix.Notify.Failure('Agrega los datos obligatorios');
    }
  }

  ngOnDestroy() {
    this.subscriptionGetCompanyActual.unsubscribe();
    clearInterval(this.autoTime);
  }
}
