import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ChecksService {
  EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  constructor(private afs: AngularFirestore) {}

  getCountCheck(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados')
      .doc('countCheck')
      .valueChanges();
  }

  updateCountCheck(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('acumulados')
      .doc('countCheck')
      .set(obj);
  }

  addCheck(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobaciones')
      .add(obj);
  }
  addCheckPublic(idCompany, idProject, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobacionesPublic')
      .add(obj);
  }
  getChecksPublic(idCompany, idProject, user) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobacionesPublic', ref =>
        ref.where('createBy', '==', user)
      )
      .snapshotChanges()
      .pipe(
        map(action => {
          return action.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  getChecksPublicas(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobacionesPublic')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
          });
        })
      );
  }
  updateChecks(idCompany, idProject, id, obj) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobacionesPublic')
      .doc(id)
      .update(obj);
  }

  reportePeriodo(idCompany, idProject, periodoSeleccionado) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobaciones', ref =>
        ref.where('periodo', '==', periodoSeleccionado)
      )
      .valueChanges();
  }
  reporteNumero(idCompany, idProject, numeros) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobaciones', ref =>
        ref
          .where('countCheck', '>=', numeros.primerNumero)
          .where('countCheck', '<=', numeros.ultimoNumero)
      )
      .valueChanges();
  }
  getComprobaciones(idCompany, idProject) {
    return this.afs
      .collection('empresas')
      .doc(idCompany)
      .collection('proyectos')
      .doc(idProject)
      .collection('comprobaciones')
      .valueChanges();
  }
  exportAsExcel(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { comprobaciones: worksheet },
      SheetNames: ['comprobaciones'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION
    );
  }
}
