import { Component, OnInit, OnDestroy } from '@angular/core';
import { Budget, SumaCuentas } from 'src/app/models/budget';
import { User } from 'src/app/models/user';
import * as XLSX from 'xlsx';
import { BudgetService } from 'src/app/services/budget.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxXml2jsonService } from 'ngx-xml2json';
import Notiflix from 'notiflix-angular';
import { ProjectService } from 'src/app/services/project.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Subscription, empty } from 'rxjs';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css'],
})
export class BudgetComponent implements OnInit, OnDestroy {
  willDownload = false;
  dataTable = [];
  addDataDB: any = {};
  getBudget: any;
  idCompany;
  idProject;
  idBudget = [] as any;
  dataBudget = {} as Budget;
  dataEditBudget;
  index: number;
  importeSubtotal;
  importeTotal;
  importeGranTotal;
  addRowBudget = {} as Budget;
  cuentaPadre;
  dataString: string;
  sheetName;
  admin = false;
  // loading = true;
  getIVA: any = {};
  getIVAAcumulado;
  totales: any = {};
  cuentasTotales = {} as SumaCuentas;
  cuentas: any = [];
  catCtas: any = {};
  dataTableEdit = {} as any;

  budgetArrays = [] as any;
  budget = [] as any;
  project = {} as any;
  conversion = 1;
  monedaSeleccionada = '' as string;
  cuentasAgrupadas = [] as any;
  ctaSeleccionada: any;

  filtroNivel = 4;
  allBudget = [];
  indexPrev: number;

  subscribeGetBudget: Subscription;
  subscribeAuthUser: Subscription;
  subscribeIvaAcumulado: Subscription;
  subscribeGetIva: Subscription;
  subscriberGetProject: Subscription;

  constructor(
    public budgetService: BudgetService,
    public projectService: ProjectService,
    private authService: AuthService,
    private router: Router,
    private ngxXml2jsonService: NgxXml2jsonService,
    private empresaService: EmpresasService
  ) {}

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.idCompany = url.root.children.primary.segments[1].path;
    this.idProject = url.root.children.primary.segments[3].path;
    this.getAuth();
    this.getBudgets();
    this.getIvaEstimado();
    this.getProject();
  }
  getProject() {
    this.subscriberGetProject = this.empresaService
      .getProjectActual(this.idCompany, this.idProject)
      .subscribe(res => {
        this.project = res;
        this.monedaSeleccionada = this.project.monedaLocal;
      });
  }
  cambioMoneda() {
    if (this.project.monedaLocal === this.monedaSeleccionada) {
      this.conversion = 1;
    } else if (
      this.project.monedaLocal === 'USD' &&
      this.monedaSeleccionada === 'MXN'
    ) {
      this.conversion = this.project.tipoCambioGral;
    } else if (
      this.project.monedaLocal === 'MXN' &&
      this.monedaSeleccionada === 'USD'
    ) {
      this.conversion = 1 / this.project.tipoCambioGral;
    }
  }
  getBudgets() {
    Notiflix.Loading.Dots('Cargando...');
    this.subscribeGetBudget = this.budgetService
      .getBudget(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res) {
          const budget = Object.assign([], res); // Convertimos el objeto a array
          const arrBudget = this.projectService.ctasFilter2(budget); // Esta funcion desglosa todo el presupuesto
          this.budgetArrays = budget; // este es el array que contiene hijos (children)
          this.allBudget = arrBudget;
          this.filtroPorNivel();

          this.getCtas();
          // <<-- Obtener cuentas -->>
          this.getBudget.forEach(element => {
            if (element.Total_Estimado >= 0 || element.Total_Estimado <= 0) {
              this.cuentas.push(element);
            }
          });
          // <<-- Obtener cuentas -->>
        }
        Notiflix.Loading.Remove(3000);
      });
  }
  filtroPorNivel() {
    this.getBudget = [] as any;
    this.allBudget.forEach(element => {
      if (element.CodigoAgrupador.split('-').length <= this.filtroNivel) {
        this.getBudget.push(element); // Este es el array que mostramos en la tabla
      }
    });
  }
  getAuth() {
    this.authService.isLogin().then(res => {
      const user: User = res;
      this.subscribeAuthUser = this.authService
        .roleUser(user.uid)
        .subscribe(res2 => {
          const userType = res2[0].userType;
          if (userType === 'Administrador') {
            this.admin = true;
          } else {
            this.admin = false;
          }
        });
    });
  }
  exportAsXLSX(): void {
    // console.log(this.getBudget);
    // console.log(this.totales);
    const arr = [] as any;
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const obj = {
        CodigoAgrupador: element.CodigoAgrupador,
        Descripcion: element.Descripcion,
        Detalle: element.Detalle ? element.Detalle : '',
        Cantidad: element.Cantidad ? element.Cantidad : '',
        Unidad: element.Unidad ? element.Unidad : '',
        TC: element.TC ? element.TC : undefined,
        Precio_Estimado:
          element.Precio_Estimado || element.Precio_Estimado === 0
            ? element.Precio_Estimado
            : undefined,
        Importe_Estimado:
          element.Importe_Estimado || element.Importe_Estimado === 0
            ? element.Importe_Estimado
            : undefined,
        Subtotal_Estimado:
          element.Subtotal_Estimado || element.Subtotal_Estimado === 0
            ? element.Subtotal_Estimado
            : undefined,
        Total_Estimado:
          element.Total_Estimado || element.Total_Estimado === 0
            ? element.Total_Estimado
            : undefined,
        nivel:
          element.CodigoAgrupador.split('-').length === 1
            ? 1
            : element.CodigoAgrupador.split('-').length === 2
            ? 2
            : element.CodigoAgrupador.split('-').length === 3 &&
              element.children.length > 0
            ? 3.5
            : element.CodigoAgrupador.split('-').length === 4
            ? 4
            : 3,
      };
      arr.push(obj);
    }

    interface objs {
      CodigoAgrupador: '';
      Descripcion: '';
      Detalle: '';
      Cantidad: '';
      Unidad: '';
      TC: '';
      Precio_Estimado: '';
      Importe_Estimado: '';
      Subtotal_Estimado: '';
      Total_Estimado: 0;
      nivel: '';
    }
    let obj = {
      CodigoAgrupador: '',
      Descripcion: '',
      Detalle: '',
      Cantidad: '',
      Unidad: '',
      TC: '',
      Precio_Estimado: '',
      Importe_Estimado: '',
      Subtotal_Estimado: '',
      Total_Estimado: 0,
      nivel: '',
    };

    obj.CodigoAgrupador = '0150';
    obj.Descripcion = 'IVA';
    obj.Total_Estimado = this.getIVA.estimado * this.conversion;
    arr.push(obj);
    obj = {} as objs;
    obj.CodigoAgrupador = '';
    obj.Descripcion = 'TOTAL ABOVE THE LINE';
    obj.Total_Estimado = this.totales.ATLE * this.conversion;
    arr.push(obj);
    obj = {} as objs;
    obj.CodigoAgrupador = '';
    obj.Descripcion = 'TOTAL BELOW THE LINE PRODUCCIÓN';
    obj.Total_Estimado = this.totales.BTLPE * this.conversion;
    arr.push(obj);
    obj = {} as objs;
    obj.CodigoAgrupador = '';
    obj.Descripcion = 'TOTAL BELOW THE LINE POST';
    obj.Total_Estimado = this.totales.BTLPOSTE * this.conversion;
    arr.push(obj);
    obj = {} as objs;
    obj.CodigoAgrupador = '';
    obj.Descripcion = 'TOTAL BELOW THE LINE OTHER';
    obj.Total_Estimado = this.totales.BTLOE * this.conversion;
    arr.push(obj);
    obj = {} as objs;
    obj.CodigoAgrupador = '';
    obj.Descripcion = '	GRAN TOTAL';
    obj.Total_Estimado =
      (this.totales.ATLE +
        this.totales.BTLPE +
        this.totales.BTLPOSTE +
        this.totales.BTLOE +
        this.getIVA.estimado) *
      this.conversion;
    obj.nivel = '';
    arr.push(obj);
    obj = {} as objs;

    this.budgetService.exportAsExcelFile(
      arr,
      'budget_export' + '_' + this.project.name
    );
  }
  onFileChange(ev) {
    Notiflix.Loading.Dots('Cargando...');
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        this.sheetName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const arr = jsonData[this.sheetName];
      arr.forEach(element => {
        element.CodigoAgrupador = element.CodigoAgrupador.toString();
      });
      this.dataTable = arr;
      console.log(this.dataTable);
      this.addDataDB = Object.assign({}, arr);
      Notiflix.Loading.Remove(3000);
    };
    setTimeout(() => {
      reader.readAsBinaryString(file);
    }, 500);
  }
  saveBudget() {
    this.budgetArrays = this.convertirArray(this.dataTable);
    const budget = Object.assign({}, this.budgetArrays);
    this.budgetService
      .addPresupuesto2(budget, this.idCompany, this.idProject)
      .then(() => {
        console.log('guardado exitoso');
        this.budgetArrays = [];
        this.dataTable = [];
      });
    // const arrBudget = this.dataTable.map(element => {
    //   element.CodigoAgrupador = element.CodigoAgrupador.toString();
    //   return element;
    // });
    // const budget = Object.assign({}, arrBudget);
    // this.budgetService.addPresupuesto(budget, this.idCompany, this.idProject);
    // this.dataTable = [];
  }
  select(data, index) {
    console.log(data);
    if (this.admin) {
      $('#modalEditBudget').modal('show');
      this.dataBudget = data;
      this.index = index;
    }
  }
  select1(data, index) {
    const segmentos = data.CodigoAgrupador.split('-');
    if (this.admin) {
      if (segmentos.length === 3) {
        $('#modalEditBudgetApproved').modal('show');
        this.dataBudget = data;
        this.index = index;
      }
    }
  }
  modifyBudget() {
    this.budgetArrays = this.convertirArray(this.getBudget);
    const editBudget = Object.assign({}, this.budgetArrays);
    console.log(editBudget);
    this.budgetService
      .addPresupuesto2(editBudget, this.idCompany, this.idProject)
      .then(() => {
        console.log('se guardo correctamente');
        Notiflix.Notify.Success('Guardado exitosamente');
      });
  }
  deleteRow() {
    const i = this.index;
    console.log(i);
    Notiflix.Confirm.Show(
      'ALERTA',
      '¿Quieres eliminar la fila?',
      'Aceptar',
      'Cancelar',
      () => {
        $('#modalEditBudget').modal('hide');
        this.getBudget.splice(i, 1);
        this.getBudget = [...this.getBudget];
        this.sumarImporte();
      },
      () => {}
    );
  }
  sumarImporte() {
    $('#modalEditBudget').modal('hide');
    $('#modalEditBudgetApproved').modal('hide');
    const segmentosSelect = this.dataBudget.CodigoAgrupador.split('-');

    if (segmentosSelect.length === 4) {
      let suma0 = 0;
      for (let index = 0; index < this.getBudget.length; index++) {
        const element = this.getBudget[index];
        const segmento1 = element.CodigoAgrupador.split('-');
        if (
          segmento1[0] === segmentosSelect[0] &&
          segmento1[1] === segmentosSelect[1] &&
          segmento1[2] === segmentosSelect[2] &&
          segmento1.length === 4
        ) {
          suma0 += element.Importe_Estimado;
        }
      }
      for (let index = 0; index < this.getBudget.length; index++) {
        const element = this.getBudget[index];
        const segmento1 = element.CodigoAgrupador.split('-');
        if (
          segmento1[0] === segmentosSelect[0] &&
          segmento1[1] === segmentosSelect[1] &&
          segmento1[2] === segmentosSelect[2] &&
          segmento1.length === 3
        ) {
          element.Importe_Estimado = suma0;
          break;
        }
      }
    }

    let suma = 0;
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segmento1 = element.CodigoAgrupador.split('-');
      if (
        segmento1[0] === segmentosSelect[0] &&
        segmento1[1] === segmentosSelect[1] &&
        segmento1.length === 3
      ) {
        suma += element.Importe_Estimado;
      }
    }

    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segmento1 = element.CodigoAgrupador.split('-');
      if (
        segmento1[0] === segmentosSelect[0] &&
        segmento1[1] === segmentosSelect[1] &&
        segmento1.length === 2
      ) {
        element.Subtotal_Estimado = suma;
        break;
      }
    }

    let suma2 = 0;
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segmento1 = element.CodigoAgrupador.split('-');
      if (segmento1[0] === segmentosSelect[0] && segmento1.length === 2) {
        suma2 += element.Subtotal_Estimado;
      }
    }

    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segmento1 = element.CodigoAgrupador.split('-');
      if (segmento1[0] === segmentosSelect[0] && segmento1.length === 1) {
        element.Total_Estimado = suma2;
        break;
      }
    }
    this.varianteOperacion();
  }
  sumarImporte2() {
    $('#modalAdd').modal('hide');
    let suma = 0;
    let suma2 = 0;
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      if (this.dataBudget.Cuenta === element.Cuenta_Padre) {
        suma = suma + element.Importe_Estimado;
      }
    }
    this.dataBudget.Subtotal_Estimado = suma;
    for (let index2 = 0; index2 < this.getBudget.length; index2++) {
      const element = this.getBudget[index2];
      if (element.Cuenta_Padre === this.dataBudget.Cuenta_Padre) {
        suma2 = suma2 + element.Subtotal_Estimado;
      }
    }
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      if (this.dataBudget.Cuenta_Padre === element.Cuenta) {
        element.Total_Estimado = suma2;
      }
    }
  }
  operations() {
    this.dataBudget.Importe_Estimado =
      this.dataBudget.Cantidad *
      this.dataBudget.TC *
      this.dataBudget.Precio_Estimado;
  }
  openAddRow() {
    $('#modalEditBudget').modal('hide');
    $('#modalAdd').modal('show');
  }
  operations2() {
    this.addRowBudget.Importe_Estimado =
      this.addRowBudget.Cantidad *
      this.addRowBudget.TC *
      this.addRowBudget.Precio_Estimado;
  }
  addRow() {
    let suma = 0;
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segments = element.CodigoAgrupador.split('-');
      const cuentaPadre = segments[0] + '-' + segments[1];
      if (
        this.dataBudget.CodigoAgrupador === cuentaPadre &&
        segments.length === 3
      ) {
        suma = suma + 1;
      }
    }
    const i = this.index + suma + 1;
    const codigo = suma + 1;
    const tercerSegmento = codigo.toString().padStart(3, '0');
    const cuenta = this.dataBudget.CodigoAgrupador + '-' + tercerSegmento;
    this.addRowBudget.CodigoAgrupador = cuenta;
    this.addRowBudget.children = [];
    this.getBudget.splice(i, 0, this.addRowBudget);
    this.getBudget = [...this.getBudget];
    this.addRowBudget = {};
    $('#modalAdd').modal('hide');
    this.sumarImporte();
  }
  deleteBudget() {
    Notiflix.Confirm.Show(
      'ALERTA',
      '¿Quieres eliminar el presupuesto?',
      'Aceptar',
      'Cancelar',
      () => {
        Notiflix.Loading.Dots('Cargando...');
        this.budgetService
          .deleteBudget(this.idCompany, this.idProject)
          .then(() => {
            this.getBudget = false;
            Notiflix.Notify.Success('Se elimino correctamente');
          })
          .catch(err => {
            console.log('Error: ', err);
            Notiflix.Notify.Failure('Ocurrio un error al eliminar');
          });
        Notiflix.Loading.Remove(3000);
      }
    );
  }
  aprobarPresupuesto() {
    Notiflix.Confirm.Show(
      'ALERTA',
      '¿Quieres aprobar el presupuesto?',
      'Aceptar',
      'Cancelar',
      () => {
        const arr = [];
        for (let index = 0; index < this.getBudget.length; index++) {
          const element: Budget = this.getBudget[index];
          if (element.Importe_Estimado >= 0 || element.Importe_Estimado <= 0) {
            // element.Precio_Aprobado = element.Precio_Estimado;
            element.Importe_Aprobado = element.Importe_Estimado;
            element.Variante = 0;
          } else if (
            element.Subtotal_Estimado >= 0 ||
            element.Subtotal_Estimado <= 0
          ) {
            element.Subtotal_Aprobado = element.Subtotal_Estimado;
            element.Variante = 0;
          } else if (
            element.Total_Estimado >= 0 ||
            element.Total_Estimado <= 0
          ) {
            element.Total_Aprobado = element.Total_Estimado;
            element.Variante = 0;
          }
          arr.push(element);
        }
        this.getBudget = arr;
        this.getIVA.aprobado = this.getIVA.estimado;
        this.modifyBudget();
        this.saveIva();
        Notiflix.Notify.Success('Se aprobó correctamente el presupuesto');
      }
    );
  }
  varianteOperacion() {
    for (let index = 0; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      if (element.Total_Estimado <= 0 || element.Total_Estimado >= 0) {
        element.Variante = element.Total_Aprobado - element.Total_Estimado;
      } else if (element.Subtotal_Estimado || element.Subtotal_Estimado === 0) {
        element.Variante =
          element.Subtotal_Aprobado - element.Subtotal_Estimado;
      } else if (
        element.Gran_total_Estimado ||
        element.Gran_total_Estimado === 0
      ) {
        element.Variante =
          element.Gran_total_Aprobado - element.Gran_Total_Estimado;
      } else if (element.Importe_Estimado || element.Importe_Estimado === 0) {
        element.Variante = element.Importe_Aprobado - element.Importe_Estimado;
      }
    }
    this.totalFunction();
  }
  savePtoAprobado() {
    if (this.dataBudget.comentario === undefined) {
    } else {
      if (this.dataBudget.comentario.trim() === '') {
      } else {
        this.authService.isLogin().then(res => {
          const user: User = res;
          this.dataBudget.quienCambio = user.email;
        });
        this.sumarImporte();
      }
    }
  }
  cancelarPreview() {
    this.dataTable = [];
  }
  onFileChangeXML(ev) {
    const archivo = ev.target.files[0];
    const lector = new FileReader();
    lector.onload = e => {
      this.xmlToJson(e);
    };
    lector.readAsText(archivo);
  }
  xmlToJson(lector) {
    const res = lector.target.result;
    const parser = new DOMParser();
    const xml = parser.parseFromString(res, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.assignData(obj);
  }
  assignData(obj: any) {
    // console.log(obj);
    const arrBudget = [];
    let categories: any;

    if (Array.isArray(obj.budget.categories.category)) {
      categories = obj.budget.categories.category;
    } else {
      categories = Object.assign([], [obj.budget.categories.category]);
    }
    const accounts: any = obj.budget.accounts.account;
    const details: any = obj.budget.details.detail;

    categories.forEach(elementCategory => {
      const objCategory: Budget = {};
      objCategory.CodigoAgrupador = elementCategory.cNumber || '000';
      // objCategory.Cuenta = parseInt(elementCategory.cNumber, 10);
      objCategory.Descripcion = elementCategory.cDescription;
      objCategory.Total_Estimado = parseInt(elementCategory.cTotal, 10);
      arrBudget.push(objCategory);
      accounts.forEach((elementAccount, index) => {
        if (elementAccount.categoryID === elementCategory.cID) {
          if (elementAccount.aDescription) {
            const objAccount: Budget = {};
            let sumaSubcuenta = 0;
            objAccount.CodigoAgrupador =
              elementCategory.cNumber + '-' + elementAccount.aNumber || '000';
            objAccount.Descripcion = elementAccount.aDescription;
            objAccount.Subtotal_Estimado = parseInt(elementAccount.aTotal, 10);

            // Esto se ejecuta en caso de que tenga Fringes <<<<<
            if (elementAccount.aDescription === 'Total Fringes') {
              if (arrBudget[arrBudget.length - 1].Descripcion === 'FRINGE') {
                arrBudget[arrBudget.length - 1].Subtotal_Estimado = parseInt(
                  elementAccount.aTotal,
                  10
                );

                delete objAccount.Subtotal_Estimado;

                objAccount.Precio_Estimado = parseInt(
                  elementAccount.aTotal,
                  10
                );
                objAccount.Importe_Estimado = parseInt(
                  elementAccount.aTotal,
                  10
                );
                objAccount.Cantidad = 1;
                objAccount.Unidad = '';
                objAccount.TC = 1;

                objAccount.CodigoAgrupador =
                  arrBudget[arrBudget.length - 1].CodigoAgrupador + '-001';

                arrBudget.push(objAccount);
              }
            } else {
              arrBudget.push(objAccount);
            }

            details.forEach(elementDetails => {
              if (elementDetails.accountID === elementAccount.aID) {
                if (elementDetails.dDescription.length) {
                  const objDetail: Budget = {};
                  sumaSubcuenta = sumaSubcuenta + 1;
                  objDetail.CodigoAgrupador =
                    elementCategory.cNumber +
                      '-' +
                      elementAccount.aNumber +
                      '-' +
                      sumaSubcuenta.toString().padStart(3, '0') || '000';
                  objDetail.Descripcion = elementDetails.dDescription;
                  objDetail.Cantidad = parseInt(elementDetails.dAmount, 10);
                  if (elementDetails.dUnit.length) {
                    objDetail.Unidad = elementDetails.dUnit;
                  }
                  objDetail.TC = elementDetails.dX;
                  objDetail.Precio_Estimado = parseInt(
                    elementDetails.dRate,
                    10
                  );
                  objDetail.Importe_Estimado = parseInt(
                    elementDetails.dSubtotal,
                    10
                  );
                  objDetail.Acumulado = 0;
                  arrBudget.push(objDetail);
                }
              }
            });
          }
        }
      });
    });
    console.log(arrBudget);
    this.dataTable = arrBudget;
    this.addDataDB = Object.assign({}, arrBudget);
  }
  getIvaEstimado() {
    this.subscribeGetIva = this.budgetService
      .getIvaEstimado(this.idCompany, this.idProject)
      .subscribe(res => {
        const iva: any = res;
        if (res === undefined) {
          this.getIVAAcumulado = 0;
        } else {
          this.getIVAAcumulado = iva.acumulado;
          const objIva: any = res;
          this.getIVA = objIva;
        }
      });
  }
  getCtas() {
    this.budgetService
      .getCtas(this.idCompany, this.idProject)
      .subscribe(res => {
        if (res === undefined) {
        } else {
          this.cuentasTotales = res;
          this.totalFunction();
        }
      });
  }
  saveSumaCtas() {
    $('#sumas').modal('hide');
    this.cuentasTotales.TATLA = parseInt(this.cuentasTotales.TATLA, 10);
    this.cuentasTotales.TATLDE = parseInt(this.cuentasTotales.TATLDE, 10);
    this.cuentasTotales.TBTLPA = parseInt(this.cuentasTotales.TBTLPA, 10);
    this.cuentasTotales.TBTLPDE = parseInt(this.cuentasTotales.TBTLPDE, 10);
    this.cuentasTotales.TBTLPOSTA = parseInt(this.cuentasTotales.TBTLPOSTA, 10);
    this.cuentasTotales.TBTLPOSTDE = parseInt(
      this.cuentasTotales.TBTLPOSTDE,
      10
    );
    this.cuentasTotales.TBTLOA = parseInt(this.cuentasTotales.TBTLOA, 10);
    this.cuentasTotales.TBTLODE = parseInt(this.cuentasTotales.TBTLODE, 10);
    this.budgetService
      .saveSumaCtas(this.idCompany, this.idProject, this.cuentasTotales)
      .then(() => {
        Notiflix.Notify.Success('Se guardaron las cuentas correctamente');
        this.totalFunction();
        this.saveIva();
      });
  }
  saveIva() {
    this.budgetService.addIva(this.idCompany, this.idProject, this.getIVA);
  }
  totalFunction() {
    let sumaATLAprobado = 0;
    let sumaATLEstimado = 0;
    let sumaBTLPAprobado = 0;
    let sumaBTLPEstimado = 0;
    let sumaBTLPOSTAprobado = 0;
    let sumaBTLPOSTEstimado = 0;
    let sumaBTLOAprobado = 0;
    let sumaBTLOEstimado = 0;
    this.getBudget.forEach(element => {
      if (element.Total_Estimado >= 0 || element.Total_Estimado <= 0) {
        const segmentos = element.CodigoAgrupador.split('-');
        if (
          segmentos[0] >= this.cuentasTotales.TATLDE &&
          segmentos[0] <= this.cuentasTotales.TATLA
        ) {
          sumaATLAprobado = sumaATLAprobado + element.Total_Aprobado;
          sumaATLEstimado = sumaATLEstimado + element.Total_Estimado;
        } else if (
          segmentos[0] >= this.cuentasTotales.TBTLPDE &&
          segmentos[0] <= this.cuentasTotales.TBTLPA
        ) {
          sumaBTLPAprobado = sumaBTLPAprobado + element.Total_Aprobado;
          sumaBTLPEstimado = sumaBTLPEstimado + element.Total_Estimado;
        } else if (
          segmentos[0] >= this.cuentasTotales.TBTLPOSTDE &&
          segmentos[0] <= this.cuentasTotales.TBTLPOSTA
        ) {
          sumaBTLPOSTAprobado = sumaBTLPOSTAprobado + element.Total_Aprobado;
          sumaBTLPOSTEstimado = sumaBTLPOSTEstimado + element.Total_Estimado;
        } else if (
          segmentos[0] >= this.cuentasTotales.TBTLODE &&
          segmentos[0] <= this.cuentasTotales.TBTLOA
        ) {
          sumaBTLOAprobado = sumaBTLOAprobado + element.Total_Aprobado;
          sumaBTLOEstimado = sumaBTLOEstimado + element.Total_Estimado;
        }
      }
    });
    this.totales.ATLA = sumaATLAprobado;
    this.totales.ATLE = sumaATLEstimado;
    this.totales.BTLPA = sumaBTLPAprobado;
    this.totales.BTLPE = sumaBTLPEstimado;
    this.totales.BTLPOSTA = sumaBTLPOSTAprobado;
    this.totales.BTLPOSTE = sumaBTLPOSTEstimado;
    this.totales.BTLOA = sumaBTLOAprobado;
    this.totales.BTLOE = sumaBTLOEstimado;
    Notiflix.Notify.Info('No olvides guardar cambios antes de salir');
  }
  editPreview(data, i) {
    console.log(data, i);
    $('#modalEditPreview').modal('show');
    this.dataTableEdit = data;
    this.indexPrev = i;
  }
  openRowPrev() {
    $('#modalEditPreview').modal('hide');
    $('#modalAgregarPreview').modal('show');
    this.dataTableEdit = {};
  }
  addRowPrev() {
    Notiflix.Confirm.Show(
      'Agregar Fila',
      '¿Estas seguro?',
      'Si',
      'No',
      () => {
        this.dataTable.splice(this.indexPrev, 0, this.dataTableEdit);
        this.dataTable = [...this.dataTable];
        Notiflix.Notify.Success('Agregado correctamente');
        $('#modalAgregarPreview').modal('hide');
      },
      () => {}
    );
  }
  deleteRowPrev() {
    Notiflix.Confirm.Show(
      'Eliminar Fila',
      '¿Estas seguro?',
      'Si',
      'No',
      () => {
        this.dataTable.splice(this.indexPrev, 1);
        this.dataTable = [...this.dataTable];
        Notiflix.Notify.Success('Eliminado correctamente');
        $('#modalEditPreview').modal('hide');
      },
      () => {}
    );
  }
  onFileChangeTXT(ev) {
    // console.log(ev);
    const archivo = ev.target.files[0];
    if (!archivo) {
      return;
    }
    const lector = new FileReader();
    lector.onload = e => {
      const ee: any = e;
      const contenido = ee.target.result;
      this.convertToObject(contenido);
    };
    lector.readAsText(archivo);
  }
  convertToObject(contenido) {
    let numeroDetalle = 0;
    let cuenta = '';
    let subCuenta = '';
    const arreglo = contenido.split(/\r?\n/).map(linea => {
      // return linea.split(',');
      const arr = linea.split(/\t/);
      console.log(arr);
      if (arr.length === 6 && arr[0]) {
        if (arr[0].split('-')[0]) {
          cuenta = arr[0].split('-')[0]; // En diferente caso activar esto en el caso de 0000-00
        } else {
          cuenta = arr[0]; // Opcion Normal
        }
        const obj: Budget = {
          CodigoAgrupador: cuenta.toString().padEnd(4, '0'),
          Descripcion: arr[1],
          Total_Estimado: parseInt(arr[5], 10),
        };
        return obj;
      } else if (arr.length === 5 && arr[2]) {
        numeroDetalle = 0;
        if (arr[2].split('-')[1]) {
          subCuenta = arr[2].split('-')[1]; // En diferente caso activar estoen el caso de 0000-00
        } else {
          subCuenta = arr[2]; // Opcion Normal
        }
        const obj: Budget = {
          CodigoAgrupador:
            cuenta.toString().padEnd(4, '0') + // Cambios para seleccionar el numero de digitos de cada segmento
            '-' +
            subCuenta.toString().padEnd(4, '0'), // Cambios para seleccionar el numero de digitos de cada segmento
          Descripcion: arr[3],
          Subtotal_Estimado: parseInt(arr[4], 10), // Cambios para seleccionar el numero de digitos de cada segmento
        };
        return obj;
      } else if (arr.length === 13 && arr[7]) {
        numeroDetalle += 1;
        const obj: Budget = {
          CodigoAgrupador:
            cuenta.toString().padEnd(4, '0') + // Cambios para seleccionar el numero de digitos de cada segmento
            '-' +
            subCuenta.toString().padEnd(4, '0') + // Cambios para seleccionar el numero de digitos de cada segmento
            '-' +
            numeroDetalle.toString().padStart(3, '0'), // Cambios para seleccionar el numero de digitos de cada segmento
          Descripcion: arr[7],
          Cantidad: parseInt(arr[8], 10),
          Unidad: arr[9],
          TC: parseInt(arr[10], 10),
          Precio_Estimado: parseInt(arr[11], 10),
          Importe_Estimado: parseInt(arr[12], 10),
          Acumulado: 0,
          AcumuladoTemporal: 0,
          AcumuladoComprometido: 0,
        };
        return obj;
      }
    });
    const arrFilter = [] as any;
    arreglo.forEach(element => {
      if (element !== undefined) {
        arrFilter.push(element);
      }
    });
    // console.log(arreglo);
    console.log(arrFilter);

    // Correccion de FRINGES
    for (let index = 0; index < arrFilter.length; index++) {
      const elementAnterior = arrFilter[index - 1];
      const element = arrFilter[index];
      if (
        element.Descripcion === 'Total Fringes' &&
        elementAnterior.Descripcion === 'FRINGE'
      ) {
        elementAnterior.Subtotal_Estimado = element.Subtotal_Estimado;
        element.Precio_Estimado = element.Subtotal_Estimado;
        element.Importe_Estimado = element.Subtotal_Estimado;
        element.Unidad = 'Unit';
        element.Cantidad = 1;
        element.TC = 1;
        delete element.Subtotal_Estimado;
        element.CodigoAgrupador = elementAnterior.CodigoAgrupador + '-001';
      } else if (
        element.Descripcion === 'Total Fringes' &&
        elementAnterior.Descripcion !== 'FRINGE'
      ) {
        arrFilter.splice(index, 1);
      }
    }

    this.dataTable = arrFilter;
    this.addDataDB = Object.assign({}, arrFilter);
  }
  convertirArray(arrayBudget) {
    console.log(arrayBudget);
    const arr = [] as any;

    for (let index = 0; index < arrayBudget.length; index++) {
      const element: Budget = arrayBudget[index];
      const segmentos = element.CodigoAgrupador.toString().split('-');
      const arr2 = [] as any;

      if (segmentos.length === 1) {
        for (let index2 = 0; index2 < arrayBudget.length; index2++) {
          const element2 = arrayBudget[index2];
          const segmentos2 = element2.CodigoAgrupador.toString().split('-');
          const arr3 = [] as any;
          if (segmentos2.length === 2 && segmentos[0] === segmentos2[0]) {
            for (let index3 = 0; index3 < arrayBudget.length; index3++) {
              const element3 = arrayBudget[index3];
              const segmentos3 = element3.CodigoAgrupador.split('-');
              const arr4 = [] as any;

              if (
                segmentos3.length === 3 &&
                segmentos2[0] === segmentos3[0] &&
                segmentos2[1] === segmentos3[1]
              ) {
                for (let index4 = 0; index4 < arrayBudget.length; index4++) {
                  const element4 = arrayBudget[index4];
                  const segmentos4 = element4.CodigoAgrupador.split('-');

                  if (
                    segmentos4.length === 4 &&
                    segmentos3[0] === segmentos4[0] &&
                    segmentos3[1] === segmentos4[1] &&
                    segmentos3[2] === segmentos4[2]
                  ) {
                    arr4.push(element4);
                  }
                }
                element3.children = arr4;
                arr3.push(element3);
              }
            }

            element2.children = arr3;
            arr2.push(element2);
          }
        }
        element.children = arr2;
        arr.push(element);
      }
    }
    this.budgetArrays = arr;
    return arr;
  }
  exportTableToExcel(id) {
    var type = 'xlsx';
    var elt = document.getElementById(id);
    var wb = XLSX.utils.table_to_book(elt /*{sheet:"Sheet JS"}*/);
    return XLSX.writeFile(wb, undefined || 'Budget.' + (type || 'xlsx'));
  }
  agrupar() {
    $('#modalEditBudget').modal('hide');
    $('#modalAgrupar').modal('show');
    this.cuentasAgrupadas = [];
    let activar = false;
    for (let index = this.index + 1; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segments = element.CodigoAgrupador.split('-');
      const segentsData = this.dataBudget.CodigoAgrupador.split('-');
      if (
        segments[0] === segentsData[0] &&
        segments[1] === segentsData[1] &&
        segments.length === 3
      ) {
        element.index = index;
        element.seleccionado = false;
        this.cuentasAgrupadas.push(element);
        activar = true;
      } else {
        if (activar) {
          break;
        }
      }
    }
    console.log(this.cuentasAgrupadas);
  }
  sumarAgrupados() {
    let sumaCantidad = 0;
    let sumaTC = 0;
    let sumaPrecio = 0;
    let sumaImporte = 0;
    let childrens = [] as any;

    if (this.dataBudget.children) {
      childrens = this.dataBudget.children;
    }

    this.cuentasAgrupadas.forEach(element => {
      if (element.seleccionado) {
        element.CodigoAgrupador =
          this.dataBudget.CodigoAgrupador +
          '-' +
          (childrens.length + 1).toString().padStart(2, '0');
        sumaCantidad += element.Cantidad;
        // sumaTC += parseFloat(element.TC);
        sumaPrecio += element.Precio_Estimado;
        sumaImporte += element.Importe_Estimado;
        childrens.push(element);
      }
    });

    console.log(childrens);
    this.dataBudget.children = childrens;
    this.dataBudget.Cantidad = sumaCantidad;
    this.dataBudget.TC = 1;
    this.dataBudget.Precio_Estimado = sumaPrecio;
    this.dataBudget.Importe_Estimado = sumaImporte;
    this.sumarImporte();
    $('#modalAgrupar').modal('hide');
  }
  desagrupar() {
    $('#modalEditBudget').modal('hide');
    $('#modalDesagrupar').modal('show');
    this.cuentasAgrupadas = [];
    let activar = false;
    for (let index = this.index + 1; index < this.getBudget.length; index++) {
      const element = this.getBudget[index];
      const segments = element.CodigoAgrupador.split('-');
      const segentsData = this.dataBudget.CodigoAgrupador.split('-');
      if (
        segments[0] === segentsData[0] &&
        segments[1] === segentsData[1] &&
        segments[2] === segentsData[2] &&
        segments.length === 4
      ) {
        element.index = index;
        element.seleccionado = true;
        this.cuentasAgrupadas.push(element);
        activar = true;
      } else {
        if (activar) {
          break;
        }
      }
    }
    console.log(this.cuentasAgrupadas);
  }
  restarDesagrupados() {
    const cuentaPadre = this.dataBudget.CodigoAgrupador;
    let numeroCuentaPadre = parseFloat(cuentaPadre.split('-')[2]);

    this.cuentasAgrupadas.forEach(element => {
      const segmentos = cuentaPadre.split('-');
      numeroCuentaPadre += 1;
      const numeroDetalle = numeroCuentaPadre.toString().padStart(3, '0');
      const codigo = (element.CodigoAgrupador =
        segmentos[0] + '-' + segmentos[1] + '-' + numeroDetalle);

      this.getBudget[element.index].CodigoAgrupador = codigo;
      this.getBudget[element.index].children = [];
      this.getBudget[element.index].nivel = 3;

      this.dataBudget.Cantidad -= element.Cantidad;
      this.dataBudget.Precio_Estimado -= element.Precio_Estimado;
      this.dataBudget.Importe_Estimado -= element.Importe_Estimado;
    });
    this.dataBudget.nivel = 3;
    this.dataBudget.children = [];
    this.sumarImporte();
    $('#modalDesagrupar').modal('hide');
  }
  selectCta() {
    $('#editDataProductor').modal('show');
  }
  ngOnDestroy() {
    if (this.subscribeGetBudget) {
      this.subscribeGetBudget.unsubscribe();
    }
    this.subscribeAuthUser.unsubscribe();
    if (this.subscribeIvaAcumulado) {
      this.subscribeIvaAcumulado.unsubscribe();
    }
    this.subscriberGetProject.unsubscribe();
  }
}
