import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../models/user';
import { AuthService } from '../../../services/auth.service';
import { EmpresasService } from '../../../services/empresas.service';
import { Router, NavigationEnd } from '@angular/router';
import Notiflix from 'notiflix-angular';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  dataUser = {} as User;
  user = {} as User;
  userDB = {} as User;
  editingProfile = {} as User;
  file;
  uploadF;
  urlImage: Observable<string>;
  urlImageDefault = '../../../../assets/logos/noPhoto.jpg';
  userType;
  isAdmin = false;
  nameProject;
  idCompany = '';
  idProject = '';
  project = {} as any;
  inter: any;

  subscriberURL: Subscription;
  subscriberRoleUser: Subscription;
  subscriberProjectActual: Subscription;

  constructor(
    private authService: AuthService,
    private empresaService: EmpresasService,
    private storage: AngularFireStorage,
    private router: Router
  ) {}
  ngOnInit() {
    this.timeOut();
    let url = this.router.parseUrl(this.router.url).root.children.primary
      .segments;
    if (url.length >= 5 && url[2].path === 'project') {
      this.idCompany = url[1].path;
      this.idProject = url[3].path;
      this.getNameProject2(this.idCompany, this.idProject);
    } else if (url.length < 4) {
      this.project.name = '';
      this.project.monedaLocal = '';
    }

    let idProjectAnterior = '';
    this.subscriberURL = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        url = this.router.parseUrl(this.router.url).root.children.primary
          .segments;
        if (url.length >= 5 && url[2].path === 'project') {
          this.idCompany = url[1].path;
          this.idProject = url[3].path;
          const nuevoIdProject = url[3].path;
          if (idProjectAnterior !== nuevoIdProject) {
            idProjectAnterior = nuevoIdProject;
            this.getNameProject2(this.idCompany, this.idProject); //Se ejecuta nuevamente la funcion para traer el nombre
          }
        } else if (url.length < 4) {
          // this.project.name = '';
          // this.project.monedaLocal = '';
        }
      }
    });
    this.authService.isLogin().then(resultado => {
      this.dataUser = resultado;
      this.subscriberRoleUser = this.authService
        .roleUser(this.dataUser.uid)
        .subscribe(res => {
          this.user = res[0];
          this.userType = res[0].userType;
          if (this.userType === 'Administrador') {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        });
    });
  }
  openEditProfile() {
    this.editingProfile.displayName = this.user.displayName;
  }
  uploadFile(event) {
    if (this.user.pathImageProfile) {
      this.authService.deleteImageUser(this.user);
    }
    const fileInput = event.target.files[0];
    const fileType = fileInput.type;
    const fileSize = fileInput.size;
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    if (!allowedExtensions.exec(fileType) || fileSize >= 3000000) {
      alert(
        'Por favor agrega unicamente archivos con extension .jpeg/.jpg/.png/ y tamaño maximo de 3MB '
      );
      this.uploadF = '';
      document.getElementById('labelFile').innerHTML = 'Choose file';
      (<any>document.getElementById('image')).value = '';
    } else {
      this.uploadF = event.target.files[0];
      document.getElementById('labelFile').innerHTML = this.uploadF.name;
      const id = Math.random().toString(36).substring(2);
      const filePath = `profiles/${this.dataUser.email}/${id}`;
      const path: User = {};
      path.pathImageProfile = filePath;
      path.id = this.user.id;
      this.authService.editUserDB(path);
      const ref = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.uploadF);
      task.then(() => {
        ref.getDownloadURL().subscribe(resultado => {
          const objURL: User = {};
          objURL.photoURL = resultado;
          this.authService.editProfile(objURL).then(() => {
            this.ngOnInit();
          });
        });
      });
      this.file = document.getElementById('labelFile').innerHTML =
        this.uploadF.name;
    }
  }
  editProfile() {
    this.authService.editProfile(this.editingProfile);
    const user: User = this.user;
    user.displayName = this.editingProfile.displayName;
    this.authService.editUserDB(user);
    $('#modalProfile').modal('hide');
  }
  deleteImageUser() {
    const objDelete: User = {};
    objDelete.photoURL = '';
    this.authService.editProfile(objDelete);
    this.authService.deleteImageUser(this.user);
    const objDeleteDB: User = this.user;
    objDeleteDB.pathImageProfile = '';
    this.authService.editUserDB(objDeleteDB);
  }
  onLogout() {
    this.authService.logOutUser();
  }
  toggle() {
    $('#sidebar').toggleClass('active');
    $('.overlay').toggleClass('active');
  }
  redirect() {
    this.router.navigate(['/dashboard/menuAdmin']);
  }
  getNameProject2(idCompany, idProject) {
    this.subscriberProjectActual = this.empresaService
      .getProjectActual(idCompany, idProject)
      .subscribe(res => {
        this.project = res;
      });
  }

  // Tiempo de inactividad
  timeOut() {
    let time = 0;

    // Reinicio contador a 0 cada vez que se mueve el mouse o se usa el teclado
    $(document).ready(function () {
      $(this).mousemove(() => {
        time = 0;
      });
      $(this).keypress(() => {
        time = 0;
      });
    });

    // Ejecuta la funcion logOutUser() al cumplir 15 minutos inactivo
    this.inter = setInterval(() => {
      time++;
      if (time === 900) {
        this.authService.logOutUser();
        clearInterval(this.inter);
        Notiflix.Report.Failure(
          'Sesion cerrada',
          'Tu sesion a caducado por inactividad',
          'Aceptar'
        );
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriberRoleUser.unsubscribe();
    this.subscriberURL.unsubscribe();
    if (this.subscriberProjectActual) {
      this.subscriberProjectActual.unsubscribe();
    }
    clearInterval(this.inter);
  }
}
