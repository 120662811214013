// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAP9TR1FwBUZqu07nhsmJodm2nwdFeUt-I',
    authDomain: 'enconexion-4a1e8.firebaseapp.com',
    databaseURL: 'https://enconexion-4a1e8.firebaseio.com',
    projectId: 'enconexion-4a1e8',
    storageBucket: 'enconexion-4a1e8.appspot.com',
    messagingSenderId: '52948250070',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
