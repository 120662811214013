import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/services/project.service';
import { ChecksService } from 'src/app/services/checks.service';
import { Comprobaciones } from 'src/app/models/comprobaciones';

declare var $;

@Component({
  selector: 'app-checks-report',
  templateUrl: './checks-report.component.html',
  styleUrls: ['./checks-report.component.css'],
})
export class ChecksReportComponent implements OnInit {
  tipoReporte = 'periodo';
  tipoReportePeriodo = true;
  tipoReporteNumero = false;
  idProject;
  idCompany;
  periods = [] as any;
  periodoSeleccionado;
  numeros = {};
  comprobaciones = [] as any;
  comprobantes;

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private checksService: ChecksService
  ) {}

  ngOnInit() {
    const url = this.router.url;
    // console.log(url);

    const segmentos = this.router.parseUrl(url);
    // console.log(segmentos);

    this.idCompany = segmentos.root.children.primary.segments[1].path;
    // console.log(this.idCompany);

    this.idProject = segmentos.root.children.primary.segments[3].path;
    // console.log(this.idProject);

    this.getPeriods();
  }

  checkComprobacion() {
    if (this.tipoReporte === 'periodo') {
      this.tipoReportePeriodo = true;
      this.tipoReporteNumero = false;
    } else if (this.tipoReporte === 'numero') {
      this.tipoReportePeriodo = false;
      this.tipoReporteNumero = true;
    }
  }

  getPeriods() {
    this.projectService
      .getPeriods(this.idCompany, this.idProject)
      .subscribe(res => {
        this.periods = Object.assign([], res);
        // console.log(this.periods);
      });
  }

  reportePeriodo() {
    this.checksService
      .reportePeriodo(this.idCompany, this.idProject, this.periodoSeleccionado)
      .subscribe(res => {
        this.comprobaciones = res;
        // console.log(res);
      });
  }
  reporteNumero() {
    // console.log(this.numeros);
    this.checksService
      .reporteNumero(this.idCompany, this.idProject, this.numeros)
      .subscribe(res => {
        this.comprobaciones = res;
        console.log(this.comprobaciones);
      });
  }
  checkSelect(obj) {
    $('#Checks').modal('show');

    this.periodoSeleccionado = obj;
    this.comprobantes = this.periodoSeleccionado.comprobantes;
    console.log(this.comprobantes);
  }
  exportAsXLSX(): void {
    const arr = [] as any;

    this.comprobaciones.forEach(element => {
      let obj = {
        NumeroSol: element.countCheck,
        Deudor: element.deudor,
        Deducible: element.deducible,
        Importe: element.importe,
        IVA: element.iva,
        Total: element.total,
      } as any;
      arr.push(obj);
      obj = {} as any;
    });

    this.checksService.exportAsExcel(arr, 'comprobaciones');
  }
}
