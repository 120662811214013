import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import Notiflix from 'notiflix-angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

declare var $;

@Component({
  selector: 'app-premissions',
  templateUrl: './premissions.component.html',
  styleUrls: ['./premissions.component.css'],
})
export class PremissionsComponent implements OnInit, OnDestroy {
  binding;
  permisos = [];
  permisos1 = {};
  arrPermisos = [];
  selectedUser;
  namePerfil: string;
  perfiles = [] as any;
  perfilSeleccionado = {} as any;
  routes = [] as any;

  namePages = [] as any;
  Pages = [] as any;

  subscriberGetPages;

  subscriptionGetPremissions: Subscription;
  subscriptionGetPerfil: Subscription;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.getPremissions();
    let numArray = 0;
    for (
      let index = 0;
      index < this.router.config[1].children.length;
      index++
    ) {
      const element = this.router.config[1].children[index];
      if (element.path === ':id/project/:id') {
        numArray = index;
        break;
      }
    }
    this.routes = this.router.config[1].children[numArray].children;
    console.log(this.routes);
    this.getPerfiles();
  }

  getPremissions() {
    this.subscriptionGetPremissions = this.authService
      .getPremissions()
      .subscribe(res => {
        this.permisos = Object.assign([], res);
      });
  }

  getPerfiles() {
    this.subscriptionGetPerfil = this.authService
      .getPerfiles()
      .subscribe(res => {
        this.perfiles = res;
      });
  }

  updateUser(user) {
    for (let index = 0; index < this.routes.length; index++) {
      const element = this.routes[index];
      if (element.page === user.page && user.permiso === true) {
        element.users.push(this.selectedUser);
      }
      if (element.page === user.page && user.permiso === false) {
        const i = element.users.indexOf(this.selectedUser);
        if (i !== -1) {
          element.users.splice(i, 1);
        }
      }
    }

    const obj = Object.assign({}, this.routes);

    this.authService
      .updateUser(obj)
      .then(() => {
        Notiflix.Notify.Success('Actualizado correctamente');
      })
      .catch(() => {
        Notiflix.Notify.Failure('Ocurrio un error');
      });
  }

  selectionUser() {
    this.arrPermisos = [];
    for (let index = 0; index < this.routes.length; index++) {
      const elementPermisos = this.routes[index];
      const obj = {} as any;
      obj.name = elementPermisos.name;
      obj.page = elementPermisos.page;
      obj.permiso = false;
      elementPermisos.users.forEach(elementUsers => {
        if (elementUsers === this.selectedUser) {
          obj.permiso = true;
        }
      });
      this.arrPermisos.push(obj);
    }
  }

  savePerfil() {
    const arr = [] as any;
    this.routes.forEach(element => {
      if (element.permiso === true) {
        const obj = {} as any;
        obj.name = element.data.title;
        obj.page = element.path;
        arr.push(obj);
      }
    });

    if (this.namePerfil && arr.length > 0) {
      const objPerfil = {
        name: this.namePerfil,
        pages: arr,
      };

      // console.log(objPerfil);

      this.authService
        .addPerfiles(objPerfil)

        .then(Notiflix.Notify.Success('Se guardo correctamente'));
      $('#modelId').modal('hide');
    } else {
      Notiflix.Notify.Failure(
        'Agrega un nombre y selecciona al menos una pagina'
      );
    }
    this.namePerfil = '';
    this.routes.forEach(element => {
      element.permiso = false;
    });
  }

  selectPerfil(obj) {
    $('#modalViewPerfil').modal('show');
    this.perfilSeleccionado = obj;
    // console.log(this.perfilSeleccionado);
  }
  editPerfil(obj) {
    $('#modalEditPerfil').modal('show');

    this.perfilSeleccionado = obj;

    this.routes.forEach(element => {
      this.perfilSeleccionado.pages.forEach(element1 => {
        // console.log(element);
        if (element.path === element1.page) {
          element.permiso = true;
        }
      });
    });
  }

  saveEditPerfil() {
    const arr = [] as any;
    this.routes.forEach(element => {
      if (element.permiso === true) {
        const obj = {} as any;
        obj.name = element.data.title;
        obj.page = element.path;
        arr.push(obj);
      }
    });

    const objPerfil = {
      pages: arr,
    };

    // console.log(objPerfil);

    this.authService
      .updatePerfiles(objPerfil, this.perfilSeleccionado.id)

      .then(Notiflix.Notify.Success('Se Actualizo correctamente'));
    $('#modalEditPerfil').modal('hide');

    this.namePerfil = '';
    this.routes.forEach(element => {
      element.permiso = false;
    });
  }

  closeEditPerfil() {
    $('#modalEditPerfil').modal('hide');

    this.namePerfil = '';
    this.routes.forEach(element => {
      element.permiso = false;
    });
  }

  deletePerfil(perfil) {
    Notiflix.Confirm.Show(
      '¿Estas seguro?',
      'Estas por eliminar un perfil',
      'Si',
      'No',
      () => {
        this.authService.deletePerfil(perfil.id).then(() => {
          Notiflix.Notify.Success('Perfil eliminado correctamente');
        });
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionGetPremissions.unsubscribe();
    this.subscriptionGetPerfil.unsubscribe();
  }
}
