import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import Notiflix from 'notiflix-angular';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit, OnDestroy {
  constructor(private authService: AuthService) {}
  getUsers: any = [] as User;
  getUid: any = {};
  editUser: any = {} as User;
  addUsers = {};
  pass = '';
  perfil = '';
  perfiles = [] as any;

  subscriptionGetProfiles: Subscription;

  ngOnInit() {
    this.authService.getUser().subscribe(res => {
      this.getUsers = res;
    });
    this.getProfiles();
  }

  getProfiles() {
    this.subscriptionGetProfiles = this.authService
      .getPerfiles()
      .subscribe(res => {
        this.perfiles = res;
      });
  }

  openEdit(user) {
    this.editUser = user;
  }
  editUserDB() {
    this.authService.editUserDB(this.editUser);
    $('#editUser').modal('hide');
  }
  generatePass() {
    this.authService.generateCode().then(res => {
      console.log(res);
      this.pass = res.id;
      Notiflix.Notify.Success('Se genero correctamente');
    });
  }

  updateUser(user) {
    this.authService.updateUser(user).then(res => {
      Notiflix.Notify.Success('Usaurio actualizado correctamente');
    });
  }

  deleteUser(user: User) {}

  ngOnDestroy() {
    this.subscriptionGetProfiles.unsubscribe();
  }
}
